import { ProductAttachment } from "../product.model";

export class MetadataColour {
   constructor(status: boolean, sortOrder: number) {
      this.status = status;
      this.sortOrder = sortOrder;
   }
   id: string;
   name: string;
   shortName: string;
   pantone:string;
   status: boolean;
   sortOrder: number;
   swatchList:ProductAttachment[]=[];
   // FrontEnd Transient
   hasErrors: boolean = false;
}
