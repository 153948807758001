import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from 'src/app/shared/model/user.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/service/auth.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { Printer, VendorInfo } from 'src/app/shared/model/vendors/printer.model';
import { SageService } from 'src/app/shared/service/sage/sage.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { HttpClient } from '@angular/common/http';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { TransformPipe } from 'src/app/transform.pipe';

@Component({
   selector: 'app-printer',
   templateUrl: './printer.component.html',
   styleUrls: ['./printer.component.css']
})
export class PrinterComponent implements OnInit {

   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;

   constructor(private httpClient: HttpClient,private metadataService: MetadataService,private authService: AuthService, private router: Router, private vendorService: VendorsService,
      private activatedRoute: ActivatedRoute, private toastrService: ToastrService, private sageService:SageService,private dialog: MatDialog,
      private transformPipe: TransformPipe)
       { }

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   selectedPrinterId: string;
   printer: Printer;
   sageIntegrationViewList:any[]=[];
   countriesWithRegionsDetails: any;
   currencyListFromMetadata: string[] = [];


   ngOnInit() {
      //this.getCompanyDetails();
      this.handleSubscriptions();
      this.evaluateAction();
      this.populateCountryDetails();
      this.loadCurrencyListFromMetadata();
      window.scrollTo(0, 0);
   }
   loadCurrencyListFromMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndType('SALES', 'CURRENCY').subscribe(response => {
         this.currencyListFromMetadata = response.responsePayload;
      }, err => {
         console.error('Error while loading Currency List from Metadata : ' + JSON.stringify(err));
      });
   }
   populateCountryDetails() {
      if (this.countriesWithRegionsDetails === null || this.countriesWithRegionsDetails === undefined) {
         this.httpClient.get("assets/country-region-data.json").subscribe(data => {
            this.countriesWithRegionsDetails = data;
         });
      }
   }
   async getCompanyDetails() {
     await this.sageService.getAvailableCompanies().toPromise().then(
         response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.sageIntegrationViewList = response.responsePayload;
            }
         }
      );
   }

   handleSubscriptions() {
      // Subscriptions
      this.userAccess = new UserAccess();
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });

      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
   }


   onSubmit() {
      if (this.editable && !this.createAction) {
         this.updatePrinter();
      } else {
         this.createPrinter();
      }
   }


   getPrinter() {
      let printerId = this.activatedRoute.snapshot.paramMap.get('id');
      this.selectedPrinterId = printerId;
      if (printerId) {
         this.vendorService.getPrinterById(printerId).subscribe(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               this.printer = response.responsePayload;
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }


   createPrinter() {
      this.vendorService.createPrinter(this.printer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.router.navigate(['printer/list']);
         } else {
            console.error("Printer Component - create printer failed...", response.responseStatus.message);

            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updatePrinter() {
      this.vendorService.updatePrinter(this.printer).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.toastrService.success(response.responseStatus.message);
            this.getPrinter();
            this.editable = false;
            this.viewAction = true;
            //this.router.navigate(['printer/list']);
         } else {
            console.error("Printer Component - update Printer failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }


   async evaluateAction() {
      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
         this.getPrinter();
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.createAction = true;
         this.editable = true;
         await this.getCompanyDetails();
         this.printer=new Printer();
         this.retrievePrinterDetails('Printer',this.sageIntegrationViewList,this.printer);
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.editable = true;
         this.createAction = false;
         this.getPrinter();
      }
   }

   retrievePrinterDetails(type:string,sageIntegrationViewList:any[],printer:Printer) {
      let companyList:any[]=[];
      let existingCompanyCodes=printer.vendorInfoList.map(x=>x.companyCode);
      
      sageIntegrationViewList.forEach(comp => {
         if (!existingCompanyCodes.includes(comp.companyCode)) {
            let entry: any = {};
            entry.id = comp.companyCode;
            entry.value = this.transformPipe.transform('company.name.' + comp.companyCode);
            companyList.push(entry);
         }
      });
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    disableClose: true,
    data: {
      modalType:'DROPDOWN_AND_TEXTINPUT',
      maxlength:150,
      textInputRegex:'^[a-zA-Z][0-9]+$',
      textInputDefault:'',
      textInputRegexError:type+'\'s vendor code is invalid. The format must be X00000, for e.g. A00512',
      title: 'Add Printer',
      message: 'To add a '+type+', please select the company & provide the '+type+'\'s vendor code', 
      confirmBtnText: 'Add '+type,
      cancelBtnText:'Cancel',
      detailsRequired: true,
      dropdownList: companyList
    }
  });
  confirmDialog.afterClosed().subscribe(response => {
    if(response){
      let vendorCode=response.data.detailsText;
      let companyCode=response.data.dropdownSelection.id;
      if(!printer.vendorInfoList || printer.vendorInfoList===null){
         printer.vendorInfoList=[];
      }
      this.vendorService.retrieveVendorDetails(companyCode,vendorCode).subscribe(response=>{
         if(response.responseStatus.status==='SUCCESS'){
            let vendorDetails:any[]=response.responsePayload.value;
            if(!printer.id){
               printer.name=vendorDetails[0].vendorName;
            }
            let vendorInfo:VendorInfo=new VendorInfo();
            if(vendorDetails[0].active==='Active'){
               vendorInfo.active=true;
               if(!printer.id){
                  printer.status=true;
               }

            }
            vendorInfo.vendorType=type;
            vendorInfo.companyCode=companyCode;
            vendorInfo.vendorCode=vendorCode;
            vendorInfo.vendorName=vendorDetails[0].vendorName;
            printer.vendorInfoList.push(vendorInfo);
         }else{
            this.toastrService.error(response.responseStatus.message);
            if(this.createAction){
               this.goBack();
            }
         }
      },err=>{
         this.toastrService.error(err);
         if(this.createAction){
            this.goBack();
         }
      })
    }else{
      if(this.createAction){
         this.goBack(); 
      }
    }
  });
  
   }

   goBack() {
      this.router.navigate(['printer/list']);
   }

   edit(printerId: string) {
      this.vendorService.getPrinterById(printerId).subscribe(response => {
         this.printer = response.responsePayload;
      });
      this.createAction = false;
      this.viewAction = false;
      this.editable = true;

   }

   cancelEdit() {
      this.getPrinter();
      this.editable = false;
      this.viewAction = true;
   }

   addCompanyVendorDetails(){
      this.printer.vendorInfoList
   }

   async addSageDetails(printer:Printer){
      if(!printer.vendorInfoList || printer.vendorInfoList===null){
         printer.vendorInfoList=[];
      }
      await this.getCompanyDetails();
      this.retrievePrinterDetails('Printer',this.sageIntegrationViewList,this.printer);
      
   }
}
