import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Licensor, LicensorSample, LicensorSampleDetails } from 'src/app/shared/model/licensor.model';
import { Brand } from 'src/app/shared/model/brand.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ContactDetails } from 'src/app/shared/model/contact-details.model';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { LinkDetails } from 'src/app/shared/model/link-details.model';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Metadata } from 'src/app/shared/model/metadata.model';
import { ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { GroupContactDetails } from 'src/app/shared/model/group-contact-details.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { LinkDetailsCredential } from 'src/app/shared/model/link-details-credential.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
   selector: 'app-licensor',
   templateUrl: './licensor.component.html',
   styleUrls: ['./licensor.component.css']
})

export class LicensorComponent implements OnInit {

   constructor(private fileHanldingService:FileHandlingService,private authService:AuthService,private sharedService: SharedService, private service: LicensorService, 
      private activatedRoute: ActivatedRoute, private location: Location, private toastrService: ToastrService, private metadataService: MetadataService,
      private dialog: MatDialog) { }
   createAction: boolean;
   viewAction: boolean;
   licensor_created: boolean = false;
   licensor: Licensor;
   newBrand: Brand = null;
   editable: boolean = false;
   filteredBrandList: Brand[] = [];
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   contactTypeList = [];
   addEditContactDetail: ContactDetails = new ContactDetails;
   addEditGroupContactDetails: GroupContactDetails;
   linkTypeList = [];
   metadata: Metadata = new Metadata;
   selectSampleDetailsIndex:number=0;
   defaultAllSampleSizeList: LicensorSample[];
   selectedBrandIdForLicensorSample: string;
   brandListForLicensorSample: Brand[] = [];
   brandListForLinkDetails: Brand[] = [];
   anyBrandExists = false;
   filesUploadPostUpdate:File[]=[];
   contractFilesUploadPostUpdate:File[]=[];
   lettersFilesUploadPostUpdate:File[]=[];
   artistSignOffFilesUploadPostUpdate:File[]=[];
   factoryFormsFilesUploadPostUpdate:File[]=[];
   selectedLinkDetails: LinkDetails;
   viewContactDetailsModal:boolean=false;

   //@ViewChild('addEditContactForm') addEditContactForm: any;
   @ViewChild('addEditLinkForm') addEditLinkForm: any;

   ngOnInit() {
      this.handleSubscriptions();
      this.evaluateAction();
      this.resetBrand();
      this.licensor = new Licensor;
      this.loadDefaultSampleSizeList();
      if (this.viewAction) {
         this.setMetadata();
         this.loadLicensorWithBrands(this.licensor.id);
      }
      if (this.createAction) {
      }
      window.scrollTo(0, 0);
      this.loadContactTypes();
      this.loadLinkTypes();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
   }

   evaluateAction() {
      const action = this.activatedRoute.snapshot.paramMap.get('action');
      if (action === 'create') {
         this.createAction = true;
         this.editable = true;
      } else if (action === 'view') {
         this.viewAction = true;
      }
   }

   edit() {
      this.editable = true;
      this.createAction = false;
      this.viewAction = false;
      this.filterBrandListForLicensorSample();
   }

   cancelEdit() {
      this.editable = false;
      this.createAction = false;
      this.viewAction = true;
      this.loadLicensorWithBrands(this.licensor.id);
   }

   setMetadata() {
      this.licensor.id = this.activatedRoute.snapshot.paramMap.get('id');
   }

   resetBrand() {
      this.newBrand = new Brand;
   }

   createOrUpdateLicensor() {
      this.populateLocalFileList();
      if (this.editable && !this.createAction) {
         this.updateLicensor();
      } else {
         this.createLicensor()
      }
   }
   
   createLicensor() {
      this.service.createLicensor(this.licensor).subscribe(res => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         let status: ServiceStatus = res as ServiceStatus;
         if (serviceStatus.status === 'SUCCESS') {
            this.licensor_created = true;
            if (serviceStatus.id !== null) {
               this.licensor.id = serviceStatus.id;
               this.uploadAttachments();
               this.editable = false;
               this.createAction = false;
               this.viewAction = true;
            }
         } else {
            this.toastrService.error(status.message);
         }
         this.toastrService.success(status.message);
      }, err => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Licensor Create Error : ' + JSON.stringify(err) + ' with status - ');
      });

   }

   updateLicensor() {
      //
      this.service.updateLicensor(this.licensor).subscribe(res => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         let status: ServiceStatus = res as ServiceStatus;
         if (serviceStatus.status === 'SUCCESS') {
            if (serviceStatus.id !== null) {
               this.licensor.id = serviceStatus.id;
               this.editable = false;
               this.createAction = false;
               this.viewAction = true;
               this.loadLicensorWithBrands(this.licensor.id,true);
            }
         } else {
            this.toastrService.error(status.message);
         }
         this.toastrService.success(status.message);
      }, err => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Licensor Update Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   /*****File Handling Starts */ 
   uploadAttachments() {
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.licensorId=this.licensor.id;
      attachment.fileCategory = 'LICENSOR_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.filesUploadPostUpdate, this.licensor,'licensorName',attachment,'licensorFiles');

      let contractAttachment: ProductAttachment = new ProductAttachment;
      contractAttachment.licensorId=this.licensor.id;
      contractAttachment.fileCategory = 'LICENSOR_CONTRACT_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.contractFilesUploadPostUpdate, this.licensor,'licensorName',contractAttachment,'licensorContractFiles');

      let lettersAttachment: ProductAttachment = new ProductAttachment;
      lettersAttachment.licensorId=this.licensor.id;
      lettersAttachment.fileCategory = 'LICENSOR_LETTERS_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.lettersFilesUploadPostUpdate, this.licensor,'licensorName',lettersAttachment,'licensorLettersFiles');

      let artistSignOffAttachment: ProductAttachment = new ProductAttachment;
      artistSignOffAttachment.licensorId=this.licensor.id;
      artistSignOffAttachment.fileCategory = 'LICENSOR_ARTIST_SIGN_OFF_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.artistSignOffFilesUploadPostUpdate, this.licensor,'licensorName',artistSignOffAttachment,'licensorArtistSignOffFiles');

      let factoryFormsAttachment: ProductAttachment = new ProductAttachment;
      factoryFormsAttachment.licensorId=this.licensor.id;
      factoryFormsAttachment.fileCategory = 'LICENSOR_FACTORY_FORMS_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.factoryFormsFilesUploadPostUpdate, this.licensor,'licensorName',factoryFormsAttachment,'licensorFactoryFormsFiles');
   }

   async deleteAttachments() {
      await this.fileHanldingService.deleteMarkedFile(this.licensor.licensorFiles, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.licensor.licensorContractFiles, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.licensor.licensorLettersFiles, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.licensor.licensorArtistSignOffFiles, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.licensor.licensorFactoryFormsFiles, this.createAction);
   }

   populateLocalFileList() {
      this.filesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(this.licensor.licensorFiles);
      this.contractFilesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(this.licensor.licensorContractFiles);
      this.lettersFilesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(this.licensor.licensorLettersFiles);
      this.artistSignOffFilesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(this.licensor.licensorArtistSignOffFiles);
      this.factoryFormsFilesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(this.licensor.licensorFactoryFormsFiles);
   }

    /*****File Handling Ends */ 


   createBrand() {
      this.newBrand.licensorId = this.licensor.id;
      this.newBrand.licensorName = this.licensor.licensorName;
      this.service.createBrand(this.newBrand).subscribe(res => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         if (serviceStatus.status === 'SUCCESS') {
            this.resetBrand();
            this.loadLicensorWithBrands(this.licensor.id);
         } else {
            this.toastrService.error(serviceStatus.message);
         }
         this.toastrService.success(serviceStatus.message);
      }, err => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Brand Create Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   /**
    * 
    * @param licensorId 
    */
   async loadLicensorWithBrands(licensorId: string,updateAttachment?:boolean) {
      if(updateAttachment){
        await this.deleteAttachments();
        let attachment: ProductAttachment = new ProductAttachment;
         attachment.licensorId=this.licensor.id;
         attachment.fileCategory = 'LICENSOR_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, attachment,'licensorFiles');


         let contractAttachment: ProductAttachment = new ProductAttachment;
         contractAttachment.licensorId=this.licensor.id;
         contractAttachment.fileCategory = 'LICENSOR_CONTRACT_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, contractAttachment,'licensorContractFiles');

         let lettersAttachment: ProductAttachment = new ProductAttachment;
         lettersAttachment.licensorId=this.licensor.id;
         lettersAttachment.fileCategory = 'LICENSOR_LETTERS_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor,lettersAttachment,'licensorLettersFiles');

         let artistSignOffAttachment: ProductAttachment = new ProductAttachment;
         artistSignOffAttachment.licensorId=this.licensor.id;
         artistSignOffAttachment.fileCategory = 'LICENSOR_ARTIST_SIGN_OFF_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, artistSignOffAttachment,'licensorArtistSignOffFiles');


         let factoryFormsAttachment: ProductAttachment = new ProductAttachment;
         factoryFormsAttachment.licensorId=this.licensor.id;
         factoryFormsAttachment.fileCategory = 'LICENSOR_FACTORY_FORMS_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, factoryFormsAttachment,'licensorFactoryFormsFiles');

      }

      this.service.loadLicensorWithBrands(licensorId).subscribe((res) => {
         this.licensor = res as Licensor;

         let attachment: ProductAttachment = new ProductAttachment;
         attachment.licensorId=this.licensor.id;
         attachment.fileCategory = 'LICENSOR_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor,attachment,'licensorFiles');

         let contractAttachment: ProductAttachment = new ProductAttachment;
         contractAttachment.licensorId=this.licensor.id;
         contractAttachment.fileCategory = 'LICENSOR_CONTRACT_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor,contractAttachment,'licensorContractFiles');

         let lettersAttachment: ProductAttachment = new ProductAttachment;
         lettersAttachment.licensorId=this.licensor.id;
         lettersAttachment.fileCategory = 'LICENSOR_LETTERS_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor,lettersAttachment,'licensorLettersFiles');


         let artistSignOffAttachment: ProductAttachment = new ProductAttachment;
         artistSignOffAttachment.licensorId=this.licensor.id;
         artistSignOffAttachment.fileCategory = 'LICENSOR_ARTIST_SIGN_OFF_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, artistSignOffAttachment,'licensorArtistSignOffFiles');


         let factoryFormsAttachment: ProductAttachment = new ProductAttachment;
         factoryFormsAttachment.licensorId=this.licensor.id;
         factoryFormsAttachment.fileCategory = 'LICENSOR_FACTORY_FORMS_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.licensor, factoryFormsAttachment,'licensorFactoryFormsFiles');

         this.filteredBrandList = this.licensor.brands;
         this.filteredBrandList = this.sharedService.sortListByPropertyName(this.filteredBrandList,'brandName');
         this.brandListForLicensorSample = this.sharedService.sortListByPropertyName(this.filteredBrandList,'brandName');
         this.brandListForLinkDetails=[];
         if(this.filteredBrandList && this.filteredBrandList.length>0){
            this.brandListForLinkDetails = JSON.parse(JSON.stringify(this.filteredBrandList));
         }
         
         if(updateAttachment){
            this.uploadAttachments();
         }
         //
      });
   }

   deleteBrand(brand: Brand) {
      this.service.deleteBrand(brand.id).subscribe((res) => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         let status: ServiceStatus = res as ServiceStatus;
         if (serviceStatus.status === 'SUCCESS') {
            this.loadLicensorWithBrands(this.licensor.id);
         } else {
            this.toastrService.error(serviceStatus.message);
         }
         this.toastrService.success(serviceStatus.message);
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Brand Delete Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   goBack() {
      this.location.back();
   }

   onKeyupBrandFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredBrandList = this.licensor.brands.filter(items => items.brandName.toUpperCase().indexOf(value.toUpperCase()) > -1 || items.skuCode.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredBrandList = this.licensor.brands;
         }
      }
   }

   /*************** Start: Contact Details ********************/
   addGroupContact(){
      let groupContactDetailsTemp = new GroupContactDetails;
      this.collapseAllGroupContacts();
      this.licensor.groupContactDetails.push(groupContactDetailsTemp);
      setTimeout(()=>{
         this.expandGroupContacts(this.licensor.groupContactDetails.length-1);
      },200);
      
   }

   deleteGroupContactDetails(groupContactDetail:GroupContactDetails){
      this.licensor.groupContactDetails = this.licensor.groupContactDetails.filter(obj => obj !== groupContactDetail);
   }
   
   addContactDetail(groupContactDetail:GroupContactDetails) {
      this.viewContactDetailsModal=true;
      let addEditContactDetailTemp = new ContactDetails;
      addEditContactDetailTemp.type = 'LICENSOR';
      groupContactDetail.contactDetailList.push(addEditContactDetailTemp);
      this.addEditGroupContactDetails=groupContactDetail;
      this.addEditContactDetail=addEditContactDetailTemp;
      //
      
      document.getElementById('contactDetailModalToggler').click();
   }

   viewOrEditContactDetail(groupContactDetail: GroupContactDetails, details: ContactDetails) {
      this.viewContactDetailsModal=true;
      this.addEditGroupContactDetails=groupContactDetail;
      this.addEditContactDetail=details;
      
      document.getElementById('contactDetailModalToggler').click();
   }

   deleteContactDetails(groupContactDetail: GroupContactDetails,details: ContactDetails) {
      groupContactDetail.contactDetailList = groupContactDetail.contactDetailList.filter(obj => obj !== details);
   }

   saveAddEditContactDetail() {
      this.viewContactDetailsModal=false;
      //
   }

   closeAddEditContactDetail() {
      if(this.addEditContactDetail && (this.addEditContactDetail.id === undefined || this.addEditContactDetail.id === null)){
         this.addEditGroupContactDetails.contactDetailList=this.addEditGroupContactDetails.contactDetailList.filter(x=>(x.id!==undefined &&  x.id!==null));
         this.viewContactDetailsModal=false;
      }
   }
   /*************** End: Contact Details ********************/

   /*************** Start: Link Details *********************/
   addLinkDetail(){
      let newLinkDetailsCredential: LinkDetailsCredential = new LinkDetailsCredential;
      let newLinkDetail: LinkDetails = new LinkDetails;
      newLinkDetail.linkDetailsCredentialsList.push(newLinkDetailsCredential);

      this.licensor.linkDetails.push(newLinkDetail);
   }

   addLinkCredential(linkDetails: LinkDetails){
      linkDetails.linkDetailsCredentialsList.push(new LinkDetailsCredential);
   }

   deleteLinkCredential(linkDetails: LinkDetails, linkDetailsCredential:LinkDetailsCredential){
      linkDetails.linkDetailsCredentialsList = linkDetails.linkDetailsCredentialsList.filter(obj => obj !== linkDetailsCredential);
   }
 
   deleteLinkDetails(details: LinkDetails) {
      this.licensor.linkDetails = this.licensor.linkDetails.filter(obj => obj !== details);
   }
 
   openLinkDetailCredential(){
      document.getElementById('linkCredentialModalToggler').click();
   }

   addLinkDetailCredential(linkDetails: LinkDetails){
      let linkDetailsCredential = new LinkDetailsCredential;
      linkDetails.linkDetailsCredentialsList.push(linkDetailsCredential);
   }

   onClickOkLinkCredentialModal(){
      document.getElementById('linkCredentialModalToggler').click();
   }

   openBrandSelectionModal(linkDetails: LinkDetails) {
      this.selectedLinkDetails = linkDetails;
      let allBrand:Brand = this.getAllBrand();
      if (!this.checkIfBrandExists(this.brandListForLinkDetails, allBrand.id)) {
         this.brandListForLinkDetails.unshift(this.getAllBrand());
      }
      document.getElementById('linkBrandSelectionModalToggler').click();
   }

   getAllBrand(): Brand {
      let allBrand: Brand = new Brand;
      allBrand.id = 'ANY';
      allBrand.brandName = 'Default'
      return allBrand;
   }

   isBrandSelected(brand: Brand) {
      if (this.selectedLinkDetails?.associatedBrands === undefined || this.selectedLinkDetails?.associatedBrands === null) {
         return false;
      }
      let temp = this.selectedLinkDetails?.associatedBrands.filter(itemInList => itemInList.id === brand.id);
      return (temp.length == 1);
   }

   removeBrandSelection(brand: Brand, linkDetails: LinkDetails, index: number) {
      this.licensor.linkDetails[index].associatedBrands = this.licensor.linkDetails[index].associatedBrands.filter(associatedBrand => associatedBrand.id !== brand.id);
   }

   onBrandSelection(event: any, brand: Brand) {
      if (this.selectedLinkDetails?.associatedBrands === undefined || this.selectedLinkDetails?.associatedBrands === null) {
         this.selectedLinkDetails.associatedBrands = [];
      }
      if (event.target.checked) {
         if (!this.checkIfBrandExists(this.selectedLinkDetails.associatedBrands, brand.id)) {
            if (brand.id === 'ANY') {
               this.selectedLinkDetails.associatedBrands = [];
            } else {
               this.selectedLinkDetails.associatedBrands = this.selectedLinkDetails.associatedBrands.filter(associatedBrand => associatedBrand.id !== 'ANY');
            }
            this.selectedLinkDetails.associatedBrands.push(brand);
         }
      } else {
         this.selectedLinkDetails.associatedBrands = this.selectedLinkDetails.associatedBrands.filter(associatedBrand => associatedBrand.id !== brand.id);
      }
   }

   onClickOkLinkBrandSelectionModal() {
      document.getElementById('linkBrandSelectionModalToggler').click();
   }

   checkIfBrandExists(list: Brand[], brandId: string) {
      if (list) {
         let temp = list.filter(itemInList => itemInList.id === brandId);
         return (temp.length == 1);
      } else {
         return false;
      }
   }

   /*************** End: Link Details *********************/

   sort(list: string[]): string[] {
      if (list != null) {
         let sortedList: string[] = list.sort(function (a, b) {
            var textA = a.toUpperCase();
            var textB = b.toUpperCase();
            return (textA.charAt(0) < textB.charAt(0)) ? -1 : (textA.charAt(0) > textB.charAt(0)) ? 1 : 0;
         });
         return sortedList;
      }
   }

   loadContactTypes() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('LICENSOR', 'CONTACT', 'TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.contactTypeList = this.sort(res.responsePayload);
         }
      });
   }

   loadLinkTypes() {
      this.metadataService.getMetadataValueListByClassificationAndTypeAndSubType('LICENSOR', 'LINK', 'TYPE').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.linkTypeList = this.sort(res.responsePayload);
         }
      });
   }

   getById(list, id) {
      return list.find(x => x.id === id);
   }


   /*******************************Start: Licensor Sample ***************************************/

   loadDefaultSampleSizeList() {
      this.service.getDefaultSampleSizeList().subscribe(response => {
         let allSampleSizesList: LicensorSample[] = response.responsePayload;
         this.defaultAllSampleSizeList = allSampleSizesList;
         //
      });
   }

   onLicensorSampleBrandClick(licensorSampleDetail: LicensorSampleDetails, selectedIndex: number) {
      //
      this.selectSampleDetailsIndex = selectedIndex;
   }

   addNewLicensorSampleDetail() {
      //
      let index = this.licensor.licensorSampleDetails.length;
      //
      let licensorDetail = new LicensorSampleDetails;
      licensorDetail.brandId = this.selectedBrandIdForLicensorSample;
      licensorDetail.brandName = this.getBrandName();
      licensorDetail.allSampleSizesList = cloneDeep(this.defaultAllSampleSizeList);
      this.licensor.licensorSampleDetails[index] = licensorDetail;
      this.filterBrandListForLicensorSample();
      this.selectedBrandIdForLicensorSample = null;
   }

   getBrandName() {
      if (this.selectedBrandIdForLicensorSample === 'ANY') {
         this.anyBrandExists = true;
         return 'Default';
      } else {
         let temp: Brand[] = this.licensor.brands.filter(listElem => listElem.id === this.selectedBrandIdForLicensorSample);
         return temp[0].brandName;
      }
   }

   deleteLicensorSampleDetail(licensorSampleDetailToDelete, index: number) {
      this.licensor.licensorSampleDetails = this.licensor.licensorSampleDetails.filter(item => item !== licensorSampleDetailToDelete);
      this.filterBrandListForLicensorSample();
      this.selectedBrandIdForLicensorSample = "";
      if (licensorSampleDetailToDelete.brandId === 'ANY') {
         this.anyBrandExists = false;
      }
      //
      if (this.selectSampleDetailsIndex === index) {
         this.selectSampleDetailsIndex = 0;
      } else if (this.selectSampleDetailsIndex - index >= 1) {
         this.selectSampleDetailsIndex = index;
      } else {
         // TODO:
      }
   }

   filterBrandListForLicensorSample() {
      this.brandListForLicensorSample = cloneDeep(this.licensor.brands);
      for (let index = 0; index < this.licensor.licensorSampleDetails.length; index++) {
         if (this.licensor.licensorSampleDetails[index].brandId === 'ANY') {
            this.anyBrandExists = true;
         }
         this.brandListForLicensorSample = this.brandListForLicensorSample?.filter(item => item.id !== this.licensor.licensorSampleDetails[index].brandId);
      }
   }

   onBrandChange($event) {
   }

   /*******************************End: Licensor Sample ***************************************/

   logLicensor(){
      //
   }

   collapseAllGroupContacts(){
     
     for (let index = 0; index < this.licensor.groupContactDetails.length; index++) {
      $('#contactDetails'+index).removeClass('show');
     }
   }

   expandGroupContacts(index:number){
       $('#contactDetails'+index).addClass('show');
    }


   /**************START: Licensor Status Change *****************/

   onLicensorStatusChange(){
      let status : boolean = this.licensor.licensorStatus;
      if(!this.createAction){
         if(this.licensor.licensorStatus){
            this.confirmBrandStatusChange();
         }else{
            this.licensor.changeBrandStatusOnLicensorStatusChange=true;
         }
      }
   }

   confirmBrandStatusChange() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Activate all brands' ,
          message: 'Activate all Brands under Licensor - '+this.licensor.licensorName, 
          confirmBtnText: 'Yes',
          cancelBtnText:' No  '
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.licensor.changeBrandStatusOnLicensorStatusChange=true;
        }else{
         this.licensor.changeBrandStatusOnLicensorStatusChange=false;
        }
      });
   }
   getEnterCount(str:string,max:number){
      if(str && str!==null){
         let rowCount=str.split(/\r\n|\r|\n/).length;
         if(rowCount>max){
            return max;
         }else{
            return rowCount;
         }
      }
    }

   /**************END: Licensor Status Change *****************/
}
