import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-dashlet-detail-dialog',
  templateUrl: './dashlet-detail-dialog.component.html',
  styleUrls: ['./dashlet-detail-dialog.component.css']
})
export class DashletDetailDialogComponent implements OnInit {

  dashletDetails:DashletDetails;
  allowDiscard: boolean = true;

  constructor(public dialogRef: MatDialogRef<DashletDetailDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    if (this.data.dashletDetails) {
      this.dashletDetails = this.data.dashletDetails;
   }
   /*if (!this.data.cancelBtnText) {
      this.data.cancelBtnText = 'Cancel';
   }
   if (!this.data.maxlength) {
      this.data.maxlength = 30;
   }*/
  
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
 }
 discardModalWithNoAction() {
  this.close();
}


}
