<form #confirmForm="ngForm" autocomplete="off" #formVar="ngForm">
   <div *ngIf="data.modalType!=='TEXT_INPUT' && data.modalType!=='TEXTAREA_INPUT' && data.modalType!=='DROPDOWN' && data.modalType!=='NUMBER_INPUT' && data.modalType!=='DROPDOWN_AND_TEXTAREA' && data.modalType!=='DROPDOWN_AND_TEXTINPUT'">
      <div class="d-flex justify-content-between align-items-center ">
         <h1 mat-dialog-title>{{data.title}}</h1>
         <p>&nbsp;</p>
         <span name="discardModal" type="button" class="ml-4 close" *ngIf="allowDiscard" (click)="discardModalWithNoAction()" aria-label="Close" aria-hidden="true">&times;</span>
      </div>

      <hr>
      <div mat-dialog-content *ngIf="data.message">
         <span style="white-space: pre-line;">{{data.message}}</span> 
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" [mat-dialog-close]="true">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" [mat-dialog-close]="false">
      </div>
   </div>


   <!-- START: INPUT TYPE - TEXT -->
   <div *ngIf="data.modalType==='TEXT_INPUT'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <input name="inputText" class="form-control  form-control-sm mt-1" type="text" [(ngModel)]="textInput" required #textInputVar="ngModel" [maxlength]="data.maxlength"
            [pattern]="data.textInputRegex" [ngClass]="{'is-invalid':textInputVar.touched && textInputVar.errors}" (keyup.enter)="submitOnEnter && confirmBtn.click()">
         <div *ngIf="textInputVar?.invalid && (textInputVar.dirty || textInputVar.touched)" class="invalid-feedback">
            <div *ngIf="textInputVar?.errors.pattern">
               {{data.textInputRegexError}}
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn"  #confirmBtn class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: INPUT TYPE - TEXT -->


   <!-- START: INPUT TYPE - TEXT AREA -->
   <div *ngIf="data.modalType==='TEXTAREA_INPUT'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <textarea name="inputTextArea" class="form-control  form-control-sm mt-1" [(ngModel)]="textInput" required #textAreaInputVar="ngModel" [maxlength]="data.maxlength"
            [pattern]="data.textInputRegex" rows="4" [ngClass]="{'is-invalid':textAreaInputVar.touched && textAreaInputVar.errors}">
       </textarea>
         <div *ngIf="textAreaInputVar?.invalid && (textAreaInputVar.dirty || textAreaInputVar.touched)" class="invalid-feedback">
            <div *ngIf="textAreaInputVar?.errors.pattern">
               {{data.textInputRegexError}}
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: INPUT TYPE - TEXT AREA -->

   <!-- START: DROPDOWN -->
   <div *ngIf="data.modalType==='DROPDOWN'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <select class="form-control form-control-sm custom-select" [(ngModel)]="textInput" name="dropdown" #dropDownVar="ngModel" required
            [ngClass]="{'is-invalid':dropDownVar.touched && dropDownVar.errors}">
            <option *ngFor="let dropdownItem of data.dropdownList" [ngValue]="dropdownItem.id">
               {{dropdownItem.name}}</option>
         </select>
         <div *ngIf="dropDownVar?.invalid && (dropDownVar.dirty || dropDownVar.touched)" class="invalid-feedback">
            <div *ngIf="dropDownVar?.errors.required">
               Select a value from the dropdown.
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: DROPDOWN -->

   <!-- START: INPUT TYPE - NUMBER -->
   <div *ngIf="data.modalType==='NUMBER_INPUT'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <input name="inputNumber" class="form-control  form-control-sm mt-1" type="number" [(ngModel)]="numberInput" required #numberInputVar="ngModel" [maxlength]="data.maxlength"
            [ngClass]="{'is-invalid':numberInputVar.touched && numberInputVar.errors}">
         <div *ngIf="numberInputVar?.invalid && (numberInputVar.dirty || numberInputVar.touched)" class="invalid-feedback">
            <div *ngIf="numberInputVar?.errors">
               {{data.numberInputError}}
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: INPUT TYPE - NUMBER -->


   <!-- START: DROPDOWN AND DETAIL -->
   <div *ngIf="data.modalType==='DROPDOWN_AND_TEXTAREA'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <select class="form-control form-control-sm custom-select" [(ngModel)]="dropdownSelection" name="dropdown" #dropDownVar="ngModel" required
            [ngClass]="{'is-invalid':dropDownVar.touched && dropDownVar.errors}">
            <option *ngFor="let dropdownItem of data.dropdownList" [ngValue]="dropdownItem.id">
               {{dropdownItem.value}}</option>
         </select>
         <div *ngIf="dropDownVar?.invalid && (dropDownVar.dirty || dropDownVar.touched)" class="invalid-feedback">
            <div *ngIf="dropDownVar?.errors.required">
               Select a value from the dropdown.
            </div>
         </div>
      </div>


      <div class="mt-3"> 
         <label class="mb-0 ml-1" style="font-size: .60rem; color: lightslategray;">Details</label>
         <textarea name="inputTextArea" class="form-control  form-control-sm mt-1" [(ngModel)]="textInput" [required]="data.detailsRequired" #textAreaInputVar="ngModel" [maxlength]="data.maxlength"
            [pattern]="data.textInputRegex" rows="4" [ngClass]="{'is-invalid':textAreaInputVar.touched && textAreaInputVar.errors}">
        </textarea>
         <div *ngIf="textAreaInputVar?.invalid && (textAreaInputVar.dirty || textAreaInputVar.touched)" class="invalid-feedback">
            <div *ngIf="textAreaInputVar?.errors.pattern">
               {{data.textInputRegexError}}
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: DROPDOWN AND DETAIL -->


    <!-- START: DROPDOWN AND DETAIL -->
    <div *ngIf="data.modalType==='DROPDOWN_AND_TEXTINPUT'">
      <h1 mat-dialog-title>{{data.title}}</h1>
      <hr>
      <div mat-dialog-content *ngIf="data.message">
         {{data.message}}
      </div>
      <br *ngIf="data.message">
      <div>
         <select class="form-control form-control-sm custom-select" [(ngModel)]="dropdownSelection" name="dropdown" #dropDownVar="ngModel" required
            [ngClass]="{'is-invalid':dropDownVar.touched && dropDownVar.errors}">
            <option *ngFor="let dropdownItem of data.dropdownList" [ngValue]="dropdownItem.id">
               {{dropdownItem.value}}</option>
         </select>
         <div *ngIf="dropDownVar?.invalid && (dropDownVar.dirty || dropDownVar.touched)" class="invalid-feedback">
            <div *ngIf="dropDownVar?.errors.required">
               Select a value from the dropdown.
            </div>
         </div>
      </div>


      <div class="mt-3">
         <input name="inputText" class="form-control  form-control-sm mt-1" type="text" [(ngModel)]="textInput" required #textInputVar="ngModel" [maxlength]="data.maxlength"
            [pattern]="data.textInputRegex" [ngClass]="{'is-invalid':textInputVar.touched && textInputVar.errors}" (keyup.enter)="submitOnEnter && confirmBtn.click()">
         <div *ngIf="textInputVar?.invalid && (textInputVar.dirty || textInputVar.touched)" class="invalid-feedback">
            <div *ngIf="textInputVar?.errors.pattern">
               {{data.textInputRegexError}}
            </div>
         </div>
      </div>
      <div style="float:right;margin: 40px 0px 10px 0px;">
         <input name="confirmBtn" class="btn btn-danger" #confirmBtn style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" (click)="save()" [disabled]="!confirmForm.valid">
         <input name="closeBtn" class="btn btn-dark" type="button" [ngModel]="data.cancelBtnText" (click)="close()">
      </div>
   </div>
   <!-- END: DROPDOWN AND DETAIL -->


</form>