<ng-container *ngIf="printerSummariesViews?.length>0">
   <mat-tab-group>
      <ng-container *ngFor="let psv of printerSummariesViews; index as psvIndex">
         <mat-tab>
            <ng-template mat-tab-label>
               <h6><span class="ml-2 font-weight-normal f-11">{{psv.createdOn |date:'mediumDate'}}
                     <div *ngIf="!psv.isCurrent">(Previous {{psv.approvalReason}})</div>
                     <div *ngIf="psv.isCurrent">(Current)</div>
                  </span></h6>
            </ng-template>

            <div class="overflow-hidden">
             
               
              
               <ng-container *ngFor="let ps of psv.printerSummaries; index as psIndex">
                 <mat-accordion multi class="example-headers-align">
                    <mat-expansion-panel class="border-light border my-1">
                        <mat-expansion-panel-header 
                        [collapsedHeight]="'48px'" [expandedHeight]="'48px'"
                        [style]="(ps.poNumber && ps.poNumber!==null &&
                         (ps.poStatus === 'PO_CREATION_SUCCESS' || ps.poStatus === 'PO_UPDATE_SUCCESS'))?'background: #dbf4f5ab;':((ps.poStatus === 'PO_CANCELLATION_REQUIRED'))?'background:#f3a7a7':'background:#f8e5cfed'">
                            <mat-panel-title>
                              
                                    {{ps.printerName}} <span *ngIf="ps.fullPackage">( Full Package )</span>
                              
                             
                            </mat-panel-title>
                           <mat-panel-description *ngIf="ps.latest || (ps.poStatus !== 'PO_CREATION_REQUIRED' && ps.poStatus !== 'PO_UPDATE_REQUIRED')">
                              
                                 {{'po.status.'+ps?.poStatus | transform}}
                                 
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                       
                    
                 <!--<div class="card card-header mt-2">
                 <h6 class="mb-0">{{ps.printerName}} <span *ngIf="ps.fullPackage">( Full Package )</span> 
                  </h6>
                 </div>
                 <hr class="mt-0 mb-0">-->
                 <!--<div class=" card card-body pt-1">-->
                 <mat-tab-group>

                     <!-- Start: Purchase Order Summary -->
                     <mat-tab>
                       <ng-template mat-tab-label>
                          <h6><span class="ml-2 font-weight-normal f-11">PO Summary</span></h6>
                       </ng-template>
                    
                       <div class="ml-2 mt-2 overflow-hidden">
                        <ng-container *ngIf="!isManualPO">
                          <app-purchase-order [userAccess]="userAccess" [summaryId]="ps.id" [summaryType]="'PRINTER'" 
                          [latestSummary]="ps.latest"
                          [allowPoCreation]="ps.latest && 
                          ( 
                           salesOrder?.productionStatus !='COSTING_GENERATION_PENDING' 
                           && salesOrder?.productionStatus !='COSTING_APPROVAL_PENDING' 
                          )" 
                          (event)="purchaseOrderSummaryEventHandler($event)" ></app-purchase-order>
                        </ng-container>
                        <ng-container *ngIf="isManualPO">
                           <span class="f-11">This sales order was submitted before Printer Purchase Order Module. Please handle the process in Sage.</span>
                        </ng-container>
                        
                       </div>
                    </mat-tab>
                    <!-- End: Purchase Order Summary -->

                    <mat-tab>
                       <ng-template mat-tab-label>
                          <h6><span class="ml-2 font-weight-normal f-11">Summary
                             </span></h6>
                       </ng-template>
                    
                       <div class="ml-2 mt-2 overflow-hidden">
                          <div class="d-flex m-1 d-flex justify-content-between">
                             <div>
                                 <div class="custom-control custom-switch" *ngIf="ps.priorApprovedPoReferenceId">
                                    <input type="checkbox" class="custom-control-input" name="DiffToggle_{{psIndex}}_{{psvIndex}}" id="DiffToggle_{{psIndex}}_{{psvIndex}}" 
                                    [(ngModel)]="ps.diffMode" (change)="onDiffModeChange(ps)">
                                    <label class="custom-control-label" for="DiffToggle_{{psIndex}}_{{psvIndex}}">Diff Mode</label>
                              </div>
                             </div>
                             <div class="">
                                <button type="button" class="btn btn-sm btn-warning" (click)="createPrinterSummaryExcel(psv)"><i class="fas fa-file-download"></i> &nbsp;Export</button>
                             </div>
                          </div>
                          <div class="row">
                             
                             <div class="col-8">
                                
                                   <span class="f-13 mb-1">Print Summary Details</span>
                                   <ng-container *ngIf="ps.diffMode">
                                       <table class=" f-11 my-2 table table-sm table-bordered table-striped " style="width: 100% !important;" [style]="!psv.isCurrent?'outline: none;border: 3px solid red;':''"
                                       [style.opacity]="!psv.isCurrent?'0.7':'10'">
                                       <thead class="thead-dark">
                                          <tr class="text-center">
                                             <th>Printer Ship Date</th>
                                             <th>Printer Cancel Date</th>
                                             <th>Printer Currency</th>
                                             <th>Total Qty</th>
                                             <th>Total Print Cost</th>
                                             <th>Total Film Charges</th>
                                             <th>Total Other Charges</th>
                                             <th *ngIf="ps.diffMode">Change Type</th>                                          
                                          </tr>
                                       </thead>
                                       <tbody>
                                                <ng-container >
                                                   <tr class="text-center">
                                                      <td>{{ps.printerSummaryDetail.printerRequestShipDate  | date:'mediumDate'}}</td>
                                                      <td>{{ps.printerSummaryDetail.printerRequestCancelDate  | date:'mediumDate'}}</td>
                                                      <td>{{ps.printerSummaryDetail.printerCurrency}}</td>
                                                      <td>{{ps.printerSummaryDetail.totalQty}}</td>
                                                      <td>{{ps.printerSummaryDetail.totalCost}}</td>
                                                      <td>{{ps.printerSummaryDetail.totalFilmCharges}}</td>
                                                      <td>{{ps.printerSummaryDetail.totalOtherCharges}}</td>

                                                      
                                                      <td *ngIf="ps.topDiff" style="white-space: pre-line;" [style]="ps.topDiff.length>0 && ps.topDiff[0]?.added?'background-color:#aec9ae':ps.topDiff.length>0 &&ps.topDiff[0]?.removed?'background-color:#f08686bf':ps.topDiff.length>0 && ps.topDiff[0]?.modified?'background-color:#eed6aa':''">{{ps.topDiff[0]?.modified?getChangeDescriptionForPrinterSummaryDetails(ps.topDiff[0]):ps.topDiff[0]?.added?'Added':ps.topDiff[0]?.removed?'Removed':'No Change'}}</td>
                                                      
                                                                          
                                                   </tr>
                                                </ng-container>
                                          </tbody>
                                       </table>
                                 </ng-container>
                                 
                                <table class=" f-11 table table-sm table-bordered table-striped " style="width: 100% !important;" [style]="!psv.isCurrent?'outline: none;border: 3px solid red;':''"
                                   [style.opacity]="!psv.isCurrent?'0.7':'10'">
                                   <thead class="thead-dark">
                                      <tr class="text-center">
                                         <th>Style</th>
                                         <th>Job Type</th>
                                         <th>Size</th>
                                         <th>Qty</th>
                                         <th>Cost</th>
                                         <th>Currency</th>
                                         <th>Extra Blanks Qty</th>
                                         <th>Description</th>
                                         <th *ngIf="ps.diffMode">Change Type</th>                                          
                                      </tr>
                                   </thead>
                                   <tbody>
                                    <ng-container *ngIf="!ps.diffMode">
                                      <ng-container *ngFor="let psd of ps.printerSummaryDetail.printerSizeDetails">
                                         <tr class="text-center">
                                            <td>{{psd.sku}}</td>
                                            <td *ngIf="!psd.fullPackage && psd.jobType">{{psd.jobType}}</td>
                                            <td *ngIf="psd.fullPackage">Full Package</td>
                                            <td *ngIf="!psd.fullPackage && !psd.jobType">Print</td>
                                            <td>{{psd.size}}</td>
                                            <td>{{psd.qty}}</td>
                                            <td>{{psd.cost}}</td>
                                            <td>{{ps.printerSummaryDetail.printerCurrency}}</td>
                                            <td *ngIf="psd.extraQty===0">N/A</td>
                                            <td *ngIf="psd.extraQty>0">{{psd.extraQty}}</td>
                                            <td *ngIf="psd.sample">{{psd.sampleDesc}}</td>
                                            <td *ngIf="!psd.sample">&nbsp;</td>
                                         </tr>
                                      </ng-container>
                                      <tr  class="text-center f-12 text-white" style="background-color: #abb5c0;">
                                       <td colspan="3" class="text-white">Total</td>
                                       <td>{{ps.printerSummaryDetail.totalQty}}</td>
                                       <td>{{ps.printerSummaryDetail.totalCost}}</td>
                                       <td>{{ps.printerSummaryDetail.printerCurrency}}</td>
                                       <td>&nbsp;</td>
                                       <td>&nbsp;</td>
                                    </tr>
                                    </ng-container>
                                   <ng-container *ngIf="ps.diffMode && ps.psDiff">
                                      <ng-container *ngFor="let diff of ps.psDiff">
                                         
                                         <tr class="text-center" *ngIf="!diff.noChange">
                                            <td>{{diff.referenceObj.sku}}</td>
                                            <td *ngIf="!diff.referenceObj.fullPackage && diff.referenceObj.jobType">{{diff.referenceObj.jobType}}</td>
                                            <td *ngIf="diff.referenceObj.fullPackage">Full Package</td>
                                            <td *ngIf="!diff.referenceObj.fullPackage && !diff.referenceObj.jobType">Print</td>
                                            <td>{{diff.referenceObj.size}}</td>
                                            <td>{{diff.referenceObj.qty}}</td>
                                            <td>{{diff.referenceObj.cost}}</td>
                                            <td>{{ps.printerSummaryDetail.printerCurrency}}</td>
                                            <td *ngIf="diff.referenceObj.extraQty===0">N/A</td>
                                            <td *ngIf="diff.referenceObj.extraQty>0">{{diff.referenceObj.extraQty}}</td>
                                            <td *ngIf="diff.referenceObj.sample">{{diff.referenceObj.sampleDesc}}</td>
                                            <td *ngIf="!diff.referenceObj.sample">&nbsp;</td>
                                            <td style="white-space: pre-line;" [style]="diff.added?'background-color:#aec9ae':diff.removed?'background-color:#f08686bf':diff.modified?'background-color:#eed6aa':''">{{diff.modified?getChangeDescriptionForPrinterSizeDetails(diff):diff.added?'Added':diff.removed?'Removed':'No Change'}}</td>
                                            
                                         </tr>
                                      
                                      
                                      </ng-container>
                                </ng-container >
                                      
                                      
                                   </tbody>
                                </table>
                             </div>
                          
                             <div class="col-4">
                              <div>
                                <span class="f-13 mb-1">Film Charge Details</span>
                                <table class=" f-11 table table-sm table-bordered table-striped " style="width: 100% !important;" [style]="!psv.isCurrent?'outline: none;border: 3px solid red;':''"
                                   [style.opacity]="!psv.isCurrent?'0.7':'10'">
                                   <thead class="thead-dark">
                                      <tr class="text-center">
                                         <th>Style</th>
                                         <th>Screen Count</th>
                                         <th>Cost</th>
                                         <th>Currency</th>
                                         <th>Total Screen Cost</th>
                                         <th *ngIf="ps.diffMode">Change Type</th>
                                      </tr>
                                   </thead>
                                   <tbody>
                                    <ng-container *ngIf="!ps.diffMode">
                                      <ng-container *ngFor="let pfcd of ps.printerSummaryDetail?.printerFilmChargesDetails">
                                         <tr class="text-center">
                                            <td>{{pfcd.sku}}</td>
                                            <td>{{pfcd.productScreenCount}}</td>
                                            <td>{{pfcd.fcScreen}}</td>
                                            <td>{{pfcd.fcScreenCurrency}}</td>
                                            <td>{{pfcd.totalFcForStyle}}</td>
                                         </tr>
                                      </ng-container>
                                      <tr class="text-center f-12 text-white" style="background-color: #abb5c0;">
                                         <td colspan="4" class="text-white">Total</td>
                                         <td>{{ps.printerSummaryDetail.totalFilmCharges}}</td>
                                      </tr>
                                    </ng-container>
                                      <ng-container *ngIf="ps.diffMode && ps.pfcDiff">
                                          <ng-container *ngFor="let diff of ps.pfcDiff">
                                             <tr class="text-center">
                                                <td>{{diff.referenceObj.sku}}</td>
                                                <td>{{diff.referenceObj.productScreenCount}}</td>
                                                <td>{{diff.referenceObj.fcScreen}}</td>
                                                <td>{{diff.referenceObj.fcScreenCurrency}}</td>
                                                <td>{{diff.referenceObj.totalFcForStyle}}</td>
                                                <td style="white-space: pre-line;" [style]="diff.added?'background-color:#aec9ae':diff.removed?'background-color:#f08686bf':diff.modified?'background-color:#eed6aa':''">{{diff.modified?getChangeDescriptionForPrinterFilmCharges(diff):diff.added?'Added':diff.removed?'Removed':'No Change'}}</td>
                                             </tr>
                                          </ng-container>
                                       </ng-container>
                                   </tbody>
                                </table>
                                </div>
                                <div>
                                 <span class="f-13 mb-1">Other Charges</span>
                                 <table class=" f-11 table table-sm table-bordered table-striped " style="width: 100% !important;" [style]="!psv.isCurrent?'outline: none;border: 3px solid red;':''"
                                    [style.opacity]="!psv.isCurrent?'0.7':'10'">
                                    <thead class="thead-dark">
                                       <tr class="text-center">
                                          <th>Charge Decription</th>
                                          <th>Total Cost</th>
                                          <th>Currency</th>
                                          <th *ngIf="ps.diffMode">Change Type</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                     <ng-container *ngIf="!ps.diffMode">
                                       <ng-container *ngFor="let gacd of ps.printerSummaryDetail?.groupedAdditionalChargesDetails">
                                          <tr class="text-center">
                                             <td>{{gacd.chargesDescription}}</td>
                                             <td>{{gacd.totalCost}}</td>
                                             <td>{{gacd.currency}}</td>
                                          </tr>
                                       </ng-container>
                                       <!--<tr class="text-center f-12 text-white" style="background-color: #abb5c0;">
                                          <td colspan="4" class="text-white">Total</td>
                                          <td>{{ps.printerSummaryDetail.totalFilmCharges}}</td>
                                       </tr>-->
                                     </ng-container>
                                       <ng-container *ngIf="ps.diffMode && ps.groupedAddlCharges">
                                           <ng-container *ngFor="let diff of ps.groupedAddlCharges">
                                              <tr class="text-center">
                                                 <td>{{diff.referenceObj.chargesDescription}}</td>
                                                 <td>{{diff.referenceObj.totalCost}}</td>
                                                 <td>{{diff.referenceObj.currency}}</td>
                                                 <td style="white-space: pre-line;" [style]="diff.added?'background-color:#aec9ae':diff.removed?'background-color:#f08686bf':diff.modified?'background-color:#eed6aa':''">{{diff.modified?getChangeDescriptionForAdditionCharges(diff):diff.added?'Added':diff.removed?'Removed':'No Change'}}</td>
                                              </tr>
                                           </ng-container>
                                        </ng-container>
                                    </tbody>
                                 </table>
                                 </div>
                             </div>
                          </div>
                       </div>
                    </mat-tab>

                   

                    <mat-tab *ngIf="!ps.fullPackage && ps.printerSummaryDetail && ps.printerSummaryDetail.printerBlanksDistributionGroupedList && ps.printerSummaryDetail.printerBlanksDistributionGroupedList.length>0">
                       <ng-template mat-tab-label>
                          <h6><span class="ml-2 font-weight-normal f-11">Blanks Distribution
                             </span></h6>
                       </ng-template>
                       <div class="ml-2 mt-2 overflow-hidden">
                       <ng-container *ngFor="let psdGrouped of ps.printerSummaryDetail.printerBlanksDistributionGroupedList">
                          <ng-container *ngIf="psdGrouped.distributionCount>0">
                             <table class=" f-10 table table-sm table-bordered"  [style]="!psv.isCurrent?'outline: none;border: 3px solid red;':''"
                                [style.opacity]="!psv.isCurrent?'0.7':'10'">
                                <thead class="thead-dark">
                                   <tr class="text-center">
                                      <th>Style</th>
                                      <!--<th>Job Type</th>-->
                                      <th>Customer Size</th>
                                      <th>Qty</th>
                                      <th>Total Samples Qty</th>
                                      <th>Extra Blanks Qty</th>
                                      <th>Total Blanks Qty</th>
                                      
                                      <th>Blanks Code</th>
                                      <th>Blanks Size</th>
                                      <th>Blanks Qty</th>
                                      <th>COO</th>
                                      <th>Blanks Supplier</th>
                                   </tr>
                                </thead>
                                <tbody>
                                   
                                      
                                      <ng-container *ngFor="let psdGroupedPsd of psdGrouped.printerSizeDetails; index as psdGroupedPsdIndex;let first = first;">
                                            <!--<ng-container *ngIf="!psdGroupedPsd.printerBlanksDistributionSizeDetailsList || psdGroupedPsd.printerBlanksDistributionSizeDetailsList?.length===0">


                                            </ng-container>   -->
                                            <ng-container >
                                               <ng-container *ngFor="let psdDist of psdGroupedPsd.printerBlanksDistributionSizeDetailsList; index as psdDistIndex;">
                                               <tr class="text-center" [style.background-color]="psdGroupedPsdIndex%2===1?'rgba(0,0,0,.05)':''">
                                                  
                                                  <td [attr.rowspan]="psdGrouped.distributionCount" *ngIf="psdDistIndex===0 && first">{{psdGrouped.sku}}</td>
                                                  <td [attr.rowspan]="psdGroupedPsd.printerBlanksDistributionSizeDetailsList.length" *ngIf="psdDistIndex===0">{{psdGroupedPsd.size}}</td>
                                                  <td [attr.rowspan]="psdGroupedPsd.printerBlanksDistributionSizeDetailsList.length" *ngIf="psdDistIndex===0"style="width: 10%;">{{psdGroupedPsd.qty}}</td>
                                                  <td [attr.rowspan]="psdGroupedPsd.printerBlanksDistributionSizeDetailsList.length" *ngIf="psdDistIndex===0"style="width: 5%;">{{psdGroupedPsd.licensorSample+psdGroupedPsd.ppSample+psdGroupedPsd.topSample}}</td>
                                                  <td [attr.rowspan]="psdGroupedPsd.printerBlanksDistributionSizeDetailsList.length" *ngIf="psdDistIndex===0"style="width: 5%;">{{psdGroupedPsd.extraQty}}</td>
                                                  <td [attr.rowspan]="psdGroupedPsd.printerBlanksDistributionSizeDetailsList.length" *ngIf="psdDistIndex===0" style="width: 10%;">{{psdGroupedPsd.qty+psdGroupedPsd.extraQty+psdGroupedPsd.licensorSample+psdGroupedPsd.ppSample+psdGroupedPsd.topSample}}</td>
                                                  
                                                  <td [attr.rowspan]="1">{{psdDist.blankCode}}</td>
                                                  <td [attr.rowspan]="1" [style]="psdDist.overrideSize!==psdDist.size?'font-weight:bold;color:tomato':''">{{psdDist.overrideSize}}</td>
                                                  <td [attr.rowspan]="1">{{psdDist.qty}}</td>
                                                  <td [attr.rowspan]="1">{{psdDist.coo}}</td>
                                                  <td [attr.rowspan]="1">{{psdDist.supplierName}}</td>
                                               
                                                  
                                                  
                                               </tr>
                                               </ng-container>
                                            </ng-container>
                                            
                                      </ng-container>
                                   
                                   
                                </tbody>
                             </table>
                          </ng-container>
                       </ng-container>
                       </div>
                    </mat-tab>
                 </mat-tab-group>
              <!--</div>-->
              </mat-expansion-panel>
              </mat-accordion>
               </ng-container>

               
            </div>
         </mat-tab>
      </ng-container>
   </mat-tab-group>

</ng-container>