import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SageService } from 'src/app/shared/service/sage/sage.service';

@Component({
  selector: 'app-create-item-audit',
  templateUrl: './create-item-audit.component.html',
  styleUrls: ['./create-item-audit.component.css']
})
export class CreateItemAuditComponent implements OnInit {
  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  
 private eventsSubscription: Subscription;
 @Input() events: Observable<string>;
 @Input() origination:string;
 @Input() originationReferenceId:string;
 @Input() trigger:string;
 @Output() onEventFromCreateItem = new EventEmitter<string>();
 createItemAuditErrors:any[]=[];

  constructor(private authService:AuthService,private sageService:SageService) { }


  ngOnInit(){
    this.eventsSubscription = this.events.subscribe(event => {
      if(event==='RELOAD'){
        this.loadCreateItemAuditError();
      }
    })
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
   
 }

  private loadCreateItemAuditError() {
    this.sageService.getCreateItemAuditError(this.origination,this.originationReferenceId).subscribe(
       response => {
          if (response.responseStatus.status === 'SUCCESS') {
             this.createItemAuditErrors = response.responsePayload;
          }
       }
    );
 }

 retryCreateItemAudit(){
  this.sageService.retryCreateItemAuditError(this.origination,this.originationReferenceId,this.trigger,this.createItemAuditErrors).subscribe(response=>{
    this.onEventFromCreateItem.emit('RELOAD');
     this.loadCreateItemAuditError();
  });
}

/*if(this.salesOrder.submissionCount>0){
  this.loadCreateItemAuditError();
}*/

}
