<form name="printer" (ngSubmit)="onSubmit()" #printerForm="ngForm" autocomplete="off" novalidate class="mb-3">

   <div class="container">
      
      <ng-container *ngIf="printer">
      <div class="d-flex justify-content-center mt-3">
         <div class="text-color-{{printer.status+'' | replace:' ':'_'|lowercase}}">
            <h2>{{printer.name}}</h2>
         </div>
         <div></div>
      </div>
      <div *ngIf="printer.status!==undefined" class="separator-{{printer.status+'' | replace:' ':'_'|lowercase}}">
         <h6>{{printer.status?'Active':'Inactive'}}</h6>
      </div>


      <div class="row  mt-4 shadow-lg p-3 mb-5 bg-white rounded">
         <div class="col-lg-12">
            <mat-tab-group>
               <!-- animationDuration="0ms"-->

               <!-- Printer DETAILS -->
               <mat-tab>
                  <ng-template mat-tab-label>
                     <h5>Printer <span class="ml-2 font-weight-normal"></span></h5>
                  </ng-template>
                  <div class="overflow-hidden">
                     <!-- TAB Printer DETAILS -->
                     <div class="row mt-4 ml-10">

                        <div class="col-lg-4">
                           <div class="form-group">
                              <label class="mb-0 ml-1 label-medium-slight-dark">Name</label>
                              <input name="name" [(ngModel)]="printer.name" class="form-control form-control-sm" #printerName="ngModel" required [disabled]="!createAction"
                                 [ngClass]="{'is-invalid':printerName.touched && printerName.errors}" maxlength="40">
                              <div *ngIf="printerName?.invalid && (printerName.dirty || printerName.touched)" class="invalid-feedback">
                                 <div *ngIf="printerName.errors.required">
                                    Printer Name is required.
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-lg-2">
                           <div class="form-group">
                              <label class="mb-0 ml-1 label-medium-slight-dark">Status</label>
                              <select class="form-control form-control-sm" [(ngModel)]="printer.status" name="printerStatus" #printerStatus="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':printerStatus.touched && printerStatus.errors}">
                                 <option value=false>Inactive</option>
                                 <option value=true>Active</option>
                              </select>
                              <div *ngIf="!viewAction && printerStatus?.invalid && (printerStatus.dirty || printerStatus.touched)" class="invalid-feedback">
                                 <div *ngIf="printerStatus.errors.required">
                                    Printer Status is required.
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-2">
                           <label class="mb-0 label-medium">Printer Location</label>
                           <div>
                              <select name="country" [(ngModel)]="printer.countryCode" class="form-control  form-control-sm" placeholder="" #countryVar="ngModel" required
                                 [ngClass]="{'is-invalid':countryVar.touched && countryVar.errors}"  [disabled]="!editable">
                                 <ng-container *ngIf="countriesWithRegionsDetails">
                                    <option *ngFor="let country of countriesWithRegionsDetails" [ngValue]="country.countryShortCode">
                                       {{country.countryName}}</option>
                                 </ng-container>
                                 
                              </select>
                              <div *ngIf="countryVar?.invalid && (countryVar.dirty || countryVar.touched)" class="invalid-feedback">
                                 <div *ngIf="countryVar.errors.required">Country is required.</div>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-2">
                           <label class="mb-0 label-medium">Operating Currency</label>
                           <div>
                              <select class="form-control form-control-sm" style="font-size:11px;" name="currency" #currencyVar="ngModel" [disabled]="!editable" required [(ngModel)]="printer.currency"
                              [ngClass]="{'is-invalid':currencyVar.touched && currencyVar.errors}">
                              <option *ngFor="let currency of currencyListFromMetadata" [ngValue]="currency">
                                 {{currency}}</option>
                           </select>
                           <div *ngIf="currencyVar?.invalid && (currencyVar.dirty || currencyVar.touched)" class="invalid-feedback">
                              <div *ngIf="currencyVar.errors.required">
                                 Operating Currency is required.
                              </div>
                           </div>
                           </div>
                        </div>
                        <div class="col-lg-2">
                           <div class="form-group">
                              <label class="mb-0 ml-1 label-medium-slight-dark">Ex-Factory Adjust Days</label>
                              <input name="exFactoryDaysAdjust" [(ngModel)]="printer.exFactoryDaysAdjust" class="form-control form-control-sm" #exFactoryDaysAdjustVar="ngModel" required [disabled]="!editable"
                                 [ngClass]="{'is-invalid':exFactoryDaysAdjustVar.touched && exFactoryDaysAdjustVar.errors}" type="number" maxlength="3" placeholder="E.g. -45">
                              <div *ngIf="exFactoryDaysAdjustVar?.invalid && (exFactoryDaysAdjustVar.dirty || exFactoryDaysAdjustVar.touched)" class="invalid-feedback">
                                 <div *ngIf="exFactoryDaysAdjustVar.errors.required">
                                    Ex-Factory Adjust Days is required.
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                     

                        
                        <div class="col-lg-6">
                           <div class="form-group">
                              <label class="mb-0 ml-1 label-small-slight-dark">Notes</label>
                              <textarea name="printerNotes" [(ngModel)]="printer.notes" class="form-control form-control-sm" rows="2" #printerNotes="ngModel" [disabled]="!editable"
                                 maxlength="250"></textarea>
                           </div>
                        </div>
                     
                        <div class="col-lg-2">

                        </div>
                     </div>
                     <div class="mt-2">
                        <h5>Sage Details</h5>
                        <hr>
                     </div>
                     <div class="my-2">
                        <button type="button" class="btn btn-sm btn-warning" (click)="addSageDetails(printer)" *ngIf="editable">Add Sage Details</button>
                     </div>
                     <ng-container *ngIf="printer.vendorInfoList">
                        <div class="card-deck">
                        <ng-container *ngFor="let vInfo of printer.vendorInfoList">
                           <div class="card">
                              <div class="card-header">
                                 <h6 class="card-title">{{'company.name.'+vInfo.companyCode | transform}}</h6>    
                              </div>
                              <div class="card-body">
                                <div class="row mt-1">
                                 <div class="col-4" style="font-weight: 600;">Vendor Name:</div>
                                 <div class="col-8">{{vInfo.vendorName}}</div>
                             </div>
                                <div class="row mt-1">
                                    <div class="col-4" style="font-weight: 600;" >Vendor Code:</div>
                                    <div class="col-8">{{vInfo.vendorCode}}</div>
                                </div>
                                <div class="row mt-1">
                                 <div class="col-4" style="font-weight: 600;">Active:</div>
                                 <div class="col-8">{{vInfo.active?'Yes':'No'}}</div>
                             </div>
                              </div>
                              <!--<div class="card-footer">
                                <small class="text-muted">Last updated 3 mins ago</small>
                              </div>-->
                            </div>
                           
                           
                           
                        </ng-container>
                     </div>
                     </ng-container>
                  </div>
               </mat-tab>

            </mat-tab-group>

         </div>

      </div>

      <div class="row d-flex justify-content-end">
         <div class="col-lg-3">
            <div class="form-group ml-0">
               <button type="submit" class="btn btn-info btn-block" [disabled]="!printerForm.valid" *ngIf="editable && createAction">Submit</button>
            </div>
         </div>
      </div>
   </ng-container>
   </div>
   <div class="floatBtn d-flex flex-column">
      <button type="submit" class=" btn btn-circle bg-info mb-2" *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
         [disabled]="printerForm.invalid"><i class="fas fa-save fa-2x" style="color:white"></i>
      </button>
      <a rel="noopener" (click)="cancelEdit()" class=" btn btn-circle bg-dark mb-2" *ngIf="editable && !createAction" matTooltip="Cancel Edit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-times fa-2x" style="color:white"></i></a>
      <a class="btn btn-circle bg-dark mb-2" (click)="edit(printer.id)" *ngIf="viewAction && userAccess?.canEditVendors" matTooltip="Edit" matTooltipPosition="left" matTooltipClass="bg-dark"> <i
            class="fas fa-pencil-alt fa-lg" style="color: white;"></i></a>
      <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" *ngIf="viewAction || createAction" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i
            class="fas fa-arrow-left  fa-2x"></i></a>
   </div>

</form>