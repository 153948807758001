import { Product, ProductAttachment } from './product.model';
import { BrandRights } from './brand-rights.model';

export class Brand {
    id:string;
    licensorId:string;
    brandName:string;
    shortName:string;
    licensorName:string;
    licenseType:string;
    licenseOwner:string;
    productList:Product[];
    skuCode:string;
    pnlUrl:string=null;
    logoUrl:string=null;
    pnls:ProductAttachment[]=[];
    logos:ProductAttachment[]=[];
    brandFiles:ProductAttachment[]=[];
    brandTrendFiles:ProductAttachment[]=[];
    brandStatus:boolean;
    licensorColor:string;
    category:string;
    pnlDescription: string;
    accountSet: string;
    brandRights: BrandRights = new BrandRights;
    photoApprovalRequired: boolean;
    licensingPreProApprovalRequired:boolean;
    pantoneColors:string;
    changeProductStatusOnLicensorChange:boolean;
    defaultPnlId:string;
    trimHologramRequired: boolean;
    trimHologramName:string;
    trimHangtagRequired:boolean;
    trimHangtagName:string;
    brandPnlSpecialities:BrandPnlSpeciality[];
}
/*export class AvailablePnlSPeciality {
    availablePnlSpecialities:PnlSPeciality[];
}

export class PnlSPeciality {
    licensorId:string;
    brandId:string;
    brandPnlSpecialities:BrandPnlSpeciality[];
}*/

export class BrandPnlSpeciality {
    id:string;
    licensorId:string;
    brandId:string;
    fileName:string;
    displayName:string;
    status:boolean;
    deleteFlag:boolean;
    constructor(licensorId:string,brandId:string){
        this.licensorId=licensorId;
        this.brandId=brandId;
        this.status=true;
    }
}
