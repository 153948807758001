import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { Product, ProductAttachment, StatusDetails } from 'src/app/shared/model/product.model';
import { OrderDetail, OrderDetailSizeQuantity } from 'src/app/shared/model/sales/order-detail.model';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { SubOrderDetail } from 'src/app/shared/model/sales/sub-order-detail.model';
import { Blanks } from 'src/app/shared/model/blanks.model';
import { BlanksService } from 'src/app/shared/service/blanks.service';
import { CustomerService } from 'src/app/shared/service/customer.service';
import { User } from 'src/app/shared/model/user.model';
import { Customer } from 'src/app/shared/model/customer.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { SizeOrderDetail } from 'src/app/shared/model/sales/size-order-detail.model';
import { Location } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SampleShipmentDetail } from 'src/app/shared/model/sales/sample-shipment-detail.model';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';
import { SalesOrderActivity } from 'src/app/shared/model/sales/sales-order-activity.model';
import { CustomerSalesPerson } from 'src/app/shared/model/customer-sales-person.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { LicensorSample } from 'src/app/shared/model/licensor.model';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { ExternalLinks } from 'src/app/shared/model/common/external-links.model';
import { ExternalLinksService } from 'src/app/shared/service/external-links/external-links.service';
import { ChangeContainer } from 'src/app/shared/model/common/change-container.model';
import { CanComponentDeactivate } from 'src/app/auth/guards/deactivate.gaurd';
import { AllocationSheetComponent } from '../costing/allocation/allocation-sheet/allocation-sheet.component';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { SageService } from 'src/app/shared/service/sage/sage.service';
import { VendorsService } from 'src/app/shared/service/vendors/vendors.service';
import { Printer } from 'src/app/shared/model/vendors/printer.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { MetadataSize } from 'src/app/shared/model/product-metadata/metadata-size.model';


@Component({
   selector: 'app-sales-order',
   templateUrl: './sales-order.component.html',
   styleUrls: ['./sales-order.component.css']
})
export class SalesOrderComponent implements OnInit, CanComponentDeactivate {
   showProductScreen: boolean = false;
   showCreateProductScreen:boolean=false;
   selectedTab: number=0;
   HISTORY_TAB_INDEX: number = 2;
   FILE_ATT_TAB_INDEX: number = 1;
   COSTING_TAB_INDEX:number=4;

   constructor(private productCatalogService: ProductCatalogService, private productService: ProductService, private activatedRoute: ActivatedRoute,
      private salesService: SalesService, private authService: AuthService, private toastrService: ToastrService, private customerService: CustomerService,
      private metadataService: MetadataService, private fileHanldingService: FileHandlingService,
      private blanksService: BlanksService, private sharedService: SharedService, private router: Router, private dialog: MatDialog, private licensorService: LicensorService,
      private salesHelperService: SalesHelperService, private externalLinksService:ExternalLinksService,private location: Location,
      private productMetadataService:ProductMetadataService,private sageService:SageService,private productionTrackerServcice:ProductionTrackerService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
         return false;
      }
   }
   @ViewChild(AllocationSheetComponent) costing: AllocationSheetComponent;
   confirm(): boolean {
      if(this.costing && !this.costing.confirm()){
         return false;
      }
      return (!this.editable && !this.createAction);
   }

   @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.editable || this.createAction || !this.costing?.confirm()){
      return false;
    }else{
    return true;
    }
  }


   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   currentDate: Date = new Date();
   createAction: boolean = false;
   viewAction: boolean = false;
   cloneAction: boolean = false;
   editable: boolean = false;
   editableFileAttachments: boolean = false;
   //currentSalesOrderId: string;
   salesOrder: SalesOrder = new SalesOrder;
   selectedProductList: Product[] = [];
   //blanksList: Blanks[] = null; // DO NOT REMOVE
   customerList: Customer[] = [];
   currencyListFromMetadata: string[] = [];

   packagingTypeListFromMetadata: string[] = [];
   //sizesListFromMetadata: string[] = [];
   sizesMetadataList: MetadataSize[] = [];
   defaultSizeListFromMetadata: string[] = [];
   currentOrderDetail: OrderDetail = new OrderDetail;

   selectedOrderDetailForProductChange: OrderDetail;
   orderDetailToClone: OrderDetail;
   orderDetailOperationType: string = '';
   salesRepListForCustomer: CustomerSalesPerson[] = [];
   //customerPoTypeListFromMetadata: string[] = [];
   showSubOrderDetailPoCustomer: boolean = false;
   colspanIncrementCount: number = 0;
   poCustomerList: Customer[];
   productionStatusList:any[]=[];
   selectedProductionStatusTemp:string;
   fileClosingExternalLinks:ExternalLinks[]=[];
   salesOrderActivityList:SalesOrderActivity[]=[];
   
   costingEventsSubject: Subject<string> = new Subject<string>();
   blankSummaryEventsSubject: Subject<string> = new Subject<string>();
   printSummaryEventsSubject: Subject<string> = new Subject<string>();
   createItemAuditEventsSubject: Subject<string> = new Subject<string>();
   prodTrackerEventsSubject: Subject<string> = new Subject<string>();

   skuStatusesNotAllowedForPreProRequiredFromMetadata: string[] = [];

   requestFilterSalesOrderType: string;
   createItemAuditErrorsCount:number;
   participatingPrinterList:Printer[]=[];
   
   async ngOnInit() {
      this.productionStatusList=this.salesHelperService.productionStatusList;
      this.salesHelperService.loadOrderDetailMetadata();
      this.handleSubscriptions();
      this.evaluateAction();
      window.scrollTo(0, 0);
      await this.getCustomerListForLoggedInUser();
      await this.getSalesOrder();
      this.createDefaultSizeListFromMetadata();// Calling again even though execued with subscription because For Estimation type can only be determined after load if sales Order
      //this.loadBlanks();
      await this.getCustomerAssociationList();
      this.loadCurrencyListFromMetadata();
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });
      this.productMetadataService.sizesMetadataListObservable.subscribe(res => {
         this.sizesMetadataList = res;
         this.createDefaultSizeListFromMetadata();
      });
      this.salesHelperService.packagingTypeListFromMetadata.subscribe(res => {
         this.packagingTypeListFromMetadata = res;
      });

      this.salesHelperService.skuStatusesNotAllowedForPreProRequiredFromMetadata.subscribe(res=>{
         this.skuStatusesNotAllowedForPreProRequiredFromMetadata = res;
      });
      /*this.vendorService.printerListObservable.subscribe(res => {
         if(res && res!=null && res.length>0){
           this.printerList.length=0;
           this.printerList.push(...res);
         }
         
      });*/
   }

   evaluateAction() {

      if (this.activatedRoute.snapshot.data && this.activatedRoute.snapshot.data.requestFilterSalesOrderType) {
         this.requestFilterSalesOrderType = this.activatedRoute.snapshot.data.requestFilterSalesOrderType;
         //console.log('requestFilterSalesOrderType = ', this.requestFilterSalesOrderType);
      }

      const activatedRoutePath = this.activatedRoute.snapshot.routeConfig.path;
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.cloneAction = false;
         this.createAction = true;
         this.editable = true;
         if(this.requestFilterSalesOrderType === 'Estimation'){
            this.salesOrder.orderType="Estimation";
         }
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "edit") {
         this.cloneAction = false;
         this.createAction = false;
         this.editable = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "clone") {
         this.cloneAction = true;
         this.createAction = true;
         this.editable = true;
      }
   }


   /************************************* START: BLANKS DETAILS ********************************/
   /* DO NOT REMOVE
   
   loadBlanks() {
      this.blanksService.getBlanksList().subscribe(response => {
         this.blanksList = response.responsePayload;
         this.blanksList = this.sharedService.sortListByPropertyName(this.blanksList, 'skuCode');
      }, err => {
         console.error('Error while loading Blanks : ' + JSON.stringify(err));
      });
   }

   getBlanks(blanksId: string): Blanks {
      let blanks = this.blanksList.find(item => item.id === blanksId);
      return blanks;
   }

   
   onBlanksSkuCodeChange(event: any, orderDetail: OrderDetail) {
       let blanks = new Blanks;
       blanks = this.getBlanks(orderDetail.blanksId);
       if (blanks === undefined || blanks === null) {
          blanks = new Blanks;
       }
       orderDetail.blanks = blanks;
    }

    */

   /************************************* END: BLANKS DETAILS ********************************/

   /*loadPackagingTypeListFromMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndType('SALES', 'PACKAGING_TYPE').subscribe(response => {
         this.packagingTypeListFromMetadata = response.responsePayload;
      }, err => {
         console.error('Error while loading Prackaging Type from Metadata : ' + JSON.stringify(err));
      });
   }*/

   loadCurrencyListFromMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndType('SALES', 'CURRENCY').subscribe(response => {
         this.currencyListFromMetadata = response.responsePayload;
      }, err => {
         console.error('Error while loading Currency List from Metadata : ' + JSON.stringify(err));
      });
   }

   /*loadCustomerPOTypeListFromMetadata() {
      this.metadataService.getMetadataValueListByClassificationAndType('SALES', 'CUSTOMER_PO_TYPE').subscribe(response => {
         this.customerPoTypeListFromMetadata = response.responsePayload;
      }, err => {
         console.error('Error while loading Customer PO TYPE from Metadata : ' + JSON.stringify(err));
      });
   }*/

   /************************************* START: CUSTOMER RELATED *************************************/

   async getCustomerListForLoggedInUser() {
      await this.customerService.getCustomerListForLoggedInUser(this.currentLoggedInUser).toPromise().then(response => {
         this.customerList = response.responsePayload;
         this.customerList = this.sharedService.sortListByPropertyName(this.customerList, 'customerName');
      }, err => {
         console.error('Error while loading Customers : ' + JSON.stringify(err));
      });
      
   }

   getCustomer(customerId: string): Customer {
      let customer = this.customerList.find(item => item.id === customerId);
      return customer;
   }

   setProductionCoordinatorIntoSalesOrder(customer: Customer) {
      this.salesOrder.productionCoordinatorId = customer.productionCoordinator?.id;
      this.salesOrder.productionCoordinator = customer.productionCoordinator;
   }

   onCustomerChange() {
      let customer = new Customer;
      customer = this.getCustomer(this.salesOrder.customerId);
      if(customer){
         this.salesOrder.customerName = customer.customerName;
         this.loadSalesRepListForCustomer();
         this.setDefaultSharedSalesReps(customer);
         this.resetSubOrderDetailPOCustomerDropdowns(customer);
         this.getCustomerAssociationList();
         this.setProductionCoordinatorIntoSalesOrder(customer);
      }
   }

   resetSubOrderDetailPOCustomerDropdowns(customer:Customer) {
      if (this.salesOrder.customerId) {
         for (let index = 0; index < this.salesOrder.orderDetails.length; index++) {
            let orderDetail: OrderDetail = this.salesOrder.orderDetails[index];
            for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
               let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
               if(subOrderDetail.poCustomerId && customer.customerAssociationList && customer.customerAssociationList.length>0){
                  let validAssociatedCustomer=customer.customerAssociationList.filter(x=>x.id===subOrderDetail.poCustomerId);
                  if(!validAssociatedCustomer || validAssociatedCustomer.length===0){
                     subOrderDetail.poCustomerId = null; // if assigned associated customer is no more associated to the customer
                  }else{
                     //let the association be retained
                  }
               }else{
                  subOrderDetail.poCustomerId = null;// if assigned associated customer is no more associated to the customer
               }
               
               
            }
         }
      }
   }

   setDefaultSharedSalesReps(customer: Customer) {
      this.salesOrder.sharedSalesRepUserNameList = [];
      let currentUserSalesPersonObj: CustomerSalesPerson = customer.customerSalesPersonList.find(x => x.userId === this.currentLoggedInUser.id);
      if (currentUserSalesPersonObj && currentUserSalesPersonObj.sharedSalesRepUserNameList && currentUserSalesPersonObj.sharedSalesRepUserNameList.length > 0) {
         Object.assign(this.salesOrder.sharedSalesRepUserNameList, currentUserSalesPersonObj.sharedSalesRepUserNameList);
      }
   }

   loadSalesRepListForCustomer() {
      if (this.customerList && this.customerList != null && this.customerList.length > 0) {
         let customer = new Customer;
         customer = this.getCustomer(this.salesOrder.customerId);
         this.salesRepListForCustomer = customer.customerSalesPersonList;
         //console.log('SAles Rep Name for customer',customer, this.salesRepListForCustomer);
      }
   }


   /************************************* END: CUSTOMER RELATED *************************************/


   /************************************* START: SALES ORDER *************************************/

   async getSalesOrder() {
      let salesOrderId = this.activatedRoute.snapshot.paramMap.get('id');
      //this.currentSalesOrderId = salesOrderId;
      if (salesOrderId) {
         await this.loadSalesOrder(salesOrderId);
         this.selectedTab=this.sharedService.getTabSelection("SALES_"+salesOrderId);
         if(this.salesOrder.productionStatus===this.salesHelperService.productionStatusForCostingPendingApproval
            || this.salesOrder.productionStatus===this.salesHelperService.productionStatusForCostingPending){
            this.selectedTab=this.COSTING_TAB_INDEX;
         }
      }
   }

   tabInstantiated(){
      
   }

   async loadSalesOrder(salesOrderId: string) {
      if (salesOrderId) {
         await this.salesService.getSalesOrder(salesOrderId).toPromise().then(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let salesOrderFromDB: SalesOrder = response.responsePayload as SalesOrder;
               if (this.cloneAction) {
                  this.cloneAction = false;
                  this.cloneSalesOrder(salesOrderFromDB);
               } else {
                  this.salesOrder = salesOrderFromDB;
                  //this.currentSalesOrderId = salesOrderFromDB.id;
               }
               this.loadParticipatingVendors(salesOrderId);
                this.getCustomerAssociationList();
                this.evaluateCreateItemsErrorCount();
            } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }

   
   onEventFromCreateItem(eventName:string){
      //alert('Reload from onEventFromCreateItem');
      this.evaluateCreateItemsErrorCount();
     
    }

   private evaluateCreateItemsErrorCount() {
      if (this.salesOrder.submissionCount > 0) {
         this.createItemAuditErrorsCount = 0;
         this.sageService.getCreateItemAuditErrorCount('SALES_ORDER', this.salesOrder.id).subscribe(res => {
            if (res && res > 0) {
               this.createItemAuditErrorsCount = res;
            }
         });
      }
   }

   cloneSalesOrder(salesOrderFromDB: SalesOrder) {
      if (salesOrderFromDB) {
         //this.currentSalesOrderId = undefined;
         this.salesOrder = JSON.parse(JSON.stringify(salesOrderFromDB));
         this.salesOrder.id = undefined;
         this.salesOrder.status = undefined;
         this.salesOrder.orderType = undefined;
         this.salesOrder.productionStatus = undefined;
         this.salesOrder.productionStatusNote = undefined;
         this.salesOrder.productionStatusReason = undefined;
         this.salesOrder.salesOrderNumber = undefined;
         this.salesOrder.orderCreatedDate = undefined;
         this.salesOrder.orderInitialSubmitDate = undefined;
         this.salesOrder.createdBy = undefined;
         this.salesOrder.salesRepName = undefined;
         this.salesOrder.customerPONumberList = [];
         this.salesOrder.salesOrderHistoryExport = [];
         this.salesOrder.customerPOAttachments = [];
         this.salesOrder.distributionListAttachments = [];
         this.salesOrder.estimationSpecSheet = [];
         this.salesOrder.pnlSpecialityAttachments = [];
         this.salesOrder.blanksPOAttachments =[];
         this.salesOrder.blanksPackagingAttachments =[];
         this.salesOrder.blanksPodAttachments=[];
         this.salesOrder.printerPOAttachments =[];
         this.salesOrder.printerPackagingAttachments =[];
         this.salesOrder.finishingSheetAttachments =[];
         this.salesOrder.orderProcessingChecklistAttachments =[];
         this.salesOrder.signedBolAttachments =[];
         this.salesOrder.invoicesAttachments=[];
         this.salesOrder.emailConfirmationAttachments=[];
         this.salesOrder.chargeBackCommunicationAttachments=[];
         this.salesOrder.customerDiscountsAttachments=[];
         this.salesOrder.freightQuotesAttachments=[];
         this.salesOrder.flagged=false;
   
         this.salesOrder.activityNotes = [];
         this.salesOrder.orderDetails = [];
         if (this.salesOrder.sampleShipmentDetailList && this.salesOrder.sampleShipmentDetailList.length > 0) {
            this.salesOrder.sampleShipmentDetailList.forEach(sampleShipmentDetail => {
               sampleShipmentDetail.id = undefined;
               sampleShipmentDetail.parentId = undefined;
            });
         }

         if (salesOrderFromDB.orderDetails && salesOrderFromDB.orderDetails.length > 0) {
            salesOrderFromDB.orderDetails.forEach(orderDetail => {
               if(orderDetail.status!=='CANCELLED'){
                  let clonedOrderDetail = this.cloneOrderDetails(orderDetail, undefined);
                  this.salesOrder.orderDetails.push(clonedOrderDetail);
               }
            });
         }
         this.reCalculateFullSalesOrder();
         this.onCustomerChange();//this.recheckProductionCoordinator();
      }
   }

   reCalculateFullSalesOrder() {
      this.salesHelperService.reCalculateFullSalesOrder(this.salesOrder);
   }

   doesProductExistsInSalesOrder(productId: string): boolean {
      if (this.salesOrder.orderDetails !== null && this.salesOrder.orderDetails !== undefined) {
         for (let index = 0; index < this.salesOrder.orderDetails.length; index++) {
            if (!this.salesOrder.orderDetails[index].deleteFlag && this.salesOrder.orderDetails[index].status!=='CANCELLED') {
               if (this.salesOrder.orderDetails[index].productId === productId) {
                  this.toastrService.info('SKU ' + this.salesOrder.orderDetails[index].product.sku + ' already present in the sales order.');
                  return true;
               }
            }
         }
      }
      return false;
   }

   isProductPreProApprovaRequired(product: Product): boolean {
      //let preProApprovalSkuStatusNotAllowedList: string[] = ['Not Submitted', 'Concept Approved', 'Concept Requires Revision']; 
      if (product.licensingPreProApprovalRequired) {
         if(this.skuStatusesNotAllowedForPreProRequiredFromMetadata.includes(product.approvalStatus)){
            this.toastrService.info('SKU ' + product.sku + '  must be pre-pro approved before ordering. Please contact Licensing.');
            return true;
         }
      }
      return false;
   }

   editSalesOrder() {
      this.editable = true;
      this.loadSalesRepListForCustomer();
   }

   async cancelEditOrCreateSalesOrder() {
      if (this.salesOrder.id === null || this.salesOrder.id === undefined) {
         this.cancelCreateConfirmDialog();
      } else {
         await this.loadSalesOrder(this.salesOrder.id);
         this.editable = false;
      }
   }

   cancelCreateConfirmDialog() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Cancel Create ' +this.salesOrder.orderType+' Order',
            message: this.salesOrder.orderType+' Order is not saved. Are you sure you want to cancel ?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            if(this.salesOrder.orderType === 'Estimation'){
               this.router.navigate(['estimation/list']);
            }else{
               this.router.navigate(['salesOrder/list']);
            }
         } else {

         }
      });
   }

   async getCustomerAssociationList(): Promise<Customer[]> {
      if (this.salesOrder.customerId && (!this.customerList || this.customerList === null || this.customerList.length === 0)) {
         await this.customerService.getCustomerById(this.salesOrder.customerId).toPromise().then(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let customerForSalesOrder = response.responsePayload;
               if (customerForSalesOrder) {
                  this.customerList = [];
                  this.customerList.push(customerForSalesOrder);
               }
            }
         }
         );
      }
      if (this.salesOrder && this.salesOrder.customerId && this.customerList && this.customerList != null && this.customerList.length > 0) {
         let customer: Customer = this.getCustomer(this.salesOrder.customerId);
         let customerAssociationList: Customer[] = customer.customerAssociationList;
         if (customerAssociationList !== null && customerAssociationList !== undefined && customerAssociationList.length>0) {
            this.showSubOrderDetailPoCustomer = true;
            this.colspanIncrementCount = 1;
         } else {
            this.showSubOrderDetailPoCustomer = false;
            this.colspanIncrementCount = 0;
         }

         this.poCustomerList = customerAssociationList;
         return customerAssociationList;
      }

   }

   saveSalesOrder() {
      this.salesOrder.status = "DRAFT";
      if (this.salesOrder === null || this.salesOrder.id === null || this.salesOrder.id === undefined) {
         this.createSalesOrder(this.salesOrder);
      } else {
         this.updateSalesOrder(this.salesOrder);
      }
   }

   createSalesOrder(salesOrder: SalesOrder) {
      this.salesService.createSalesOrder(salesOrder).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let createdSalesOrderFromDB = response.responsePayload;
            this.editable = false;
            this.createAction = false;
            //this.loadSalesOrder(createdSalesOrderFromDB.id);
            if(salesOrder.orderType === 'Estimation'){
               this.router.navigate(['estimation/view/' + createdSalesOrderFromDB.id]);
            }else{
               this.router.navigate(['salesOrder/view/' + createdSalesOrderFromDB.id]);
            }
            this.toastrService.success(response.responseStatus.message);
         } else {
            console.error("Sales Order Component - Sales Order create failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   updateSalesOrder(salesOrder: SalesOrder) {
      this.salesService.updateSalesOrder(salesOrder).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let updatedSalesOrderFromDB = response.responsePayload;
            this.salesOrder=updatedSalesOrderFromDB;
            this.editable = false;
            
            //this.loadSalesOrder(updatedSalesOrderFromDB.id);
            this.toastrService.success(response.responseStatus.message);
         } else {
            console.error("Sales Order Component - Sales Order update failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   submitSalesOrder() {
      if(this.salesOrder && this.salesOrder.orderType !=='Estimation'){
         if (this.validateIfSalesOrderContainsMocks()) {
            return;
         }
         this.populateLicensorSampleDetails();
      }else if (this.salesOrder && this.salesOrder.orderType ==='Estimation'){
         this.reCalculateFullSalesOrder();
         this.submitSalesOrderAfterPopulatingLicensorSampleDetails();
      }
   }

   async submitSalesOrderAfterPopulatingLicensorSampleDetails() {
      //this.salesOrder.status = "SUBMITTED"; // This line of code is moved to backend
      await this.salesService.submitSalesOrder(this.salesOrder).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.loadSalesOrder(this.salesOrder.id);
            this.toastrService.success(response.responseStatus.message);
         } else {
            console.error("Sales Order Component - Sales Order Submit failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   validateIfSalesOrderContainsMocks(): boolean {
      let isMockFlag: boolean = false;
      let stylesNotConvertedToSku = [];
      for (let index = 0; index < this.salesOrder.orderDetails.length; index++) {
         let orderDetail: OrderDetail = this.salesOrder.orderDetails[index];
         if (!orderDetail.product.convertedToSKU) {
            stylesNotConvertedToSku.push(orderDetail.product.sku);
            isMockFlag = true;
         }
      }

      if (isMockFlag) {
         this.toastrService.error("Sales Order cannot be submitted. Sales Order contains styles [" + stylesNotConvertedToSku + "] that are Mocks.")
         return isMockFlag;
      }
      return false;
   }

   revokeSalesOrderSubmission() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            maxlength: 250,
            modalType: 'TEXTAREA_INPUT',
            textInputRegex: '^(.|\\s)*\\S(.|\\s)*$',
            textInputDefault: '',
            textInputRegexError: 'Reason is required',
            title: 'Unsubmit ' +this.salesOrder.orderType+ ' Order# [' + this.salesOrder.salesOrderNumber + ']',
            message: 'Are you sure you want to unsubmit the ' +this.salesOrder.orderType+ ' Order? If Yes, Please provide reason.',
            confirmBtnText: 'Yes',
            cancelBtnText: 'Cancel'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            //salesOrder.setRevokeReason(res);
            //let salesOrderActivity: SalesOrderActivity = new SalesOrderActivity;
           // salesOrderActivity.notes = res;
            this.salesOrder.activityNotes = [];
            this.salesOrder.activityNotes.push(res);
            //this.salesHelperService.updateAndPropogateSalesOrderStatus(this.salesOrder, 'DRAFT', 'ACTIVE', 'ACTIVE');
            this.reCalculateFullSalesOrder();
            this.unsubmitSalesOrder(this.salesOrder);
         } else {

         }
      });
   }

   unsubmitSalesOrder(salesOrder: SalesOrder) {
      this.salesService.unsubmitSalesOrder(salesOrder).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            this.loadSalesOrder(this.salesOrder.id);
            this.toastrService.success(response.responseStatus.message);
         } else {
            console.error("Sales Order Component - Sales Order unsubmit failed...", response.responseStatus.message);
            this.toastrService.error(response.responseStatus.message);
         }
      },
         error => {
            console.error(error);
            this.toastrService.error(error.message);
         });
   }

   /************************************* END: SALES ORDER *************************************/


   /************************************* START: ORDER DETAILS *********************************/

   handleEventFromOrderDetails(eventType: string) {
      if (eventType === 'RECALCULATE_FULL_SALES_ORDER') {
         this.reCalculateFullSalesOrder();
      }
      else if (eventType === 'UPDATE_SALES_ORDER') {
         this.updateSalesOrder(this.salesOrder);
      }
   }

   handleCloneEventFromOrderDetails(orderDetail: OrderDetail) {
      this.cloneOrderDetail(orderDetail);
   }

   handleChangeProductSku(orderDetail: OrderDetail) {
      this.changeProductSku(orderDetail);
   }

   addNewOrderDetail() {
      this.showProductScreen = true;
      this.orderDetailOperationType = 'ADD';
      this.productCatalogService.selectedProductsForCatalog = [];
      document.getElementById('skuSelectionModalToggler').click();
   }

   createAndAddOrderDetail() {
      this.showCreateProductScreen = true;
      this.orderDetailOperationType = 'ADD';
      this.productCatalogService.selectedProductsForCatalog = [];
      document.getElementById('skuCreationModalToggler').click();
   }

   adhocProductCreatedFroEstimation(productId:string){
      //document.getElementById('closeCreateSkusModalModal').click();
      this.productService.loadProductById(productId).subscribe(res=>{
         let product = res as Product;
         if(product!==null && product!=undefined){
            this.productCatalogService.addProduct(product);
            this.addSelectedProductsToSalesOrder('skuCreationModalToggler');
         }
      });
   }
   

   showProductScreenCancelled() {
      this.showProductScreen = false;
   }

   addSelectedProductsToSalesOrder(modalId:string) {
      this.showProductScreen = false;
      this.showCreateProductScreen=false;
      this.selectedProductList = this.productCatalogService.selectedProductsForCatalog;
      for (let index = 0; index < this.selectedProductList.length; index++) {
         if (!this.doesProductExistsInSalesOrder(this.selectedProductList[index].id) && !this.isProductPreProApprovaRequired(this.selectedProductList[index])) {
            if (this.salesOrder.orderDetails === null || this.salesOrder.orderDetails === undefined) {
               this.salesOrder.orderDetails = [];
            }
            let newProductForOrder = this.selectedProductList[index];
            this.addNewProductToSalesOrder(newProductForOrder);
         }
         this.orderDetailOperationType = '';
      }
         	document.getElementById(modalId).click();
      
   }


   private addNewProductToSalesOrder(newProductForOrder: Product) {
      if (newProductForOrder.exclusiveToCustomers) {
         this.productService.isProductExclusiveToCustomer(newProductForOrder.id, this.salesOrder.customerId).subscribe(
            res => {
               let serviceStatus: ServiceStatus = res as ServiceStatus;
               if (serviceStatus.status === 'SUCCESS') {
                  this.createNewOrderDetail(newProductForOrder.id, this.salesOrder.id, newProductForOrder);
               } else {
                  this.toastrService.error(newProductForOrder.sku + ' is an Exclusive Product and can not be added for ' + this.salesOrder.customerName);
               }
            }
         );
      } else {
         this.createNewOrderDetail(newProductForOrder.id, this.salesOrder.id, newProductForOrder);
      }
   }

   changeProductSku(orderDetail: OrderDetail) {
      this.selectedOrderDetailForProductChange = orderDetail;
      this.showProductScreen = true;
      this.orderDetailOperationType = 'UPDATE';
      this.productCatalogService.selectedProductsForCatalog = [];
      document.getElementById('skuSelectionModalToggler').click();
   }

   replaceProductSku() {
      this.selectedProductList = this.productCatalogService.selectedProductsForCatalog;
      if (this.selectedProductList.length && this.selectedProductList.length > 1) {
         this.toastrService.error('Please select one product/Sku to replace the current style.');
         return false;
      }
      let productSelectedForChange = this.selectedProductList[0];
      if (this.selectedProductList.length && productSelectedForChange !== null && productSelectedForChange !== undefined) {
         if (!this.doesProductExistsInSalesOrder(productSelectedForChange.id)) {
            this.selectedOrderDetailForProductChange.product = productSelectedForChange;
            this.selectedOrderDetailForProductChange.productId = productSelectedForChange.id;
            this.showProductScreen = false;
            this.orderDetailOperationType = '';
            this.resetLicensorSampleQuantity(this.selectedOrderDetailForProductChange);
            this.salesHelperService.populateBrandRequiredTrimsIntoOrderDetail(this.salesOrder,this.selectedOrderDetailForProductChange.product,this.selectedOrderDetailForProductChange);
            document.getElementById('skuSelectionModalToggler').click();
            return true;
         } else {
            return false;
         }
      } else {
         // No Product Selected
         this.showProductScreen = false;
         document.getElementById('skuSelectionModalToggler').click();
      }
   }

   cloneOrderDetail(orderDetail: OrderDetail) {
      this.orderDetailToClone = orderDetail;
      this.showProductScreen = true;
      this.orderDetailOperationType = 'CLONE';
      this.productCatalogService.selectedProductsForCatalog = [];
      document.getElementById('skuSelectionModalToggler').click();
   }

   cloneOrderDetailWithSelectedProduct() {
      this.showProductScreen = false;
      this.selectedProductList = this.productCatalogService.selectedProductsForCatalog;
      for (let index = 0; index < this.selectedProductList.length; index++) {
         if (!this.doesProductExistsInSalesOrder(this.selectedProductList[index].id)) {
            this.cloneIndividualOrderDetail(index);
         }
         this.orderDetailOperationType = '';
      }
      document.getElementById('skuSelectionModalToggler').click();
      this.reCalculateFullSalesOrder();
   }

   private cloneIndividualOrderDetail(index: number) {
      let clonedOrderDetail: OrderDetail = this.cloneOrderDetails(this.orderDetailToClone, this.orderDetailToClone.parentId);
      clonedOrderDetail.product = this.selectedProductList[index];
      clonedOrderDetail.productId = this.selectedProductList[index].id;
      if (this.salesOrder.orderDetails === null || this.salesOrder.orderDetails === undefined) {
         this.salesOrder.orderDetails = [];
      }
      this.salesHelperService.populateBrandRequiredTrimsIntoOrderDetail(this.salesOrder,clonedOrderDetail.product,clonedOrderDetail);
      this.salesOrder.orderDetails.push(clonedOrderDetail);
   }

   cloneOrderDetails(orderDetailToClone: OrderDetail, parentId: string): OrderDetail {
      let newOrderDetail: OrderDetail = JSON.parse(JSON.stringify(orderDetailToClone));
      let defaultIdValue: any = undefined;
      newOrderDetail.id = defaultIdValue;
      newOrderDetail.parentId = parentId;
      if (newOrderDetail.subOrderDetailList && newOrderDetail.subOrderDetailList.length > 0) {
         newOrderDetail.subOrderDetailList.forEach(element => {
            element['id'] = defaultIdValue;
            if (element.sizeOrderDetailList && element.sizeOrderDetailList.length > 0) {
               element.sizeOrderDetailList.forEach(element => {
                  element['id'] = defaultIdValue;
               });
            }
         });
      }
      if (newOrderDetail.orderDetailSizeQuantityList && newOrderDetail.orderDetailSizeQuantityList.length > 0) {
         newOrderDetail.orderDetailSizeQuantityList.forEach(element => {
            element['id'] = defaultIdValue;
         });
      }
      this.resetLicensorSampleQuantity(newOrderDetail);
      return newOrderDetail
   }

   createNewOrderDetail(productId: string, parentId: string, product: Product) {
      let newOrderDetail = new OrderDetail;
      newOrderDetail.productId = productId;
      newOrderDetail.parentId = parentId;
      newOrderDetail.product = product;
      newOrderDetail.status = "ACTIVE";
      if(this.salesOrder.orderType === 'Estimation'){
         newOrderDetail.cost=0;
         newOrderDetail.retailCost=0;
      }
      this.createOrderDetailSizeAndQuantity(newOrderDetail);
      let newSubOrderDetail = this.salesHelperService.createNewSubOrderDetailForSales(this.salesOrder, newOrderDetail, this.packagingTypeListFromMetadata);
      let newSubOrderDetailForPPSample = this.salesHelperService.createNewSubOrderDetailForPPSample(this.salesOrder,newOrderDetail, this.packagingTypeListFromMetadata);
      let newSubOrderDetailForTopSample = this.salesHelperService.createNewSubOrderDetailForTopSample(this.salesOrder,newOrderDetail, this.packagingTypeListFromMetadata);
      let newSubOrderDetailForLicensorSample = this.salesHelperService.createNewSubOrderDetailForLicensorSample(this.salesOrder,newOrderDetail, this.packagingTypeListFromMetadata);
      newOrderDetail.subOrderDetailList.push(newSubOrderDetail);
      newOrderDetail.subOrderDetailList.push(newSubOrderDetailForPPSample);
      newOrderDetail.subOrderDetailList.push(newSubOrderDetailForTopSample);
      newOrderDetail.subOrderDetailList.push(newSubOrderDetailForLicensorSample);
      //this.populateBlanksColour(newOrderDetail);
      this.salesHelperService.reCalculateOrderDetailSizeAndQuantity(newOrderDetail);
      this.salesHelperService.populateBrandRequiredTrimsIntoOrderDetail(this.salesOrder,product,newOrderDetail);
      this.salesOrder.orderDetails.push(newOrderDetail);
      this.salesHelperService.reCalculateFullSalesOrder(this.salesOrder);
      //return newOrderDetail;
   }

   populateBlanksColour(orderDetail: OrderDetail) {
      if (orderDetail.product.treatment !== null && orderDetail.product.treatment !== undefined && (orderDetail.product.treatment === 'None' || orderDetail.product.treatment === 'none')) {
         orderDetail.blanksColour = orderDetail.product.color;
      } else {
         orderDetail.blanksColour = null;
      }
   }

   createOrderDetailSizeAndQuantity(orderDetail: OrderDetail) {
      let newOrderDetailSizeQuantityList: OrderDetailSizeQuantity[] = [];
      for (let index = 0; index < this.defaultSizeListFromMetadata.length; index++) {
         let defaultSizeForNewOrderDetail: string = this.defaultSizeListFromMetadata[index];
         let orderDetailSizeQuantity: OrderDetailSizeQuantity = new OrderDetailSizeQuantity;
         orderDetailSizeQuantity.parentId = orderDetail.id;
         orderDetailSizeQuantity.size = defaultSizeForNewOrderDetail;
         orderDetailSizeQuantity.overrideSize = null;
         orderDetailSizeQuantity.quantity = 0;
         orderDetailSizeQuantity.selected = true;
         newOrderDetailSizeQuantityList.push(orderDetailSizeQuantity);
      }
      orderDetail.orderDetailSizeQuantityList = newOrderDetailSizeQuantityList;
   }

   createDefaultSizeListFromMetadata() {
      let classificationForDefaultSizes: string = 'SALES';
      if(this.salesOrder.orderType === 'Estimation'){
         classificationForDefaultSizes = 'ESTIMATION';
      }
      if(this.sizesMetadataList?.length>0 &&  (!this.defaultSizeListFromMetadata|| this.defaultSizeListFromMetadata.length===0)){
         this.metadataService.getMetadataValueListByClassificationAndType(classificationForDefaultSizes, 'DEFAULT_SIZES').subscribe(response => {
            this.defaultSizeListFromMetadata = [];
            let defaultSizesFromMetadata: string[] = response.responsePayload;
            for (let index = 0; index < this.sizesMetadataList.length; index++) {
               let sizeFromMetadata: string = this.sizesMetadataList[index].size;
               if (this.sizesMetadataList[index].status && defaultSizesFromMetadata.includes(sizeFromMetadata)) {
                  this.defaultSizeListFromMetadata.push(sizeFromMetadata);
               }
            }
         }, err => {
            console.error('Error while creating Defaule Sizes List from Metadata : ' + JSON.stringify(err));
         });
      }
   }

   getOrderDetailFromSalesOrder(orderDetailId: string) {
      for (let index = 0; index < this.salesOrder.orderDetails.length; index++) {
         let orderDetail: OrderDetail = this.salesOrder.orderDetails[index];
         if (orderDetail.id === orderDetailId) {
            return orderDetail;
         }
      }
   }

   /************************************* END: ORDER DETAILS *******************************/
   goBack() {
      this.location.back();
      //this.router.navigate(['salesOrder/list']);
   }

   

   addSampleShipmentDetails() {
      this.salesOrder.sampleShipmentDetailList.push(new SampleShipmentDetail);
   }

   deleteSampleShipmentDetails(sampleShipmentDetail: SampleShipmentDetail) {
      sampleShipmentDetail.deleteFlag = true;
   }

   /************************* END: SIZE OVERRIDE CHECKBOX AND DROPDOWN HANDLING **************************/

   exportExcel(type: string) {
      this.salesService.exportExcel(this.salesOrder, type).subscribe(res => {
         if (res && res !== null) {
            //('ArrayBuffer ->',res)
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '_' + mm + '_' + yyyy;
            const filename = this.salesOrder.salesOrderNumber + '_' + this.salesOrder.customerName + '_' + todayStr + ".xlsx"
            this.toastrService.success("Sales order exported as excel.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Sales order export failed.");
         }
         //
         //alert(res);
      }, err => {
         this.toastrService.error("Sales order export failed. Error - " + err);
      });
   }

   excelExportConfirmDialog() {
      let productionSheetTypeList: any[] = [{ 'id': 'INITIAL', 'name': 'Production' }, { 'id': 'PRINTER_REF', 'name': 'Printer' }]
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Production Sheet - Excel Export',
            modalType: 'DROPDOWN',
            message: 'Please select the Production Sheet Type from the dropdown.',
            confirmBtnText: 'Ok',
            cancelBtnText: 'Cancel',
            dropdownList: productionSheetTypeList
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            /*if (res.toUpperCase() === 'PRODUCTION'.toUpperCase()) {
               res = 'INITIAL'
            }
            
            */
            this.exportExcel(res);
         } else {

         }
      });
   }

   /****************************Start: CATALOG Redirection ****************************/

   redirectToCatalogForCurrentSales() {
      this.resetCatalogSelection();
      this.salesOrder.orderDetails.forEach(orderDetail => {
         this.productCatalogService.addProduct(orderDetail.product);
      });
      this.router.navigate(['/product-catalog']);
   }

   resetCatalogSelection() {
      this.productCatalogService.selectedProductsForCatalog = [];
      if (this.productCatalogService.catalogSettings) {
         this.productCatalogService.catalogSettings.catalogName = 'Catalog';
      }
   }
   
   /****************************End: CATALOG Redirection ****************************/

   /****************************File Handling Starts ********************************/

   filesUploadPostUpdate: File[] = [];

   loadAttachments() {
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_CUSTOMER_PO_FILES','customerPOAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_DIST_LIST_FILES','distributionListAttachments');
      
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_ESTIMATION_SPECSHEET_FILES','estimationSpecSheet');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_PNL_SPECIALITY_FILES','pnlSpecialityAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_BLANKS_PO_FILES','blanksPOAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_BLANKS_PACKAGING_FILES','blanksPackagingAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_BLANKS_POD_FILES','blanksPodAttachments');
      
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_PRINTER_PO_FILES','printerPOAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_PRINTER_PACKAGING_FILES','printerPackagingAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_FINISHING_SHEET_FILES','finishingSheetAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_ORDER_PROCESSING_CHECKLIST_FILES','orderProcessingChecklistAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_SIGNED_BOL_FILES','signedBolAttachments');

      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_INVOICES_FILES','invoicesAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_EMAIL_CONFIRMATION_FILES','emailConfirmationAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_CHARGE_BACK_COMMUNICATION_FILES','chargeBackCommunicationAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_CUST_DISCOUNT_FILES','customerDiscountsAttachments');
      this.fileHanldingService.loadChildAttachmentsForSales(this.salesOrder,'SALES_FREIGHT_QUOTES_FILES','freightQuotesAttachments');
      this.loadExternalLinks();
   }
   loadExternalLinks() {
      if (this.salesOrder.id) {
         this.externalLinksService.getExternalLinksList(this.salesOrder.id, 'SALES_ORDER', 'FILE_CLOSING').subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.fileClosingExternalLinks = res.responsePayload;
            }
         });
      }
   }

   loadActivities() {
      if (this.salesOrder.id) {
         this.salesService.getActivitiesList(this.salesOrder.id).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.salesOrderActivityList = res.responsePayload;
            }
         });
      }
   }

   

   /*private loadChildAttachments(category: string,objAttributeName:string) {
      let attachment=this.getTempAttachmentWithParentId(category);
      this.fileHanldingService.loadAttachmentsUrls(this.salesOrder, attachment, objAttributeName);
   }*/
 

   saveFileAttachments() {
      this.deleteAttachments();
      this.editableFileAttachments = false;
      this.saveAttachments('SALES_CUSTOMER_PO_FILES',this.salesOrder.customerPOAttachments, 'customerPOAttachments');
      this.saveAttachments('SALES_DIST_LIST_FILES',this.salesOrder.distributionListAttachments, 'distributionListAttachments');
      
      this.saveAttachments('SALES_ESTIMATION_SPECSHEET_FILES',this.salesOrder.estimationSpecSheet, 'estimationSpecSheet');
      this.saveGroupAttachments('SALES_PNL_SPECIALITY_FILES',this.salesOrder.pnlSpecialityAttachments, 'pnlSpecialityAttachments');
      
      this.saveAttachments('SALES_BLANKS_PO_FILES',this.salesOrder.blanksPOAttachments, 'blanksPOAttachments');
      this.saveAttachments('SALES_BLANKS_PACKAGING_FILES',this.salesOrder.blanksPackagingAttachments, 'blanksPackagingAttachments');
      this.saveAttachments('SALES_BLANKS_POD_FILES',this.salesOrder.blanksPodAttachments, 'blanksPodAttachments');
      
      this.saveGroupAttachments('SALES_PRINTER_PO_FILES',this.salesOrder.printerPOAttachments, 'printerPOAttachments');
      this.saveAttachments('SALES_PRINTER_PACKAGING_FILES',this.salesOrder.printerPackagingAttachments, 'printerPackagingAttachments');
      this.saveAttachments('SALES_FINISHING_SHEET_FILES',this.salesOrder.finishingSheetAttachments, 'finishingSheetAttachments');
      this.saveAttachments('SALES_ORDER_PROCESSING_CHECKLIST_FILES',this.salesOrder.orderProcessingChecklistAttachments, 'orderProcessingChecklistAttachments');
      this.saveAttachments('SALES_SIGNED_BOL_FILES',this.salesOrder.signedBolAttachments, 'signedBolAttachments');

      this.saveAttachments('SALES_INVOICES_FILES',this.salesOrder.invoicesAttachments,'invoicesAttachments');
      this.saveAttachments('SALES_EMAIL_CONFIRMATION_FILES',this.salesOrder.emailConfirmationAttachments,'emailConfirmationAttachments');
      this.saveAttachments('SALES_CHARGE_BACK_COMMUNICATION_FILES',this.salesOrder.chargeBackCommunicationAttachments,'chargeBackCommunicationAttachments');
      this.saveAttachments('SALES_CUST_DISCOUNT_FILES',this.salesOrder.customerDiscountsAttachments,'customerDiscountsAttachments');
      this.saveAttachments('SALES_FREIGHT_QUOTES_FILES',this.salesOrder.freightQuotesAttachments,'freightQuotesAttachments');
      this.saveExternalLinks();
   }
   saveExternalLinks() {
      if (this.salesOrder.id) {
         this.externalLinksService.saveExternalLinksList(this.fileClosingExternalLinks,this.salesOrder.id).subscribe(res => {
            if (res.responseStatus.status === 'SUCCESS') {
               this.loadExternalLinks();
            }
         });
      }
   }

   saveAttachments(category:string,attachmentList: ProductAttachment[],objAttributeName:string) {
      this.filesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(attachmentList);
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId = this.salesOrder.id;
      attachment.fileCategory = category;
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.filesUploadPostUpdate, this.salesOrder, 'salesOrderNumber', attachment,objAttributeName);
   }
   
   saveGroupAttachments(category:string,attachmentList: ProductAttachment[],objAttributeName:string) {
      //this.filesUploadPostUpdate = this.fileHanldingService.populateLocalFileinList(attachmentList);
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId = this.salesOrder.id;
      attachment.fileCategory = category;
      this.fileHanldingService.uploadLocalFileToRepoNonBlockingGroupedAttachments(attachmentList, this.salesOrder, 'salesOrderNumber', attachment,objAttributeName);
   }
   

   cancelFileAttachments() {
      this.editableFileAttachments = false;
      this.loadAttachments();
   }

   async deleteAttachments() {
      await this.deleteChildAttachments();
      this.loadAttachments();
   }

   async deleteChildAttachments() {
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.customerPOAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.distributionListAttachments, this.createAction);
      
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.estimationSpecSheet, this.createAction);
      
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.pnlSpecialityAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.blanksPOAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.blanksPackagingAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.blanksPodAttachments, this.createAction);
      
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.printerPOAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.printerPackagingAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.finishingSheetAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.orderProcessingChecklistAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.signedBolAttachments, this.createAction);

      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.invoicesAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.emailConfirmationAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.chargeBackCommunicationAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.customerDiscountsAttachments, this.createAction);
      await this.fileHanldingService.deleteMarkedFile(this.salesOrder.freightQuotesAttachments, this.createAction);
   }

   editFileAttachments() {
      this.editableFileAttachments = true;
   }

   /**************************** File Handling Ends ****************************/

   selectedTabChange(event: any) {
      if(this.salesOrder.id){
         this.sharedService.setTabSelection("SALES_"+this.salesOrder.id,event.index);
      }
      switch (event.tab.textLabel) {
         case 'fileAttachment':
            if (!this.editableFileAttachments) {
               this.loadAttachments();
            }
            break;
         case 'history':
            this.loadActivities();
            break;
         case 'costing':
            this.costingEventsSubject.next('RELOAD');
            break;
         case 'blankSummary':
            this.blankSummaryEventsSubject.next('RELOAD');
            break;
         case 'printerSummary':
            this.printSummaryEventsSubject.next('RELOAD');
            break;
         case 'createItemAuditErrors':
            this.createItemAuditEventsSubject.next('RELOAD');
            break;
            case 'productionTracker':
               this.prodTrackerEventsSubject.next('RELOAD');
               break;
            
         default:
            break;
      }
      /*switch (index) {
         case this.FILE_ATT_TAB_INDEX:
            if (!this.editableFileAttachments) {
               this.loadAttachments();
            }
            break;
         case this.HISTORY_TAB_INDEX:
            //this.loadSalesOrderHistoryExport();
            break;
         default:
            break;
      }*/
   }

   

   

   /*
   loadSalesOrderHistoryExport(){
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.parentId=this.salesOrder.id;
      attachment.fileCategory = 'SALES_ORDER_HISTORY_EXPORT';
      this.fileHanldingService.loadAttachmentsUrls(this.salesOrder, attachment, 'salesOrderHistoryExport');
   }
   */

   loadAdditionalDetailForSalesOrderActivity(activity: SalesOrderActivity, operation: string) {
      if (operation === 'LOAD_ATTACHMENT' && activity.attachmentId && activity.attachmentId !== null
         && (!activity.attachment || activity.attachment === null)) {
         let criteria: ProductAttachment = new ProductAttachment;
         criteria.id = activity.attachmentId;
         this.fileHanldingService.loadAttachmentById(criteria).subscribe(res => {
            let response = res as ProductAttachment;
            if (response && response !== null) {
               activity.attachment = response;
            } else {
               this.toastrService.error('Failed to load attachment for id - ' + activity.attachmentId);
            }
         }, error => {
            this.toastrService.error('Error while loading attachment for id - ' + activity.attachmentId, +' Error - ' + error);
         });
      }

      if (operation === 'LOAD_ATTACHMENT' && activity.diffAttachmentId && activity.diffAttachmentId !== null
         && (!activity.changeContainerArr || activity.changeContainerArr === null)) {
         this.salesService.loadChangeContainerForSubmissionDiff(activity.diffAttachmentId).subscribe(res => {
            let response = res as string;
            if (response && response !== null) {
               let arr:ChangeContainer[]=[];
               let obj=JSON.parse(JSON.stringify(response));
               arr.push(obj);
               activity.changeContainerArr = arr;
               //console.log(activity.changeContainerArr);
            } else {
               this.toastrService.error('Failed to load diff attachment for id - ' + activity.diffAttachmentId);
            }
         }, error => {
            this.toastrService.error('Error while loading diff attachment for id - ' + activity.diffAttachmentId, +' Error - ' + error);
         });
      }
   }

   removeSharedSalesPerson(sharedRepUserName) {
      this.salesOrder.sharedSalesRepUserNameList = this.salesOrder.sharedSalesRepUserNameList.filter(x => x !== sharedRepUserName);
   }

   selectedSalesRepUserName: string;
   addSharedSalesPerson(selectedSalesRepUserName: string) {
      if (this.salesOrder.sharedSalesRepUserNameList === undefined) {
         this.salesOrder.sharedSalesRepUserNameList = [];
      }
      this.salesOrder.sharedSalesRepUserNameList.push(selectedSalesRepUserName);
      this.selectedSalesRepUserName = undefined;
   }

   /******************************* START: Licensor Sample Auto Population *************************** */

   populateLicensorSampleDetails() {
      this.licensorService.getLicensorSampleListForSalesOrder(this.salesOrder).subscribe(async response => {
         let licensorSampleListForSalesOrder: any = response.responsePayload;
            for (let i = 0; i < this.salesOrder.orderDetails?.length; i++) {
            let orderDetail: OrderDetail = this.salesOrder.orderDetails[i];
            //Only for new orderDetails
            if (!orderDetail.licensorSampleStatus || orderDetail.licensorSampleStatus === null) {
               let licensorSampleList: LicensorSample[] = licensorSampleListForSalesOrder[orderDetail.product.sku];
               if (licensorSampleList !== null && licensorSampleList !== undefined && this.salesOrder.orderType==='Sales') {
                  orderDetail.licensorSampleStatus='REQUIRED';
                  this.selectMissingSizesForLicensorSamples(orderDetail, licensorSampleList);
                  for (let index = 0; index < licensorSampleList.length; index++) {
                     let licensorSample: LicensorSample = licensorSampleList[index];
                     let totalLicensorSamples: number = this.getTotalLicensorSampleForSize(licensorSample.size, licensorSampleList);
                     this.setLicensorSampleQty(licensorSample.size, totalLicensorSamples, orderDetail);
                  }
               } else {
                  orderDetail.licensorSampleStatus='NOT_REQUIRED';
                  for (let index = 0; index < orderDetail.orderDetailSizeQuantityList.length; index++) {
                     if (orderDetail.orderDetailSizeQuantityList[index].selected) {
                        this.setLicensorSampleQty(orderDetail.orderDetailSizeQuantityList[index].size, 0, orderDetail);
                     }
                  }
               }
            }
         }
      
         this.reCalculateFullSalesOrder();
         await this.submitSalesOrderAfterPopulatingLicensorSampleDetails();
      });
   }

   selectMissingSizesForLicensorSamples(orderDetail: OrderDetail, licensorSampleList: LicensorSample[]) {
      let ordeDetailSizeQuantityListForModal = this.salesHelperService.prepareSizeListForModal(orderDetail, this.sizesMetadataList);
      licensorSampleList.forEach(licensorSample => {
         ordeDetailSizeQuantityListForModal.forEach(modalList => {
            if (modalList.size === licensorSample.size && !modalList.selected) {
               modalList.selected = true;
            }
         });
      });
      this.salesHelperService.onSizeSelectionFromModal(this.salesOrder, ordeDetailSizeQuantityListForModal, this.packagingTypeListFromMetadata, orderDetail, false);
   }

   getTotalLicensorSampleForSize(licensorSampleSize: string, licensorSampleList: LicensorSample[]): number {
      let totalSampleQty: number = 0;
      let licensorSample: LicensorSample = this.findLicensorSampleBySize(licensorSampleSize, licensorSampleList);
      if (licensorSample !== null) {
         let physicalQty = licensorSample.preProductionSampleQuantity + licensorSample.finalSampleQuantity;
         if (physicalQty > 0) {
            totalSampleQty = physicalQty;
         } else {
            totalSampleQty = licensorSample.photoSampleQuantity;
         }
         // totalSampleQty = (licensorSample.preProductionSampleQuantity + licensorSample.finalSampleQuantity + licensorSample.photoSampleQuantity);
         return totalSampleQty;
      }
      return 0;
   }

   findLicensorSampleBySize(orderSize: string, licensorSampleList: LicensorSample[]): LicensorSample {
      for (let i = 0; i < licensorSampleList?.length; i++) {
         let licensorSample: LicensorSample = licensorSampleList[i];
         if (licensorSample.size === orderSize) {
            return licensorSample;
         }
      }
      return null;
   }

   setLicensorSampleQty(licensorSampleSize: string, licensorSampleQuantity: number, orderDetail: OrderDetail) {
      
      let orderDetailSizeQtyForLicensorSampleSize = orderDetail.orderDetailSizeQuantityList.filter(x => x.size === licensorSampleSize);
      if (orderDetailSizeQtyForLicensorSampleSize && orderDetailSizeQtyForLicensorSampleSize != null && orderDetailSizeQtyForLicensorSampleSize.length > 0 && orderDetailSizeQtyForLicensorSampleSize[0]) {
         if (orderDetailSizeQtyForLicensorSampleSize[0].quantity === 0) {
            if (orderDetail.overrideSizes
               && (!orderDetailSizeQtyForLicensorSampleSize[0].overrideSize
                  || orderDetailSizeQtyForLicensorSampleSize[0].overrideSize === null)) {
               orderDetailSizeQtyForLicensorSampleSize[0].overrideSize = orderDetailSizeQtyForLicensorSampleSize[0].size;
               this.salesHelperService.propogateSizeOverrideDetail(orderDetail, orderDetailSizeQtyForLicensorSampleSize[0].size, orderDetailSizeQtyForLicensorSampleSize[0].overrideSize, true);
            }
            if (orderDetail.individualSizeCosts
               && (!orderDetailSizeQtyForLicensorSampleSize[0].cost
                  || orderDetailSizeQtyForLicensorSampleSize[0].cost === null)) {
               orderDetailSizeQtyForLicensorSampleSize[0].cost = 0;
            }
         }
      }

      for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
         let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
         let sampleSizeNotInOrderDetail: boolean = true;
         if (subOrderDetail.category === 'SAMPLE' && subOrderDetail.type === 'LICENSOR_SAMPLE') {
            for (let index = 0; index < subOrderDetail.sizeOrderDetailList.length; index++) {
               let sizeOrderDetail: SizeOrderDetail = subOrderDetail.sizeOrderDetailList[index];
               if (sizeOrderDetail.size === licensorSampleSize) {
                  sizeOrderDetail.quantity = licensorSampleQuantity;
                  sampleSizeNotInOrderDetail = false;
               }
            }
            if (sampleSizeNotInOrderDetail) {
               this.toastrService.error('Something went wrong with the sample qty');
            }
         }
      }
   }

   resetLicensorSampleQuantity(orderDetail: OrderDetail) {
      orderDetail.licensorSampleStatus=undefined;
      for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
         let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
         if (subOrderDetail.category === 'SAMPLE' && subOrderDetail.type === 'LICENSOR_SAMPLE') {
            for (let index = 0; index < subOrderDetail.sizeOrderDetailList.length; index++) {
               let sizeOrderDetail: SizeOrderDetail = subOrderDetail.sizeOrderDetailList[index];
               sizeOrderDetail.quantity = 0;
            }
         }
      }
      this.reCalculateFullSalesOrder();
   }

   /******************************* END: Licensor Sample Auto Population *************************** */

   /********************************* START: Single PO   ******************************************* */
   onChangeSinglePOCheckBox(event: any) {
      
      if (event.target.checked) {
         
      } else {
         
         this.handleSinglePONumberChangeforSubOrderDetails('UNSET');
      }
   }

   onChangeSinglePONumberInput() {
      this.handleSinglePONumberChangeforSubOrderDetails('SET');
   }

   handleSinglePONumberChangeforSubOrderDetails(operationType: string) {
      for (let i = 0; i < this.salesOrder.orderDetails?.length; i++) {
         let orderDetail: OrderDetail = this.salesOrder.orderDetails[i];
         for (let index = 0; index < orderDetail.subOrderDetailList.length; index++) {
            let subOrderDetail: SubOrderDetail = orderDetail.subOrderDetailList[index];
            if (subOrderDetail.category === 'SALES') {
               if ('UNSET' === operationType) {
                  subOrderDetail.customerPONumber = null;
               } else if ('SET' === operationType) {
                  subOrderDetail.customerPONumber = this.salesOrder.singlePoNumber;
               }

            }
         }
      }
      if ('UNSET' === operationType) {
         this.salesOrder.singlePoNumber = null;
      }
   }

   /******************************* END: Single PO   ****************************************** */

   /******************************* START: Production Coordinator   *************************** */

   recheckProductionCoordinator() {
      this.customerService.getCustomerById(this.salesOrder.customerId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let customer = response.responsePayload;
            this.setProductionCoordinatorIntoSalesOrder(customer);
         }
      });
   }

   /******************************* START: Production Coordinator   *************************** */

   totalOrderDetailDeletedCount(): number {
      return this.salesHelperService.totalOrderDetailDeletedCount(this.salesOrder);
   }

   

   editProductionStatus(){
      this.salesOrder.editProductionStatus=true;
      if(this.salesOrder.productionStatus && this.salesOrder.productionStatus!==null){
         this.selectedProductionStatusTemp=this.salesOrder.productionStatus;
      }
   }
   
   async statusChangedEventHandler(status:string){
      //this.selectedProductionStatusTemp=status;
      if(status===this.salesHelperService.productionStatusForCostingApproved){
         if(this.salesOrder.orderType === 'Estimation'){
            //console.log('1.estimation approval Event');
            await this.loadSalesOrder(this.salesOrder.id);      
            //console.log('2.Load Sales Order for Estimation Approval Event');
            this.reCalculateFullSalesOrder();
            //console.log('3.Recalcuate Sales Order for Estimation Approval Event');
            this.updateSalesOrder(this.salesOrder);
            //console.log('4.Update Sales Order for Estimation Approval Event');
            //console.log('5.Reload & Recalculate complete for estimation approval');
            this.router.navigate(['estimation/approval/pending/list']);
         }
      }else{
         await this.loadSalesOrder(this.salesOrder.id);
      }
   }

   confirmAndSaveProductionStatusChange() {
      if (this.salesOrder.productionStatus !== this.selectedProductionStatusTemp) {
         if (this.selectedProductionStatusTemp === 'COSTING_GENERATION_PENDING') {
            let reasonList: any[] = [
               { id: "Blanks Issue", value: "Blanks Issue" },
               { id: "Printer Costing Issue", value: "Printer Costing Issue" }, 
               { id: "Freight Cost Issue", value: "Freight Cost Issue" }, 
               { id: "Sales Resubmission Issue", value: "Sales Resubmission Issue" }, 
               { id: "Customer Requested Discount", value: "Customer Requested Discount" }, 
               { id: "Chargebacks", value: "Chargebacks" }, 
               { id: "Re-Costing Required", value: "Re-Costing Required" }

            ];
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
               disableClose: true,
               data: {
                  maxlength: 200,
                  modalType: 'DROPDOWN_AND_TEXTAREA',
                  textInputRegex: '^(.|\\s)*\\S(.|\\s)*$',
                  textInputDefault: '',
                  textInputRegexError: 'Reason is required',
                  title: 'Costing Pending Status - Change Reason',
                  message: 'Please select the appropriate Reason from the dropdown and enter the details (if any).',
                  confirmBtnText: 'Ok',
                  cancelBtnText: 'Cancel',
                  detailsRequired: true,
                  dropdownList: reasonList
               }
            });
            confirmDialog.afterClosed().subscribe(res => {
               //console.log(res);
               if (res) {
                  let reason:string;
                  let note:string;
                  if(res.data && res.data.detailsText){
                     note=res.data.detailsText;
                  }
                  if(res.data && res.data.dropdownSelection){
                     reason=res.data.dropdownSelection.value;
                  }
                  this.saveProductionStatusChange(reason,note);
               } else {
                  this.cancelProductionStatusChange();
               }
            });
         } else {
            this.saveProductionStatusChange();
         }
      } else {
         this.cancelProductionStatusChange();
      }
   }

   async saveProductionStatusChange(statusReason?:string,statusNote?:string) {
      if (this.salesOrder.productionStatus && this.salesOrder.productionStatus !== null) {
         await this.salesHelperService.saveProductionStatusChange(this.salesOrder, this.selectedProductionStatusTemp,statusReason,statusNote).then(() => {
            this.loadSalesOrder(this.salesOrder.id);
         }, err => {
            this.toastrService.error('Error while updating Production Status : ' + JSON.stringify(err));
         });
      }
   }

   cancelProductionStatusChange(){
      this.salesOrder.editProductionStatus=false;
      this.selectedProductionStatusTemp=undefined;
   }

   cancelSalesOrder() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         disableClose: true,
         data: {
            title: 'Cancel All Styles',
            message: 'Please note, Order details once cancelled cannot be reverted. Are you sure you want to cancel ALL the Styles ?',
            confirmBtnText: 'Yes',
            cancelBtnText: 'No'
         }
      });
      confirmDialog.afterClosed().subscribe(res => {
         if (res) {
            this.salesOrder.orderDetails.forEach(orderDetail => {
               //console.log("Cancelling ->" + orderDetail.product.sku);
               this.salesHelperService.updateAndPropogateOrderDetailStatus(orderDetail, 'CANCELLED', 'CANCELLED');
            });
            this.reCalculateFullSalesOrder();
         } else {

         }
      });
    
   }
   getActiveStylesCount():number{
      let retVal:number=0;
      if(this.salesOrder && this.salesOrder.orderDetails && this.salesOrder.orderDetails.length>0){
         retVal=this.salesOrder.orderDetails.filter(x=>x.status==='ACTIVE').length;
      }
      return retVal;
   }
   
   getPnlSpecialityProducts(salesOrder:SalesOrder):Product[]{
      let retVal:Product[]=[];
      if(salesOrder && salesOrder.orderDetails && salesOrder.orderDetails.length>0){
         salesOrder.orderDetails.forEach(od => {
            if(od.pnlSpecialityRequired && od.pnlSpecialityType==='Buyer Provided PNL'){
               retVal.push(od.product);
            }
         });
      }
      return retVal;
   }

   requestForReCosting() {
            let reasonList: any[] = [
               { id: "Blanks Issue", value: "Blanks Issue" },
               { id: "Printer Costing Issue", value: "Printer Costing Issue" }, 
               { id: "Freight Cost Issue", value: "Freight Cost Issue" }, 
               { id: "Sales Resubmission Issue", value: "Sales Resubmission Issue" }, 
               { id: "Customer Requested Discount", value: "Customer Requested Discount" }, 
               { id: "Chargebacks", value: "Chargebacks" }, 
               { id: "Re-Costing Required", value: "Re-Costing Required" }

            ];
            const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
               disableClose: true,
               data: {
                  maxlength: 200,
                  modalType: 'DROPDOWN_AND_TEXTAREA',
                  textInputRegex: '^(.|\\s)*\\S(.|\\s)*$',
                  textInputDefault: '',
                  textInputRegexError: 'Reason is required',
                  title: 'Request For Re-Costing',
                  message: 'Please select the appropriate Reason from the dropdown and enter the details (if any).',
                  confirmBtnText: 'Ok',
                  cancelBtnText: 'Cancel',
                  detailsRequired: true,
                  dropdownList: reasonList
               }
            });
            confirmDialog.afterClosed().subscribe(async res => {
               //console.log(res);
               if (res) {
                  let reason:string;
                  let note:string;
                  if(res.data && res.data.detailsText){
                     note=res.data.detailsText;
                  }
                  if(res.data && res.data.dropdownSelection){
                     reason=res.data.dropdownSelection.value;
                  }
                  await this.salesService.requestForReCosting(this.salesOrder.id,reason,note).toPromise().then(response=>{
                     if (response.responseStatus.status === 'SUCCESS') {
                        this.getSalesOrder();
                        this.toastrService.success(response.responseStatus.message);
                     } else {
                        console.error("Sales Order Component - Sales Order create failed...", response.responseStatus.message);
                        this.toastrService.error(response.responseStatus.message);
                     }
                  }, err=>{
                     this.toastrService.error('Error while updating Production Status : ' + err);
                  });
               } 
            });
   }
   
   /*isAuitSelected(){
      let retVal=false;
      if(this.createItemAuditErros && this.createItemAuditErros.length>0){
         let arr=this.createItemAuditErros.filter(x=>x.selected);
         if(arr && arr.length>0){
            retVal= true;
         }
      }
      return retVal;
   }*/

   loadParticipatingVendors(salesOrderId:string){
      this.productionTrackerServcice.getParticipatingPrinters(salesOrderId).subscribe(printerList=>{
         if(printerList && printerList!=null && printerList.length>0){
            this.participatingPrinterList.length=0;
            
            this.participatingPrinterList.push(...printerList);
         }
      });
   }
   getPrinterPOAttachmentGroupDetails(salesOrder:SalesOrder){
      return this.participatingPrinterList;
   }


   


}

