<div class="container-fluid">

   <div class="row mt-2 d-flex justify-content-center">
      <h2 class="d-inline">Product Metadata</h2>
   </div>
   <hr>

   <div class="row  shadow-lg p-3 mb-5 bg-white rounded">
      <div class="col-lg-12">

         <mat-tab-group  (selectedTabChange)="selectedTabChange($event)" animationDuration="0ms">

            <!-------------------------------------------- COLOUR ------------------------------------------------->

            <mat-tab label="colour">

               <form name="colourMetadata" #colourMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Colour
                        <span class="ml-2 font-weight-normal" *ngIf="colourMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='colourfilterInput' class="form-control mb-2 f-13" [disabled]="colourEditable" #colourFilter placeholder="Filter by Colour Name, Short Name ..."
                              (keyup)="onKeyupColourFilter($event,colourFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredColourList.length}}
                           {{colourFilter.value.length>0? 'Colours for "'+colourFilter.value+'"' : 'Colours'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="colourDrop($event)" [cdkDropListDisabled]="!colourEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:70%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 30%;" class=" align-middle text-center text-nowrap border">Name</th>
                                 <th style="width: 30%" class=" align-middle text-center text-nowrap border">Short Name</th>
                                 <th style="width: 30%" class=" align-middle text-center text-nowrap border">Pantone</th>
                                 <th style="width: auto" class=" align-middle text-center text-nowrap border">Swatch</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let colour of filteredColourList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="colour.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="colourEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteColour{{colour.id}}_{{counter}}" class="btn ml-4" style="border: 0px;" (click)="removeColour(counter)" *ngIf="!colour.id" class="ml-2"><i
                                                   class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{colour.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-nowrap">
                                          <input name="colourName{{colour.id}}_{{counter}}" [(ngModel)]="colour.name" class="form-control form-control-sm font-sm" #colourName="ngModel" maxlength="50"
                                             required onblur="this.value = this.value.trim()" [disabled]="colour.id" [ngClass]="{'is-invalid':colourName.touched && colourName.errors}">
                                          <div *ngIf="colourName?.invalid && (colourName.dirty || colourName.touched)" class="invalid-feedback">
                                             <div *ngIf="colourName.errors.required">
                                                Colour Name is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;"  class="align-middle text-nowrap">
                                          <div>
                                             <input name="colourShortName{{colour.id}}_{{counter}}" [(ngModel)]="colour.shortName" class="form-control form-control-sm font-sm"
                                                #colourShortName="ngModel" onblur="this.value = this.value.trim()" minlength="3" maxlength="10" required [disabled]="colour.id"
                                                [ngClass]="{'is-invalid':colourShortName.touched && colourShortName.errors}">
                                             <div *ngIf="colourShortName?.invalid && (colourShortName.dirty || colourShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="colourShortName.errors.required">
                                                   Colour Short Name is required.
                                                </div>
                                                <div *ngIf="colourShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;"  class="align-middle text-nowrap">
                                          <div>
                                             <input name="colourPantone{{colour.id}}_{{counter}}" [(ngModel)]="colour.pantone" class="form-control form-control-sm font-sm"
                                                #colourPantone="ngModel" onblur="this.value = this.value.trim()" [disabled]="!colourEditable"
                                                [ngClass]="{'is-invalid':colourPantone.touched && colourPantone.errors}">
                                             <div *ngIf="colourPantone?.invalid && (colourPantone.dirty || colourPantone.touched)" class="invalid-feedback">
                                                <div *ngIf="colourPantone.errors.required">
                                                   Colour Pantone is required.
                                                </div>
                                                
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-nowrap">
                                          <ng-container *ngIf="colour.id">
                                             <app-attachment-operations [header]="'Swatch'" [editable]="userAccess?.canCreateProductMetadata"  [createAction]="userAccess?.canCreateProductMetadata" [attachments]="colour.swatchList"
                                             [canUpload]="userAccess?.canCreateProductMetadata" [canRenameFile]="false" [canDownload]="false" 
                                             [canDelete]="userAccess?.canCreateProductMetadata" [category]="'METADATA_COLOR_SWATCH'" [viewType]="'IMAGE_VIEW'" [showHeader]="false" [headerSize]="'6'" [showEmptyListError]="false" 
                                             (onNewUpload)="fileUploaded(colour)" (onDelete)="fileDeleted(colour)" [singular]="true">
                                             </app-attachment-operations>
                                       </ng-container>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !colourEditable" (click)="colourFilter.value='';editColour()"><i
                           class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && colourEditable" (click)="addColour()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button name="colour" type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="colourMetadataForm.invalid" *ngIf="colourEditable" (click)="saveColour()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="colourEditable" (click)="cancelColour()"><i class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>

            <!-------------------------------------------- SIZE ------------------------------------------------->

            <mat-tab label="size">

               <form name="sizeMetadata" #sizeMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Size
                        <span class="ml-2 font-weight-normal" *ngIf="sizeMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='sizefilterInput' class="form-control mb-2 f-13" [disabled]="sizeEditable" #sizeFilter placeholder="Filter by Size Name, Short Name ..."
                              (keyup)="onKeyupSizeFilter($event,sizeFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredSizeList.length}}
                           {{sizeFilter.value.length>0? 'Seizes for "'+sizeFilter.value+'"' : 'Sizes'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="sizeDrop($event)" [cdkDropListDisabled]="!sizeEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:50%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 30%;" class=" align-middle text-center text-nowrap border">Size</th>
                                 <th style="width: 30%" class=" align-middle text-center text-nowrap border">Sage Size Code</th>
                                 <th style="width: 30%" class=" align-middle text-center text-nowrap border">Sage Size Description</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let size of filteredSizeList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="size.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="sizeEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteSize{{size.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeSize(counter)" *ngIf="!size.id" class="ml-2"><i
                                                   class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{size.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap">
                                          <input name="sizeName{{size.id}}_{{counter}}" [(ngModel)]="size.size" class="form-control form-control-sm font-sm" #sizeName="ngModel" maxlength="50" required
                                             onblur="this.value = this.value.trim()" [disabled]="size.id" [ngClass]="{'is-invalid':sizeName.touched && sizeName.errors}">
                                          <div *ngIf="sizeName?.invalid && (sizeName.dirty || sizeName.touched)" class="invalid-feedback">
                                             <div *ngIf="sizeName.errors.required">
                                                Size is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="sizeShortName{{size.id}}_{{counter}}" [(ngModel)]="size.shortName" class="form-control form-control-sm font-sm" #sizeShortName="ngModel"
                                                onblur="this.value = this.value.trim()" oninput="this.value = this.value.toUpperCase()" minlength="2" maxlength="2" required [disabled]="size.id"
                                                [ngClass]="{'is-invalid':sizeShortName.touched && sizeShortName.errors}">
                                             <div *ngIf="sizeShortName?.invalid && (sizeShortName.dirty || sizeShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="sizeShortName.errors.required">
                                                   Size Short Name is required.
                                                </div>
                                                <div *ngIf="sizeShortName.errors.minlength">
                                                   Minimum Length is 2
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="sizeDescription{{size.id}}_{{counter}}" [(ngModel)]="size.description" class="form-control form-control-sm font-sm" #sizeDescription="ngModel"
                                                onblur="this.value = this.value.trim()" minlength="3" maxlength="15" required [disabled]="size.id"
                                                [ngClass]="{'is-invalid':sizeDescription.touched && sizeDescription.errors}">
                                             <div *ngIf="sizeDescription?.invalid && (sizeDescription.dirty || sizeDescription.touched)" class="invalid-feedback">
                                                <div *ngIf="sizeDescription.errors.required">
                                                   Size Description is required.
                                                </div>
                                                <div *ngIf="sizeShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>
                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !sizeEditable" (click)="sizeFilter.value='';editSize()"><i
                           class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && sizeEditable" (click)="addSize()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="sizeMetadataForm.invalid" *ngIf="sizeEditable" (click)="saveSize()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="sizeEditable" (click)="cancelSize()"><i class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>


            <!-------------------------------------------- TREATMENT ------------------------------------------------->

            <mat-tab label="treatment">

               <form name="treatmentMetadata" #treatmentMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Treatment
                        <span class="ml-2 font-weight-normal" *ngIf="treatmentMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='treatmentfilterInput' class="form-control mb-2 f-13" [disabled]="treatmentEditable" #treatmentFilter placeholder="Filter by Treatment Name, Short Name ..."
                              (keyup)="onKeyupTreatmentFilter($event,treatmentFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredTreatmentList.length}}
                           {{treatmentFilter.value.length>0? 'Treamtments for "'+treatmentFilter.value+'"' : 'Treatments'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="treatmentDrop($event)" [cdkDropListDisabled]="!treatmentEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:50%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 50%;" class=" align-middle text-center text-nowrap border">Treatment Name</th>
                                 <th style="width: 50%" class=" align-middle text-center text-nowrap border">Treatment Short Name</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let treatment of filteredTreatmentList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="treatment.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="treatmentEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteTreatment{{treatment.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeTreatment(counter)" *ngIf="!treatment.id"
                                                class="ml-2"><i class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{treatment.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap">
                                          <input name="treatmentName{{treatment.id}}_{{counter}}" [(ngModel)]="treatment.name" class="form-control form-control-sm font-sm" #treatmentName="ngModel"
                                             maxlength="50" onblur="this.value = this.value.trim()" required [disabled]="treatment.id"
                                             [ngClass]="{'is-invalid':treatmentName.touched && treatmentName.errors}">
                                          <div *ngIf="treatmentName?.invalid && (treatmentName.dirty || treatmentName.touched)" class="invalid-feedback">
                                             <div *ngIf="treatmentName.errors.required">
                                                Treatment is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="treatmentShortName{{treatment.id}}_{{counter}}" [(ngModel)]="treatment.shortName" class="form-control form-control-sm font-sm"
                                                #treatmentShortName="ngModel" onblur="this.value = this.value.trim()" minlength="3" maxlength="20" required [disabled]="treatment.id"
                                                [ngClass]="{'is-invalid':treatmentShortName.touched && treatmentShortName.errors}">
                                             <div *ngIf="treatmentShortName?.invalid && (treatmentShortName.dirty || treatmentShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="treatmentShortName.errors.required">
                                                   Treatment Short Name is required.
                                                </div>
                                                <div *ngIf="treatmentShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>
                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !treatmentEditable"
                        (click)="treatmentFilter.value='';editTreatment()"><i class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && treatmentEditable" (click)="addTreatment()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="treatmentMetadataForm.invalid" *ngIf="treatmentEditable" (click)="saveTreatment()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="treatmentEditable" (click)="cancelTreatment()"><i class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>


            <!-------------------------------------------- CATEGORY ------------------------------------------------->

            <mat-tab label="category">

               <form name="categoryMetadata" #categoryMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Category
                        <span class="ml-2 font-weight-normal" *ngIf="categoryMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='categoryfilterInput' class="form-control mb-2 f-13" [disabled]="categoryEditable" #categoryFilter placeholder="Filter by Category Name, Short Name ..."
                              (keyup)="onKeyupCategoryFilter($event,categoryFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredCategoryList.length}}
                           {{categoryFilter.value.length>0? 'Category for "'+categoryFilter.value+'"' : 'Category'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="categoryDrop($event)" [cdkDropListDisabled]="!categoryEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:50%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 50%;" class=" align-middle text-center text-nowrap border">Category Name</th>
                                 <th style="width: 50%" class=" align-middle text-center text-nowrap border">Category Short Name</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let category of filteredCategoryList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="category.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="categoryEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteCategory{{category.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeCategory(counter)" *ngIf="!category.id" class="ml-2"><i
                                                   class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{category.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap">
                                          <input name="categoryName{{category.id}}_{{counter}}" [(ngModel)]="category.name" class="form-control form-control-sm font-sm" #categoryName="ngModel"
                                             maxlength="50" required onblur="this.value = this.value.trim()" [disabled]="category.id"
                                             [ngClass]="{'is-invalid':categoryName.touched && categoryName.errors}">
                                          <div *ngIf="categoryName?.invalid && (categoryName.dirty || categoryName.touched)" class="invalid-feedback">
                                             <div *ngIf="categoryName.errors.required">
                                                Category is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="categoryShortName{{category.id}}_{{counter}}" [(ngModel)]="category.shortName" class="form-control form-control-sm font-sm"
                                                #categoryShortName="ngModel" onblur="this.value = this.value.trim()" minlength="3" maxlength="10" required [disabled]="category.id"
                                                [ngClass]="{'is-invalid':categoryShortName.touched && categoryShortName.errors}">
                                             <div *ngIf="categoryShortName?.invalid && (categoryShortName.dirty || categoryShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="categoryShortName.errors.required">
                                                   Category Short Name is required.
                                                </div>
                                                <div *ngIf="categoryShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>

                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !categoryEditable" (click)="categoryFilter.value='';editCategory()"><i
                           class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && categoryEditable" (click)="addCategory()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="categoryMetadataForm.invalid" *ngIf="categoryEditable" (click)="saveCategory()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="categoryEditable" (click)="cancelCategory()"><i class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>

            <!-------------------------------------------- ROYALTY CATEGORY ------------------------------------------------->

            <mat-tab label="royaltyCategory">

               <form name="royaltyCategoryMetadata" #royaltyCategoryMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Royalty Category
                        <span class="ml-2 font-weight-normal" *ngIf="royaltyCategoryMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='royaltyCategoryfilterInput' class="form-control mb-2 f-13" [disabled]="royaltyCategoryEditable" #royaltyCategoryFilter
                              placeholder="Filter by Royalty Category Name, Short Name ..." (keyup)="onKeyupRoyaltyCategoryFilter($event,royaltyCategoryFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredRoyaltyCategoryList.length}}
                           {{royaltyCategoryFilter.value.length>0? 'Royalty Category for "'+royaltyCategoryFilter.value+'"' : 'Royalty Category'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="royaltyCategoryDrop($event)" [cdkDropListDisabled]="!royaltyCategoryEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:50%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 50%;" class=" align-middle text-center text-nowrap border">Royalty Category Name</th>
                                 <th style="width: 50%" class=" align-middle text-center text-nowrap border">Royalty Category Short Name</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let royaltyCategory of filteredRoyaltyCategoryList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="royaltyCategory.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="royaltyCategoryEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteRoyaltyCategory{{royaltyCategory.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeRoyaltyCategory(counter)"
                                                *ngIf="!royaltyCategory.id" class="ml-2"><i class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{royaltyCategory.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap">
                                          <input name="royaltyCategoryName{{royaltyCategory.id}}_{{counter}}" [(ngModel)]="royaltyCategory.name" class="form-control form-control-sm font-sm"
                                             #royaltyCategoryName="ngModel" onblur="this.value = this.value.trim()" maxlength="50" required [disabled]="royaltyCategory.id"
                                             [ngClass]="{'is-invalid':royaltyCategoryName.touched && royaltyCategoryName.errors}">
                                          <div *ngIf="royaltyCategoryName?.invalid && (royaltyCategoryName.dirty || royaltyCategoryName.touched)" class="invalid-feedback">
                                             <div *ngIf="royaltyCategoryName.errors.required">
                                                RoyaltyCategory is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="royaltyCategoryShortName{{royaltyCategory.id}}_{{counter}}" [(ngModel)]="royaltyCategory.shortName"
                                                class="form-control form-control-sm font-sm" #royaltyCategoryShortName="ngModel" onblur="this.value = this.value.trim()" minlength="2" maxlength="20"
                                                required [disabled]="royaltyCategory.id" [ngClass]="{'is-invalid':royaltyCategoryShortName.touched && royaltyCategoryShortName.errors}">
                                             <div *ngIf="royaltyCategoryShortName?.invalid && (royaltyCategoryShortName.dirty || royaltyCategoryShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="royaltyCategoryShortName.errors.required">
                                                   Royalty Category Short Name is required.
                                                </div>
                                                <div *ngIf="royaltyCategoryShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>
                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !royaltyCategoryEditable"
                        (click)="royaltyCategoryFilter.value='';editRoyaltyCategory()"><i class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && royaltyCategoryEditable" (click)="addRoyaltyCategory()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="royaltyCategoryMetadataForm.invalid" *ngIf="royaltyCategoryEditable"
                        (click)="saveRoyaltyCategory()"><i class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="royaltyCategoryEditable" (click)="cancelRoyaltyCategory()"><i
                           class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>


            <!-------------------------------------------- GARMENT ------------------------------------------------->

            <mat-tab label="garment">

               <form name="garmentMetadata" #garmentMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Garment
                        <span class="ml-2 font-weight-normal" *ngIf="garmentMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='garmentfilterInput' class="form-control mb-2 f-13" [disabled]="garmentEditable" #garmentFilter placeholder="Filter by Garment Name, Short Name ..."
                              (keyup)="onKeyupGarmentFilter($event,garmentFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredGarmentList.length}}
                           {{garmentFilter.value.length>0? 'Garment for "'+garmentFilter.value+'"' : 'Garment'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="garmentDrop($event)" [cdkDropListDisabled]="!garmentEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:50%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 50%;" class=" align-middle text-center text-nowrap border">Garment Name</th>
                                 <th style="width: 50%" class=" align-middle text-center text-nowrap border">Garment Short Name</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let garment of filteredGarmentList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="garment.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="garmentEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <a name="deleteGarment{{garment.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeGarment(counter)" *ngIf="!garment.id" class="ml-2"><i
                                                   class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-left text-nowrap pl-3">{{garment.sortOrder}}</td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap">
                                          <input name="garmentName{{garment.id}}_{{counter}}" [(ngModel)]="garment.name" class="form-control form-control-sm font-sm" #garmentName="ngModel"
                                             maxlength="50" required onblur="this.value = this.value.trim()" [disabled]="garment.id"
                                             [ngClass]="{'is-invalid':garmentName.touched && garmentName.errors}">
                                          <div *ngIf="garmentName?.invalid && (garmentName.dirty || garmentName.touched)" class="invalid-feedback">
                                             <div *ngIf="garmentName.errors.required">
                                                Garment is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <input name="garmentShortName{{garment.id}}_{{counter}}" [(ngModel)]="garment.shortName" class="form-control form-control-sm font-sm"
                                                #garmentShortName="ngModel" onblur="this.value = this.value.trim()" minlength="3" maxlength="15" required [disabled]="garment.id"
                                                [ngClass]="{'is-invalid':garmentShortName.touched && garmentShortName.errors}">
                                             <div *ngIf="garmentShortName?.invalid && (garmentShortName.dirty || garmentShortName.touched)" class="invalid-feedback">
                                                <div *ngIf="garmentShortName.errors.required">
                                                   Garment Short Name is required.
                                                </div>
                                                <div *ngIf="garmentShortName.errors.minlength">
                                                   Minimum Length is 3
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>

                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !garmentEditable" (click)="garmentFilter.value='';editGarment()"><i
                           class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && garmentEditable" (click)="addGarment()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="garmentMetadataForm.invalid" *ngIf="garmentEditable" (click)="saveGarment()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="garmentEditable" (click)="cancelGarment()"><i class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>


            <!-------------------------------------------- PRODUCT CONFIG ------------------------------------------------->

            <mat-tab label="productConfig">

               <form name="productConfigMetadata" #productConfigMetadataForm="ngForm" autocomplete="off" novalidate class="mb-3">
                  <ng-template mat-tab-label>
                     <h5>Product Config
                        <span class="ml-2 font-weight-normal" *ngIf="productConfigMetadataForm.invalid">
                           <i class="fa fa-exclamation-triangle fa-xs" style="color:red" aria-hidden="true"></i></span>
                     </h5>
                  </ng-template>

                  <div class="overflow-hidden">

                     <div class="row mt-3">
                        <div class="col-sm-6">
                           <input id='productConfigFilterInput' class="form-control mb-2 f-13" [disabled]="productConfigEditable" #productConfigFilter
                              placeholder="Filter by Garment, Product Category, Royalty Product Category ..." (keyup)="onKeyupProductConfigFilter($event,productConfigFilter.value)">
                        </div>
                        <div class="col-sm-6 mt-2">
                           Found {{filteredProductConfigList.length}}
                           {{productConfigFilter.value.length>0? 'Rows for "'+productConfigFilter.value+'"' : 'Rows'}}
                        </div>
                     </div>

                     <div cdkDropList (cdkDropListDropped)="productConfigDrop($event)" [cdkDropListDisabled]="!productConfigEditable">

                        <table class="table table-condensed table-sm customtablehover mt-2" style="width:100%">
                           <thead class="thead-dark">
                              <tr class="f-11">
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Actions</th>
                                 <th style="width: 5%;" class=" align-middle text-center text-nowrap border">Sort Order</th>

                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Product Garment</th>
                                 <th style="width: 10%" class=" align-middle text-center text-nowrap border">Product Garment Code</th>
                                 <th style="width: 10%" class=" align-middle text-center text-nowrap border">Product Category</th>
                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Royalty Category</th>

                                 <th style="width: 10%;" class=" align-middle text-center text-nowrap border">Sage Category Code</th>
                                 <th style="width: 10%" class=" align-middle text-center text-nowrap border">Sage Sub Category Code</th>
                                 <th style="width: 10%;" class=" align-middle text-center  border">Size Applicable For Sage Description</th>
                              </tr>

                           </thead>
                           <tbody>
                              <ng-container *ngFor="let productConfig of filteredProductConfigList; index as counter">
                                 <ng-container>
                                    <tr class="f-11 border" cdkDrag [style]="productConfig.hasErrors?'background-color: rgb(253 180 180);':''">
                                       <td>
                                          <span>
                                             <svg width="24px" fill="lightgray" viewBox="0 0 24 24" *ngIf="productConfigEditable">
                                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                                                </path>
                                                <path d="M0 0h24v24H0z" fill="none"></path>
                                             </svg>
                                             <button name="cloneProductConfig{{productConfig.id}}_{{counter}}" type="button" (click)="cloneProductConfig(counter)" *ngIf="productConfigEditable"
                                                class="mb-1 btn btn-dark btn-sm mr-1 ml-1"><i class="fas fa-copy"></i></button>
                                             <a name="deleteProductProductConfig{{productConfig.id}}_{{counter}}" class="btn" style="border: 0px;" (click)="removeProductConfig(counter)"
                                                *ngIf="!productConfig.id" class="ml-2"><i class="fas fa-trash fa-1x" style="color: #dc3545"></i></a>
                                          </span>
                                       </td>
                                       <td style="width: auto;" class="align-middle text-center text-nowrap pl-3">{{productConfig.sortOrder}}</td>

                                       <td style="width: auto;">
                                          <select name="pg{{productConfig.id}}_{{counter}}" class="form-control form-control-sm custom-select" [(ngModel)]="productConfig.productGarment"
                                             #productGarment="ngModel" required [ngClass]="{'is-invalid':productGarment.touched && productGarment.errors}" [disabled]="productConfig.id">
                                             <option *ngFor="let garment of garmentList" [ngValue]="garment.name">
                                                {{garment.name}}</option>
                                          </select>
                                          <div *ngIf="productGarment?.invalid && (productGarment.dirty || productGarment.touched)" class="invalid-feedback">
                                             <div *ngIf="garmentCategory.errors.required">
                                                Product Garment is required.
                                             </div>
                                          </div>
                                       </td>


                                       <td style="width: auto;">
                                          <div>
                                             <input name="pgc{{productConfig.id}}_{{counter}}" [(ngModel)]="productConfig.productGarmentCode" class="form-control form-control-sm font-sm"
                                                #productGarmentCode="ngModel" oninput="this.value = this.value.toUpperCase()" minlength="2" maxlength="2" required [disabled]="productConfig.id"
                                                [ngClass]="{'is-invalid':productGarmentCode.touched && productGarmentCode.errors}">
                                             <div *ngIf="productGarmentCode?.invalid && (productGarmentCode.dirty || productGarmentCode.touched)" class="invalid-feedback">
                                                <div *ngIf="productGarmentCode.errors.required">
                                                   productGarmentCode is required.
                                                </div>
                                                <div *ngIf="productGarmentCode.errors.minlength">
                                                   Minimum Length is 2
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <select name="gpc{{productConfig.id}}_{{counter}}" class="form-control form-control-sm custom-select" [(ngModel)]="productConfig.productCategory"
                                             #garmentCategory="ngModel" required [ngClass]="{'is-invalid':garmentCategory.touched && garmentCategory.errors}" [disabled]="productConfig.id">
                                             <!--<option value="N/A">N/A</option>-->
                                             <option *ngFor="let category of categoryList" [ngValue]="category.name">
                                                {{category.name}}</option>
                                          </select>
                                          <div *ngIf="garmentCategory?.invalid && (garmentCategory.dirty || garmentCategory.touched)" class="invalid-feedback">
                                             <div *ngIf="garmentCategory.errors.required">
                                                Product Category is required.
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <select name="grc{{productConfig.id}}_{{counter}}" class="form-control form-control-sm custom-select" [(ngModel)]="productConfig.royaltyProductCategory"
                                             #garmentRoyaltyCategory="ngModel" required [ngClass]="{'is-invalid':garmentRoyaltyCategory.touched && garmentRoyaltyCategory.errors}"
                                             [disabled]="productConfig.id">
                                             <option *ngFor="let royaltyCategory of royaltyCategoryList" [ngValue]="royaltyCategory.name">
                                                {{royaltyCategory.name}}</option>
                                          </select>
                                          <div *ngIf="garmentRoyaltyCategory?.invalid && (garmentRoyaltyCategory.dirty || garmentRoyaltyCategory.touched)" class="invalid-feedback">
                                             <div *ngIf="garmentRoyaltyCategory.errors.required">
                                                Royalty Category is required.
                                             </div>
                                          </div>
                                       </td>

                                       <td style="width: auto;">
                                          <div>
                                             <select name="sc{{productConfig.id}}_{{counter}}" class="form-control form-control-sm custom-select" [(ngModel)]="productConfig.sageCategory"
                                                #sageCategory="ngModel" required [ngClass]="{'is-invalid':sageCategory.touched && sageCategory.errors}" [disabled]="productConfig.id">
                                                <option *ngFor="let sageCategoryCode of sageCategoryCodeList" [ngValue]="sageCategoryCode">
                                                   {{sageCategoryCode}}</option>
                                             </select>
                                             <div *ngIf="sageCategory?.invalid && (sageCategory.dirty || sageCategory.touched)" class="invalid-feedback">
                                                <div *ngIf="sageCategory.errors.required">
                                                   Sage Category Code is required.
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                       <td style="width: auto;">
                                          <div>
                                             <select name="sbc{{productConfig.id}}_{{counter}}" class="form-control form-control-sm custom-select" [(ngModel)]="productConfig.sageSubCategory"
                                                #sageSubCategory="ngModel" required [ngClass]="{'is-invalid':sageSubCategory.touched && sageSubCategory.errors}" [disabled]="productConfig.id">
                                                <option *ngFor="let sageSubCategory of sageSubCategoryCodeList" [ngValue]="sageSubCategory">
                                                   {{sageSubCategory}}</option>
                                             </select>
                                             <div *ngIf="sageSubCategory?.invalid && (sageSubCategory.dirty || sageSubCategory.touched)" class="invalid-feedback">
                                                <div *ngIf="sageSubCategory.errors.required">
                                                   Sage Sub Category Code is required.
                                                </div>
                                                <div *ngIf="sageSubCategory.errors.minlength">
                                                   Minimum Length is 2
                                                </div>
                                             </div>
                                          </div>
                                       </td>

                                       <td>
                                          <div class="custom-control custom-checkbox mr-sm-2 ml-3">
                                             <input type="checkbox" class="custom-control-input ml-2" name="sizeApplicableForSageCode{{counter}}" id="sizeApplicableForSageCode{{counter}}"
                                                [checked]="productConfig.sizeApplicableForSageCode" [(ngModel)]="productConfig.sizeApplicableForSageCode" [disabled]="productConfig.id">
                                             <label class="custom-control-label font-weight-bold f-13" for="sizeApplicableForSageCode{{counter}}">
                                                &nbsp;
                                             </label>
                                          </div>
                                       </td>
                                    </tr>
                                 </ng-container>
                              </ng-container>
                           </tbody>
                        </table>
                     </div>

                  </div>
                  <div *ngIf="true" class="row col-sm-auto">
                     <button type="button" class="btn btn-warning btn-sm text-black" *ngIf="userAccess?.canEditProductMetadata && !productConfigEditable"
                        (click)="productConfigFilter.value='';editProductConfig()"><i class="fas fa-pencil-alt fa-1x"></i>&nbsp;Edit</button>
                     <button type="button" class="btn btn-success btn-sm text-white ml-2" *ngIf="userAccess?.canCreateProductMetadata && productConfigEditable" (click)="addProductConfig()"><i
                           class="fas fa-plus fa-1x"></i>&nbsp;Add</button>
                     <button type="button" class="btn btn-info btn-sm text-white ml-2" [disabled]="productConfigMetadataForm.invalid" *ngIf="productConfigEditable" (click)="saveProductConfig()"><i
                           class="fas fa-save fa-1x"></i>&nbsp;Save</button>
                     <button type="button" class="btn btn-danger btn-sm text-white ml-2" *ngIf="productConfigEditable" (click)="cancelProductConfig()"><i
                           class="fas fa-times fa-1x"></i>&nbsp;Cancel</button>
                  </div>

               </form>

            </mat-tab>

         </mat-tab-group>

      </div>

   </div>

</div>

<div class="floatBtn d-flex flex-column">
   <button type="button" class="btn btn-circle bg-secondary mb-2" matTooltip="Scroll To Top" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'});" matTooltipPosition="left"
      matTooltipClass="bg-dark">
      <i class="fas fa-arrow-up fa-2x" style="color:white"></i>
   </button>
   <a (click)="goBack()" class="btn btn-circle" style="background-color: tomato;" matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
</div>