import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'transform'
})
export class TransformPipe implements PipeTransform {

  transform(variable: string): any {
    if(variable && variable!==null){
      let evalRes= eval("mapping."+variable);
      if(evalRes){
        return evalRes;
      }else{
        return variable;
      }
    }else{
      return variable;
    }
  }


}

export const mapping: any = {
  sales: {
    productionStatus: {
      REVIEW_SUBMISSION: 'Review Submission',
      REVIEW_RESUBMISSION: 'Review Resubmission',
      COSTING_GENERATION_PENDING: 'Costing Pending',
      COSTING_APPROVAL_PENDING: 'Costing Approval Pending',
      COSTING_APPROVAL_COMPLETE: 'Costing Approval Complete',
      PO_PLACEMENT_PENDING: 'PO Placement Pending',
      FULFILLMENT_IN_PROGRESS: 'Fullfilment In-Progress',
      SHIPPED: 'Shipped',
      PARTIALLY_SHIPPED:'Partially Shipped',
      INVOICED: 'Invoiced',
      PARTIALLY_INVOICED:'Partially Invoiced',
      FILE_CLOSED: 'File Closed',
      CANCELLED: 'Cancelled',
      STATUS_UPDATE_REQUIRED: 'Status Update Required',
      null:'N/A'
    },
    sampleType:{
      'PP_SAMPLE': 'PP Sample',
      'TOP_SAMPLE': 'Top Sample',
    }
  },
  estimation: {
    productionStatus: {
      COSTING_GENERATION_PENDING: 'Estimation Pending',
      COSTING_APPROVAL_PENDING: 'Estimation Complete',
      COSTING_APPROVAL_COMPLETE: 'Estimation Approval Complete',
      ESTIMATIONS_ACKNOWLEDGED: 'Estimations Acknowledged',
      CANCELLED: 'Cancelled',
      null:'N/A'
    }
  },
  po:{
    status:{
      PO_CREATION_REQUIRED : 'Purchase Order Creation Required',
      PO_CREATION_SUCCESS : 'Purchase Order Created',
      PO_UPDATE_REQUIRED : 'Purchase Order Update Required',
      PO_UPDATE_SUCCESS : 'Purchase Order Updated',
      PO_CANCELLATION_REQUIRED : 'Purcahse Order Cancellation Required',
      PO_CANCELLATION_SUCCESS : 'Purchase Order Cancelled',
      null: 'N/A'
    }
  },
  company:{
    name:{
      PHILCOS_CANADA: 'Philcos Canada',
      PHILCOS_USA: 'Philcos USA',
      null: 'N/A'
    }
  }
};