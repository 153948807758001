import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../../model/api-response';
import { Printer } from '../../model/vendors/printer.model';
import { BlanksSupplier } from '../../model/vendors/blanks-supplier.model';
import { SharedService } from '../shared.service';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
   
   
   constructor(private http: HttpClient, private sharedService:SharedService) { 
      this.initializeMetadataSubscription();
   }

   readonly rooturl = environment.apiEndPoint;
   printerListSubject: BehaviorSubject<Printer[]>;
   public printerListObservable: Observable<Printer[]>;

   blankSupplierListSubject: BehaviorSubject<BlanksSupplier[]>;
   public blankSupplierListObservable: Observable<BlanksSupplier[]>;

   initializeMetadataSubscription() {

      this.printerListSubject = new BehaviorSubject<Printer[]>([]);
      this.printerListObservable = this.printerListSubject.asObservable();

      this.blankSupplierListSubject = new BehaviorSubject<BlanksSupplier[]>([]);
      this.blankSupplierListObservable = this.blankSupplierListSubject.asObservable();
      this.getVendorList();
   }

   getVendorList() {
    
      this.getBlanksSupplierList().subscribe(res=>{
        if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
          let blanksSupplierList=res.responsePayload;
          blanksSupplierList = this.sharedService.sortListByPropertyName(blanksSupplierList,'name');
          this.blankSupplierListSubject.next(blanksSupplierList);
        }
      });
      this.getPrinterList().subscribe(res=>{
        if(res && res.responseStatus.status==='SUCCESS' && res.responsePayload !=null){
          let printerList=res.responsePayload;
          printerList = this.sharedService.sortListByPropertyName(printerList,'name');
          this.printerListSubject.next(printerList);
        }
      });
    }

   getBlanksSupplierList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/vendors/blanksSupplier/list', null);
   }

   getPrinterList(): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/vendors/printer/list', null);
   }

   getPrinterById(printerId: string): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/vendors/printer/'+printerId, null);
   }

   createPrinter(printer: Printer): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/printer/create', printer);
   }

   updatePrinter(printer: Printer): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/printer/update', printer);
   }

   deletePrinter(printer: Printer): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/printer/delete', printer);
   }
   
   getBlanksSupplierById(blanksSupplierId: string): Observable<ApiResponse> {
      return this.http.post<any>(this.rooturl + '/vendors/blanksSupplier/'+blanksSupplierId, null);
   }

   createBlanksSupplier(blanksSupplier: BlanksSupplier): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/blanksSupplier/create', blanksSupplier);
   }

   updateBlanksSupplier(blanksSupplier: BlanksSupplier): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/blanksSupplier/update', blanksSupplier);
   }

   deleteBlanksSupplier(blanksSupplier: BlanksSupplier): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/vendors/blanksSupplier/delete', blanksSupplier);
   }
   
   retrieveVendorDetails(companyCode: any, vendorCode: any) {
      return this.http.get<ApiResponse>(this.rooturl+'/vendors/retrieve/details/'+companyCode+'/'+vendorCode);
   }
}
