import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderCostingService } from 'src/app/shared/service/sales/sales-order-costing.service';
import { CostingAllocation } from 'src/app/shared/model/sales/costing/allocation/costing-allocation.model';
import { CostingSheet } from 'src/app/shared/model/sales/costing/costingSheet/costing-sheet.model';
import { CostingHelperService } from 'src/app/shared/service/sales/costing-helper.service';
import { StyleCosting } from 'src/app/shared/model/sales/costing/allocation/style-costing.model';
import { CostTypeDetail } from 'src/app/shared/model/sales/costsheet/cost-type-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { ToastrService } from 'ngx-toastr';

@Component({
   selector: 'app-costing-sheet',
   templateUrl: './costing-sheet.component.html',
   styleUrls: ['./costing-sheet.component.css']
})
export class CostingSheetComponent implements OnInit {

   viewType:string='dynamic';
   constructor(private activatedRoute: ActivatedRoute, private costingService: SalesOrderCostingService, private location: Location, private costingHelperService: CostingHelperService,
      private salesService:SalesService,private toastrService:ToastrService
   ) { }

   async ngOnInit(): Promise<void> {
      this.setSalesOrderIdAndOrderDetailId();
      await this.getCostingAllocation(this.salesOrderId);
   }

   freshCostTypeDetailListForHeaders: CostTypeDetail[] = [];
   freshCostTypeDetailListForOrganization: CostTypeDetail[] = [];
   freshCostTypeDetailListForCustomer: CostTypeDetail[] = [];
   freshCostTypeDetailListForPrinterTopLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForPrinterStyleLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForSupplierTopLevel: CostTypeDetail[] = [];
   freshCostTypeDetailListForSupplierStyleLevel: CostTypeDetail[] = [];

   salesOrderId: string;
   orderDetailId: string;
   costingAllocation: CostingAllocation;
   costingSheets: CostingSheet[] = [];
   currentStyleCosting: StyleCosting;
   salesOrder:SalesOrder;

   editable: boolean = false;


   setSalesOrderIdAndOrderDetailId() {
      this.salesOrderId = this.activatedRoute.snapshot.paramMap.get('salesOrderId');
      this.orderDetailId = this.activatedRoute.snapshot.paramMap.get('orderDetailId');
      let viewType=this.activatedRoute.snapshot.paramMap.get('viewType');
      if(viewType){
         console.log('viewType =',viewType);
         this.viewType=viewType;
      }
      //console.log(this.salesOrderId);
   }

   async loadSalesOrder(salesOrderId: string) {
      if (salesOrderId) {
         await this.salesService.getSalesOrder(salesOrderId).toPromise().then(response => {
            if (response.responseStatus.status === 'SUCCESS') {
               let salesOrderFromDB: SalesOrder = response.responsePayload as SalesOrder;
               this.salesOrder = salesOrderFromDB;
             } else {
               this.toastrService.error(response.responseStatus.message);
            }
         });
      }
   }

   async getCostingAllocation(salesOrderId: string) {
      await this.loadSalesOrder(salesOrderId);
      await this.loadCostTypeDetailList(this.salesOrder);
      this.costingService.getAllocationSheet(salesOrderId).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.costingAllocation = costingAllocation;
            this.populateCostingSheetsForStyle();
            
            //console.log('CostingSheets = ', this.costingSheets);
         }
      });
   }

   populateCostingSheetsForStyle() {
      if(this.viewType==='dynamic'){
      this.costingService.generateCostingSheetsCombinationForEachStyle(this.salesOrder,this.costingAllocation,
         this.freshCostTypeDetailListForPrinterTopLevel,
         this.freshCostTypeDetailListForPrinterStyleLevel,
         this.freshCostTypeDetailListForSupplierTopLevel,
         this.freshCostTypeDetailListForSupplierStyleLevel,
         this.freshCostTypeDetailListForOrganization,
         this.freshCostTypeDetailListForCustomer,
         this.freshCostTypeDetailListForHeaders
       );
      }

      this.costingAllocation.styleCosting.forEach(styleCosting => {
         if (styleCosting.orderDetailId === this.orderDetailId) {
            this.currentStyleCosting = styleCosting;
            this.costingSheets = [];
            this.costingSheets = styleCosting.costingSheets;
            return;
         }
      });
   }

   goBack() {
      this.location.back();
   }

   onUnitCostChange(costingSheet: CostingSheet) {

   }

   onRemarksChange(costTypeDetail: CostTypeDetail, costingSheet: CostingSheet) {

   }

   viewOrEditCostingSheetNotes() {

   }

   close() {
      window.close();
   }



   async loadCostTypeDetailList(salesOrder:SalesOrder) {
      await this.loadCostTypeDetailListForHeaders('HEADER', 'HEADER',salesOrder);
      await this.loadCostTypeDetailListForOrganization('ORG', 'ORG',salesOrder);
      await this.loadCostTypeDetailListForCustomer('CUSTOMER', 'TOP_INDIVIDUAL',salesOrder);
      await this.loadCostTypeDetailListForPrinterTopLevel('PRINTER', 'TOP_INDIVIDUAL',salesOrder);
      await this.loadCostTypeDetailListForSupplierTopLevel('SUPPLIER', 'TOP_INDIVIDUAL',salesOrder);
      await this.loadCostTypeDetailListForPrinterStyleLevel('PRINTER', 'STYLE_COMBINATION_AVERAGE',salesOrder);
      await this.loadCostTypeDetailListForSupplierStyleLevel('SUPPLIER', 'STYLE_COMBINATION_AVERAGE',salesOrder);
    }
    
   async loadCostTypeDetailListForHeaders(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForHeaders = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForOrganization(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForOrganization = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForCustomer(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForCustomer = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForPrinterTopLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForPrinterTopLevel = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForPrinterStyleLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForPrinterStyleLevel = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForSupplierTopLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification, salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForSupplierTopLevel = tmpCostTypeDetailList;
         }
      });
   }

   async loadCostTypeDetailListForSupplierStyleLevel(classification: string, subClassification: string,salesOrder:SalesOrder) {
      await this.costingService.loadCostTypes(classification, subClassification,salesOrder.currency).toPromise().then(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            let tmpCostTypeDetailList = response.responsePayload;
            this.freshCostTypeDetailListForSupplierStyleLevel = tmpCostTypeDetailList;
         }
      });
   }
}


