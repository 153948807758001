import { Component, Input, OnInit } from '@angular/core';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';
import { ProductAttachment } from 'src/app/shared/model/product.model';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { SharedService } from 'src/app/shared/service/shared.service';

@Component({
  selector: 'app-dashlet-detail',
  templateUrl: './dashlet-detail.component.html',
  styleUrls: ['./dashlet-detail.component.css']
})
export class DashletDetailComponent implements OnInit {


  

  dataFromParent:any;
  currentSortColumnIndex:number;
  currentSortMode:string;
  searchValue:string;
  @Input() 
  set data(val : any){
    this.dataFromParent=val;
    //this.filteredResultList=this.dataFromParent.dashletDetails.objectList;
    this.getDetailsObjectList();
    this.searchValue='';
    this.currentSortColumnIndex=undefined;
    this.currentSortMode=undefined;
  }
  @Input() showTitle:boolean=true;

  filteredResultList:any[]=[];
  originalResultList:any[]=[];

  constructor(private dashboardService: DashboardService,private sharedService:SharedService) {
   
   }
  ngOnInit(): void {
    //this.filteredResultList=this.dataFromParent.dashletDetails.objectList;
  }

  getDetailsObjectList() {
    if(this.dataFromParent.dashletDetails.id && this.dataFromParent.dashletDetails.detailDisplayType){
      
      this.dashboardService.getDashlet(this.dataFromParent.dashletDetails.id,this.dataFromParent.dashletDetails.type,true).subscribe((res) => {
        let dashletDetails:DashletDetails = res as DashletDetails;
        if(dashletDetails){
          this.filteredResultList=dashletDetails.objectList;
          this.originalResultList=dashletDetails.objectList;;
        }
  });
    }
  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
  }
  
  }

  onKeyupSearch(event, value) {

    if (event.key !== 'Shift') {
      if (value.trim().length > 0) {
        this.filteredResultList = this.originalResultList.filter(row =>
          this.allInOneString(row).toUpperCase().indexOf(value.toUpperCase()) > -1);

        //this.groupList();
      } else {
        this.filteredResultList = this.originalResultList;
      }
    }
  }

  allInOneString(row: any) {
    let allInOne = '';
    row.forEach(columns => {
      if(columns.propValue){
        allInOne = allInOne + '~' + columns.propValue;
      }
    });
    return allInOne;
  }
  sortListByPropertyName(colIndex:number){
    let propertyToSortOn=this.filteredResultList[0][colIndex].propName;
    
    let sortMode='ASC';
    if(this.currentSortColumnIndex===colIndex){
      if(this.currentSortMode==='ASC'){
        sortMode='DESC'
      }
    }
    this.currentSortColumnIndex=colIndex;
    this.currentSortMode=sortMode;
    this.filteredResultList=this.sortComplexListByPropertyName(this.currentSortColumnIndex,this.filteredResultList,'propValue','propType',sortMode);
    
  }

  sortComplexListByPropertyName(colIndex:number,list: any, propertyName: string,propertyTypeVar:string, mode?:string): [] {
    if (propertyName && list && list != null) {
      console.log('Sorting');
       let sortedList: [] = list.sort(function (a1, b1) {
        
          if (true) {
             const propertyValue = a1[colIndex][propertyName];
             const propertyType = a1[colIndex][propertyTypeVar];
             console.log('Sorting Property Type: ',propertyType);
             if (propertyType==='Date') {
                const dateA: Date = new Date(a1[colIndex][propertyName]);
                const dateB: Date = new Date(b1[colIndex][propertyName]);
                console.log('Compaere Dates: ', dateA, dateB, dateA.getTime() > dateB.getTime(),dateA.getTime() < dateB.getTime(), mode);
                if(mode && mode==='DESC'){
                  console.log('Sorting',mode);
                  if (dateA === null && dateB === null) return 0;
                  else if (dateA === null) return -1; // Place null values at the end
                  else if (dateB === null) return 1; // Place null values at the end
                  else return (dateB.getTime() - dateA.getTime());
                }else{
                  console.log('Sorting',mode);
                  if (dateA === null && dateB === null) return 0;
                  else if (dateA === null) return 1; // Place null values at the end
                  else if (dateB === null) return -1; // Place null values at the end
                  else return (dateA.getTime() - dateB.getTime());
                }
              // return (dateA.getTime() > dateB.getTime());
            } 
             else if (typeof propertyValue === 'string') {
                var textA = a1[colIndex][propertyName];
                var textB = b1[colIndex][propertyName];
                if(!textA){textA=null};
                if(!textB){textB=null};
                if(mode && mode==='DESC'){
                  if (textA === null || textA === undefined) return 1; // Place null/undefined values at the end
                  if (textB === null || textB === undefined) return -1; // Place null/undefined values at the end
                  return textB.localeCompare(textA);
               }else{
                if (textA === null || textA === undefined) return -1; // Place null/undefined values at the beginning
                if (textB === null || textB === undefined) return 1; // Place null/undefined values at the beginning
                return textA.localeCompare(textB);
               }
               // return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
             }
             
             else if (typeof propertyValue === 'number') {
                const numA = (a1[colIndex][propertyName]);
                const numB = (b1[colIndex][propertyName]);
                
                if(mode && mode==='DESC'){
                   return(numA > numB) ? -1 : (numA < numB) ? 1 : 0;
                }else{
                   return(numA < numB) ? -1 : (numA > numB) ? 1 : 0;
                }
             }
          }
       });
       console.log('sortedList',sortedList)
       return sortedList;
    }
    return list;
 }

 approvalStatusColor(status:string):string{
  if(this.sharedService.producatStatusColor.get(status)!==undefined){
    return this.sharedService.producatStatusColor.get(status);
  }else{
    return '';
  }

}

}
