<form #ProductForm="ngForm" (ngSubmit)="createOrUpdateProduct()"  autocomplete="off" #formVar="ngForm" novalidate>
   <div class="container" id="containerId">
           <div class="d-flex justify-content-center align-items-center mt-3">
                   <div class="text-color-{{approvalStatusColor(product.approvalStatus)}}"><h3>{{product.sku}}&nbsp;</h3></div>
                   <div (click)="changePriority(product)" class="d-flex justify-content-center align-items-center"  *ngIf="product.id && !editable"
                   [matTooltip]="product.priority?'Remove priority':'Mark as priority'" matTooltipPosition="right" [matTooltipDisabled]="!userAccess?.canChangeProductPriority" matTooltipClass="bg-tomato"
                                      style="display: block; height:1rem;width: 1rem;">
                                  <span *ngIf="!product.priority && userAccess?.canChangeProductPriority">
                                      <i class="far fa-star fa-lg"
                                          style="color: #6c757d24;" 
                                          onmouseover="this.style.color='tomato'" 
                                          onmouseout="this.style.color='#6c757d24'">
                                      </i>
                                  </span>
                                      <span *ngIf="product.priority">
                                          <i class="fas fa-star fa-lg" style="color: tomato;"  >
                                          </i>
                                      </span>
                  </div>
           </div>
           
           <div *ngIf="product.approvalStatus" class="separator-{{approvalStatusColor(product.approvalStatus)}}"><h6>{{product.approvalStatus}}</h6></div>
           <div class="row mt-1">
                   <!--Image & File section starts-->
                   
                   <div class="col-md-7 ml-1">
                           <!--IMAGES Starts-->
                           <div>
                                   <app-attachment-operations [header]="'Visuals'" [editable]="editable" 
                                   [createAction]="createAction" [attachments]="product.productImages" 
                                   [canUpload]="userAccess?.canUploadVisuals" [canDownload]="userAccess?.canDownloadVisuals" 
                                   [canDelete]="userAccess?.canDeleteVisuals" [category]="'IMAGES'" [viewType]="'IMAGES'"
                                   [emptyListError]="'Visual is mandatory to create the product'" [viewCreatedBy]="false" ></app-attachment-operations>
                                   <div *ngIf="product?.isCollection">
                                       <button type="button" (click)="resetCollectionAttachments()"
                                       class="btn bg-warning btn-sm mt-1 ml-2" *ngIf="product.isCollection && !editable && userAccess?.canEditProduct"
                                       matTooltip="Reset Visuals" matTooltipPosition="below" matTooltipClass="bg-dark">
                                       Reset Visuals
                                       </button>
                                   </div>
                           </div>
                           <ng-container *ngIf="product.origination!=='Estimation'">
                              <div class="mt-4">
                                 <mat-tab-group>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>Master Artwork</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                             <app-attachment-operations  [header]="'Master Artwork'" [editable]="editable"  [showHeader]="false"
                                             [createAction]="createAction" [attachments]="product.masterArts" 
                                             [canUpload]="userAccess?.canUploadMasterArts" [canDownload]="userAccess?.canDownloadMasterArts" 
                                             [canDelete]="userAccess?.canDeleteMasterArts" [canRenameFile]="true" [category]="'MASTER_ARTS'" [viewCreatedBy]="false" ></app-attachment-operations>
                                       </div>
                                    </mat-tab>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>PNG Front</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                             <app-attachment-operations  [header]="'PNG Front'" [editable]="editable"  [showHeader]="false"
                                             [createAction]="createAction" [attachments]="product.pngFront" 
                                             [canUpload]="userAccess?.canUploadPngFront" [canDownload]="userAccess?.canDownloadPngFront" 
                                             [canDelete]="userAccess?.canDeletePngFront" [canRenameFile]="true" [category]="'PNG_FRONT'" [viewCreatedBy]="false" ></app-attachment-operations>
                                       </div>
                                    </mat-tab>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>PNG Back</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                             <app-attachment-operations  [header]="'PNG Back'" [editable]="editable"  [showHeader]="false"
                                             [createAction]="createAction" [attachments]="product.pngBack" 
                                             [canUpload]="userAccess?.canUploadPngBack" [canDownload]="userAccess?.canDownloadPngBack" 
                                             [canDelete]="userAccess?.canDeletePngBack" [canRenameFile]="true" [category]="'PNG_BACK'" [viewCreatedBy]="false" ></app-attachment-operations>
                                       </div>
                                    </mat-tab>
                                 </mat-tab-group>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="product.origination!=='Estimation'">
                              <div class="mt-4">
                                 <mat-tab-group>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>Printer Files</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                          <app-attachment-operations [header]="'Printer Files'" [editable]="editable" [showHeader]="false"
                                          [createAction]="createAction" [attachments]="product.seperations" 
                                          [canUpload]="userAccess?.canUploadPrinterFiles" [canDownload]="userAccess?.canDownloadPrinterFiles" 
                                          [canDelete]="userAccess?.canDeletePrinterFiles" [category]="'SEPERATIONS'" [canRenameFile]="true" [viewCreatedBy]="false" ></app-attachment-operations>
                                       </div>
                                    </mat-tab>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>Brand PNL</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                             <app-attachment-operations [header]="'Brand PNL'" [editable]="editable" [showHeader]="false"
                                             [createAction]="createAction" [attachments]="editable?product.brandPnl:getPreferredPnl()" 
                                             [canUpload]="false" [canDownload]="userAccess?.canDownloadPnls" [canDelete]="false" [viewCreatedBy]="false" [likeable]="userAccess?.canPreferPnl" (onLike)="pnlSelectionChangeForProduct($event)"></app-attachment-operations>
                                       </div>
                                    </mat-tab>  
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>Printer Matrix</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                             <app-printer-matrix [product]="product" [editable]="editable && userAccess?.canCreatePrinterMatrix"></app-printer-matrix>
                                       </div>
                                       <button type="button"
                                                               [routerLink]="['/preview-submission-sheet/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/PRINTER_MATRIX_FORM']"
                                                               class="btn btn-dark btn-sm mt-1" *ngIf="!editable && userAccess?.canCreatePrinterMatrix"
                                                               matTooltip="Generate Printer Matrix Form" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                               <i class="fas fa-plus fa-lg "></i>&nbsp; Printer Matrix
                                       </button>
                                    </mat-tab>                                      
                                 </mat-tab-group>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="product.origination!=='Estimation'">
                              <div class="mt-4" *ngIf="userAccess?.canViewProductApprovalSection">
                              <mat-tab-group>
                                 <mat-tab>
                                    
                                    <ng-template mat-tab-label>
                                       <h5>Approval Documents</h5>
                                    </ng-template>
                                    <div class="overflow-hidden mt-3">
                                       <div >
                                          <app-attachment-operations [header]="'Approval Documents'" [editable]="editable" [showHeader]="false"
                                          [createAction]="createAction" [attachments]="product.approvalDocs" [isSelectable]="true"
                                          [canUpload]="userAccess?.canUploadApprovalDocs" [canDownload]="userAccess?.canDownloadApprovalDocs" [canRenameFile]="true"
                                          [canDelete]="userAccess?.canDeleteApprovalDocs" [category]="'APPROVAL_DOCS'" [viewCreatedBy]="false" ></app-attachment-operations>
                                       </div>
                                          <button type="button"
                                                         [routerLink]="['/preview-submission-sheet/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/'+(product.isCollection?'COLLECTION':'PRODUCT')+'/SUBMISSION_SHEET']"
                                                         class="btn btn-warning btn-sm mt-1" *ngIf="!editable && userAccess?.canCreateSubmissionSheet"
                                                         matTooltip="Generate Submission sheet" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp; Submission sheet
                                          </button>
                                          <button type="button"
                                                         [routerLink]="['/preview-approval-form/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/APPROVAL_FORM']"
                                                         class="btn btn-info btn-sm mt-1 ml-2" *ngIf="isApprovalFormAvailable && !editable && userAccess?.canCreateApprovalForms"
                                                         matTooltip="Genreate Approval form" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp; Approval form
                                          </button>
                                          <button type="button"
                                                         [routerLink]="['/preview-approval-form/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/RETAILER_INFO_FORM']"
                                                         class="btn btn-info btn-sm mt-1 ml-2" *ngIf="isRetailerInfoAvailable && !editable && userAccess?.canCreateApprovalForms"
                                                         matTooltip="Genreate Retailer Info" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp; Retailer Info
                                          </button>
                                          <button type="button" (click)="sendApprovalEmail()"
                                                         class="btn bg-navy-blue btn-sm mt-1 ml-2" *ngIf="product?.approvalDocs?.length>0 && !editable && userAccess?.canCreateApprovalForms"
                                                         matTooltip="Send Approval Email" matTooltipPosition="below" matTooltipClass="bg-dark">
                                                         <i class="fa fa-envelope text-light fa-lg "></i>
                                          </button>
                                    </div>
                                    </mat-tab>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <h5>Approval Photos</h5>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3">
                                          <div>
                                             <app-attachment-operations  [header]="'Approval Photos'" [editable]="editable" [showHeader]="false"
                                             [createAction]="createAction" [attachments]="product.photoApproval" [isSelectable]="true"
                                             [canUpload]="userAccess?.canUploadPhotoApproval" [canDownload]="userAccess?.canDownloadPhotoApproval" [canRenameFile]="true"
                                             [canDelete]="userAccess?.canDeletePhotoApproval" [category]="'PHOTO_APPROVAL'" [viewCreatedBy]="false" ></app-attachment-operations>
                                          </div>
                                          <button type="button"
                                                         [routerLink]="['/preview-submission-sheet/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/PRE_PRO_PHOTO_APPROVAL_FORM']"
                                                         class="btn btn-warning btn-sm mt-1" *ngIf="!editable && userAccess?.canCreatePhotoApproval"
                                                         matTooltip="Generate Photo Approval Form" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp;Pre-Pro Photo Approval
                                          </button>
                                          <button type="button"
                                                         [routerLink]="['/preview-submission-sheet/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/FINAL_PHOTO_APPROVAL_FORM']"
                                                         class="btn btn-dark btn-sm mt-1 ml-2" *ngIf="!editable && userAccess?.canCreatePhotoApproval"
                                                         matTooltip="Generate Photo Approval Form" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp;Final Photo Approval
                                          </button>
                                          <button type="button"
                                                         [routerLink]="['/preview-approval-form/'+product.licensorId+'/'+product.brandId+'/'+product.id+'/PRODUCT/SAMPLE_FORM']"
                                                         class="btn btn-info btn-sm mt-1 ml-2" *ngIf="isSampleFormAvailable && !editable && userAccess?.canCreatePhotoApproval"
                                                         matTooltip="Genreate Approval form" matTooltipPosition="below" matTooltipClass="bg-dark" skipLocationChange>
                                                         <i class="fas fa-plus fa-lg "></i>&nbsp; Sample form
                                          </button>
                                          <button type="button" (click)="sendPhotoApprovalEmail()"
                                                         class="btn bg-navy-blue btn-sm mt-1 ml-2" *ngIf="product?.photoApproval?.length>0 && !editable && userAccess?.canCreatePhotoApproval"
                                                         matTooltip="Send Photo Approval Email" matTooltipPosition="below" matTooltipClass="bg-dark">
                                                         <i class="fa fa-envelope text-light fa-lg "></i>
                                          </button>
                                       </div>
                                    </mat-tab>
                                    <mat-tab>
                                       <ng-template mat-tab-label>
                                          <span (click)="loadApprovalEmailLogs()"><h5>Emails Sent</h5></span>
                                       </ng-template>
                                       <div class="overflow-hidden mt-3 border-box" *ngIf="approvalEmailLogs && approvalEmailLogs.length>0">
                                          <div class="mt-2" >
                                             <ng-container *ngFor="let emailLog of approvalEmailLogs">
                                                <div><span class="font-weight-light ml-3"><i class="fa fa-envelope"></i>  Sent by <em class="font-weight-bold">{{emailLog.sentBy}}</em> on <em class="font-weight-bold">{{emailLog.sentDate | date:'medium'}}</em></span>
                                                   <div class="ml-3">
                                                      <ul class="ml-3">
                                                         <ng-container *ngIf="emailLog.emailAttachments && emailLog.emailAttachments.length>0">
                                                            <li class="text-muted"  *ngFor="let att of emailLog.emailAttachments.split(',')">{{att}}</li>
                                                         </ng-container>
                                                         <ng-container *ngIf="!emailLog.emailAttachments || emailLog.emailAttachments.length===0">
                                                            <div class="text-muted">No attachments sent!</div>
                                                         </ng-container>
                                                      </ul>
                                                   </div>
                                                </div>
                                                <hr class="mt-1 mb-1">
                                                
                                             </ng-container>
                                          </div>
                                       </div>
                                       <div class="mt-2" *ngIf="approvalEmailLogs?.length===0">
                                          <p class="text-muted"><em>No emails sent</em></p>
                                       </div>
                                    </mat-tab>
                                 </mat-tab-group>
                                 
                                 
                              </div>
                           </ng-container>
                           
                           
                   </div>
                   <!--Image & File section starts-->

                   <!-- Datails Starts-->
                   <div class="col-md ml-1">
                      <mat-tab-group (selectedTabChange)="selectedTabChange($event)">
                         <mat-tab>
                            <ng-template mat-tab-label>
                               <h5>Details</h5>
                            </ng-template>
                            <div class="ml-2 mt-4 overflow-hidden">
                               <!--<p class="text-info" *ngIf="product.isCollection">This product is a collection</p>
                               <p class="text-info" *ngIf="product.isCollectionMember">This product is a member of a collection</p>-->
                               <input type="hidden" name="ProductId" [(ngModel)]="product.id" #ProductId>
                               <ng-container *ngIf="!(product.origination==='Estimation' && createAction)">
                                 <div class="form-group row">
                                    <label class="col-sm-4 f-13">SKU</label>
                                    <div class="col-sm-8">
                                       <div class="input-group">
                                          <input id="skuId" name="sku" [(ngModel)]="product.sku" (keyup)="onKeyupSkuCodeFilter($event,sku.value)" (paste)="onKeyupSkuCodeFilter($event,sku.value)"
                                             class="form-control form-control-sm" #sku="ngModel" required [disabled]="!editable"
                                             [ngClass]="{'is-invalid':sku.touched && sku.errors}" oninput="this.value = this.value.toUpperCase()"
                                             maxlength="25" minlength="4">
                                          <div class="input-group-append">
                                             <button type="button" class="btn btn-sm btn-dark"[disabled]="!editable" (click)="getNextAvaiableSKUSeries()"
                                             matTooltip="Get next available sku series" matTooltipPosition="below"
                                             matTooltipClass="bg-dark"><i class="fas fa-step-forward"></i></button>
                                          </div>
                                          <div *ngIf="sku?.invalid && (sku.dirty || sku.touched)" class="invalid-feedback">
                                             <div *ngIf="sku.errors.required">
                                                SKU is required.
                                             </div>
                                             <div *ngIf="sku.value?.length > 0 && sku.value?.length&lt;4">
                                                Minimum required SKU length is 4
                                             </div>
                                 
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </ng-container>

                               <div class="form-group row" *ngIf="product.isCollection">
                                        <label class="col-sm-4 f-13" >Collection Name</label>
                                        <div class="col-sm-8">
                                                 <textarea name="Description" [(ngModel)]="product.description" rows="{{(product.description?.length / 25)>2?(product.description?.length / 25):2}}"
                                                       class="form-control form-control-sm" [disabled]="!editable" maxlength="250"
                            #descriptionVar="ngModel" required oninput="this.value = this.value.toUpperCase()"
                            [ngClass]="{'is-invalid':descriptionVar.touched && descriptionVar.errors}" pattern="^[-_ a-zA-Z0-9]+$" appLegalSuperScripts>
                                                 </textarea>
                                                 <div *ngIf="descriptionVar?.invalid && (descriptionVar.dirty || descriptionVar.touched)"
                                                                class="invalid-feedback">
                                                                <div *ngIf="descriptionVar.errors.required">
                                                                         Collection Name is required.
                                                                </div>
                                                                <div *ngIf="descriptionVar.errors.pattern">
                                                                  Collection Name can only contain hyphen, underscore, space, alphabets and  numbers
                                                               </div>
                                                 </div>
                                        </div>
                               </div>
                               <div class="form-group row" *ngIf="!product.isCollection">
                                 <label class="col-sm-4 f-13" >Description</label>
                                 <div class="col-sm-8">
                                          <textarea name="Description" [(ngModel)]="product.description" rows="{{(product.description?.length / 25)>2?(product.description?.length / 25):2}}"
                                                class="form-control form-control-sm" [disabled]="!editable" maxlength="250"
                                                #descriptionVar="ngModel" required
                                                [ngClass]="{'is-invalid':descriptionVar.touched && descriptionVar.errors}" appLegalSuperScripts>
                                          </textarea>
                                          <div *ngIf="descriptionVar?.invalid && (descriptionVar.dirty || descriptionVar.touched)"
                                                         class="invalid-feedback">
                                                         <div *ngIf="descriptionVar.errors.required">
                                                                  Description is required.
                                                         </div>
                                          </div>
                                 </div>
                              </div>
                              <ng-container *ngIf="product.origination!=='Estimation'">
                                 <div class="form-group row">
                                    <label class="col-sm-4 f-13">Approval Status</label>
                                    <!--<div class="col-sm-8">
                                       <div class="input-group">
                                          <input name="approvalStatusVar" [(ngModel)]="product.approvalStatus"
                                          class="form-control form-control-sm" #approvalStatusVar required readonly>
                                          <div class="input-group-append">
                                             <button id="statusChangeModalId" *ngIf="editable" class="btn btn-warning btn-sm" #statusChangeModalBtn type="button" [disabled]="!editable" data-toggle="modal" data-target="#skuStatusChange"><i class="fas fa-pencil-alt"></i></button>
                                             <button id="statusChangeModalId" *ngIf="!editable" class="btn btn-dark btn-sm" #statusChangeModalBtn type="button" [disabled]="editable" data-toggle="modal" data-target="#skuStatusChange"><i class="fas fa-list"></i></button>
                                          </div>
                                          </div>
                                    </div>-->
                                    <div class="col-sm-8">
                                       <div class="input-group">
                                          <select class="form-control form-control-sm custom-select" [(ngModel)]="product.approvalStatus"
                                             name="approvalStatusVar" #approvalStatusVar="ngModel" required [disabled]="!editable" (change)="statusChanged(product,$event)"
                                             [ngClass]="{'is-invalid':approvalStatusVar.touched && approvalStatusVar.errors}">
                                             <option *ngFor="let approvalStatus1 of approvalStatusList" [ngValue]="approvalStatus1">
                                                {{approvalStatus1}}</option>
                                          </select>
                                          <div class="input-group-append" *ngIf="userAccess?.canViewSkuTracker">
                                             <button id="statusChangeModalId" *ngIf="editable && userAccess?.canEditSkuTracker" class="btn btn-warning btn-sm" #statusChangeModalBtn type="button" [disabled]="!editable" data-toggle="modal" data-target="#skuStatusChange"><i class="fas fa-pen"></i></button>
                                             <button id="statusChangeModalId" *ngIf="!editable" class="btn btn-dark btn-sm" #statusChangeModalBtn type="button" (click)="viewStatusChangeModal()" [disabled]="editable" data-toggle="modal" data-target="#skuStatusChange"><i class="fas fa-list"></i></button>
                                          </div>
                                          <div *ngIf="approvalStatusVar?.invalid && (approvalStatusVar.dirty || approvalStatusVar.touched)"
                                             class="invalid-feedback">
                                             <div *ngIf="approvalStatusVar.errors.required">
                                                Approval Status is required.
                                             </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                          
                                 </div>
                              
                                 <!--<div class="form-group row" *ngIf="(product.approvalStatus === 'Concept Requires Revision' || product.approvalStatus === 'Pre-Pro Samples Requires Revision' || product.approvalStatus === 'Concept Approved with Changes')">-->
                                 <div class="form-group row" ><!--*ngIf="licComReqMandAppStatus.includes(product.approvalStatus) || licComReqOptAppStatus.includes(product.approvalStatus)"-->
                                    <label class="col-sm-4 f-13">Licensor Comments</label>
                                    <div class="col-sm-8">
                                          <textarea name="LicensorComments" [(ngModel)]="newTempStatusDetails.licensorComments" rows="{{(newTempStatusDetails?.licensorComments?.length / 25)>2?(newTempStatusDetails?.licensorComments?.length / 25):2}}"
                                             class="form-control form-control-sm" [disabled]="!editable"
                                             #licensorCommentsVar="ngModel"  maxlength="2000"
                                             [ngClass]="{'is-invalid':licensorCommentsVar.touched && licensorCommentsVar.errors}" appLegalSuperScripts>
                                          </textarea><!--[required]="licComReqMandAppStatus.includes(product.approvalStatus)"-->
                                          <div *ngIf="licensorCommentsVar?.invalid && (licensorCommentsVar.dirty || licensorCommentsVar.touched)"
                                             class="invalid-feedback">
                                             <div *ngIf="licensorCommentsVar.errors.required">
                                                Licensor Comments is required.
                                             </div>
                                          </div>
                                    </div>
                                 </div>
                                 <div class="form-group row" ><!--*ngIf="licComReqMandAppStatus.includes(product.approvalStatus) || licComReqOptAppStatus.includes(product.approvalStatus)"-->
                                    <label class="col-sm-4 f-13">Philcos Comments</label>
                                    <div class="col-sm-8">
                                          <textarea name="PhilcosComments" [(ngModel)]="newTempStatusDetails.philcosComments" rows="{{(newTempStatusDetails?.philcosComments?.length / 25)>2?(newTempStatusDetails?.philcosComments?.length / 25):2}}"
                                             class="form-control form-control-sm" [disabled]="!editable"
                                             #philcosCommentsVar="ngModel" 
                                             [ngClass]="{'is-invalid':philcosCommentsVar.touched && philcosCommentsVar.errors}" appLegalSuperScripts>
                                          </textarea><!--[required]="licComReqMandAppStatus.includes(product.approvalStatus)"-->
                                          <div *ngIf="philcosCommentsVar?.invalid && (philcosCommentsVar.dirty || philcosCommentsVar.touched)"
                                             class="invalid-feedback">
                                             <div *ngIf="philcosCommentsVar.errors.required">
                                                Philcos Comments is required.
                                             </div>
                                          </div>
                                    </div>
                                 </div>
                              </ng-container>
                              <ng-container *ngIf="product.origination!=='Estimation'">
                                 <div class="form-group row">
                                    <label class="col-sm-4 f-13">Art Status</label>
                                    <div class="col-sm-8">
                                       <div class="input-group">
                                          <select class="form-control form-control-sm custom-select" [(ngModel)]="product.artStatus"
                                             name="artStatusVar" #artStatusVar="ngModel"  [disabled]="!editable" 
                                             [ngClass]="{'is-invalid':artStatusVar.touched && artStatusVar.errors}">
                                             <option *ngFor="let artStatus of artStatusList" [ngValue]="artStatus">
                                                {{artStatus}}</option>
                                          </select>
                                          
                                          <div *ngIf="artStatusVar?.invalid && (artStatusVar.dirty || artStatusVar.touched)"
                                             class="invalid-feedback">
                                             <div *ngIf="artStatusVar.errors.required">
                                                Approval Status is required.
                                             </div>
                                          </div>
                                       </div>
                                    
                                    </div>
                                          
                                 </div>
                              
                              </ng-container>
                               <div class="form-group row">
                                        <label class="col-sm-4 f-13" *ngIf="!product.licensorId || (product.licensorId && !userAccess?.canViewLicensorsSection)">Licensor</label>
                                        <label class="col-sm-4 f-13" *ngIf="product.licensorId && userAccess?.canViewLicensorsSection"><a  style="color: #333;" class="text-decoration-none" target="_blank" [routerLink]="['/licensor/view/'+product.licensorId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Licensor">Licensor<i class="fas fa-external-link-alt ml-1"></i></a></label>
                                        <div class="col-sm-8" *ngIf="!editable">
                                                 <input name="Licensor" [(ngModel)]="product.licensorName" class="form-control form-control-sm" readonly>
                                        </div>
                                        <div class="col-sm-8" *ngIf="editable">
                                                 <select class="form-control form-control-sm custom-select" [(ngModel)]="product.licensorId"
                                                       name="licensorVar" #licensorVar="ngModel" (change)="onLicensorChange($event)" required
                                                       [ngClass]="{'is-invalid':licensorVar.touched && licensorVar.errors}">
                                                       <!--<option *ngFor="let licensor of licensorList" [ngValue]="licensor.id">
                                                                {{licensor.licensorName}}</option>-->
                                                      <ng-container *ngFor="let licensor of licensorList">
                                                         <option  [ngValue]="licensor.id" *ngIf="licensor.licensorStatus">{{licensor.licensorName}}</option>
                                                      </ng-container>
                                                      <option *ngIf="licensorList?.length>0" disabled style="font-style: italic;font-weight: bolder;color:rgba(238, 30, 30, 0.842);"><span class="text-center">Inactive Licensors</span></option>
                                                          <hr *ngIf="licensorList?.length>0">
                                                      <ng-container *ngFor="let licensor of licensorList">
                                                         <option  [ngValue]="licensor.id"  *ngIf="!licensor.licensorStatus">{{licensor.licensorName}}</option>
                                                      </ng-container>
                                                 </select>
                                                 <div *ngIf="licensorVar?.invalid && (licensorVar.dirty || licensorVar.touched)"
                                                       class="invalid-feedback">
                                                       <div *ngIf="licensorVar.errors.required">
                                                                Licensor is required. 
                                                       </div>
                                                 </div>
                               
                                        </div>
                               
                               </div>
                               <div class="form-group row">
                                        <label class="col-sm-4 f-13" *ngIf="!product.brandId || (product.brandId && !userAccess?.canViewBrandsSection)">Brand</label>
                                        <label class="col-sm-4 f-13" *ngIf="product.brandId && userAccess?.canViewBrandsSection">
                                           <a  style="color: #333;" class="text-decoration-none" target="_blank" [routerLink]="['/brand/view/'+product.brandId]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Brand">
                                             Brand
                                             <i class="fas fa-external-link-alt ml-1"></i>
                                          </a>
                                       </label>
                                        <div class="col-sm-8" *ngIf="!editable">
                         <input name="Brand" [(ngModel)]="product.brandName" class="form-control form-control-sm"
                                                       readonly >
                                        </div>
                                        <div class="col-sm-8" *ngIf="editable">
                                                 <select class="form-control form-control-sm custom-select" [(ngModel)]="product.brandId"
                                                          name="brandVar" [disabled]="!product.licensorId" (change)="onBrandChange($event)"
                                                          #brandVar="ngModel" required [ngClass]="{'is-invalid':brandVar.touched && brandVar.errors}">
                                                          <ng-container *ngFor="let brand of brandList">
                                                            <option  [ngValue]="brand.id" *ngIf="brand.brandStatus">{{brand.brandName}}</option>
                                                          </ng-container>
                                                          
                                                          <option *ngIf="brandList?.length>0 && licensorHasInactiveBrand" disabled style="font-style: italic;font-weight: bolder;color:rgba(238, 30, 30, 0.842);"><span class="text-center">Inactive Brands</span></option>
                                                          <hr *ngIf="brandList?.length>0 && licensorHasInactiveBrand">
                                                          <ng-container *ngFor="let brand of brandList">
                                                            <option  [ngValue]="brand.id" *ngIf="!brand.brandStatus">{{brand.brandName}}</option>
                                                          </ng-container>
                                                          
                                                 </select>
                         <div *ngIf="brandVar?.invalid && (brandVar.dirty || brandVar.touched)" class="invalid-feedback">
                                                                <div *ngIf="brandVar.errors.required">
                                                                         Brand is required.
                                                                </div>
                                                 </div>
                                        </div>
                               </div>
                               <div class="form-group row">
                                        <div class="col-sm-4">
                                                 <div class="row">
                                                       <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Color </label>
                                                 </div>
                                        </div>
                                        <div class="col-sm-8" *ngIf="!editable">
                         <input name="colorVar" [(ngModel)]="product.color" class="form-control form-control-sm"
                            #colorVar readonly>
                                        </div>
                                       <div class="col-sm-8" *ngIf="editable">
                                          <!--<select class="form-control form-control-sm custom-select" [(ngModel)]="product.color" name="colorVar"
                                             #colorVar="ngModel" [required]="product.origination!=='Estimation'"
                                             [ngClass]="{'is-invalid':colorVar.touched && colorVar.errors}"
                                             (change)="onDropdownChange('Color','', product?.color)">
                                             <option *ngFor="let color of colorList" [ngValue]="color.name"
                                                [style]="'background-image:url('+color.swatchList[0]?.fileSignedUrl+');'">
                                                <ng-container *ngIf="false && color.swatchList && color.swatchList.length>0">
                                                   <img [src]="color.swatchList[0].thumbnailSignedUrl" class="img-thumbnail img-fluid "
                                                      style="display: block;  width:30px;  height:30px;"
                                                      (error)="handleThumbnailError(color.swatchList[0],$event)">
                                                </ng-container>
                                                {{color.name}} {{color.swatchList.length}}
                                             </option>
                                          </select>-->
                                         
                                             <mat-select class="form-control form-control-sm " [(ngModel)]="product.color" name="color.name"
                                             #colorVar="ngModel" [required]="product.origination!=='Estimation'"
                                             [ngClass]="{'is-invalid':colorVar.touched && colorVar.errors}"
                                             (change)="onDropdownChange('Color','', product?.color)">
                                               <mat-option *ngFor="let color of colorList" [value]="color.name" (mouseenter)="previewColourImage(color,true,$event);" (mouseleave) ="previewColourImage(color,false,$event)">
                                                <div class="d-flex justify-content-between align-items-center" 
                                                >
                                                 {{color.name}}
                                                 <span *ngIf="color.swatchList && color.swatchList.length>0">
                                                 <img [src]="color.swatchList[0].fileSignedUrl"
                                                            class="" 
                                                            style="display: block;  width:30px;  height:30px; z-index: 100000;padding: 0%;"
                                                            (error)="handleThumbnailError(color.swatchList[0],$event)">
                                                 </span><!--img-thumbnail img-fluid zoom-5-left-->
                                                </div>
                                               </mat-option>
                                             </mat-select>
                                          
                                          <div *ngIf="colorVar?.invalid && (colorVar.dirty || colorVar.touched)" class="invalid-feedback">
                                             <div *ngIf="colorVar.errors.required">
                                                Color is required.
                                             </div>
                                          </div>
                                       </div>
                               </div>
                               <div class="form-group row">
                                     <label class="col-sm-4 f-13">Treatment</label>
                                     <div class="col-sm-8" *ngIf="!editable">
                                        <input name="treatmentVar" [(ngModel)]="product.treatment" class="form-control form-control-sm" #treatmentVar
                                           readonly>
                                     </div>
                                     <div class="col-sm-8" *ngIf="editable">
                                        <select class="form-control form-control-sm custom-select" [(ngModel)]="product.treatment" name="treatmentVar"
                                           #treatmentVar="ngModel" [required]="product.origination!=='Estimation'" [ngClass]="{'is-invalid':treatmentVar.touched && treatmentVar.errors}"
                                           (change)="onDropdownChange('Treatment','', product?.treatment)">
                                           <option *ngFor="let treatment of treatmentList" [ngValue]="treatment">
                                              {{treatment}}</option>
                                        </select>
                                        <div *ngIf="treatmentVar?.invalid && (treatmentVar.dirty || treatmentVar.touched)" class="invalid-feedback">
                                           <div *ngIf="treatmentVar.errors.required">
                                              Treatment is required.
                                           </div>
                                        </div>
                                     </div>
                               </div>
                              
                              <div class="form-group row">
                                 <div class="col-sm-4">
                                    <div class="row">
                                       <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Garment </label>
                                    </div>
                                 </div>
                              
                                 <div class="col-sm-8" *ngIf="!editable">
                                    <input name="garmentVar" [(ngModel)]="product.garment" class="form-control form-control-sm" #garmentVar readonly>
                                 </div>
                                 <div class="col-sm-8" *ngIf="editable">
                              
                              
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="product.garment" name="garmentVar"
                                       #garmentVar="ngModel" [required]="product.origination!=='Estimation'"
                                       [ngClass]="{'is-invalid':garmentVar.touched && garmentVar.errors}"
                                       (change)="onDropdownChange('Garment','', product?.garment)">
                                       <option *ngFor="let garment of garmentList" [ngValue]="garment">
                                          {{garment}}</option>
                                    </select>
                                    <div *ngIf="garmentVar?.invalid && (garmentVar.dirty || garmentVar.touched)" class="invalid-feedback">
                                       <div *ngIf="garmentVar.errors.required">
                                          Garment is required.
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="form-group row">
                                 <div class="col-sm-4">
                                    <div class="row">
                                       <label class="col-sm-4 f-13" style="margin-bottom: 0px;">Category</label>
                                    </div>
                                 </div>
                                 <div class="col-sm-8" *ngIf="!editable">
                                    <input name="categoryVar" [(ngModel)]="product.category" class="form-control form-control-sm" #categoryVar
                                       readonly>
                                 </div>
                                 <div class="col-sm-8" *ngIf="editable">
                                    <select class="form-control form-control-sm custom-select" [(ngModel)]="product.category" name="categoryVar"
                                       #categoryVar="ngModel" [required]="product.origination!=='Estimation'"
                                       [ngClass]="{'is-invalid':categoryVar.touched && categoryVar.errors}"
                                       (change)="onDropdownChange('Category','', product?.category)">
                                       <option value>Choose Category</option>
                                       <option *ngFor="let category of categoryList" [ngValue]="category">
                                          {{category}}</option>
                                    </select>
                                    <div *ngIf="categoryVar?.invalid && (categoryVar.dirty || categoryVar.touched)" class="invalid-feedback">
                                       <div *ngIf="categoryVar.errors.required">
                                          Category is required.
                                       </div>
                                    </div>
                                 </div>
                              </div>

                               <div class="form-group row">
                                     <label class="col-sm-4 f-13">Print Effect</label>
                                     <div class="col-sm-8" *ngIf="!editable">
                                           <input name="printEffectVar" [(ngModel)]="product.printEffect" class="form-control form-control-sm"
                                              #printEffectVar readonly>
                                     </div>
                                     <div class="col-sm-8" *ngIf="editable">
                                           <select class="form-control form-control-sm custom-select" [(ngModel)]="product.printEffect"
                                              name="printEffectVar" #printEffectVar="ngModel" [required]="product.origination!=='Estimation'"
                                              [ngClass]="{'is-invalid':printEffectVar.touched && printEffectVar.errors}"
                                              (change)="onDropdownChange('Print_Effect','', product?.printEffect)">
                                              <option *ngFor="let printEffect of printEffectList" [ngValue]="printEffect">
                                                 {{printEffect}}</option>
                                           </select>
                                           <div *ngIf="printEffectVar?.invalid && (printEffectVar.dirty || printEffectVar.touched)"
                                              class="invalid-feedback">
                                              <div *ngIf="printEffectVar.errors.required">
                                                 Print Effect is required.
                                              </div>
                                           </div>
                                     </div>
                               </div>
                               <ng-container *ngIf="product.origination!=='Estimation'">
                                 <div class="form-group row">
                                       <label class="col-sm-4 f-13"># of Screens</label>
                                       <div class="col-sm-8">
                                             <input type="number" name="screenCount" [(ngModel)]="product.screenCount"
                                                class="form-control form-control-sm" [disabled]="!editable"
                                                #screenCountVar="ngModel" required pattern="\d{1,3}"
                                                [ngClass]="{'is-invalid':screenCountVar.touched && screenCountVar.errors}">
                                             <div *ngIf="screenCountVar?.invalid && (screenCountVar.dirty || screenCountVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="screenCountVar.errors?.required">
                                                   Screen Count is required.
                                                </div>
                                                <div *ngIf="screenCountVar.errors?.pattern">
                                                   Screen count is invalid.
                                                </div>
                                             </div>
                                       </div>
                                 </div>

                                 <div class="form-group row" *ngIf="userAccess?.isSupportAdmin">
                                    <label class="col-sm-4 f-13">Pre Pro Sample Approved Printer</label>
                                    <div class="col-sm-8">
                                          <select class="form-control form-control-sm custom-select" [(ngModel)]="product.preProSampleApprovedPrinterId"
                                             name="preProSampleApprovedPrinterVar" #preProSampleApprovedPrinterVar="ngModel" [disabled]="!editable"
                                             [ngClass]="{'is-invalid':preProSampleApprovedPrinterVar.touched && preProSampleApprovedPrinterVar.errors}"
                                             >
                                             <option *ngFor="let printer of printerList" [ngValue]="printer.id">
                                                {{printer.name}}</option>
                                          </select>
                                          <div *ngIf="preProSampleApprovedPrinterVar?.invalid && (preProSampleApprovedPrinterVar.dirty || preProSampleApprovedPrinterVar.touched)"
                                             class="invalid-feedback">
                                             <div *ngIf="preProSampleApprovedPrinterVar.errors.required">
                                                Pre Pro Approved Sample Printer is required.
                                             </div>
                                          </div>
                                    </div>
                                 </div>

                                 <div class="form-group row" *ngIf="editable||product.imageCode?.length>0">
                                             <label class="col-sm-4 f-13">Image Code</label>
                                             <div class="col-sm-8">
                                                   <textarea name="imageCode" [(ngModel)]="product.imageCode" rows="{{(product.imageCode?.length / 25)>2?(product.imageCode?.length / 25):2}}"
                                                   class="form-control form-control-sm" [disabled]="!editable" maxlength="150"
                                                   #imageCodeVar="ngModel">
                                                   </textarea>
                                             </div>
                                 </div>
                                 <div class="form-group row" *ngIf="editable||product.photographer?.length>0">
                                    <label class="col-sm-4 f-13">Photographer</label>
                                    <div class="col-sm-8">
                                          <textarea name="photographer" [(ngModel)]="product.photographer" rows="{{(product.photographer?.length / 25)>2?(product.photographer?.length / 25):2}}"
                                          class="form-control form-control-sm" [disabled]="!editable" maxlength="120"
                                          #photographerVar="ngModel">
                                          </textarea>
                                    </div>
                                 </div>
                                 <div class="form-group row" *ngIf="product?.brandCategory==='Sports' && !product.isCollection">
                                    <label class="col-sm-4 f-13">Pantone Colors</label>
                                    <div class="col-sm-8">
                                          <textarea name="pantoneColors" [(ngModel)]="product.pantoneColors" [rows]="getEnterCount(PCvar.value,10)"
                                          class="form-control form-control-sm" [disabled]="!editable" maxlength="200" 
                                          #pantoneColorsVar="ngModel" #PCvar>
                                          </textarea>
                                          <!-- rows="{{(product.pantoneColors?.length / 25)>2?(product.pantoneColors?.length / 25):2}}"-->
                                    </div>
                                 </div>
                                 <div class="form-group row">
                                       <label class="col-sm-4 f-13">Notes</label>
                                       <div class="col-sm-8">
                                             <textarea name="notes" [(ngModel)]="product.notes" rows="{{(product.notes?.length / 25)>2?(product.notes?.length / 25):2}}"
                                                class="form-control form-control-sm" [disabled]="!editable" maxlength="250"
                                                #notesVar="ngModel"
                                                [ngClass]="{'is-invalid':notesVar.touched && notesVar.errors}" appLegalSuperScripts>
                                             </textarea>
                                             <div *ngIf="notesVar?.invalid && (notesVar.dirty || notesVar.touched)"
                                                class="invalid-feedback">
                                                <div *ngIf="notesVar.errors.required">
                                                   Notes is required.
                                                </div>
                                             </div>
                                       </div>
                                 </div>    

                                 <div>
                                    <div class="form-group d-flex d-inline mb-0">
                                       <div class="mt-1">
                                          <h5>Tags</h5>
                                       </div>
                                       <div class="d-flex justify-content-end mr-1 ml-auto" *ngIf="editable">
                                          <em><span *ngIf="product.tagList.length>19" class="mr-2 mt-2">Maximum of 20 tags allowed</span></em>
                                          <div class="ml-1">
                                             <input name="Tag" id="tagId" [(ngModel)]="currentTag" maxlength="24" class="form-control form-control-sm" oninput="this.value = this.value.toUpperCase()" (keyup.enter)="addTagVar.click()"
                                                placeholder="Add Tag" #tagVar="ngModel" [disabled]="product.tagList.length>19" [ngClass]="{'is-invalid':!(product.tagList.length>6)   && (tagVar.dirty || tagVar.touched)}"
                                                [required]="!(product.tagList.length>=7)">
                                             <div *ngIf="!(product.tagList.length>6)  && (tagVar.dirty || tagVar.touched)" class="invalid-feedback">
                                                Minimum of 7 tags required
                                             </div>
                                          </div>
                                          <div class="ml-1">
                                             <button type="button" class="btn btn-info btn-sm" [disabled]="currentTag.length==0 || product.tagList.length>19" (click)="addTag()" #addTagVar><i class="fas fa-plus"></i></button>
                                 
                                          </div>
                                 
                                       </div>
                                    </div>
                                    <hr class="mt-2">
                                 </div>

                                 <div class="form-group">
                                          <!--<label>Tags</label>-->

                                          <div class="row ml-1">
                                                   <div class="col-xs-1 d-inline m-1 mt-0"
                                                         *ngFor="let tag of product.tagList;index as i">
                                                         <div>
                                                                  <div class="chip color-{{i%4}}">
                                                                           {{tag}}
                                                                           <span class="closebtn" *ngIf="editable" (click)="removeTag(tag)">&times;</span>
                                                                  </div>
                                                         </div>
                                                   </div>
                                          </div>
                                 </div>

                                 <div class="mt-4" *ngIf="(!editable && product.isCollection)">
                                    <div class="mt-1">
                                       <h5>Associated Products</h5>
                                    </div>
                                    <hr class="mt-2">
                                    <ng-container *ngFor="let member of collectionMembers">
                                       <div class="d-flex flex-row align-items-center" *ngIf="member">
                                          <div class="row">
                                             <div class="col-auto">
                                                <app-image-with-preview [disablePreview]="true" [attachment]="member?.productImages[0]"></app-image-with-preview>
                                             </div>
                                             <div class="col-auto px-1 ">
                                                <a type="button" class="f-13 btn btn-link pb-0" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                                [routerLink]="['/product/view/'+member.licensorId+'/'+member.brandId+'/'+member.id]">{{member.sku}}</a>
                                                <div class="ml-3">
                                                   <i class="fas fa-circle fa-sm text-color-{{approvalStatusColor(member.approvalStatus)}}" ></i>
                                                   <a type="button" class="f-11 btn btn-link py-0 pl-1 text-muted" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                                   [routerLink]="['/product/view/'+member.licensorId+'/'+member.brandId+'/'+member.id]">{{member.approvalStatus}}</a>
                                                </div>
                                             </div>
                                          
                                          </div>
                                       </div>
                                    </ng-container>
                                 </div>

                                 <div class="mt-4" *ngIf="(!editable && product.isCollectionMember)">
                                    <div class="mt-1">
                                       <h5>Associated Collection</h5>
                                    </div>
                                    <hr class="mt-2">
                                    <ng-container *ngFor="let colection of collections">
                                       <div class="d-flex flex-row align-items-center" *ngIf="colection">
                                          <div class="row">
                                             <div class="col-auto">
                                                <app-image-with-preview [disablePreview]="true" [attachment]="colection?.productImages[0]"></app-image-with-preview>
                                             </div>
                                             <div class="col-auto px-1 ">
                                                <a type="button" class="f-13 btn btn-link pb-0" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                                [routerLink]="['/product/view/'+colection.licensorId+'/'+colection.brandId+'/'+colection.id]">{{colection.sku}}</a>
                                                <div class="ml-3">
                                                   <i class="fas fa-circle fa-sm text-color-{{approvalStatusColor(colection.approvalStatus)}}" ></i>
                                                   <a type="button" class="f-11 btn btn-link py-0 pl-1 text-muted" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark"
                                                   [routerLink]="['/product/view/'+colection.licensorId+'/'+colection.brandId+'/'+colection.id]">{{colection.approvalStatus}}</a>
                                                </div>
                                             </div>
                                          
                                          </div>
                                       </div>
                                       
                                    </ng-container>
                                 </div>
                                 

                                 <div class="mt-4" *ngIf="editable || (!editable && product.exclusiveToCustomers && product.exclusiveCustomers?.length>0)">
                                    
                                    <!--<div class="d-inline custom-control custom-checkbox mr-sm-2" >
                                       <input type="checkbox" class="custom-control-input ml-2" name="isStyleExclusive" id="isStyleExclusive" 
                                          [checked]="product.exclusiveCustomers?.length>0" [disabled]="true">
                                       <label class="custom-control-label text-break" for="isStyleExclusive">
                                          <h5 style="color: black;">Exclusive To Customer(s)</h5>
                                       </label>
                                    </div> -->   
                                    <div class="form-group d-flex d-inline mb-0">
                                       <div class="mt-1">
                                          <h5>Exclusive To Customer(s)</h5>
                                       </div>
                                       <div class="d-flex justify-content-end mr-1 ml-auto" *ngIf="editable">
                                          <div class="ml-1">
                                             <select class="form-control form-control-sm custom-select" [(ngModel)]="currentCustomer"
                                                name="customer" #customerVar="ngModel" [disabled]="!editable">
                                                <option *ngFor="let customer of customerList" [ngValue]="customer">
                                                   {{customer.customerName}}</option>
                                             </select>
                                          </div>
                                          <div class="ml-1">
                                             <button type="button" class="btn btn-info btn-sm" [disabled]="!currentCustomer.customerId" (click)="addCustomer()" #addCustomerVar><i class="fas fa-plus"></i></button>
                                          </div>
                                    
                                       </div>
                                    </div>                                    
                                    <hr class="mt-2">
                                 </div>
                                 <div class="form-group" *ngIf="product.exclusiveCustomers">
                                    <div class="ml-1">
                                       <div class="col-xs-1 mt-0">
                                          <table class="table table-sm f-11">
                                             <thead>
                                                <tr>
                                                   <th style="width: 40%;">Customer</th>
                                                   <th style="width: 25%;">From</th>
                                                   <th style="width: 25%;">To</th>
                                                   <th style="width: 10%;">Status</th>
                                                   <!--<th>Added By</th>
                                                   <th>Reason</th>-->
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <ng-container  *ngFor="let customer of product.exclusiveCustomers;index as i">
                                                <tr>
                                                   <td>
                                                      <span *ngIf="editable && !customer.id" (click)="removeCustomer(customer)">
                                                         <i class="fas fa-trash " style="color: #dc3545;cursor: pointer;"></i> &nbsp;
                                                      </span>
                                                       <span>{{customer.customerName}}</span>
                                                   </td>
                                                   <td>{{customer.createdOn | date:'mediumDate'}}</td>
                                                   <td *ngIf="editable">
                                                      <div class="d-flex d-inline">
                                                         <input matInput [matDatepicker]="expiryDatePicker" id="shipDate" name="shipDate+{{i}}" [(ngModel)]="customer.expiry" style="cursor: pointer;font-size: 11px;" [min]="customer.createdOn"
                                                            class="form-control  form-control-sm" required #expiryDateVar="ngModel" [ngClass]="{'is-invalid':expiryDateVar.touched && expiryDateVar.errors}" [disabled]="!editable || (editable && customer.expired)">
                                                         <mat-datepicker #expiryDatePicker></mat-datepicker>
                                                         <button class="btn btn-sm" type="button" (click)="expiryDatePicker.open()"><i class="fas fa-calendar-alt"></i></button>
                                                      </div>
                                                   </td>
                                                   <td *ngIf="!editable">{{customer.expiry| date:'mediumDate'}}</td>
                                                   <td>
                                                      <span class=" f-10 text-danger" *ngIf="customer.expired">Expired</span>
                                                      <span class=" f-10 text-success" *ngIf="!customer.expired">Exclusive</span>
                                                   </td>
                                                   <!--<td>{{customer.createdBy}}</td>
                                                   <td>{{customer.reason}}</td>-->
                                                   
                                                </tr>
                                                </ng-container>
                                             </tbody>
                                          </table>
                                          <!--<div>
                                             <div class="chip h-color-{{i%4}}">
                                                {{customer.customerName}}
                                                <span class="closebtn" *ngIf="editable" (click)="removeCustomer(customer)">&times;</span>
                                             </div>
                                          </div>-->
                                       </div>
                                       <!--<div *ngIf="!editable && (product.exclusiveCustomers===null || product.exclusiveCustomers?.length==0)">
                                          <p class="text-muted" ><em>No Exclusive Customers</em></p>
                                       </div>-->
                                    </div>
                                 </div>
                                 <div class="mt-4" *ngIf="editable || (!editable && product.isCollection)">

                                 </div>
                              </ng-container>

                               <div class="row">
                                        <div class="mb-4 col-sm-4"></div>
                                        <div class="mb-4 col-sm-8">
                                          <ng-container *ngIf="product.origination!=='Estimation'">
                                             <button type="button" class="btn btn-info btn-block" (click)="createOrUpdateProduct()" *ngIf="editable && createAction"
                                             [disabled]="!ProductForm.valid || !(product.tagList.length>=7) || !isVisualAvailable()">Submit</button>
                                          </ng-container>
                                          <ng-container *ngIf="product.origination==='Estimation'">
                                             <button type="button" class="btn btn-info btn-block" (click)="createOrUpdateProduct()" *ngIf="editable && createAction"
                                             [disabled]="!ProductForm.valid || !isVisualAvailable()">Submit</button>
                                          </ng-container>                      
                                        </div>
                               </div> 
                         </div>
                      </mat-tab>
                     
                     <ng-container *ngIf="product.origination!=='Estimation'">
                        <mat-tab *ngIf="product.convertedToSKU && userAccess?.canViewUpc" label="upcTab">
                           <ng-template mat-tab-label>
                              <h5>UPC</h5>
                           </ng-template>
                           <!-- Table Style-->
                           <div class="overflow-hidden">
                              <div class="row mt-4">
                                 <div class="col">
                                    <table class="table table-sm table-striped table-bordered table-condensed">
                                       <thead class="text-white bg-dark" ><!--style="background-color:#1BDDB1;"-->
                                          <tr>
                                             <th class="font-weight-light align-middle text-center text-nowrap" style="width:30%">Size</th>
                                             <th class="font-weight-light align-middle text-center text-nowrap" style="width:65%">UPC Code</th>
                                             <th *ngIf="editable" class="font-weight-light" style="width:5%"></th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <ng-container *ngFor="let upc of upcDetail?.upcList; index as upcIndex">
                                             <tr [ngModelGroup]="upcIndex" #upcViewEdit="ngModelGroup" >
                                                <td class="align-middle text-center text-nowrap"  style="width:30%">{{upc.size}}</td>
                                                <td class="align-middle text-center text-nowrap"  style="width:65%" *ngIf="!editable" >{{upc.upcCode}}</td>
                                                <td *ngIf="editable">
                                                   <input name="upcCode_{{upcIndex}}" [disabled]="upc.upcCodeCreationType !=='MANUAL'" 
                                                   class="form-control  form-control-sm mt-1" type="text" [(ngModel)]="upc.upcCode" required  #upcInput="ngModel"                            
                                                   [ngClass]="{'is-invalid':upcInput.touched && upcInput.errors}">
                                                   <div *ngIf="upcInput?.invalid && (upcInput.dirty || upcInput.touched)" class="invalid-feedback">
                                                      <div *ngIf="upcInput.errors.required">Upc Code is required.</div>
                                                   </div>
                                                </td>
                                                <td class="align-middle text-center text-nowrap" style="white-space: nowrap;width: 5%;" *ngIf="editable">
                                                <!--
                                                   <button *ngIf="true" type="button" id="edit_{{upcIndex}}" (click)="editUpcCode(upc)" 
                                                      class="mb-1 btn btn-sm btn-warning"><i class="fas fa-pencil-alt"></i></button>    
                                                -->                                              
                                                   <button *ngIf="upc.upcCodeCreationType ==='MANUAL' && upcViewEdit.valid" type="button" id="update_{{upcIndex}}" 
                                                      (click)="updateUpcCode(upc)" class="ml-1 mb-1 btn btn-sm btn-info"><i class="fas fa-save" style="color:white"></i></button>
                                                   <button *ngIf="upc.upcCodeCreationType ==='MANUAL' && upcViewEdit.valid" type="button" id="delete_{{upcIndex}}" 
                                                      (click)="deleteUpc(upc)" class="ml-1 mb-1 btn btn-sm btn-danger"><i class="fas fa-trash"></i></button>
                                                <!--
                                                   <button *ngIf="true" type="button" id="edit_{{index}}" (click)="updateUpcCode(upc)" 
                                                      class="mb-1 btn btn-sm btn-warning"><i class="fas fa-pencil-alt"></i></button>                                                
                                                   <button *ngIf="true" type="button" (click)="cancelUpc(upc)" 
                                                      class=" btn btn-dark  btn-sm"><i class="fas fa-times"></i></button>
                                                   -->
                                                </td>

                                             </tr>
                                          </ng-container>
                                       </tbody>
                                       <tbody *ngIf="upcDetail?.upcList?.length===0">
                                          <tr>
                                             <td colspan="2">
                                                <p class="text-muted"><em>UPC Code Not Available</em></p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>

                              <div class="row m-1" *ngIf="upcDetail.sizeListWithNoUpcCode.length>0 && editable && !createAction && userAccess?.canCreateUpc">
                                 <div class="col-6">
                                    <button type="button" class="btn btn-sm btn-info btn-block" data-toggle="modal" 
                                    data-target="#createUPCModal" (click)="setUpcCodeCreationType('AUTO')">Generate UPC Codes <i class="fas fa-plus"></i>
                                    </button>
                                 </div>
                                 <div class="col-6">
                                    <button type="button" class="btn btn-sm btn-info btn-block" data-toggle="modal" 
                                    data-target="#createUPCModal" (click)="setUpcCodeCreationType('MANUAL')">Create UPC Codes <i class="fas fa-plus"></i>
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </mat-tab>
                        <mat-tab *ngIf="product.convertedToSKU && userAccess?.canViewSalesOrder" label="associatedSalesOrdersTab">
                           <ng-template mat-tab-label>
                              <h5>Sales Orders</h5>
                           </ng-template>
                           <!-- Table Style-->
                           <div class="overflow-hidden">
                              <div class="row mt-4">
                                 <div class="col">
                                    <table class="table table-sm table-striped table-bordered table-condensed">
                                       <thead class="text-white bg-dark" ><!--style="background-color:#1BDDB1;"-->
                                          <tr class="f-11">
                                             <th class="font-weight-light align-middle text-center text-wrap" style="width:25%">Sales Order #</th>
                                             <th class="font-weight-light align-middle text-center text-wrap" style="width:25%">Customer</th>
                                             <th class="font-weight-light align-middle text-center text-wrap" style="width:25%">Ship Date</th>
                                             <!-- <th class="font-weight-light align-middle text-center text-wrap" style="width:25%">Sales Rep</th> -->
                                             <th class="font-weight-light align-middle text-center text-wrap" style="width:25%">Status</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                       <ng-container *ngFor="let salesOrder of associatedSalesOrders; index as salesOrderIndex">
                                          <tr class="f-11" *ngIf="salesOrder.status!=='DRAFT'">
                                             <td class="align-middle text-center text-nowrap"  style="width:25%">
                                                
                                                <span class="form-control form-control-sm" class="text-nowrap" style="background: transparent; font-size: 12px; padding-left: .30rem;border-width: 0px;">
                                                   <ng-container *ngIf="(userAccess?.canViewAllSalesOrder || (currentLoggedInUser?.id === salesOrder.productionCoordinatorId) || (currentLoggedInUser?.userName === salesOrder.createdBy) 
                                                   || (salesOrder.sharedSalesRepsUserNames?.includes(currentLoggedInUser?.userName)) ) then giveSalesOrderAccess else restrictSalesOrderAccess"></ng-container>
                                                   <ng-template #giveSalesOrderAccess>
                                                      <a  style="color: #333;" class="text-decoration-none" target="_blank"  [routerLink]="['/salesOrder/view/'+salesOrder.id]"  matTooltipPosition="below" matTooltipClass="bg-dark" matTooltip="View Sales Order">
                                                         {{salesOrder?.salesOrderNumber}}
                                                         <i class="fas fa-external-link-alt ml-1 showLinkIcon"></i>   
                                                   </a>
                                                   </ng-template>
                                                   <ng-template #restrictSalesOrderAccess>
                                                      <ng-container *ngIf="!userAccess?.canViewSalesOrder">{{salesOrder.salesOrderNumber}}</ng-container>
                                                   </ng-template>
                                                   
                                                   
                                             </span>
                                                </td>
                                             <td class="align-middle text-center text-nowrap"  style="width:25%">{{salesOrder.customerName}}</td>
                                             <td class="align-middle text-center text-nowrap"  style="width:25%">{{salesOrder.orderShipDate | date:'mediumDate'}}</td>
                                             <!--<td class="align-middle text-center text-nowrap"  style="width:25%">{{salesOrder.salesRepName}}</td>-->
                                             <td class="align-middle text-center text-nowrap"  style="width:25%">{{salesOrder.status}}</td>
                                          </tr>
                                       </ng-container>
                                       </tbody>
                                       <tbody *ngIf="associatedSalesOrders?.length===0">
                                          <tr class="f-11">
                                             <td colspan="4">
                                                <p class="text-muted"><em>Product is not associated with any sales order</em></p>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>

                              <div class="row m-1" *ngIf="upcDetail.sizeListWithNoUpcCode.length>0 && editable && !createAction && userAccess?.canCreateUpc">
                              <div class="col-6">
                                 <button type="button" class="btn btn-sm btn-info btn-block" data-toggle="modal" 
                                 data-target="#createUPCModal" (click)="setUpcCodeCreationType('AUTO')">Generate UPC Codes <i class="fas fa-plus"></i>
                                 </button>
                              </div>
                              <div class="col-6">
                                 <button type="button" class="btn btn-sm btn-info btn-block" data-toggle="modal" 
                                 data-target="#createUPCModal" (click)="setUpcCodeCreationType('MANUAL')">Create UPC Codes <i class="fas fa-plus"></i>
                                 </button>
                              </div>
                           </div>
                           </div>
                        </mat-tab>
                     </ng-container>
                   </mat-tab-group>

                </div>

           </div>


           <div *ngIf="userAccess?.canViewProductSimilarDesigns">
                   <h5 class="text-center mt-3" *ngIf="similarProducts?.length>0">Similar Designs</h5>
                   <hr *ngIf="similarProducts?.length>0">
                   <div>
                           <owl-carousel [options]="SlideOptions" [items]="similarProducts" [carouselClasses]="['owl-theme', 'sliding']" >  
                                   <div class="item" *ngFor="let simProduct of similarProducts">  
                                           <div class="card transform-on-hover h-80 ml-4"><!--*ngIf="simProduct.id!==product.id"-->
                                                   <div class=" card-body d-flex justify-content-center align-items-center"
                                                       style="height:250px;">
                                                       <a class="lightbox" *ngIf="simProduct.productImages[0]"  [routerLink]="['/product/view/'+simProduct.licensorId+'/'+simProduct.brandId+'/'+simProduct.id]"  style="cursor: pointer;" ><!--(click)="similarDesignSelected(simProduct,$event)"-->
                                                           <img [src]="simProduct.productImages[0].thumbnailSignedUrl"
                                                               class="card-img-top img-auto-size" *ngIf="simProduct.productImages[0]"
                                                               (error)="handleThumbnailError(simProduct.productImages[0],$event)"
                                                               >
                                                       </a>
                                                       <a class="lightbox"  *ngIf="!simProduct.productImages[0]" [routerLink]="['/product/view/'+simProduct.licensorId+'/'+simProduct.brandId+'/'+simProduct.id]"  style="cursor: pointer;" ><!--(click)="similarDesignSelected(simProduct,$event)"--> 
                                                           <img src="assets/default-image.jpg" class="card-img-top img-auto-size" >
                                                       </a>
                                                   </div>
                                                   <div class="card-footer">
                                                       <div class="d-flex justify-content-center m-0">
                                                           <h6 class="d-inline text-break">{{simProduct.sku}}</h6>
                                                       </div>
                                                   </div>
                                           </div> 
                                   </div>  
                                   
                           </owl-carousel> 
                   </div>
                   
           </div>
           <div class="mb-2">&nbsp;</div>
   </div>




           
   <!--
           MODAL for adding metadata like color, garment, treatment etc.       
   -->
   <div class="modal fade" id="addNewMetadataModal" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" data-focus-on="input:first"
   aria-labelledby="addMetadataModalTitle" aria-hidden="true">
           <div class="modal-dialog modal-dialog-scrollable" role="document">
                   <div class="modal-content">
                           <div class="modal-header"
            style="padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.50rem; padding-right: 0.50rem;background-color: #f7f7f7;">
            <h5 class="modal-title" id="exampleModalLongTitle" style="font-weight: bold;">Add {{metadata.metadataType}} {{metadata.metadataSubType}}</h5>
                                   <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="closeMetadataModal()">
                                           <span aria-hidden="true">&times;</span>
                                   </button>
                           </div>
                           <div class="modal-body">
            <input name="metadataValue" class="form-control mb-2" type="text" #metadataInput placeholder="Enter Value..."
            [(ngModel)]="metadata.metadataValue">
                           </div>
                           <div class="modal-footer"
            style="padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.50rem; padding-right: 0.50rem;background-color: #f7f7f7;">
            <button type="button" id="metadataModalCloseButton" class="btn btn-secondary btn-sm" data-dismiss="modal"
               (click)="closeMetadataModal()">Close</button>
                                   <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
               [disabled]="!metadata.metadataValue" (click)="saveMetadata()">Save</button>
                           </div>
                   </div>
           </div>
   </div>


    <!-- Create UPC Modal -->
    <div class="modal fade" id="createUPCModal" tabindex="-1" role="dialog" aria-labelledby="CreateUPCModalTitle" aria-hidden="true">
       <div ngModelGroup="UPCModelGroup" #upcCtrl="ngModelGroup" class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content">
             <div class="modal-header">
                <h5 class="modal-title" id="CreateUPCModalLongTitle">Select Size(s)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true">&times;</span>
                </button>
             </div>
             
             <div class="row mt-4 mb-2 ml-0 border-bottom">
                <div class="mr-4 col-sm-3">
                   <input type="radio" id="selectAllSize" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(2.0);" 
                   name="selectSizeRadio" value="true" class="mr-2" (click)="onSelectOrUnselectAll('true')">
                   <label for="selectAllSize"><span></span>&nbsp;Select All</label>
                </div>
                <div>
                   <input type="radio" id="unselectAllSize" [(ngModel)]="selectUnSelectAllRadio" style="transform: scale(2.0);" 
                   name="selectSizeRadio" value="false" class="mr-2" (click)="onSelectOrUnselectAll('false')" >
                   <label for="unselectAllSize"><span></span>&nbsp;Unselect All</label>
                </div>
             </div> 


             <div class="modal-body">
                <div class="ml-2" *ngFor="let upc of upcDetail.sizeListWithNoUpcCode; index as index">
                   <div class="row">
                     <div class="col-sm-4">
                        <label class="cb-container">
                           <h6 class="ml-4 mt-1">{{upc.size}}</h6>
                           <input type="checkbox" name="upcSize_{{index}}" [checked]="upc.sizeSelected" [(ngModel)]="upc.sizeSelected"
                           #sizeCheckBox (click)="onSizeSelectionForUpcCreateOrGenerate($event,upc);">
                           <span class="checkmark"></span>
                        </label>
                     </div>
                     <div class="col-sm-7" *ngIf="upcCodeCreationType ==='MANUAL'">
                        <div *ngIf="!sizeCheckBox.checked">
                           <input name="upcCode_{{index}}" class="form-control  form-control-sm mb-2" type="text" [(ngModel)]="upc.upcCode" placeholder="Enter Upc Code...">
                        </div>
                        <div *ngIf="sizeCheckBox.checked">
                           <input name="upcCode_{{index}}" class="form-control  form-control-sm mb-2" type="text" #upcCodeInput="ngModel" placeholder="Enter Upc Code..." required [(ngModel)]="upc.upcCode"
                              [ngClass]="{'is-invalid':upcCodeInput.touched && upcCodeInput.errors}">
                           <div *ngIf="upcCodeInput?.invalid && (upcCodeInput.dirty || upcCodeInput.touched)" class="invalid-feedback">
                              <div *ngIf="upcCodeInput.errors.required">Upc Code is required.</div>
                           </div>
                        </div>
                     </div>
                   </div> 
                </div>
             </div>

             <div class="modal-footer">
                <div class="m-1 d-flex">
                   <button class="btn bg-dark text-white btn-block" (click)="createOrGenerateUpcCodes(upcCodeCreationType)" 
                   data-dismiss="modal" [disabled]="!upcSizeSelected || !upcCtrl.valid">
                   <span *ngIf="upcCodeCreationType ==='AUTO'">Generate UPC Codes</span>
                   <span *ngIf="upcCodeCreationType ==='MANUAL'">Create UPC Codes</span>
                  </button>
                </div>
             </div>
          </div>
       </div>
    </div>
    <!-- Create UPC MODAL ENDS-->

<button id="metadataModalToggler" type="button" style="display: none;" data-toggle="modal" data-target="#addNewMetadataModal"></button>
<button id="composeEmailToggler" type="button" style="display: none;" data-toggle="modal" data-target="#composeEmail"></button>

<div class="floatBtn d-flex flex-column">

 <button id="sendEmail" type="button" class="btn btn-circle mb-2 bg-navy-blue" (click)="sendProductEmail()" *ngIf="viewAction && userAccess?.canCreateProduct"
   matTooltip="Send Product Email" matTooltipPosition="left" matTooltipClass="bg-dark">
    <i class="fa fa-envelope text-light fa-2x"></i>
 </button>

 <button type="button" [routerLink]="['/product/create/'+undefined+'/'+undefined+'/'+undefined]"  class="btn btn-circle mb-2"
    *ngIf="viewAction && userAccess?.canCreateProduct" matTooltip="Create New Product" matTooltipPosition="left"
    matTooltipClass="bg-dark">
    <i class="fas fa-plus fa-lg text-light"></i>
 </button><!--(click)="newProductCreation()"-->
 <!--<button type="button"
            [routerLink]="['/preview-submission-sheet/'+product.licensorId+'/'+product.brandId+'/'+product.id]"
            class="btn btn-circle mb-2" *ngIf="viewAction && userAccess?.canCreateProduct"
            matTooltip="Create Submission Sheet" matTooltipPosition="left" matTooltipClass="bg-dark">
            <i class="fas fa-plus fa-lg text-light"></i>
    </button>-->
 <!--<button type="button" class="btn btn-warning btn-circle  mb-2" (click)="productCloned()"
    *ngIf="viewAction && userAccess?.canEditProduct" style="background-color: #ffc107;"
    matTooltip="Create Duplicate Product" matTooltipPosition="left" matTooltipClass="bg-dark"><i
       class="fas fa-clone fa-2x"></i>
 </button>-->
 <button type="button" class="btn btn-warning btn-circle  mb-2" (click)="clone()"
    *ngIf="viewAction && !product.isCollection && userAccess?.canEditProduct" style="background-color: #ffc107;"
    matTooltip="Create Duplicate Product" matTooltipPosition="left" matTooltipClass="bg-dark"><i
       class="fas fa-clone fa-2x"></i>
 </button>

 <ng-container *ngIf="product.origination!=='Estimation'">
   <button type="button" (click)="createOrUpdateProduct()" class=" btn btn-circle bg-info mb-2"
      *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
      [disabled]="!ProductForm.valid || !(product.tagList.length>=7) || product.productImages?.length===0">
      <i class="fas fa-save fa-2x" style="color:white"></i>
   </button>
</ng-container>
 <ng-container *ngIf="product.origination==='Estimation'">
      <button type="button" (click)="createOrUpdateProduct()" class=" btn btn-circle bg-info mb-2"
      *ngIf="editable && !createAction" matTooltip="Save Changes" matTooltipPosition="left" matTooltipClass="bg-dark"
      [disabled]="!ProductForm.valid || !isVisualAvailable()">
      <i class="fas fa-save fa-2x" style="color:white"></i>
   </button>
</ng-container>    

 <button type="button" class="btn btn-circle bg-dark" *ngIf="editable && !createAction" matTooltip="Cancel Edit"
    matTooltipPosition="left" matTooltipClass="bg-dark" (click)="cancelEdit()"><i class="fas fa-times fa-2x"
       style="color:white"></i>
 </button>
 <a class="btn btn-circle bg-dark mb-2" (click)="edit()" *ngIf="viewAction && (userAccess?.canEditProduct || (product.origination==='Estimation' && userAccess?.canEditAdhocProduct))"
     matTooltip="Edit Product" matTooltipPosition="left" matTooltipClass="bg-dark"> <i class="fas fa-pen fa-lg"
       style="color: white;"></i></a>
 <a (click)="goBack()" class="btn btn-circle" *ngIf="viewAction || createAction" style="background-color: tomato;"
    matTooltip="Exit" matTooltipPosition="left" matTooltipClass="bg-dark"><i class="fas fa-arrow-left  fa-2x"></i></a>
 <!--fa-file-pdf-->
</div>
<!--SKu Status Chnage Modal-->
<app-status-change [editable]="editable" [userAccess]="userAccess" editBtnVisible="userAccess?.canEditProduct?'true':'false'" [modalId]="'skuStatusChange'" [product]="product" (onCancel)="skuEditCancel($event)" (onSave)="skuSave($event)" (onEditClicked)="edit()" [approvalStatusList]="approvalStatusList" [existingStatus]="previousApprovalStatus" [saveBtnText]="'Confirm'"></app-status-change>
<!-- Sku Status Chnage Modal Ends-->
<div *ngIf="showEmailEditor">
<app-compose-email  [modalId]="'composeEmail'" [emailHeader]="'Email'" [composeEmail]="composeEmail" (onSend)="emailSent($event)"></app-compose-email>
</div>

<div style="font-size: x-small;" class="text-muted"><em>Product Version: {{product?.version}}</em></div>
</form>

