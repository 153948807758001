<ng-container *ngIf="dashletDetails">
   <div class="card  table-within-dashlet">
      <div class=" h-100 d-flex justify-content-center mt-1 mb-1">{{dashletDetails?.title}}</div>
      <!--style="max-height:150px; min-height:150px; overflow-y: auto;"-->
      <div class="mb-3" style="overflow-y: auto;" >

         <table class=" f-10 table table-sm table-bordered customtablehover table-condensed mb-0">
            <thead class="thead-dark" style="position: sticky;top: 0">
               <tr class="text-center">
                  <ng-container *ngFor="let header of dashletDetails?.tableHeaders;">
                     <th>{{header}}</th>
                  </ng-container>
               </tr>
            </thead>
            <tbody>
               <ng-container *ngFor="let row of  dashletDetails?.tableRows; index as rowIndex;">
                  <tr class="text-center border" style="cursor: pointer;" (click)="handleClick(dashletDetails)" [style.background-color]="rowIndex%2===1?'rgba(0,0,0,.05)':''">
                     <ng-container *ngFor="let rowData of row; index as rowDataIndex">
                        <td> {{rowData}} </td>
                     </ng-container>
                  </tr>
               </ng-container>
            </tbody>
         </table>

      </div>
      <!--<app-dynamic-configuration [dashletDetails]="dashletDetails" (onFilterChange)="loadDashlet()"></app-dynamic-configuration>-->
   </div>
</ng-container>