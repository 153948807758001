<!--
<div style="width: 100%;">
    <div class="d-flex justify-content-between align-items-center ">
       <h1 mat-dialog-title>{{data.title}}</h1>
       <p>&nbsp;</p>
       <span name="discardModal" type="button" class="ml-4 close" *ngIf="allowDiscard" (click)="discardModalWithNoAction()" aria-label="Close" aria-hidden="true">&times;</span>
    </div>

    <hr class="hr-min">
    <div mat-dialog-content *ngIf="dashletDetails">
            
        <ng-container *ngIf="dashletDetails && dashletDetails.objectList &&  dashletDetails.objectList.length>0">
            <table class="table  f-11 " style="width: 100%;">
                <ng-container *ngFor="let row of dashletDetails.objectList; index as rowIndex; let firstObj = first;">
                <thead *ngIf="firstObj" class="thead-dark">
                    <tr>
                        <ng-container *ngFor="let column of row; index as colIndex">
                            <ng-container *ngIf="column.type==='link' || column.type==='display' ||  column.type==='display_image' || column.type==='display_textarea'" >
                                <th class="">{{column.propLabel}}</th>
                            </ng-container>
                            
                        </ng-container>
                        
                    </tr>
                </thead>
            <tbody>
            
                <tr>
                    <ng-container *ngFor="let column of row; index as colIndex">
                        <td *ngIf="column.type==='link'">
                        <a [routerLink]="[column.clickableLink]" target="_blank">
                            {{column.propValue}}
                        </a>
                        </td>
                        <td *ngIf="column.type==='display'">
                            <span *ngIf="column.propType==='String'">{{column.propValue}}</span>
                            <span *ngIf="column.propType==='Date'">{{column.propValue | date:'mediumDate'}}</span>
                        </td>

                        <td *ngIf="column.type==='display_image'">
                            <img [src]="column?.propValue?.thumbnailSignedUrl" 
                            class="img-thumbnail img-fluid zoom-4 "
                            style="display: block;  width:60px;  height:60px; min-width: 60px;"
                            [style.border-color]="'cadetblue'"
                            (error)="handleThumbnailError(column.propValue,$event)"
                           />
                        </td>
                        <td *ngIf="column.type==='display_textarea'">
                            <textarea name="textarea" [value]="column.propValue" rows="{{(column.propValue?.length / 25)>2?(column.propValue?.length / 25):2}}"
                                class="form-control form-control-sm f-11" disabled ></textarea>
                           
                        </td>
                </ng-container>
                </tr>
            
            </tbody>
        </ng-container>
        
            </table>
        </ng-container>
    </div>
    
 </div>
-->

<div style="width: 100%;">
    <div class="d-flex justify-content-between align-items-center ">
       <h1 mat-dialog-title>{{data.title}}</h1>
       <p>&nbsp;</p>
       <span name="discardModal" type="button" class="ml-4 close" *ngIf="allowDiscard" (click)="discardModalWithNoAction()" aria-label="Close" aria-hidden="true">&times;</span>
    </div>

    <hr class="hr-min">
    <div mat-dialog-content *ngIf="data.dashletDetails">
        <app-dashlet-detail [data]="data" [showTitle]="false"></app-dashlet-detail>
    </div>
</div>