<ng-container *ngIf="false">
   <div class="card mt-2" [style.background-color]="dashletDetails?.idList?.length > 2?'':''">
      <div class="h-100 d-flex justify-content-center" style="border:0px;">
         <div class="p-2">
            <div class="d-flex justify-content-center">
               <h1 class="align-text-top align-middle" style="font-size: 3rem; cursor: pointer;" (click)="handleClick(dashletDetails)">{{dashletDetails.idList.length}}</h1>
            </div>
            <div class="d-flex justify-content-center">
               <span class="f-11">{{dashletDetails.title}}</span>
            </div>
         </div>
      </div>
      <!--   
      <div class="d-flex justify-content-start align-items-end">
         <div class="text-nowrap text-muted f-11 mt-1">
            {{dashletDetails.footerDesc}}
         </div>
      </div>
   -->
   </div>
</ng-container>


<ng-container *ngIf="dashletDetails ">
   <div class="card mt-2" [style]="dashletDetails?.focused?'border-bottom: 5px solid tomato;':''" style="font-size: 3rem; cursor: pointer;"  (click)="handleObjectListClick(dashletDetails)">
      <div class="h-100 d-flex justify-content-center" style="border:0px;">
         <div class="p-2">
            <div class="d-flex justify-content-center">
               <h1 class="align-text-top align-middle" style="font-size: 3rem; cursor: pointer;" >{{dashletDetails.resultCount}}</h1>
            </div>
            <div class="d-flex justify-content-center">
               <span class="f-11">{{dashletDetails.title}}</span>
            </div>
         </div>
      </div>
      <!--   
      <div class="d-flex justify-content-start align-items-end">
         <div class="text-nowrap text-muted f-11 mt-1">
            {{dashletDetails.footerDesc}}
         </div>
      </div>
   -->
   <!--<app-dynamic-configuration [dashletDetails]="dashletDetails" (onFilterChange)="loadDashlet()"></app-dynamic-configuration>-->
   </div>
</ng-container>

<ng-container *ngIf="showObjects">
   <div>
      <app-dashlet-detail [data]="dataForInlineDetailsDisplay"></app-dashlet-detail>
   </div>
</ng-container>

<!--
<ng-container *ngIf="showObjects && dashletDetails && dashletDetails.objectList &&  dashletDetails.objectList.length>0">
   <table class="table table-sm f-10 table-bordered table-condensed">

      <tbody>
      <ng-container *ngFor="let row of dashletDetails.objectList; index as rowIndex">
         <tr>
            <ng-container *ngFor="let column of row; index as colIndex">
               <td *ngIf="column.type==='link'">
                  <a [routerLink]="[column.clickableLink]" target="_blank">
                     {{column.propValue}}
                 </a>
               </td>
               <td *ngIf="column.type==='display'">
                  <span>{{column.propValue}}</span>
               </td>
         </ng-container>
         </tr>
      </ng-container>
   </tbody>

   </table>
</ng-container>
-->