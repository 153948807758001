import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { BlankSummaryView } from 'src/app/shared/model/sales/costing/allocation/summary/blank-summary.model';
import {  PrinterSummary, PrinterSummaryView } from 'src/app/shared/model/sales/costing/allocation/summary/printer-summary.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SalesHelperService } from 'src/app/shared/service/sales/sales-helper.service';
import { SalesService } from 'src/app/shared/service/sales/sales.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseOrderComponent } from 'src/app/sage-management/purchase-order/purchase-order.component';
import {  formatDate } from '@angular/common';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';

@Component({
  selector: 'app-printer-summary',
  templateUrl: './printer-summary.component.html',
  styleUrls: ['./printer-summary.component.css']
})
export class PrinterSummaryComponent implements OnInit {

  
  printerSizeDetailsDiffList:any[]=[];
  userAccessSubscription: Subscription;
   userAccess: UserAccess;
  isManualPO:boolean=true;
   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;
   //diffMode:boolean;
  private eventsSubscription: Subscription;
  @Input() salesOrderNumber:string;
  @Input() salesOrder:SalesOrder;
  printerSummariesViews:PrinterSummaryView[]=[];
  @Input() salesOrderId:string;
  @Input() events: Observable<string>;
  constructor(private salesService: SalesService, private salesHelperService: SalesHelperService, private dialog: MatDialog,
    private sharedService:SharedService, private toastrService:ToastrService,private authService:AuthService) { }

  ngOnInit(){
    this.eventsSubscription = this.events.subscribe(event => {
      if(event==='RELOAD'){
        this.loadPrinterSummary();
      }
    })
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
   
 }
  
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  loadPrinterSummary() {
    this.salesService.getPrinterSummary(this.salesOrderId).subscribe(res => {
       if (res.responseStatus.status === 'SUCCESS') {
        let printerSummaries:PrinterSummary[]=res.responsePayload
        this.salesHelperService.createGroupingsForSummary(printerSummaries);
          this.printerSummariesViews=this.salesHelperService.createPrinterSummariesViews(printerSummaries);
          this.isManualPO=this.isManualPrinterPO();
          //console.log(this.printerSummariesViews);
       }
    })
 }

 createPrinterSummaryExcel(printerSummaryView: PrinterSummaryView) {
  this.salesService.createPrinterSummaryExcel(this.salesOrderId, printerSummaryView.submissionCount, printerSummaryView.submissionApprovalCount, printerSummaryView.costingApprovalCount).subscribe(res => {
     if (res && res !== null) {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        const todayStr = dd + '-' + mm + '-' + yyyy;
        const filename = 'PrinterSummary_CS#' + this.salesOrderNumber + '_' + todayStr + ".xlsx"
        this.toastrService.success("Printer Summary exported successfully");
        this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
     } else {
        this.toastrService.error("Export failed.");
     }
  }, err => {
     this.toastrService.error("Export failed. Error - " + err);
  });
}

purchaseOrderSummaryEventHandler(eventVal:string){
  if("RELOAD_SUMMARY"===eventVal){
    this.loadPrinterSummary();
  }
}
/*
getChangeDescriptionForPrinterSizeDetails(diff:any){
  let inlcudeElemetsList=['jobType','fullPackage','qty','cost','size','sku','currency'];
  let reTval='';
  let diffArr:any=diff.diffArr;
  if(diffArr && diffArr!=null){
    diffArr.forEach(diffElement => {
      let root:string=diffElement.path.replace('/','');
      if(inlcudeElemetsList.includes(root)){
        reTval=reTval+(diffElement.path.replace('/','')).toUpperCase()+" : "+diffElement.fromValue+" -> "+diffElement.value+"\n";
      }
    });
    }
  return reTval;
}
*/

getChangeDescriptionForPrinterSizeDetails(diff:any){
 
  let inlcudeElemetsList=['jobType','fullPackage','qty','cost','size','sku','currency'];
  let displayConfigList={
    'jobType':{'label':'Job Type','type':'string'},
    'fullPackage':{'label':'Full Package','type':'string'},
    'qty':{'label':'Qty','type':'string'},
    'cost':{'label':'Cost','type':'string'},
    'size':{'label':'Size','type':'string'},
    'sku':{'label':'Sku','type':'string'},
    'currency':{'label':'Currency','type':'string'}
  }
  let reTval='';
  let diffArr:any=diff.diffArr;
  if(diffArr && diffArr!=null){
    diffArr.forEach(diffElement => {
      let root:string=diffElement.path.replace('/','');
      if(inlcudeElemetsList.includes(root)){
        let fromValue=diffElement.fromValue;
        let toValue=diffElement.value;
        let config=displayConfigList[diffElement.path.replace('/','')];
        if(config.type==='date'){
          if(fromValue && fromValue!==null){
            fromValue= formatDate(fromValue, 'mediumDate', 'en_US');//this.datepipe.transform(fromValue,'dd MMM, yyyy')
          }
          if(toValue && toValue!==null){
            toValue=formatDate(toValue, 'mediumDate', 'en_US');
          }
        }
        reTval=reTval+(config.label)+" : "+fromValue+" -> "+toValue+"\n";
      }
    });
    }
  return reTval;
}


getChangeDescriptionForPrinterFilmCharges(diff:any){
 
  let inlcudeElemetsList=['fcScreen','fcScreenCurrency','productScreenCount','sku','totalFcForStyle'];
  let displayConfigList={
    'fcScreen':{'label':'Screen Cost','type':'string'},
    'fcScreenCurrency':{'label':'Currency','type':'string'},
    'productScreenCount':{'label':'Screen Count','type':'string'},
    'sku':{'label':'Sku','type':'string'},
    'totalFcForStyle':{'label':'totalFcForStyle','type':'string'}
  }
  let reTval='';
  let diffArr:any=diff.diffArr;
  if(diffArr && diffArr!=null){
    diffArr.forEach(diffElement => {
      let root:string=diffElement.path.replace('/','');
      if(inlcudeElemetsList.includes(root)){
        let fromValue=diffElement.fromValue;
        let toValue=diffElement.value;
        let config=displayConfigList[diffElement.path.replace('/','')];
        if(config.type==='date'){
          if(fromValue && fromValue!==null){
            fromValue= formatDate(fromValue, 'mediumDate', 'en_US');//this.datepipe.transform(fromValue,'dd MMM, yyyy')
          }
          if(toValue && toValue!==null){
            toValue=formatDate(toValue, 'mediumDate', 'en_US');
          }
        }
        reTval=reTval+(config.label)+" : "+fromValue+" -> "+toValue+"\n";
      }
    });
    }
  return reTval;
}


getChangeDescriptionForAdditionCharges(diff:any){
 
  let inlcudeElemetsList=['chargesDescription','totalCost','currency'];
  let displayConfigList={
    'chargesDescription':{'label':'Description','type':'string'},
    'totalCost':{'label':'Total Cost','type':'string'},
    'currency':{'label':'Currency','type':'string'}
    
  }
  let reTval='';
  let diffArr:any=diff.diffArr;
  if(diffArr && diffArr!=null){
    diffArr.forEach(diffElement => {
      let root:string=diffElement.path.replace('/','');
      if(inlcudeElemetsList.includes(root)){
        let fromValue=diffElement.fromValue;
        let toValue=diffElement.value;
        let config=displayConfigList[diffElement.path.replace('/','')];
        if(config.type==='date'){
          if(fromValue && fromValue!==null){
            fromValue= formatDate(fromValue, 'mediumDate', 'en_US');//this.datepipe.transform(fromValue,'dd MMM, yyyy')
          }
          if(toValue && toValue!==null){
            toValue=formatDate(toValue, 'mediumDate', 'en_US');
          }
        }
        reTval=reTval+(config.label)+" : "+fromValue+" -> "+toValue+"\n";
      }
    });
    }
  return reTval;
}

getChangeDescriptionForPrinterSummaryDetails(topLevelDiff:any){
  let inlcudeElemetsList=['printerRequestShipDate','printerRequestCancelDate','totalFilmCharges','printerCurrency','totalQty','totalCost','totalOtherCharges'];
  let displayConfigList={
    'printerRequestShipDate':{'label':'Printer Ship Date','type':'date'},
    'printerRequestCancelDate':{'label':'Printer Cancel Date','type':'date'},
    'totalFilmCharges':{'label':'Total Film Charges','type':'string'},
    'printerCurrency':{'label':'Printer Currency','type':'string'},
    'totalQty':{'label':'Total Qty','type':'string'},
    'totalCost':{'label':'Total Cost','type':'string'},
    'totalOtherCharges':{'label':'Total Other Charges','type':'string'},
  }
  let reTval='';
  let diffArr:any=topLevelDiff.diffArr;
  if(diffArr && diffArr!=null){
    diffArr.forEach(diffElement => {
      let root:string=diffElement.path.replace('/','');
      if(inlcudeElemetsList.includes(root)){
        let fromValue=diffElement.fromValue;
        let toValue=diffElement.value;
        let config=displayConfigList[diffElement.path.replace('/','')];
        if(config.type==='date'){
          if(fromValue && fromValue!==null){
            fromValue= formatDate(fromValue, 'mediumDate', 'en_US');//this.datepipe.transform(fromValue,'dd MMM, yyyy')
          }
          if(toValue && toValue!==null){
            toValue=formatDate(toValue, 'mediumDate', 'en_US');
          }
        }
        reTval=reTval+(config.label)+" : "+fromValue+" -> "+toValue+"\n";
      }
    });
    }
  return reTval;
}

onDiffModeChange(ps:PrinterSummary){
  if(ps.diffMode){
    this.salesService.getPrinterSummaryDiff(ps.id).subscribe(res=>{
      if (res.responseStatus.status === 'SUCCESS') {
          ps.psDiff=res.responsePayload.detailsDiffHelper;
          ps.topDiff=res.responsePayload.topLevelDiffHelper;
          ps.pfcDiff=res.responsePayload.screenChargesDiffHelper;
          ps.groupedAddlCharges=res.responsePayload.groupAddlChargesDiffHelper;
       }
      
    });
  }
}

isManualPrinterPO(){
  if(this.salesOrder && this.salesOrder.orderInitialSubmitDate){
    
     let printerPoModuleDeploymentDate=new Date('2024-05-02');
     let initialSubmissionDate=new Date(this.salesOrder.orderInitialSubmitDate);
     return initialSubmissionDate < printerPoModuleDeploymentDate;
  }else{
     return true;
  }
}

}
