import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ConsolidatedInventoryReport } from '../model/consolidated-inventory-report.model';

@Injectable({
   providedIn: 'root'
})
export class WmsService {

   constructor(private http: HttpClient) { }
   
   readonly rooturl = environment.wmsApiEndPoint;

   createConsolidateInventoryTabbedExcelReport(addImage: boolean, consolidatedInventoryReportList: ConsolidatedInventoryReport[]) {
      return this.http.post(this.rooturl + '/consolidated/inventory/report/tabbed/excel/'+addImage, consolidatedInventoryReportList, { responseType: 'blob' });
   }

   
   createConsolidateInventorySinglePageExcelReport(addImage: boolean, consolidatedInventoryReport: ConsolidatedInventoryReport) {
      return this.http.post(this.rooturl + '/consolidated/inventory/report/singlePage/excel/'+addImage, consolidatedInventoryReport, { responseType: 'blob' });
   }

}
