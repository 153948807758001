

<div style="width: 100%;">
    <div class="d-flex justify-content-between align-items-center " *ngIf="showTitle">
       <h1 mat-dialog-title>{{dataFromParent.title}}</h1>
    </div>

    <hr class="hr-min" *ngIf="showTitle">
    <div *ngIf="dataFromParent?.dashletDetails">
            
        <div class="row">
            <div class="col-sm-6">
               <input id='searchFilter' class="form-control mb-2 f-11" type="search"  [(ngModel)]="searchValue" #search placeholder="Search..."
                  (keyup)="onKeyupSearch($event,search.value)">
            </div>
         </div>
         <div class=" my-1 f-11">
            Found {{filteredResultList.length}} {{filteredResultList.length>1?'records':'record'}}
         </div>

        <ng-container *ngIf="filteredResultList &&  filteredResultList.length>0">
            <div class="tableContainerSticky">
            <table class="table  f-11 " style="width: 100%;"><!--table-bordered table-condensed-->
                <ng-container *ngFor="let row of filteredResultList; index as rowIndex; let firstObj = first;">
                <thead *ngIf="firstObj" class="thead-dark">
                    <tr>
                        <ng-container *ngFor="let column of row; index as colIndex">
                            <ng-container *ngIf="column.type==='link' || column.type==='display' ||  column.type==='display_image' || column.type==='display_textarea'" >
                                <th class="" (click)="sortListByPropertyName(colIndex)">{{column.propLabel}}
                                    <span *ngIf="colIndex===currentSortColumnIndex && currentSortMode==='ASC'"><i class="fas fa-sort-amount-up-alt fa-lg mr-1" style="color:white; vertical-align: middle;"></i></span>
                                    <span *ngIf="colIndex===currentSortColumnIndex && currentSortMode==='DESC'"><i class="fas fa-sort-amount-down fa-lg" style="color:white; vertical-align: middle;"></i></span>
                                    <span *ngIf="colIndex!==currentSortColumnIndex"><i class="fas fa-sort fa-lg" style="color:#a9a9a940; vertical-align: middle;"></i></span>
                                </th>
                            </ng-container>
                            
                        </ng-container>
                        
                    </tr>
                </thead>
            <tbody>
            
                <tr>
                    <ng-container *ngFor="let column of row; index as colIndex">
                        <td *ngIf="column.type==='link'">
                        <a [routerLink]="[column.clickableLink]" target="_blank">
                            {{column.propValue}}
                        </a>
                        </td>
                        <td *ngIf="column.type==='display'">
                            <span *ngIf="column.propType==='String'">
                                <ng-container *ngIf="column.propName==='production_status'">
                                    {{'sales.productionStatus.'+column.propValue| transform}}
                                </ng-container>
                                <ng-container *ngIf="column.propName==='estimation_production_status'">
                                    {{'estimation.productionStatus.'+column.propValue| transform}}
                                </ng-container>
                                <ng-container *ngIf="column.propName==='approval_status'">
                                    <span class="text-color-{{approvalStatusColor(column.propValue)}}">{{column.propValue}}</span>
                                </ng-container>
                                <ng-container *ngIf="column.propName!=='production_status' && column.propName!=='estimation_production_status' && column.propName!=='approval_status'">
                                    {{column.propValue}}
                                </ng-container>
                            </span>
                            <span *ngIf="column.propType==='Date'">{{column.propValue | date:'mediumDate'}}</span>
                        </td>

                        <td *ngIf="column.type==='display_image'">
                            <img [src]="column?.propValue?.thumbnailSignedUrl" 
                            class="img-thumbnail img-fluid zoom-4 "
                            style="display: block;  width:60px;  height:60px; min-width: 60px;"
                            [style.border-color]="'cadetblue'"
                            (error)="handleThumbnailError(column.propValue,$event)"
                           />
                        </td>
                        <td *ngIf="column.type==='display_textarea'"><!--rows="{{(column.propValue?.length / 25)>2?(column.propValue?.length / 25):2}}-->
                            <textarea name="textarea" [value]="column.propValue" rows="{{(column.propValue?.length / 25)>2?((column.propValue?.length / 25)>5?5:2):2}}"
                                class="form-control form-control-sm f-11" disabled ></textarea>
                           
                        </td>
                </ng-container>
                </tr>
            
            </tbody>
        </ng-container>
        
            </table>
        </div>
        </ng-container>
    </div>
    <!--<div style="float:right;margin: 5px 0px 10px 0px;" class="shadow">
       <input name="confirmBtn" class="btn btn-danger" style="margin:0px 10px;" type="button" [ngModel]="data.confirmBtnText" [mat-dialog-close]="true">
       <input name="closeBtn" class="btn btn-dark" type="button" value="Close" [mat-dialog-close]="false">
    </div>-->
 </div>

