import { Component, OnInit, Input } from '@angular/core';
import { Dashboard } from 'src/app/shared/model/dashoard/dashboard.model';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { DashboardConfigView } from 'src/app/shared/model/dashoard/dashboard-config-view.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
   width = document.documentElement.clientWidth;
   currentUserSubscription: Subscription;
   currentUser: User;
   dataForInlineDetailsDisplay:DashletDetails;
   focusSubject: Subject<string> = new Subject<string>();

   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   
   routeParamSubscriber:any;
   constructor(private activatedRoute: ActivatedRoute,private router: Router,private dashboardService: DashboardService,private authService: AuthService) { }

   @Input() dashboardName: string = 'GENERAL';
   dashboard: Dashboard;
   dashboardConfigViewList: DashboardConfigView[] = [];

   ngOnInit(): void {
      
      this.routeParamSubscriber = this.activatedRoute.params.subscribe(params => {
         this.dataForInlineDetailsDisplay=undefined;
         this.evaluateAction();
         this.handleSubscriptions();
      });
     
   }
   evaluateAction() {
      const name = this.activatedRoute.snapshot.paramMap.get('name');
      if(name){
         this.dashboardName=name;
      }
     
   }

   ngOnDestroy(): void {
      this.routeParamSubscriber.unsubscribe();
      this.currentUserSubscription.unsubscribe();
      this.userAccessSubscription.unsubscribe();
   }


   handleSubscriptions() {
      
      this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
         this.currentUser = user;
         if(this.currentUser && this.currentUser!=null){
            this.loadDashboard(this.dashboardName);
         }
      });
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
         //
      });
   }

   loadDashboard(dashboardName: string) {
      this.dashboardService.getDashboard(dashboardName).subscribe((res) => {
         this.dashboard = res as Dashboard;
         this.loadDashboardConfig(this.dashboard);
      });
   }

   loadDashboardConfig(dashboard: Dashboard) {
      this.dashboardService.getDashboardConfig(dashboard.id).subscribe((res) => {
         this.dashboardConfigViewList = res as DashboardConfigView[];
         if(!this.dashboardConfigViewList || this.dashboardConfigViewList.length===0){
            if(this.currentUser.orgType==='Internal'){
               //this.router.navigate(['product-search']);
               this.router.navigate(['product-search']);
            }else if(this.currentUser.orgType==='Printer'){
               this.router.navigate(['printer-portal/list']);
            }
         }
      });
   }

   showDetails(dataForInlineDetailsDisplay:any){
      this.dataForInlineDetailsDisplay=dataForInlineDetailsDisplay;

   }
   focusedShowDetails(dataForInlineDetailsDisplayId:string){
      this.focusSubject.next(dataForInlineDetailsDisplayId);
   }
   
}

