<ng-container *ngIf="dashletDetails">
   <div class="border p-1 " [class]="expanded?'d-flex flex-row':''" style=" width: fit-content;"><!---->
    <div>
      <div class="d-flex justify-content-between mt-1 px-2">
         <span (click)="dashletDetails.chart.tabular=!dashletDetails.chart.tabular;">
            <div *ngIf="dashletDetails.chart?.tabular"><i class="far fa-chart-bar"></i></div>
            <div *ngIf="!dashletDetails.chart?.tabular"><i class="fas fa-th"></i></div>
         </span>
         <div class="d-flex justify-content-center" [class]="expanded?'f-14':'f-12'">{{dashletDetails.title}}</div>
         
         <div>
            <button type="button" class="btn btn-sm f-10" (click)="expand()" *ngIf="!expanded"><i class="fas fa-expand-alt"></i></button>
            <button type="button" class="btn btn-sm f-10" (click)="collapse()" *ngIf="expanded"><i class="fas fa-compress-alt"></i></button>
         </div>
         
         
      </div>
      <ng-container *ngIf="dashletDetails.chartData && view">
       <google-chart [title]="dashletDetails.chart?.title" [width]="dashletDetails.chart.options.width" [height]="dashletDetails.chart.options.height" [dynamicResize]="dynamicResize" (ready)="onReady($event)" (select)="onSelect(dashletDetails.chartData,$event)"
           [type]="dashletDetails.chart?.tabular?'Table':dashletDetails.chart?.type" [style]="dashletDetails.chart?.tabular?'overflow-y:auto':''" [data]="dashletDetails.chartData" [columns]="dashletDetails.columns && dashletDetails.columns.length>0?dashletDetails.columns:undefined"
           [options]="dashletDetails.chart?.options" >
       </google-chart>
   </ng-container>
   <ng-container *ngIf="!dashletDetails.chartData">
       <span class="text-muted">No Data</span>
   </ng-container>
   </div>

   <div class=" d-flex flex-column" style="background: #a3b8b912;" *ngIf="expanded && dashletDetails.configuration && dashletDetails.configuration.filters">
      <span class="text-muted f-9 ml-2">Configure</span>
      <app-dynamic-configuration [dashletDetails]="dashletDetails" (onFilterChange)="loadDashlet()"></app-dynamic-configuration>
   </div>
   </div>
</ng-container>