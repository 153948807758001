import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { BrandPnlSpeciality } from 'src/app/shared/model/brand.model';
import { MetadataColour } from 'src/app/shared/model/product-metadata/metadata-colour.model';
import { Product, ProductAttachment } from 'src/app/shared/model/product.model';
import { OrderDetailInfo } from 'src/app/shared/model/sales/fulfillment/order-detail-info.model';
import { ProductionTracker } from 'src/app/shared/model/sales/fulfillment/production-tracker.model';
import { OrderDetail } from 'src/app/shared/model/sales/order-detail.model';
import { SalesOrder } from 'src/app/shared/model/sales/sales-order.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { BrandService } from 'src/app/shared/service/brand.service';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ProductionTrackerService } from 'src/app/shared/service/sales/fulfillment/production-tracker.service';
import { SharedService } from 'src/app/shared/service/shared.service';

@Component({
  selector: 'app-style-details',
  templateUrl: './style-details.component.html',
  styleUrls: ['./style-details.component.css']
})
export class StyleDetailsComponent implements OnInit {

  constructor(private authService: AuthService, private toastrService: ToastrService,private activatedRoute: ActivatedRoute,
    private productService:ProductService,private brandService:BrandService,private sharedService:SharedService,private productMetadataService:ProductMetadataService) { }

  userAccessSubscription: Subscription;
  userAccess: UserAccess;

  currentLoggedInUserSubscription: Subscription;
  currentLoggedInUser: User;
  editable: boolean = false;
  //orderDetailId:string;
  @Input() orderDetailInfo:OrderDetailInfo;
  @Input() salesOrder:SalesOrder;// Only Contains PNL Speciality Attachments
  productionTracker: ProductionTracker;
  brandPnlSpecialities:BrandPnlSpeciality[]=[];
  colorList:MetadataColour[]=[];
  currentPantoneColor:string;

  async ngOnInit() {
    this.handleSubscriptions();
    this.loadProduct(this.orderDetailInfo.orderDetail.product);

  }
 

  handleSubscriptions() {
    this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
       this.userAccess = access;
    });
    this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
       this.currentLoggedInUser = currentLoggedInUser;
    });
    this.brandService.availablePnlSpecialityFromMetadataObservable.subscribe(res=>{
      if(res && res!=null && res.length>0){
         this.brandPnlSpecialities=[];
         //this.brandPnlSpecialities=res;
         res.forEach(pnlSpecialityTemp => {
            if(pnlSpecialityTemp.brandId===this.orderDetailInfo.orderDetail.product.brandId 
               && pnlSpecialityTemp.licensorId===this.orderDetailInfo.orderDetail.product.licensorId){
                  this.brandPnlSpecialities.push(pnlSpecialityTemp);
                  //console.log('PnlSPeciality for '+this.orderDetail.product.sku,this.pnlSpeciality);
               }
         });
      }
   });

   this.productMetadataService.colorMetadataListObservable.subscribe(res => {
    this.colorList.length=0;
    this.colorList.push(...res);
    this.setPantoneForProduct();
    
    
    });



  }

  handleThumbnailError(productAttachment: ProductAttachment, event) {
    if(productAttachment){
    if (isNaN(productAttachment?.thumbnailErrorCount)) {
      productAttachment.thumbnailErrorCount = 0;
    }
    event.target.src = productAttachment.fileSignedUrl;
    var x: number = productAttachment.thumbnailErrorCount;
    productAttachment.thumbnailErrorCount = x + 1;
    if (productAttachment.thumbnailErrorCount < 2) {
      event.target.src = productAttachment.fileSignedUrl;
      productAttachment.thumbnailSignedUrl = productAttachment.fileSignedUrl;
    } else {
      event.target.src = "/assets/default-image.jpg"
    }
    }
  }

  loadProduct(product:Product) {
      if(product!==null && product!=undefined){
          this.productService.loadProductAttachmentsUrls(product,'PNL',product.licensorId,product.brandId);
          this.productService.loadProductImagesUrls(product,product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'MASTER_ARTS',product.licensorId,product.brandId);
          this.productService.loadProductAttachmentsUrls(product,'SEPERATIONS',product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'APPROVAL_DOCS',product.licensorId,product.brandId);
          //this.productService.loadProductAttachmentsUrls(product,'PHOTO_APPROVAL',product.licensorId,product.brandId);
          //this.loadPrinterMatrix();
          this.setPantoneForProduct();
      }
    
  }
  setPantoneForProduct() {
    //console.log("Subscribed Color List",this.colorList);
    if(this.colorList && this.colorList.length>0 && this.orderDetailInfo.orderDetail && this.orderDetailInfo.orderDetail.product){
      let currentPantoneColorArr=this.colorList.filter(x=>x.name===this.orderDetailInfo.orderDetail.product.color);
      if(currentPantoneColorArr && currentPantoneColorArr.length===1){
        this.currentPantoneColor=currentPantoneColorArr[0].pantone;
      }
    }
  }

  getPreferredPnl(orderDetail:OrderDetail):ProductAttachment[]{
    return this.productService.getPreferredPnlForOrder(this.salesOrder,orderDetail,this.brandPnlSpecialities);
   }

   approvalStatusColor(status:string):string{
    if(this.sharedService.producatStatusColor.get(status)!==undefined){
       return this.sharedService.producatStatusColor.get(status);
    }else{
       return '';
    }
  }
  
  isLicensorPreProApprovalMessageRequired(product){
    return this.sharedService.isLicensorPreProApprovalMessageRequired(product);
  //  return product.licensingPreProApprovalRequired && !(this.sharedService.licPreProApprovalNotRequiredStatus.includes(product.approvalStatus));
 }


}
