import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DashletDetails } from '../model/dashoard/dashlet-details.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  readonly rooturl = environment.apiEndPoint;

  getSalesDashBoardDetails(){
    return this.http.get(this.rooturl + "/dashboard/sales/getSalesDashBoardDetails");
  }

  getCostingDashBoardDetails(){
   return this.http.get(this.rooturl + "/dashboard/costing/getCostingDashBoardDetails");
 }

 getCostingPendingReasonDashBoardDetails(){
   return this.http.get(this.rooturl + "/dashboard/costing/getCostingPendingReasonDashBoardDetails");
 }

 getDashBoardTables(){
   return this.http.get(this.rooturl + "/dashboard/costing/getDashBoardDetails/table");
 }

 getDashboard(dashboardName:string){
   return this.http.get(this.rooturl + "/dashboard/"+dashboardName);
 }

 getDashboardConfig(dashboardId:string){
   return this.http.get(this.rooturl + "/dashboard/config/"+dashboardId);
 }

 getDashlet(dashletId:string, dashletType: string,loadDetails?:boolean){
  if(loadDetails){
    return this.http.get(this.rooturl + "/dashlet/"+dashletId+"/"+dashletType+"/loadDetails");
  }else{
    return this.http.get(this.rooturl + "/dashlet/"+dashletId+"/"+dashletType); 
  }
   
 }

 getDashletWithConfiguration(dashletDetails:DashletDetails,loadDetails?:boolean){
  //if(loadDetails){
    //return this.http.get(this.rooturl + "/dashlet/"+dashletId+"/"+dashletType+"/loadDetails");
  //}else{
    return this.http.post(this.rooturl + "/dashlet/load",dashletDetails); 
  //}
   
 }

 setFilterSelectionOnload(dashletDetails:DashletDetails){
  if(dashletDetails && dashletDetails.configuration && dashletDetails.configuration.filters){
  dashletDetails.configuration.filters.forEach(filter => {
    filter.selections=[];
    filter.filterOptions.forEach(options => {
      if(options.selected){
        filter.selections.push(options.value);
      }
    });
    
  });
}
  
}

}
