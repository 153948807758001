import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DashletDetailDialogComponent } from '../dashlet-detail/dashlet-detail-dialog/dashlet-detail-dialog.component';
import { Observable, Subscription } from 'rxjs';

@Component({
   selector: 'app-dashlet-count',
   templateUrl: './dashlet-count.component.html',
   styleUrls: ['./dashlet-count.component.css']
})
export class DashletCountComponent implements OnInit {

   constructor(private dashboardService: DashboardService, private router: Router, private dialog: MatDialog) { }
   private focusEventsSubscription: Subscription;
   @Input() focusEvents: Observable<string>;
   @Input() dashletId: string;
   @Input() dashletType: string;
   dashletDetails: DashletDetails;
   showObjects:boolean;
   dataForInlineDetailsDisplay:any;
   @Output() showDetails = new EventEmitter<any>();
   @Output() focusedShowDetails = new EventEmitter<any>();

   ngOnInit(): void {
      this.loadDashlet();
      this.focusEventsSubscription=this.focusEvents.subscribe(event => {
         if(event && this.dashletDetails && this.dashletDetails.id!==event && this.dashletDetails.focused){
          this.dashletDetails.focused=false;
         }
       });
   }

   ngOnDestroy() {
      this.focusEventsSubscription.unsubscribe();
   }

   loadDashlet() {
      this.dashboardService.getDashlet(this.dashletId, this.dashletType).subscribe((res) => {
         this.dashletDetails = res as DashletDetails;

      });
   }

   handleClick(dashletDetails: DashletDetails) {
      if (dashletDetails && dashletDetails.landingPath) {
         this.router.navigate([dashletDetails.landingPath]);
      }
   }

   handleObjectListClick(dashletDetailsObject: DashletDetails) {
      dashletDetailsObject.focused=true;
      this.focusedShowDetails.emit(dashletDetailsObject.id);
      if (dashletDetailsObject && dashletDetailsObject.objectList) {
        
         let data: any = {
            title: dashletDetailsObject.title,
            dashletDetails: dashletDetailsObject
         }

         if ("INLINE"===dashletDetailsObject.detailDisplayType) {
            this.dataForInlineDetailsDisplay=data;
            this.showDetails.next(this.dataForInlineDetailsDisplay);
         } else if ("REDIRECT"===dashletDetailsObject.detailDisplayType) {
            this.handleClick(dashletDetailsObject);
         }else if ("POPUP"===dashletDetailsObject.detailDisplayType) {
            const detailDialog = this.dialog.open(DashletDetailDialogComponent, {
               width: '90vw',
               maxWidth: '90vw',
               minWidth: '50vw',
               minHeight: '50%',
               data: data
            });
            detailDialog.afterClosed().subscribe(res => {

            });
         }
      }
   }
}
