import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { DashletDetails } from 'src/app/shared/model/dashoard/dashlet-details.model';

@Component({
   selector: 'app-dashlet-chart',
   templateUrl: './dashlet-chart.component.html',
   styleUrls: ['./dashlet-chart.component.css']
})
export class DashletChartComponent implements OnInit {

   constructor(private dashboardService: DashboardService) { }

   @Input() dashletId: string;
   @Input() dashletType: string;
   dashletDetails: DashletDetails=new DashletDetails();
   width = document.documentElement.clientWidth;
   height = document.documentElement.clientHeight;
   dynamicResize:boolean;
   view:boolean=true;
   expanded:boolean;
   ngOnInit(): void {
      this.dashletDetails.id=this.dashletId;
      this.dashletDetails.type=this.dashletType;
      this.loadDashlet();
   }

   ngOnDestroy() {
   }

   loadDashlet() {
      this.dashboardService.getDashletWithConfiguration(this.dashletDetails).subscribe((res) => {
         let dashletDetailsTmp = res as DashletDetails;
         dashletDetailsTmp.chart = JSON.parse(dashletDetailsTmp.chart);
         dashletDetailsTmp.chart.options.width=undefined
         dashletDetailsTmp.chart.options.height=undefined;
         this.dashletDetails =dashletDetailsTmp;
         
         //this.dashletDetails.chart = JSON.parse(this.dashletDetails.chart);
         this.dashboardService.setFilterSelectionOnload(this.dashletDetails);
         if(!this.expanded){
            this.collapse();
         }
         /*if(this.width && this.width<900){
            this.dashletDetails.chart.options.width=this.width - 40;
            this.dashletDetails.chart.options.height=this.height*.28;
            if(this.dashletDetails.chart.options.chartArea){
            this.dashletDetails.chart.options.chartArea.width=(this.width) - 50;
            this.dashletDetails.chart.options.chartArea.height=this.height*.25;
            }
         }else{
            this.dashletDetails.chart.options.width=(this.width *.32) - 30;
            this.dashletDetails.chart.options.height=this.height*.28;
            if(this.dashletDetails.chart.options.chartArea){
            this.dashletDetails.chart.options.chartArea.width=(this.width *.32) - 40;
            this.dashletDetails.chart.options.chartArea.height=this.height*.27;
            }
         }*/
      });
   }

   onSelect(data, event) {
      console.log('Dashboard event ->', event);
      const { row, column } = event.selection[0];
      const montAndyear = data[row][0];
      const sales = data[row][column];
      console.log('selection', montAndyear, sales);
      if(this.dashletDetails.columns){
         console.log(this.dashletDetails.columns[column]);
      }
      /*let selectedItem;
      if (column === 1) {
           selectedItem = "current";
      }
      if (column === 2) {
           selectedItem = "target";
      }*/

   }

   onReady(event) {

   }

   expand(){
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      this.expanded=true;
      this.view=false;
      this.dynamicResize=true;
      if(this.width && this.width<900){
         this.dashletDetails.chart.options.width=(this.width *.75) - 30;
         this.dashletDetails.chart.options.height=(this.height *.75) - 30;
         if(this.dashletDetails.chart.options.chartArea){
         this.dashletDetails.chart.options.chartArea.width=(this.width *.70) - 30;
         this.dashletDetails.chart.options.chartArea.height=(this.height *.70) - 30;
         }
      }else{
         this.dashletDetails.chart.options.width=(this.width *.75) - 30;
         this.dashletDetails.chart.options.height=(this.height *.75) - 30;
         if(this.dashletDetails.chart.options.chartArea){
         this.dashletDetails.chart.options.chartArea.width=(this.width *.70) - 30;
         this.dashletDetails.chart.options.chartArea.height=(this.height *.70) - 30;
         }
      }
      setTimeout(() => {
         this.view=true;
      }, 0);
      //
   }

   collapse(){
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      this.expanded=false;
      this.view=false;
      this.dynamicResize=true;
 
      if(this.width && this.width<900){
         this.dashletDetails.chart.options.width=this.width - 40;
         this.dashletDetails.chart.options.height=this.height*.28;
         if(this.dashletDetails.chart.options.chartArea){
         this.dashletDetails.chart.options.chartArea.width=(this.width) - 50;
         this.dashletDetails.chart.options.chartArea.height=this.height*.25;
         }
      }else{
         this.dashletDetails.chart.options.width=(this.width *.32) - 30;
         this.dashletDetails.chart.options.height=this.height*.28;
         if(this.dashletDetails.chart.options.chartArea){
         this.dashletDetails.chart.options.chartArea.width=(this.width *.32) - 40;
         this.dashletDetails.chart.options.chartArea.height=this.height*.25;
         }
      }


      setTimeout(() => {
         this.view=true;
      }, 0);
      //
   }

}
