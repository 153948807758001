import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from './../../../environments/environment';
import { ApiResponse } from '../model/api-response';
import { Customer } from '../model/customer.model';
import { User } from '../model/user.model';

import { SharedService } from './shared.service';

@Injectable({
   providedIn: 'root'
})

export class CustomerService {

   customerListSubject: BehaviorSubject<Customer[]>;
   public customerListObservable: Observable<Customer[]>;

   constructor(private http: HttpClient,private sharedService:SharedService) {
      this.initializeMetadataSubscription();
    }

   readonly rooturl = environment.apiEndPoint;

   public static CUSTOMER_TIER_MASS='Mass';
   public static CUSTOMER_TIER_ALL='ALL';
   public static CUSTOMER_TIER_MID='Mid';
   
   initializeMetadataSubscription() {
      this.customerListSubject = new BehaviorSubject<Customer[]>([]);
      this.customerListObservable = this.customerListSubject.asObservable();
      this.getCustomersForSubscription();
   }

   getCustomersForSubscription(){
      this.getCustomers().toPromise().then(res=>{
         if(res.responseStatus.status==='SUCCESS'){
            let customers=res.responsePayload as Customer[];
            customers = this.sharedService.sortListByPropertyName(customers,'customerName');
          this.customerListSubject.next(customers);
            //console.log('Got Customer list');
         }
      })
    }

   getCustomers(): Observable<ApiResponse>{
      return this.http.post<any>(this.rooturl + '/customer/list', null);
   }
   
   getCustomerListByTerritory(territory: string): Observable<any> {
      return this.http.post<any>(this.rooturl + '/customer/territory/' + territory, null);
   }

   getCustomerListByTier(tier: string): Observable<any> {
      return this.http.post<any>(this.rooturl + '/customer/list/tier', tier);
   }

   getCountriesWithProvinces(): Observable<any> {
      return this.http.post<any>(this.rooturl + '/customer/countries', null);
   }

   getCustomerById(customerId: string): Observable<any>{
      return this.http.post<any>(this.rooturl + '/customer/'+customerId, null);
   }

   createCustomer(customer: Customer): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/customer/create', customer);
    }
  
    updateCustomer(customer: Customer): Observable<ApiResponse> {
      return this.http.post<ApiResponse>(this.rooturl+'/customer/update',customer);
    }

   deleteCustomer(customer: Customer): Observable<any>{
      return this.http.post<any>(this.rooturl + '/customer/delete', customer);
   }

   getCustomerListForLoggedInUser(user: User): Observable<any> {
      return this.http.post<any>(this.rooturl + '/customer/list/user', user);
   }

   createCustomerAndProductionCoordinatorAssignmentExcel() {
      return this.http.post(this.rooturl + '/customer/production/coordinator/assignment/excel', " ",  { responseType: 'blob' });
   }

}
