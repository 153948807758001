import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError, finalize } from "rxjs/operators";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
   //private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;
    //private _isoDateFormat = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;
    //private _isoDateFormat = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    private _isoDateFormat = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d+))?)?$/;

   loaderRequiredUrls = ['/product/search', '/product/create', '/upload', '/delete', '/createCatalog', '/deleteCatalogFile', '/clone',
      '/brand/update', '/licensor/update','/licensor/sample/list/salesOrder', '/product/update', 'login/password/forgot', '/user/create', '/product/confirmSubmissionSheet',
      '/brand/load/', '/compose/email/send', '/compose/email/template', '/resetCollectionAttachments', '/email/load/logs', '/catalog/loadCatalog',
      '/catalog/saveCatalog', '/report/data', '/file/rename/', '/sales/create', '/sales/update', '/export/excel', '/sales/submit', 
      '/sales/unsubmit','/sales/get','/sales/list/','/search','/costing','/productionTracker/printerPortal/load','/RETRY','/purchaseOrder/create','/purchaseOrder/update',
      '/purchaseOrder/cancel', '/purchaseOrder/retry','/dashboard','/dashlet','/vendors/retrieve','/metadata/product/config','/consolidated/inventory'];
      /*,'/loadFileList/'*/

   loaderNotRequiredUrls = ['/uploadFile/MASTER_ARTS', '/uploadFile/SEPERATIONS', '/uploadFile/APPROVAL_DOCS', '/uploadFile/PHOTO_APPROVAL',
      '/uploadFile/LICENSOR_FILES', '/uploadFile/BRAND_FILES', 'NonBlocking'];

   doNotLogoutOnHttpFailure = ['https://www.bankofcanada.ca'];

   private doNotLogoutOnHttpFailureAppsMap:Map<string, string> = new Map([["SEARCH_APP", "WMS"]]);

      /*,'/loadFileList/'*/

   constructor(private router: Router, private toastrService: ToastrService, private injector: Injector) { }

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const loaderService = this.injector.get(LoaderService);
      let loaderVisible: boolean = this.loaderRequired(req);
      if (loaderVisible) {
         loaderService.show();
      }
      return next.handle(req).pipe(
         tap(evt => {
            if (evt instanceof HttpResponse) {
               const body = evt.body;
               //this.convertIsoDateStringToDateObject(body);
               if (evt.body && evt.body.success) {
                  this.toastrService.success(evt.body.success.message, evt.body.success.title, { positionClass: 'toast-top-right' });
               }
            }
         }),
         catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
               try {
                  console.error('AppHttpInterceptor - ERROR...', JSON.stringify(err));
                  if (err.status === 0 || err.status === 502) {
                     this.toastrService.error("Error: Backend System down. Please try again later.", null, { positionClass: 'toast-top-right' });
                  } else if (err.status === 401 || err.status === 403) {
                     this.toastrService.error("Error: UnAuthorized Error.", null, { positionClass: 'toast-top-right' });
                  } else {
                     this.toastrService.error(err.message, null, { positionClass: 'toast-top-right' });
                  }
                  if(!this.isRequestElgibleForDoNotLogoutOnHttpFailure(req)){
                     this.router.navigate(['/login']);
                  }
               } catch (e) {
                  this.toastrService.error('An error has occurred. Please contact Support Admin.', '', { positionClass: 'toast-bottom-center' });
               }
            }
            if (loaderVisible) {
               loaderService.hide();
            }
            return of(err);
         }),
         finalize(() => {
            if (loaderVisible) {
               loaderService.hide();
            }
         })
      );
   }

   loaderRequired(req: HttpRequest<any>): boolean {
      let loaderRequired: boolean = false;
      this.loaderRequiredUrls.forEach(element => {
         if (req.url.includes(element)) {
            loaderRequired = true;
         }
      });
      if(loaderRequired){
         this.loaderNotRequiredUrls.forEach(element => {
            if (req.url.includes(element)) {
               loaderRequired = false;
            }
         });
      }
      return loaderRequired;
   }

   isRequestElgibleForDoNotLogoutOnHttpFailure(req: HttpRequest<any>): boolean {
      let retVal=false;
      this.doNotLogoutOnHttpFailure.forEach(element => {
         if (req.url.includes(element)) {
            retVal = true;
         }
      });

      let reqHeaders = req.headers;
      this.doNotLogoutOnHttpFailureAppsMap.forEach((value: string, key: string) => {
         //console.log("doNotLogoutAppKey = "+ key, "doNotLogoutAppValue = ", value);
         if (reqHeaders) {
            if (reqHeaders.has(key)) {
               let headerValue = reqHeaders.get(key);
               if (headerValue && headerValue === value) {
                  retVal = true;
               }
            }
         }
     });

      return retVal;
   }


   /*
   isIsoDateString(value: any): boolean {
      if (value === null || value === undefined) {
         return false;
      }
      if (typeof value === 'string') {
         let isISODateFormat: boolean = this._isoDateFormat.test(value);
         return isISODateFormat;
      }
      return false;
   }

   convertIsoDateStringToDateObject(body: any) {
      if (body === null || body === undefined) {
         return body;
      }
      if (typeof body !== 'object') {
         return body;
      }
      for (const key of Object.keys(body)) {
         const value = body[key];
         if (this.isIsoDateString(value)) {
            //body[key] = new Date(value);
         } else if (typeof value === 'object') {
            this.convertIsoDateStringToDateObject(value);
         }
      }
   }
   */
}