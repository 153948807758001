import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../model/api-response';
import { AllocatedBlankSupplierCombinationDetails } from '../../model/sales/costing/allocation/allocated-blank-supplier-combination-details.model';
import { AllocatedPrinterCombinationDetails } from '../../model/sales/costing/allocation/allocated-printer-combination-details.model';
import { CostingAllocation } from '../../model/sales/costing/allocation/costing-allocation.model';
import { StyleCosting } from '../../model/sales/costing/allocation/style-costing.model';
import { CostTypeDetail } from '../../model/sales/costsheet/cost-type-detail.model';
import { BlanksSupplier } from '../../model/vendors/blanks-supplier.model';
import { Printer } from '../../model/vendors/printer.model';
import { VendorsService } from '../vendors/vendors.service';
import { CostingHelperService } from './costing-helper.service';
import { SharedService } from 'src/app/shared/service/shared.service';
import { CostTypeTemplate } from '../../model/sales/costsheet/cost-type-template.model';
import { AllocatedBlankSupplierDetails } from '../../model/sales/costing/allocation/allocated-blank-supplier-details.model';
import { AllocatedPrinterDetails } from '../../model/sales/costing/allocation/allocated-printer-details.model';
import { CostingSheet } from '../../model/sales/costing/costingSheet/costing-sheet.model';
import { SalesOrder } from '../../model/sales/sales-order.model';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class SalesOrderCostingService {
  
  
  private blanksSupplierList: BlanksSupplier[] = [];
  private printerList: Printer[] = [];
  masterCostTypeDetailList:CostTypeTemplate[]=[];
  
  constructor(private http: HttpClient, private vendorsService: VendorsService,private costingHelperService:CostingHelperService
   ,private sharedService:SharedService, private toastrService:ToastrService) {
     this.loadBlanksSupplierList();
     this.loadPrinterList();
     this.loadAllCostTypeMaster();
   }
   getPrinterList(){
      return this.sharedService.deepClone(this.printerList.filter(x=>x.status));
   }

   getBlankSupplierList(){
      return this.sharedService.deepClone(this.blanksSupplierList.filter(x=>x.status));
   }

   async loadAllCostTypeMaster() {
      await this.loadAllCostTypes().toPromise().then(response => {
        if (response.responseStatus.status === 'SUCCESS') {
          let tmpCostTypeDetailList = response.responsePayload;
          this.masterCostTypeDetailList = tmpCostTypeDetailList;
        }
      });
    }

   validateCostingAllocation(costingAllocation: CostingAllocation): any {
      let retVal = { status: 'VALID', reasons: [] };
      if (!costingAllocation.allocatedPrinters || costingAllocation.allocatedPrinters === null || costingAllocation.allocatedPrinters.length === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push('No Printer selected');
      }
      if (costingAllocation.allocatedPrinters && costingAllocation.allocatedPrinters !== null && costingAllocation.allocatedPrinters.length > 0) {
         costingAllocation.styleCosting.forEach(sc => {
            this.validateCostingAllocationForStyle(sc, retVal);
         });

      }
      return retVal;
  }
  
   validateCostingAllocationForStyle(sc: StyleCosting, retVal: { status: string; reasons: any[]; }) {
      if(sc.status!=='CANCELLED'){
      let allocatedSupplierComb: number = 0;
      if (sc.bSupplierCombDetails && sc.bSupplierCombDetails.length > 0) {
         sc.bSupplierCombDetails.forEach(bComb => {
            if (bComb.allocated) {
               allocatedSupplierComb = allocatedSupplierComb + 1;
            }
         });
      }
      let allocatedPrinterComb: number = 0;
      let nonFullPackageComb: number = 0;
      if (sc.printerCombDetails && sc.printerCombDetails.length > 0) {
      sc.printerCombDetails.forEach(pcomb => {
         if (pcomb.allocated) {
            allocatedPrinterComb = allocatedPrinterComb + 1;
            if (!pcomb.fullPackage) {
               nonFullPackageComb = nonFullPackageComb + 1;
            }
         }
      });
   }
      if (allocatedPrinterComb === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push(sc.sku +': Missing Printer Details');
      }
      if (nonFullPackageComb > 0 && allocatedSupplierComb === 0) {
         retVal.status = 'INVALID';
         retVal.reasons.push(sc.sku +': Missing Supplier Details');
         
      }
   }
   }

   loadCostTypes(classification:string,subClassification:string,currency:string,subjectId?:string):Observable<ApiResponse> {
      if(!subjectId){
         subjectId='NA'
      }
      return this.http.get<ApiResponse>(this.rooturl + '/costing/allocation/costType/'+classification+'/'+subClassification+'/'+subjectId+'/'+currency).
      pipe(
         map(
            response =>{
               if (response.responseStatus.status === 'SUCCESS') {
                  let tmpCostTypeDetailList = response.responsePayload;
                  this.populateCostTypeDetailFromTemplate(tmpCostTypeDetailList);
               }
               return response;
            }
          )
      )
   }

   loadAllCostTypes() {
      return this.http.get<any>(this.rooturl + '/costTypeDetail/default/list');
   }

   loadBlanksSupplierList() {
     this.vendorsService.getBlanksSupplierList().subscribe(response => {
        this.blanksSupplierList = response.responsePayload;
        this.blanksSupplierList = this.sharedService.sortListByPropertyName(this.blanksSupplierList,'name');
     }, err => {
        console.error('Error while Loading Blanks Supplier List : ' + JSON.stringify(err) + ' with status - ');
     });
  }

  loadPrinterList() {
     this.vendorsService.getPrinterList().subscribe(response => {
        this.printerList = response.responsePayload;
        this.printerList = this.sharedService.sortListByPropertyName(this.printerList,'name');
     }, err => {
        console.error('Error while Loading Printer List : ' + JSON.stringify(err) + ' with status - ');
     });
  }
 
  readonly rooturl = environment.apiEndPoint;
 /****Allocation Sheet Changes */

 getAllocationSheet(salesOrderId: string): Observable<ApiResponse> {
  return this.http.get<any>(this.rooturl + '/costing/allocation/'+salesOrderId)
  .pipe(map(
      response =>{
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.populateAdditionalAttributesInCostTypeDetails(costingAllocation);
         }
         return response;
      }
  ));
}

saveAllocationSheet(salesOrderId: string,costingAllocation: CostingAllocation): Observable<ApiResponse> {
  return this.http.post<any>(this.rooturl + '/costing/allocation/save/'+salesOrderId, costingAllocation)
  .pipe(map(
      response =>{
         if (response.responseStatus.status === 'SUCCESS') {
            let costingAllocation: CostingAllocation = response.responsePayload as CostingAllocation;
            this.populateAdditionalAttributesInCostTypeDetails(costingAllocation); 
          }
          return response;
      }
  ));
}
/** Allocation Sheet Changes */
/** Allocation Sheet Changes Calulcation */

calculateAllocationSheet(costingAllocation:CostingAllocation){
  if(costingAllocation){
     if(costingAllocation.styleCosting && costingAllocation.styleCosting.length>0){
        costingAllocation.styleCosting.forEach(sc => {
            this.calculatePrinterAndBlankCount(sc);// For UI table width pecentage determintaion
           this.calculateTotalBlanks(sc);
           this.calulateBlanksSupplierComboDetails(sc.bSupplierCombDetails,costingAllocation);
           this.calulatePrinterComboDetails(sc.printerCombDetails,costingAllocation);
           
        });
     }
     
  }
}
   calculatePrinterAndBlankCount(sc: StyleCosting) {
    let totalSupplierCount=1;//minimum 1 for NOtes section
    let totalPrinterCount=1;
    if(sc.bSupplierCombDetails && sc.bSupplierCombDetails.length>0){
      sc.bSupplierCombDetails.forEach(bComb => {
         if(bComb.bSupplierDetails && bComb.bSupplierDetails.length>0){
            totalSupplierCount=totalSupplierCount+bComb.bSupplierDetails.length;
         }
      });
    }
    if(sc.printerCombDetails && sc.printerCombDetails.length>0){
      sc.printerCombDetails.forEach(pComb => {
         if(pComb.printerDetails && pComb.printerDetails.length>0){
            totalPrinterCount=totalPrinterCount+pComb.printerDetails.length;
         }
      });
    }
    sc.printerCount=totalPrinterCount;
    sc.supplierCount=totalSupplierCount;
    
   }
calulateBlanksSupplierComboDetails(bSupplierCombDetails: AllocatedBlankSupplierCombinationDetails[],costingAllocation:CostingAllocation) {

 if(bSupplierCombDetails && bSupplierCombDetails.length>0){
     bSupplierCombDetails.forEach(bSuppComb => {
        let sizeDetailsMap = new Map<string, AverageBlankSizeDetails>();
     bSuppComb.bSupplierDetails.forEach(bsDetails => {
        let bsQty=0;
        bsDetails.blankSizeDetails.forEach(bsd => {
           if(!sizeDetailsMap.get(bsd.size)){
              sizeDetailsMap.set(bsd.size,{size:bsd.size,totalBlankQtyCost:0,totalBlankQty:0});
           }
           let mapEntry=sizeDetailsMap.get(bsd.size);
           let bsdQty=0;
           let bsdCost=0;
           if(!isNaN(bsd.qty)){
            bsdQty=bsd.qty;
           }
           if(!isNaN(bsd.cost)){
            bsdCost=bsd.cost;
           }
           bsQty=bsQty+bsd.qty;
           mapEntry.totalBlankQty=mapEntry.totalBlankQty+bsdQty;
           mapEntry.totalBlankQtyCost= mapEntry.totalBlankQtyCost + (bsdQty * this.costingHelperService.sanitizeCurrency(costingAllocation.orderCurrency,bsDetails.currency,bsdCost,costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd));
        });
        bsDetails.allocatedQty=bsQty;
     }); 
     let totalQtyAllocated:number=0;
     let totalAverageSizeCost:number=0;
     let sizeCount:number=0;
     bSuppComb.averageBlankSizeDetails.forEach(avgBsd => {
        
        let mapEntry=sizeDetailsMap.get(avgBsd.size);
        avgBsd.cost=0;
        if(mapEntry.totalBlankQty && mapEntry.totalBlankQty!==0){
         let avgCost:number=mapEntry.totalBlankQtyCost/mapEntry.totalBlankQty;
         avgBsd.cost=avgCost;
        }
        avgBsd.qty=mapEntry.totalBlankQty;
        
        totalQtyAllocated=totalQtyAllocated+ avgBsd.qty;
        totalAverageSizeCost=totalAverageSizeCost+ (avgBsd.cost * avgBsd.qty);

        sizeCount++;
     });
     bSuppComb.averageBlankCost=0;
     if(totalQtyAllocated!==0){
        bSuppComb.averageBlankCost=totalAverageSizeCost/totalQtyAllocated;
        bSuppComb.averageBlankCost=this.costingHelperService.roundUp(bSuppComb.averageBlankCost,3);
     }
        bSuppComb.totalQtyAllocated=totalQtyAllocated;
     });
 }
}

   calulatePrinterComboDetails(printerCombDetails: AllocatedPrinterCombinationDetails[],costingAllocation:CostingAllocation) {

      if (printerCombDetails && printerCombDetails.length > 0) {
         printerCombDetails.forEach(printerComb => {
            let sizeDetailsMap = new Map<string, AveragePrinterSizeDetails>();
            printerComb.printerDetails.forEach(printrDetails => {
               let pQty=0;
               printrDetails.printerSizeDetails.forEach(psd => {
                  if (!sizeDetailsMap.get(psd.size)) {
                     sizeDetailsMap.set(psd.size, { size: psd.size, totalPrintQtyCost: 0, totalPrintQty: 0 });
                  }
                  let psdQty=0;
                  let psdCost=0;
                  if(!isNaN(psd.qty)){
                     psdQty=psd.qty;
                  }
                  if(!isNaN(psd.cost)){
                     psdCost=psd.cost;
                  }
                  let mapEntry = sizeDetailsMap.get(psd.size);
                  pQty=pQty+psdQty;
                  mapEntry.totalPrintQty = psdQty;//mapEntry.totalPrintQty + psd.qty;
                  mapEntry.totalPrintQtyCost = mapEntry.totalPrintQtyCost + (psdQty * this.costingHelperService.sanitizeCurrency(costingAllocation.orderCurrency,printrDetails.currency,psdCost, costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd));

               });
               printrDetails.allocatedQty=pQty;
            });
            //console.log('Size Detail Map For Printer', sizeDetailsMap);
            let totalQtyAllocated: number = 0;
            let totalAveragePrintingCost: number = 0;
            let sizeCount: number = 0;
            printerComb.averagePrinterSizeDetails.forEach(avgPsd => {

               let mapEntry = sizeDetailsMap.get(avgPsd.size);
               avgPsd.cost = 0;
               if(mapEntry.totalPrintQty && mapEntry.totalPrintQty!==0){
                  let avgCost: number = mapEntry.totalPrintQtyCost / mapEntry.totalPrintQty;
                  avgPsd.cost = avgCost;
               }
               avgPsd.qty = mapEntry.totalPrintQty;

               totalQtyAllocated = totalQtyAllocated + avgPsd.qty;
               totalAveragePrintingCost = totalAveragePrintingCost + (avgPsd.cost * avgPsd.qty);

               sizeCount++;
            });
            //console.log('COmbo Average Printing Details -> ', totalQtyAllocated, totalAveragePrintingCost, sizeCount);
            printerComb.averagePrintCost = 0;
            if(totalQtyAllocated!==0){
               printerComb.averagePrintCost = totalAveragePrintingCost / totalQtyAllocated;
               printerComb.averagePrintCost=this.costingHelperService.roundUp(printerComb.averagePrintCost,3);
            }
            printerComb.totalQtyAllocated = totalQtyAllocated;
         });
      }
  }

calculateTotalBlanks(sc: StyleCosting) {
   let totalRequiredBlanksQuantity=0;
   if(sc.extraQtyType==='Percentage' && sc.extraQtyPercentage>0/* && sc.status!=='CANCELLED'// CAncelled must have 0 extra qty required*/){
      this.calculateExtraQty(sc,sc.extraQtyPercentage);
  }
  sc.blankSizeQty.forEach(bsq => {
     if(!isNaN(bsq.extraQty) && !isNaN(bsq.orderQty)){
        bsq.totalBlankQty=bsq.orderQty+bsq.extraQty;
        totalRequiredBlanksQuantity=totalRequiredBlanksQuantity+bsq.totalBlankQty;
     }
  });
  sc.totalRequiredBlanksQuantity=totalRequiredBlanksQuantity;
  
}

calculateExtraQty(sc:StyleCosting,extraQtyPercentage:number){
   sc.blankSizeQty.forEach(bsq => {
     let extraQtyForPercentage=((extraQtyPercentage*bsq.orderQty)/100);
     //console.log('extraQtyForPercentage',extraQtyForPercentage);
     if(extraQtyForPercentage>0){
       if(extraQtyForPercentage<1){
         extraQtyForPercentage=1;
       }else{
         extraQtyForPercentage=this.costingHelperService.roundUp(extraQtyForPercentage,0);
       }
     }
     bsq.extraQty=extraQtyForPercentage;
   });
 }

calculateTotalOrderProfit(ca: CostingAllocation) {
   if(ca){
   this.isAllStylesApproved(ca);
    let totalCost:number=0;
    let totalRevenue:number=0;
    let totalGrossProfit:number=0;
    let totalGrossProfitPercentage:number=0;
    let totalQuantity: number =0;
   if(ca && ca.styleCosting && ca.styleCosting.length>0){
      ca.styleCosting.forEach(sc => {
         if(sc.status!=='CANCELLED' && sc.costingSheets && sc.costingSheets.length>0){
            sc.costingSheets.forEach(cs => {
               if(cs.approvalStatus==='APPROVED'){
                  totalGrossProfit=totalGrossProfit+cs.grossProfit;
                  totalCost=totalCost+cs.totalCost;
                  totalRevenue=totalRevenue+cs.totalRevenue;

               }
            });
            totalQuantity = totalQuantity + sc.totalQty;
         }
      });
   }
   totalGrossProfitPercentage=(totalGrossProfit/totalRevenue)*100;
   ca.totalCost=totalCost;
   ca.totalRevenue=totalRevenue;
   ca.totalGrossProfit=totalGrossProfit;
   ca.totalGrossProfitPercentage=totalGrossProfitPercentage;
   
   ca.totalQuantity = totalQuantity;
}
 }

   isAllStylesApproved(ca: CostingAllocation):boolean {
      let allApproved: boolean = false;
      let scApproved: number = 0;
      let scApprovalPending: number = 0;
      if (ca && ca.styleCosting && ca.styleCosting.length > 0) {
         ca.styleCosting.forEach(sc => {
            if (sc.status !== 'CANCELLED' && sc.costingSheets && sc.costingSheets.length > 0) {
               let csApproved: number = 0;
               sc.costingSheets.forEach(cs => {
                  if (cs.approvalStatus === 'APPROVED') {
                     csApproved = csApproved + 1;
                  }
               });
               if (csApproved === 1) {
                  scApproved = scApproved + 1;
               } else {
                  scApprovalPending = scApprovalPending + 1;
               }
            } else if (sc.status !== 'CANCELLED' && (!sc.costingSheets || sc.costingSheets.length === 0)) {
               scApprovalPending = scApprovalPending + 1;
            }
         });
      }
      ca.stylesApproved = scApproved;
      ca.stylesPending = scApprovalPending;
      if (scApprovalPending === 0) {
         allApproved = true;
         ca.allStylesApproved = true;
      } else {
         ca.allStylesApproved = false;
      }

      return ca.allStylesApproved;
   }

/** Allocation Sheet Changes Calulcation Ends */

populateAdditionalAttributesInCostTypeDetails(ca:CostingAllocation){
   if(ca){
      if(ca.customerCostTypeDetails && ca.customerCostTypeDetails.length>0){
         this.populateCostTypeDetailFromTemplate(ca.customerCostTypeDetails);
      }
      if(ca.orgCostTypeDetails && ca.orgCostTypeDetails.length>0){
         this.populateCostTypeDetailFromTemplate(ca.orgCostTypeDetails);
      }
      if(ca.allocatedPrinters && ca.allocatedPrinters.length>0){
         ca.allocatedPrinters.forEach(ap => {
            if(ap.costTypeDetails && ap.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(ap.costTypeDetails);
            }
         });
      }
      if(ca.allocatedSuppliers && ca.allocatedSuppliers.length>0){
         ca.allocatedSuppliers.forEach(as => {
            if(as.costTypeDetails && as.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(as.costTypeDetails);
            }
         });
      }
      if(ca.styleCosting && ca.styleCosting.length>0){
         ca.styleCosting.forEach(sc => {
            if(sc.costTypeDetails && sc.costTypeDetails.length>0){
               this.populateCostTypeDetailFromTemplate(sc.costTypeDetails);
            }
            if(sc.printerCombDetails && sc.printerCombDetails.length>0){
               sc.printerCombDetails.forEach(pComb => {
                  if(pComb.printerDetails && pComb.printerDetails.length>0){
                     pComb.printerDetails.forEach(pd => {
                        if(pd.costTypeDetails && pd.costTypeDetails.length>0){
                           this.populateCostTypeDetailFromTemplate(pd.costTypeDetails);
                        }
                     });
                  }
               });
            }
            if(sc.bSupplierCombDetails && sc.bSupplierCombDetails.length>0){
               sc.bSupplierCombDetails.forEach(bComb => {
                  if(bComb.bSupplierDetails && bComb.bSupplierDetails.length>0){
                     bComb.bSupplierDetails.forEach(sd => {
                        if(sd.costTypeDetails && sd.costTypeDetails.length>0){
                           this.populateCostTypeDetailFromTemplate(sd.costTypeDetails);
                        }
                     });
                  }
               });
            }
            if(sc.costingSheets && sc.costingSheets.length>0){
               sc.costingSheets.forEach(cs => {
                  if(cs.costTypeDetailList && cs.costTypeDetailList.length>0){
                     this.populateCostTypeDetailFromTemplate(cs.costTypeDetailList);      
                    //console.log('COsting Sheet after population',cs.costTypeDetailList);
                  }
               });
            }
         });
      }
   }

}

 async populateCostTypeDetailFromTemplate(costTypeDetails:CostTypeDetail[], currency?:string){
   if(!this.masterCostTypeDetailList || this.masterCostTypeDetailList.length===0){
      await this.loadAllCostTypeMaster();
   }
   if(this.masterCostTypeDetailList && this.masterCostTypeDetailList.length>0 && costTypeDetails && costTypeDetails.length>0){
      costTypeDetails.forEach(ctd => {
         let tempArr=this.masterCostTypeDetailList.filter(x=>x.id===ctd.id);
         if(tempArr && tempArr.length>0){
            let template=tempArr[0];
            ctd.calculationType=template.calculationType;
            ctd.category=template.category;
            ctd.classification=template.classification;
            ctd.costType=template.costType;
            if(currency){
               ctd.currency=currency;
            }
            ctd.description=template.description;
            ctd.sortOrder=template.sortOrder;
            ctd.source=template.source;
            ctd.subCategory=template.subCategory;
            ctd.subClassification=template.subClassification;
         }
      });
   }
 }


 
/******************************************************************************************************************************************** */
                                                         //START: GENERATE - COSTING SHEETS 
/******************************************************************************************************************************************** */

generateCostingSheetsCombinationForEachStyle(salesOrder:SalesOrder,costingAllocation:CostingAllocation,
   freshCostTypeDetailListForPrinterTopLevel:CostTypeDetail[],
   freshCostTypeDetailListForPrinterStyleLevel:CostTypeDetail[],
   freshCostTypeDetailListForSupplierTopLevel:CostTypeDetail[],
   freshCostTypeDetailListForSupplierStyleLevel:CostTypeDetail[],
   freshCostTypeDetailListForOrganization:CostTypeDetail[],
   freshCostTypeDetailListForCustomer:CostTypeDetail[],
   freshCostTypeDetailListForHeaders:CostTypeDetail[]
   
) {
   if(costingAllocation){

    /*let validityRes=this.costingService.validateCostingAllocation(this.costingAllocation);
    if(validityRes.status==='INVALID'){
      let errMessage='';
      validityRes.reasons.forEach(reason => {
       errMessage = errMessage + '<br>' + reason;
    });
      this.toastrService.error('<span style="font-size:10px !important;">Error in Allocation: '+'<br>'+errMessage +'</span>','',{ enableHtml: true});
      return;
    }*/
     //console.log('START - Generate Costing Sheet. Original Costing Allocation = ', this.costingAllocation);

     costingAllocation.styleCosting.forEach(styleCosting => {
        // Always Clean Costing Sheets
        styleCosting.costingSheets = [];
        styleCosting.costingSheetGenerationErrors=[];
        let retVal = { status: 'VALID', reasons: [] };
        this.validateCostingAllocationForStyle(styleCosting,retVal);
        if(retVal.status==='INVALID'){
         styleCosting.costingSheetGenerationErrors=retVal.reasons;
          return;
        }

        if(styleCosting.status !=='CANCELLED'){
           
           let customerCostTypeDetailList: CostTypeDetail[] = this.populateCostTypeDetailListForCustomer(salesOrder,costingAllocation,styleCosting,freshCostTypeDetailListForCustomer);

           let styleCostTypeDetailList: CostTypeDetail[] = this.populateCostTypeDetailListForStyle(styleCosting);

           let organizationcostTypeDetailList: CostTypeDetail[] = this.populateCostTypeDetailListForOrganization(salesOrder,costingAllocation,styleCosting,freshCostTypeDetailListForOrganization);
           if(styleCosting.printerCombDetails && styleCosting.printerCombDetails.length>0){
           styleCosting.printerCombDetails.forEach(printerCombDetails => {

              if (printerCombDetails.allocated === true) {

                 let freshCostTypeDetailListForPrinterTopLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForPrinterTopLevel));
                 let freshCostTypeDetailListForPrinterStyleLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForPrinterStyleLevel));

                 let costTypeDetailListForPrinterTopLevel: CostTypeDetail[] = this.populateCostTypeDetailListForPrinterTopLevel(salesOrder,costingAllocation,styleCosting, printerCombDetails, freshCostTypeDetailListForPrinterTopLevelCloned);
                 let costTypeDetailListForPrinterStyleLevel: CostTypeDetail[] = this.populateCostTypeDetailListForPrinterStyleLevel(salesOrder,costingAllocation, styleCosting, printerCombDetails, freshCostTypeDetailListForPrinterStyleLevelCloned);

                 


                 if (!printerCombDetails.fullPackage) {
                    /*if(styleCosting.bSupplierCombDetails === null || styleCosting.bSupplierCombDetails === undefined || (styleCosting.bSupplierCombDetails && styleCosting.bSupplierCombDetails.length === 0)){
                       this.toastrService.error('Cannot Save Costing Allocation Sheet. Atleast one Blanks Supplier is required for Style ['+styleCosting.sku+ '].');
                       throw new Error('Cannot Save Costing Allocation Sheet. Atleast one Blanks Supplier is required for Style ['+styleCosting.sku+ '].');
                    }*/
                    styleCosting.bSupplierCombDetails?.forEach(bSupplierCombDetails => {
                     let freshCostTypeDetailListForSupplierTopLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForSupplierTopLevel));
                     let freshCostTypeDetailListForSupplierStyleLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForSupplierStyleLevel));
                       if (bSupplierCombDetails.allocated) {
                           let costTypeDetailListForPrinterTopLevelForSupplier=JSON.parse(JSON.stringify(costTypeDetailListForPrinterTopLevel));
                           let costTypeDetailListForPrinterStyleLevelForSupplier=JSON.parse(JSON.stringify(costTypeDetailListForPrinterStyleLevel));
                          let costTypeDetailListForSupplierTopLevel = this.populateCostTypeDetailListForSupplierTopLevel(salesOrder,costingAllocation,styleCosting,printerCombDetails, bSupplierCombDetails, freshCostTypeDetailListForSupplierTopLevelCloned);
                          let costTypeDetailListForSupplierStyleLevel = this.populateCostTypeDetailListForSupplierStyleLevel(salesOrder,costingAllocation, styleCosting, bSupplierCombDetails, freshCostTypeDetailListForSupplierStyleLevelCloned);

                          let createdCostingSheet: CostingSheet = this.mergeCostTypeDetailAndCreateCreateCostingSheet(costingAllocation,organizationcostTypeDetailList, customerCostTypeDetailList, styleCostTypeDetailList,
                           costTypeDetailListForPrinterTopLevelForSupplier, costTypeDetailListForPrinterStyleLevelForSupplier, costTypeDetailListForSupplierTopLevel, costTypeDetailListForSupplierStyleLevel, styleCosting, printerCombDetails, bSupplierCombDetails,
                           freshCostTypeDetailListForHeaders);
                          styleCosting.costingSheets.push(createdCostingSheet);
                       }
                    });
                 } else {
                  let freshCostTypeDetailListForSupplierTopLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForSupplierTopLevel));
                  let freshCostTypeDetailListForSupplierStyleLevelCloned = JSON.parse(JSON.stringify(freshCostTypeDetailListForSupplierStyleLevel));
                    let costTypeDetailListForSupplierTopLevel = freshCostTypeDetailListForSupplierTopLevelCloned;
                    let costTypeDetailListForSupplierStyleLevel = freshCostTypeDetailListForSupplierStyleLevelCloned;

                    let createdCostingSheet: CostingSheet = this.mergeCostTypeDetailAndCreateCreateCostingSheet(costingAllocation,organizationcostTypeDetailList, customerCostTypeDetailList, styleCostTypeDetailList,
                       costTypeDetailListForPrinterTopLevel, costTypeDetailListForPrinterStyleLevel, costTypeDetailListForSupplierTopLevel, costTypeDetailListForSupplierStyleLevel, styleCosting, printerCombDetails, null,
                       freshCostTypeDetailListForHeaders);
                    styleCosting.costingSheets.push(createdCostingSheet);
                 }
              }

           });
         }
         if(styleCosting.costingSheets && styleCosting.costingSheets.length===1){
           styleCosting.costingSheets[0].approvalStatus='APPROVED';
         }
        }
     });

     //console.log('END - Generate Costing Sheet. Original Costing Allocation', this.costingAllocation);
     
     this.calculateTotalOrderProfit(costingAllocation);
    
   }
  }

  /**************************************************  START: ORGANIZATION COST TYPE DETAIL ********************************** */
  populateCostTypeDetailListForOrganization(salesOrder:SalesOrder,costingAllocation:CostingAllocation,styleCosting: StyleCosting,freshCostTypeDetailListForOrganization:CostTypeDetail[]): CostTypeDetail[] {
     //console.info('Entering populateCostTypeDetailListForOrganization .......',this.freshCostTypeDetailListForOrganization);
     let freshCostTypeDetailListForOrganizationCloned: CostTypeDetail[] = JSON.parse(JSON.stringify(freshCostTypeDetailListForOrganization));
     freshCostTypeDetailListForOrganizationCloned.forEach(freshOrganizationCostTypeDetail => {
        if (costingAllocation.orgCostTypeDetails) {
           costingAllocation.orgCostTypeDetails.forEach(userInputOrgCostTypeDetail => {
              if (this.costingHelperService.isCostTypeDetailSame(freshOrganizationCostTypeDetail, userInputOrgCostTypeDetail)) {
                 this.setCostTypeDetailFromUserInputCostTypeDetail(salesOrder,costingAllocation,freshOrganizationCostTypeDetail, userInputOrgCostTypeDetail, styleCosting);
              }
           });
        }
     });
     console.info('Exiting populateCostTypeDetailListForOrganization .......', freshCostTypeDetailListForOrganizationCloned);
     return freshCostTypeDetailListForOrganizationCloned;
  }
  /**************************************************  END: ORGANIZATION COST TYPE DETAIL ********************************** */


  /**************************************************  START: CUSTOMER COST TYPE DETAIL **************************************** */
  populateCostTypeDetailListForCustomer(salesOrder:SalesOrder,costingAllocation:CostingAllocation,styleCosting: StyleCosting,freshCostTypeDetailListForCustomer:CostTypeDetail[]): CostTypeDetail[] {
     console.info('Entering populateCostTypeDetailListForCustomer .......');
     let freshCostTypeDetailListForCustomerCloned: CostTypeDetail[] = JSON.parse(JSON.stringify(freshCostTypeDetailListForCustomer));
     freshCostTypeDetailListForCustomerCloned.forEach(freshCustomerCostTypeDetail => {
        if (costingAllocation.customerCostTypeDetails) {
           costingAllocation.customerCostTypeDetails.forEach(userInputCustomerCostTypeDetail => {
              if (this.costingHelperService.isCostTypeDetailSame(freshCustomerCostTypeDetail, userInputCustomerCostTypeDetail)) {
                 this.setCostTypeDetailFromUserInputCostTypeDetail(salesOrder,costingAllocation,freshCustomerCostTypeDetail, userInputCustomerCostTypeDetail, styleCosting);
              }
           });
        }
     });
     console.info('Exiting populateCostTypeDetailListForCustomer .......', freshCostTypeDetailListForCustomerCloned);
     return freshCostTypeDetailListForCustomerCloned;
  }
  /**************************************************  END: CUSTOMER COST TYPE DETAIL **************************************** */


  /**************************************************  START: STYLE COST TYPE DETAIL ***************************************** */
  populateCostTypeDetailListForStyle(styleCosting: StyleCosting): CostTypeDetail[] {
     styleCosting.costTypeDetails.forEach(syleCostTypeDetail => {
        if (syleCostTypeDetail.category === 'SELLING_COST' && syleCostTypeDetail.subCategory === 'ROYALTY') {
           syleCostTypeDetail.remarks = syleCostTypeDetail.uiCost;
           syleCostTypeDetail.unitCost = (styleCosting.sellingPricePerUnit * syleCostTypeDetail.remarks) / 100;
        }
     });
     return styleCosting.costTypeDetails;
  }
  /**************************************************  END: STYLE COST TYPE DETAIL ******************************************* */


  /**************************************************  START: PRINTER TOP LEVEL COST TYPE DETAIL **************************************** */
  populateCostTypeDetailListForPrinterTopLevel(salesOrder:SalesOrder,costingAllocation:CostingAllocation,styleCosting: StyleCosting, printerCombDetails: AllocatedPrinterCombinationDetails, freshCostTypeDetailListPrinterTopLevel: CostTypeDetail[]): CostTypeDetail[] {
     let costTypeDetailListArrayForPrintersTopLevel: CostTypeDetail[][] = [];
     if (printerCombDetails && printerCombDetails.printerDetails) {
        printerCombDetails.printerDetails.forEach(printerDetails => {
           let tmpCostTypeDetailList: CostTypeDetail[] = this.getCostTypeDetailForPrinterTopLevel(costingAllocation,printerDetails.printerId);
           costTypeDetailListArrayForPrintersTopLevel.push(tmpCostTypeDetailList);
        });
     }
     for (let index = 0; index < freshCostTypeDetailListPrinterTopLevel.length; index++) {
        let emptyCostTypeDetail: CostTypeDetail = freshCostTypeDetailListPrinterTopLevel[index];
        this.setCostTypeDetailForAllocatedPrinter(salesOrder,costingAllocation,styleCosting, emptyCostTypeDetail, costTypeDetailListArrayForPrintersTopLevel);
     }
     return freshCostTypeDetailListPrinterTopLevel;
  }
  /**************************************************  END: PRINTER TOP LEVEL COST TYPE DETAIL **************************************** */


  /**************************************************  START: PRINTER STYLE LEVEL COST TYPE DETAIL ************************************ */
  populateCostTypeDetailListForPrinterStyleLevel(salesOrder:SalesOrder,costingAllocation: CostingAllocation, styleCosting: StyleCosting, printerCombDetails: AllocatedPrinterCombinationDetails, 
     freshCostTypeDetailListPrinterStyleLevel: CostTypeDetail[]): CostTypeDetail[] {
     
     let tmpCostTypeDetailArrayForPrinters: CostTypeDetail[][] = [];
     if (printerCombDetails && printerCombDetails.printerDetails) {
        printerCombDetails.printerDetails.forEach(printerDetails => {
           let tmpCostTypeDetailList: CostTypeDetail[] = printerDetails.costTypeDetails;
           tmpCostTypeDetailArrayForPrinters.push(tmpCostTypeDetailList);
        });
     }

     for (let index = 0; index < freshCostTypeDetailListPrinterStyleLevel.length; index++) {
        let emptyCostTypeDetail: CostTypeDetail = freshCostTypeDetailListPrinterStyleLevel[index];
        this.setCostTypeDetailForAllocatedPrinter(salesOrder,costingAllocation,styleCosting, emptyCostTypeDetail, tmpCostTypeDetailArrayForPrinters);

        if (emptyCostTypeDetail.category === 'PRODUCTION_COST' && emptyCostTypeDetail.subCategory === 'PRINTING_COST') {
           emptyCostTypeDetail.currency = costingAllocation.orderCurrency;
           emptyCostTypeDetail.unitCost = printerCombDetails.averagePrintCost;
        }
     }
     return freshCostTypeDetailListPrinterStyleLevel;
  }
  /**************************************************  END: PRINTER STYLE LEVEL COST TYPE DETAIL ************************************ */


  /**************************************************  START: SUPPLIER TOP LEVEL COST TYPE DETAIL *********************************** */
  populateCostTypeDetailListForSupplierTopLevel(salesOrder:SalesOrder,ca:CostingAllocation,styleCosting: StyleCosting,printerCombDetails:AllocatedPrinterCombinationDetails, supplierCombDetails: AllocatedBlankSupplierCombinationDetails, freshCostTypeDetailListSupplierTopLevel: CostTypeDetail[]): CostTypeDetail[] {
     let costTypeDetailListArrayForSuppliersTopLevel: CostTypeDetail[][] = [];
     let combinationBlankSupplierDetails: AllocatedBlankSupplierDetails[] = [];
     if (supplierCombDetails && supplierCombDetails.bSupplierDetails) {
        supplierCombDetails.bSupplierDetails.forEach(bSupplierDetail => {
           let tmpCostTypeDetailList: CostTypeDetail[] = this.getCostTypeDetailForSupplierTopLevel(ca,bSupplierDetail.supplierId);
           costTypeDetailListArrayForSuppliersTopLevel.push(tmpCostTypeDetailList);
           combinationBlankSupplierDetails.push(bSupplierDetail);
        });
     }
     for (let index = 0; index < freshCostTypeDetailListSupplierTopLevel.length; index++) {
        let emptyCostTypeDetail: CostTypeDetail = freshCostTypeDetailListSupplierTopLevel[index];
        this.setCostTypeDetailForAllocatedBSupplier(salesOrder,ca,styleCosting, emptyCostTypeDetail, costTypeDetailListArrayForSuppliersTopLevel, combinationBlankSupplierDetails, supplierCombDetails);
        //console.log('emptyCostTypeDetail ============ ', emptyCostTypeDetail);
        if (emptyCostTypeDetail.category === 'PRODUCTION_COST' && emptyCostTypeDetail.subCategory === 'FREIGHT_DUTY_COST' && emptyCostTypeDetail.classification === 'SUPPLIER') {
         let totalFreightCost:number=0;
         let intialDeliveryPrinter=this.getInitialBlankDeliveryPrinter(printerCombDetails)//printerCombDetails.printerDetails[0];// TODO apply logic for intial delivery
         if (supplierCombDetails && supplierCombDetails.bSupplierDetails && intialDeliveryPrinter) {
           supplierCombDetails.bSupplierDetails.forEach(bSupplierDetail => {
             let freightCostPerItem:number=this.costingHelperService.getFreightCost(ca,bSupplierDetail.supplierId,intialDeliveryPrinter.printerId);
             let freightCostForSupplier:number=bSupplierDetail.allocatedQty*freightCostPerItem;
             freightCostForSupplier=this.costingHelperService.sanitizeCurrency(salesOrder.currency, bSupplierDetail.currency, freightCostForSupplier,ca.exchangeRateUsdToCad, ca.exchangeRateCadToUsd);
             totalFreightCost=totalFreightCost+freightCostForSupplier;
           });
         }
         emptyCostTypeDetail.unitCost = totalFreightCost/supplierCombDetails.totalQtyAllocated;
         emptyCostTypeDetail.unitCost = this.costingHelperService.roundUp(emptyCostTypeDetail.unitCost,3);
        }
      
     }
     return freshCostTypeDetailListSupplierTopLevel;
  }
 getInitialBlankDeliveryPrinter(printerCombDetails: AllocatedPrinterCombinationDetails):AllocatedPrinterDetails {
   if(printerCombDetails && printerCombDetails.printerDetails && printerCombDetails.printerDetails.length>0){
     if(printerCombDetails.printerDetails.length===1){
       return printerCombDetails.printerDetails[0];
     }else{
       let tempList=printerCombDetails.printerDetails.filter(x=>x.initialBlankDelivery);
       if(tempList && tempList.length>0){
         return tempList[0];
       }
     }
   }
   return undefined;
 }
  /**************************************************  END: SUPPLIER TOP LEVEL COST TYPE DETAIL *********************************** */


  /**************************************************  START: SUPPLIER STYLE LEVEL COST TYPE DETAIL *********************************** */
  populateCostTypeDetailListForSupplierStyleLevel(salesOrder:SalesOrder,costingAllocation: CostingAllocation, styleCosting: StyleCosting, supplierCombDetails: AllocatedBlankSupplierCombinationDetails, 
     freshCostTypeDetailListSupplierStyleLevel: CostTypeDetail[]): CostTypeDetail[] {
     
     let costTypeDetailListArrayForSuppliersStyleLevel: CostTypeDetail[][] = [];
     let combinationBlankSupplierDetails: AllocatedBlankSupplierDetails[] = [];
     if (supplierCombDetails && supplierCombDetails.bSupplierDetails) {
        supplierCombDetails.bSupplierDetails.forEach(bSupplierDetail => {
           let tmpCostTypeDetailList: CostTypeDetail[] = this.getCostTypeDetailForSupplierTopLevel(costingAllocation,bSupplierDetail.supplierId);
           costTypeDetailListArrayForSuppliersStyleLevel.push(tmpCostTypeDetailList);
           combinationBlankSupplierDetails.push(bSupplierDetail);
        });
     }
     for (let index = 0; index < freshCostTypeDetailListSupplierStyleLevel.length; index++) {
        let emptyCostTypeDetail: CostTypeDetail = freshCostTypeDetailListSupplierStyleLevel[index];
        this.setCostTypeDetailForAllocatedBSupplier(salesOrder,costingAllocation,styleCosting, emptyCostTypeDetail, costTypeDetailListArrayForSuppliersStyleLevel, combinationBlankSupplierDetails, supplierCombDetails);
        if (emptyCostTypeDetail.category === 'PRODUCTION_COST' && emptyCostTypeDetail.subCategory === 'BLANK_COST') {
           emptyCostTypeDetail.currency = costingAllocation.orderCurrency;
           emptyCostTypeDetail.unitCost = supplierCombDetails.averageBlankCost;
        }
     }
     return freshCostTypeDetailListSupplierStyleLevel;
  }
  /**************************************************  END: SUPPLIER STYLE LEVEL COST TYPE DETAIL *********************************** */


  setCostTypeDetailForAllocatedPrinter(salesOrder:SalesOrder,costingAllocation: CostingAllocation,styleCosting: StyleCosting, emptyCostTypeDetail: CostTypeDetail, tmpCostTypeDetailArrayForPrinters: CostTypeDetail[][]) {
     let totalRemarksForPrinters: number = 0;
     let totalUnitCostForPrinters: number = 0;
     for (let i = 0; i < tmpCostTypeDetailArrayForPrinters.length; i++) {
        let tmpCostTypeDetailList: CostTypeDetail[] = tmpCostTypeDetailArrayForPrinters[i];
        for (let j = 0; j < tmpCostTypeDetailList.length; j++) {
           let tmpCostTypeDetail: CostTypeDetail = tmpCostTypeDetailList[j];
           if (this.costingHelperService.isCostTypeDetailSame(emptyCostTypeDetail, tmpCostTypeDetail)) {
              this.setCostTypeDetailFromUserInputCostTypeDetail(salesOrder,costingAllocation,emptyCostTypeDetail, tmpCostTypeDetail, styleCosting);
              if (tmpCostTypeDetailArrayForPrinters.length === 1 || emptyCostTypeDetail.calculationType === 'PERCENTAGE'/**Special Handling for ImportDuty */) {
                 // DO not put rearks when have more than 1 printer in combination
                 // This iS Done because in case when there are more than 1 printer, the values entered for multiple printers cannot be
                 // displayed as 1 remark. So we decided that we will not show remark.
                 totalRemarksForPrinters = totalRemarksForPrinters + emptyCostTypeDetail.remarks;
              }
              totalUnitCostForPrinters = totalUnitCostForPrinters + emptyCostTypeDetail.unitCost;
           }
        }
     }
     emptyCostTypeDetail.unitCost = totalUnitCostForPrinters;
     emptyCostTypeDetail.remarks = totalRemarksForPrinters;
  }


  setCostTypeDetailForAllocatedBSupplier(salesOrder:SalesOrder,costingAllocation:CostingAllocation,styleCosting: StyleCosting, emptyCostTypeDetail: CostTypeDetail, costTypeDetailListArrayForSuppliers: CostTypeDetail[][], allocatedBlanksSupplierDetails: AllocatedBlankSupplierDetails[], 
     allocatedBSuppComb: AllocatedBlankSupplierCombinationDetails) {
     
     let averageRemarksForSuppliers: number = 0;
     let runningCostForSuppliers: number = 0;
     for (let i = 0; i < costTypeDetailListArrayForSuppliers.length; i++) {
        let tmpCostTypeDetailList: CostTypeDetail[] = costTypeDetailListArrayForSuppliers[i];
        for (let j = 0; j < tmpCostTypeDetailList.length; j++) {
           let tmpCostTypeDetail: CostTypeDetail = tmpCostTypeDetailList[j];
           if (this.costingHelperService.isCostTypeDetailSame(emptyCostTypeDetail, tmpCostTypeDetail)) {
              this.setCostTypeDetailFromUserInputCostTypeDetail(salesOrder,costingAllocation,emptyCostTypeDetail, tmpCostTypeDetail, styleCosting);
              if (costTypeDetailListArrayForSuppliers.length === 1) {
                 // DO not put rearks when have more than 1 printer in combination
                 // This iS Done because in case when there are more than 1 printer, the values entered for multiple printers cannot be
                 // displayed as 1 remark. So we decided that we will not show remark.                  
                 averageRemarksForSuppliers = averageRemarksForSuppliers + emptyCostTypeDetail.remarks;
              }
              runningCostForSuppliers = runningCostForSuppliers + (emptyCostTypeDetail.unitCost * allocatedBlanksSupplierDetails[i].allocatedQty)
           }
        }
     }
     let averageUnitCostForSuppliers = runningCostForSuppliers / allocatedBSuppComb.totalQtyAllocated;
     //console.log('Average Unit Costs for Suppliers for Cost Type [' + emptyCostTypeDetail.description + '] = ', averageUnitCostForSuppliers);
     //console.log('Average  Remarks for Suppliers for Cost Type [' + emptyCostTypeDetail.description + '] = ', averageRemarksForSuppliers);
     emptyCostTypeDetail.unitCost = averageUnitCostForSuppliers;
     emptyCostTypeDetail.remarks = averageRemarksForSuppliers;
  }

  private mergeCostTypeDetailAndCreateCreateCostingSheet(costingAllocation:CostingAllocation,costTypeDetailListForOrganization: CostTypeDetail[], costTypeDetailListForCustomer: CostTypeDetail[], costTypeDetailListForStyle: CostTypeDetail[],
     costTypeDetailListForPrinterTopLevel: CostTypeDetail[], costTypeDetailListForPrinterStyleLevel: CostTypeDetail[], costTypeDetailListForSupplierTopLevel: CostTypeDetail[],
     costTypeDetailListForSupplierStyleLevel: CostTypeDetail[], styleCosting: StyleCosting, printerCombDetails: AllocatedPrinterCombinationDetails, supplierCombDetails: AllocatedBlankSupplierCombinationDetails,
     freshCostTypeDetailListForHeaders:CostTypeDetail[]) {

     // Concatenate/Merge all the Cost Type Details and Sort them before creating costing sheet.
     let freshCostTypeDetailListForHeadersCLoned: CostTypeDetail[] = JSON.parse(JSON.stringify(freshCostTypeDetailListForHeaders));
     let finalCostTypeDetailList: CostTypeDetail[] = this.mergeAndSortCostTypeDetails(freshCostTypeDetailListForHeadersCLoned, costTypeDetailListForOrganization, costTypeDetailListForCustomer, costTypeDetailListForStyle,
        costTypeDetailListForPrinterTopLevel, costTypeDetailListForPrinterStyleLevel, costTypeDetailListForSupplierTopLevel, costTypeDetailListForSupplierStyleLevel);

     // Create CostingSheet   console
     //console.log('creating costing sheet',this.costingAllocation,finalCostTypeDetailList, styleCosting, printerCombDetails, supplierCombDetails);
     let createdCostingSheet: CostingSheet = this.createCostingSheet(costingAllocation,finalCostTypeDetailList, styleCosting, printerCombDetails, supplierCombDetails);
     if (styleCosting.costingSheets === undefined || styleCosting.costingSheets === null || styleCosting.costingSheets.length === 0) {
        styleCosting.costingSheets = [];
     }
     //console.log('Costing Sheet Created for Style = ['+styleCosting.sku+'] = ', createdCostingSheet);
     return createdCostingSheet;
  }

  mergeAndSortCostTypeDetails(costTypeDetailListForHeaders: CostTypeDetail[], costTypeDetailListForOrganization: CostTypeDetail[], costTypeDetailListForCustomer: CostTypeDetail[],
     costTypeDetailListForStyle: CostTypeDetail[], costTypeDetailListForPrinterTopLevel: CostTypeDetail[], costTypeDetailListForPrinterStyleLevel: CostTypeDetail[],
     costTypeDetailListForSupplierTopLevel: CostTypeDetail[], costTypeDetailListForSupplierStyleLevel: CostTypeDetail[]): CostTypeDetail[] {

     let finalCostTypeDetailList: CostTypeDetail[] = [];

     if (costTypeDetailListForHeaders !== null && costTypeDetailListForHeaders !== undefined && costTypeDetailListForHeaders.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForHeaders);
     }
     if (costTypeDetailListForOrganization !== null && costTypeDetailListForOrganization !== undefined && costTypeDetailListForOrganization.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForOrganization);
     }
     if (costTypeDetailListForCustomer !== null && costTypeDetailListForCustomer !== undefined && costTypeDetailListForCustomer.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForCustomer);
     }
     if (costTypeDetailListForStyle !== null && costTypeDetailListForStyle !== undefined && costTypeDetailListForStyle.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForStyle);
     }
     if (costTypeDetailListForPrinterTopLevel !== null && costTypeDetailListForPrinterTopLevel !== undefined && costTypeDetailListForPrinterTopLevel.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForPrinterTopLevel);
     }
     if (costTypeDetailListForSupplierTopLevel !== null && costTypeDetailListForSupplierTopLevel !== undefined && costTypeDetailListForSupplierTopLevel.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForSupplierTopLevel);
     }
     if (costTypeDetailListForPrinterStyleLevel !== null && costTypeDetailListForPrinterStyleLevel !== undefined && costTypeDetailListForPrinterStyleLevel.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForPrinterStyleLevel);
     }
     if (costTypeDetailListForSupplierStyleLevel !== null && costTypeDetailListForSupplierStyleLevel !== undefined && costTypeDetailListForSupplierStyleLevel.length > 0) {
        finalCostTypeDetailList.push(...costTypeDetailListForSupplierStyleLevel);
     }

     let finalSortedCostTypeDetailList: CostTypeDetail[] = finalCostTypeDetailList.sort((a, b) => a.sortOrder - b.sortOrder);
     //console.log("Final Cost Type Details List and Sorted = ", finalCostTypeDetailList);
     return finalSortedCostTypeDetailList;
  }


  createCostingSheet(costingAllocation:CostingAllocation, costTypeDetailList: CostTypeDetail[], styleCosting: StyleCosting, printerCombDetails: AllocatedPrinterCombinationDetails, bSupplierCombDetails: AllocatedBlankSupplierCombinationDetails): CostingSheet {
     let costingSheet: CostingSheet;
     if (bSupplierCombDetails === null) {
        costingSheet = new CostingSheet(printerCombDetails.id+':FULL_PACKAGE',printerCombDetails.id, 'FULL_PACKAGE');
     } else {
        costingSheet = new CostingSheet(printerCombDetails.id+':'+bSupplierCombDetails.id,printerCombDetails.id, bSupplierCombDetails.id);
     }

     if(styleCosting.approvedCostingSheetId && styleCosting.approvedCostingSheetId!=null && styleCosting.approvedCostingSheetId===costingSheet.id){
       costingSheet.approvalStatus='APPROVED';
     }else{
       costingSheet.approvalStatus='NOT_APPROVED';
     }
     if(printerCombDetails.additionalNotes){
       costingSheet.additionalNotes=printerCombDetails.additionalNotes;
     }
     
     costingSheet.orderDetailId = styleCosting.orderDetailId;
     costingSheet.productSkuCode = styleCosting.sku;

     // Blanks Supplier
     if (bSupplierCombDetails !== null) {
        costingSheet.averageBlanksCost = bSupplierCombDetails.averageBlankCost;
        let tempAllocatedBlankSupplierNames: string = '';
        bSupplierCombDetails.bSupplierDetails.forEach(bSupplierDetails => {
           if (tempAllocatedBlankSupplierNames.length > 0) {
              tempAllocatedBlankSupplierNames = tempAllocatedBlankSupplierNames + ' + '
           }
           tempAllocatedBlankSupplierNames = tempAllocatedBlankSupplierNames + bSupplierDetails.supplierName;
        });
        costingSheet.allocatedBlankSupplierNames = tempAllocatedBlankSupplierNames;
     }else{
       costingSheet.allocatedBlankSupplierNames = 'Full Package';
     }

     // Printer
     costingSheet.totalPrintingCost = printerCombDetails.averagePrintCost;
     costingSheet.fullPackageOrder = printerCombDetails.fullPackage;
     let tempAllocatedPrinterNames: string = '';
     printerCombDetails.printerDetails.forEach(printerDetails => {
        if (tempAllocatedPrinterNames.length > 0) {
           tempAllocatedPrinterNames = tempAllocatedPrinterNames + ' + '
        }
        tempAllocatedPrinterNames = tempAllocatedPrinterNames + printerDetails.printerName;
     });
     costingSheet.allocatedPrinterNames = tempAllocatedPrinterNames;

     // CostTypeDetail List
     costingSheet.costTypeDetailList = costTypeDetailList;

     this.costingHelperService.calculateCostingSheet(costingAllocation,styleCosting, costingSheet);

     return costingSheet;
  }


  /**************************************************  START: COMMON METHODS ************************************ */

  private setCostTypeDetailFromUserInputCostTypeDetail(salesOrder:SalesOrder,costingAllocation:CostingAllocation,freshCostTypeDetail: CostTypeDetail, userInputCustomerCostTypeDetail: CostTypeDetail, styleCosting: StyleCosting) {
     freshCostTypeDetail.remarks = 0;
     freshCostTypeDetail.unitCost = 0;
     freshCostTypeDetail.uiCost = userInputCustomerCostTypeDetail.uiCost;
     if (freshCostTypeDetail.calculationType === 'ABSOLUTE') {
        //console.log('Absolute');
        freshCostTypeDetail.remarks = userInputCustomerCostTypeDetail.uiCost;
        freshCostTypeDetail.unitCost = freshCostTypeDetail.remarks / styleCosting.totalQty;
        freshCostTypeDetail.unitCost = this.costingHelperService.sanitizeCurrency(salesOrder.currency, userInputCustomerCostTypeDetail.currency, freshCostTypeDetail.unitCost,costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd);
     }
     else if (freshCostTypeDetail.calculationType === 'ABSOLUTE_UNIT') {
        //console.log('Absolute Unit');
        freshCostTypeDetail.unitCost = userInputCustomerCostTypeDetail.uiCost;
        if (this.costingHelperService.isCurrencyMismatch(userInputCustomerCostTypeDetail.currency, salesOrder.currency)) {
           freshCostTypeDetail.remarks = userInputCustomerCostTypeDetail.uiCost;
        }
        freshCostTypeDetail.unitCost = this.costingHelperService.sanitizeCurrency(salesOrder.currency, userInputCustomerCostTypeDetail.currency, freshCostTypeDetail.unitCost,costingAllocation.exchangeRateUsdToCad, costingAllocation.exchangeRateCadToUsd);
     }
     else if (freshCostTypeDetail.calculationType === 'PERCENTAGE') {
        //console.log('Percentage');
        freshCostTypeDetail.remarks = userInputCustomerCostTypeDetail.uiCost;
     }
     else {
        this.toastrService.error('Calculation Type is incorrect. = ', freshCostTypeDetail.calculationType);
     }
  }

  getCostTypeDetailForPrinterTopLevel(costingAllocation:CostingAllocation,printerIdFromCombo: string): CostTypeDetail[] {
     for (let index = 0; index < costingAllocation.allocatedPrinters.length; index++) {
        let allocatedPrinterDetails: AllocatedPrinterDetails = costingAllocation.allocatedPrinters[index];
        if (allocatedPrinterDetails.printerId === printerIdFromCombo) {
           return allocatedPrinterDetails.costTypeDetails;
        }
     }
     return null;
  }

  getCostTypeDetailForSupplierTopLevel(costingAllocation:CostingAllocation,supplierIdFromCombo: string): CostTypeDetail[] {
     for (let index = 0; index < costingAllocation.allocatedSuppliers.length; index++) {
        let allocatedBSupplierDetails: AllocatedBlankSupplierDetails = costingAllocation.allocatedSuppliers[index];
        if (allocatedBSupplierDetails.supplierId === supplierIdFromCombo) {
           return allocatedBSupplierDetails.costTypeDetails;
        }
     }
     return null;
  }

  /**************************************************  END: COMMON METHODS ************************************ */

/******************************************************************************************************************************************** */
                                                        //END: GENERATE - COSTING SHEETS 
/******************************************************************************************************************************************** */



}
export interface AverageBlankSizeDetails{
size:string;
totalBlankQtyCost:number;
totalBlankQty:number;
}

export interface AveragePrinterSizeDetails{
   size:string;
   totalPrintQtyCost:number;
   totalPrintQty:number;
   }
