
<div class="card mb-2" [style.border]="orderDetailInfo.orderDetailStatus==='CANCELLED' || orderDetailInfo.obsolete?'1px solid red':''" [style.opacity]="orderDetailInfo.orderDetailStatus==='CANCELLED' || orderDetailInfo.obsolete?'0.6':'10'">
    <div *ngIf="orderDetailInfo && orderDetailInfo.orderDetail && orderDetailInfo.orderDetail.product" class="">
        <div class="col-sm-12 card-header d-flex justify-content-between  bg-color-{{approvalStatusColor(orderDetailInfo.orderDetail.product.approvalStatus)}}"  style="cursor: pointer; padding: .45rem 1.25rem !important;">
            <div style="width: 10%;">
                <span class="ml-0 mr-3" data-toggle="collapse" id="expandOrderDetail_{{orderDetailInfo.id}}" [attr.data-target]="'#orderDetail_'+orderDetailInfo.id">
                   <i class="fas fa-expand-alt fa-1x"></i>
                </span>
             </div>
            <div style="width: 80%;" class="text-center">
                <span data-toggle="collapse" [attr.data-target]="'#orderDetail_'+orderDetailInfo.id">
                    <h6>{{orderDetailInfo.orderDetail.status==='CANCELLED'?'Cancelled ':''}}{{orderDetailInfo.orderDetail.product.sku}} - {{orderDetailInfo.orderDetail.product.approvalStatus}}
                        <span style="font-weight: 700;color:red;">{{isLicensorPreProApprovalMessageRequired(orderDetailInfo.orderDetail.product)?' (Licensor Pre Production Approval Required)':''}}</span>
                    </h6>
                    
                
                </span>
            </div>
            <div style="width: 10%;" data-toggle="collapse" [attr.data-target]="'#orderDetail_'+orderDetailInfo.id">&nbsp;</div>
         </div>
        <div id="orderDetail_{{orderDetailInfo.id}}" class="collapse show card-body" style="padding: .25rem;" >
            <div class="row" >
                <div class="col-3 d-flex align-items-center">
                    
    
                    <div style="position: relative;">
                        <!--<div class="mat-tab-label f-14 " style="font-weight: 500 !important;font-size: 18px; opacity: 1;">
                            <div class="d-flex flex-column">
                                <div class="text-color-{{approvalStatusColor(orderDetailInfo.orderDetail.product.approvalStatus)}}">{{orderDetailInfo.orderDetail.product.sku}}</div>
                                <div class="f-10 mb-2 font-weight: 500 !important; text-color-{{approvalStatusColor(orderDetailInfo.orderDetail.product.approvalStatus)}}">{{orderDetailInfo.orderDetail.product.approvalStatus}}</div>
                            </div>
                        </div>-->
                        
                        <div style="position: absolute;" *ngIf="orderDetailInfo.orderDetailStatus==='CANCELLED'" >
                            <img src="assets/cancelledBanner.png" 
                                class="" width="auto" height="70">
                        </div>
                        <div style="position: absolute;" *ngIf="orderDetailInfo.orderDetailStatus!=='CANCELLED' && orderDetailInfo.obsolete" >
                            <img src="assets/unassignedBanner.png" 
                                class="" width="auto" height="70">
                        </div>
                        <!--<app-image-with-preview [defaultHeight]="'auto'" [defaultWidth]="'230px'" [disablePreview]="true" [attachment]="orderDetailInfo.orderDetail.product.productImages[0]">
                        </app-image-with-preview>-->
                        <img [src]="orderDetailInfo.orderDetail.product.productImages[0]?.thumbnailSignedUrl" 
                        class="img-thumbnail img-fluid"
                        style="
                        border: 0;
                        display: block;
                        max-width: 230px;
                        max-height: 230px;
                        width: 100%;
                        height: auto;
                        -o-object-fit: contain;
                        object-fit: contain;"
                        
                        (error)="handleThumbnailError(orderDetailInfo.orderDetail.product.productImages[0],$event)" />
                    </div>
                    <!--<a type="button" style="color: black;" matTooltip="View product" matTooltipPosition="below" matTooltipClass="bg-dark" target="_blank"
                               [routerLink]="['/product/view/'+orderDetail.product.licensorId+'/'+orderDetail.product.brandId+'/'+orderDetail.product.id]">-->
                            <!--<app-image-with-preview [defaultHeight]="'auto'" [defaultWidth]="'230px'" [disablePreview]="true" [attachment]="orderDetail.product.productImages[0]">
                            </app-image-with-preview>-->
                            <!--</a>--> 
                </div>
                
                <div class="col-9">
                    <mat-tab-group>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <h6 class="f-14">Product Details</h6>
                            </ng-template>
                            <div class="overflow-hidden mt-1">
                                <div class="row f-11 my-1">
                                    <div class="col-6">
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">SKU</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.sku">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Licensor</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.licensorName">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Brand</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.brandName">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Approval Status</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.approvalStatus">
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Color</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.color">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row" *ngIf="currentPantoneColor">
                                            <label class="col-sm-3 col-form-label">Pantone</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="currentPantoneColor">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Treatment</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.treatment">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Category</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.category">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Garment</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.garment">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label">Print Effect</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.printEffect">
                                            </div>
                                        </div>
                                        <div class="form-group mb-1 row">
                                            <label class="col-sm-3 col-form-label"># Of Screens</label>
                                            <div class="col-sm-8">
                                              <input type="text" disabled class="form-control f-11" [value]="orderDetailInfo.orderDetail.product.screenCount">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                        <!--<mat-tab>
                            <ng-template mat-tab-label>
                                <h6 class="f-14">Files</h6>
                            </ng-template>
                            <mat-tab-group>-->
                                <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Printer Files</h6>
                                </ng-template>
                                <div class="overflow-hidden mt-3">
                                    <app-attachment-operations [header]="'Printer Files'" [editable]="false" [showHeader]="false"
                                    [createAction]="false" [attachments]="orderDetailInfo.orderDetail.product.seperations" 
                                    [canUpload]="userAccess?.canUploadPrinterFiles" [canDownload]="userAccess?.canDownloadPrinterFiles" 
                                    [canDelete]="userAccess?.canDeletePrinterFiles" [category]="'SEPERATIONS'" [canRenameFile]="false" [viewCreatedBy]="false" ></app-attachment-operations>
                                </div>
                                </mat-tab>
                                <mat-tab>
                                <ng-template mat-tab-label>
                                    <h6 class="f-14">Brand PNL</h6>
                                </ng-template>
                                <div class="f-13 my-1 text-danger">{{orderDetailInfo.orderDetail.pnlSpeciality}}</div>
                                <div class="overflow-hidden mt-3">
                                        <app-attachment-operations [header]="'Brand PNL'" [editable]="false" [showHeader]="false"
                                        [createAction]="false" [attachments]="getPreferredPnl(orderDetailInfo.orderDetail)" 
                                        [canUpload]="false" [canDownload]="userAccess?.canDownloadPnls" [canDelete]="false" [viewCreatedBy]="false"></app-attachment-operations>
                                </div>
                                </mat-tab>  
                            <!--</mat-tab-group>
                        </mat-tab>-->
                    </mat-tab-group>
                </div>
            </div>
        </div>

        



    </div>
</div>
