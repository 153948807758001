import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Brand, BrandPnlSpeciality } from 'src/app/shared/model/brand.model';
import { BrandService } from 'src/app/shared/service/brand.service';
import { ServiceStatus } from 'src/app/shared/model/service-status.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LicensorService } from 'src/app/shared/service/licensor.service';
import { Licensor } from 'src/app/shared/model/licensor.model';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ProductCatalogService } from 'src/app/shared/service/product-catalog.service';
import { Product, ProductAttachment } from 'src/app/shared/model/product.model';
import { ProductService } from 'src/app/shared/service/product.service';
import { FileDeleteCriteria } from 'src/app/shared/model/file-delete-criteria.model';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Subscription } from 'rxjs';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { MetadataService } from 'src/app/shared/service/metadata.service';
import { ApiResponse } from 'src/app/shared/model/api-response';
import { Metadata } from 'src/app/shared/model/metadata.model';
import { ToastrService } from 'ngx-toastr';
import { SearchCriteria } from 'src/app/shared/model/search-criteria.model';
import { FileHandlingService } from 'src/app/shared/service/file-handling.service';
import { EmailTemplateConfig } from 'src/app/shared/model/email-template-config.model';
import { ComposeEmail } from 'src/app/shared/model/compose-email.model';
import { ComposeEmailService } from 'src/app/shared/service/compose-email.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Customer } from 'src/app/shared/model/customer.model';
import { CustomerService } from 'src/app/shared/service/customer.service';

@Component({
   selector: 'app-brand',
   templateUrl: './brand.component.html',
   styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {
   fileUploaded: boolean = false;
   fileUploading: boolean = false;
   uploadError: boolean = false;
   progress: number = 0;
   submitted: boolean;
   createAction: boolean = false;
   viewAction: boolean = false;
   editable: boolean = false;
   brand: Brand;
   filteredProductList: Product[];
   licensorList: Licensor[];
   selectedPnlAccesUrl: string = null;
   selectedPnlId: string = null;
   selectedLogoAccesUrl: string = null;
   selectedLogoId: string = null;
   licensorVar: any;
   userAccessSubscription: Subscription;
   userAccess: UserAccess;
   pnlsToUploadPostProductCreation: File[] = [];
   logosToUploadPostProductCreation: File[] = [];
   brandFilesUploadPostUpdate: File[] = [];
   brandTrendFilesUploadPostUpdate: File[] = [];
   categoryList = [];
   metadata: Metadata;
   showEmailEditor: boolean;
   composeEmail: ComposeEmail = new ComposeEmail;
   showProducts: boolean=true;
   customerList:Customer[]=[];


   constructor(private dialog: MatDialog,private router: Router,private composeEmailService:ComposeEmailService,private fileHanldingService:FileHandlingService,private sharedService: SharedService, 
      private service: BrandService, private productService: ProductService, private licensorService: LicensorService, private activatedRoute: ActivatedRoute, private location: Location, 
      private productCatalogService: ProductCatalogService, private metadataService: MetadataService, private toastrService: ToastrService,private authService:AuthService
      , private customerService:CustomerService) {
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
         return false;
      }
   
      this.router.events.subscribe((evt) => {
         if (evt instanceof NavigationEnd) {
            // trick the Router into believing it's last link wasn't previously loaded
            //this.router.navigated = false;
            // if you need to scroll back to top, here is the right place
            window.scrollTo(0, 0);
         }
     });
   }

   ngOnInit() {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.brand = new Brand;
      this.handleSubscriptions();
      this.evaluateAction();
      if (this.viewAction) {
         this.loadBrand(this.activatedRoute.snapshot.paramMap.get('id'));
      } else {
         this.loadLicensors();
      }
      this.loadCategories();
      window.scrollTo(0, 0);
      this.metadata = new Metadata;
   }

   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      
      this.customerService.customerListObservable.subscribe(res => {
         if(res && res!=null && res.length>0){
           this.customerList.length=0;
           this.customerList.push(...res);
         }
      });
   }

   createOrUpdateBrand() {
      this.showProducts=false;
      this.handleLegalLineSymbols();
      this.populateLocalFileList();
      if (this.editable && !this.createAction) {
         this.updateBrand();
      } else {
         this.createBrand()
      }
   }

   private handleLegalLineSymbols() {
      if (this.brand !== undefined && this.brand.pnlDescription !== undefined && this.brand.pnlDescription !== null) {
         if (this.brand.pnlDescription.indexOf('(tm)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(tm)', '™');
         }
         if (this.brand.pnlDescription.indexOf('(r)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(r)', '®');
         }
         if (this.brand.pnlDescription.indexOf('(c)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(c)', '©');
         }
         if (this.brand.pnlDescription.indexOf('(TM)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(TM)', '™');
         }
         if (this.brand.pnlDescription.indexOf('(R)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(R)', '®');
         }
         if (this.brand.pnlDescription.indexOf('(C)') > -1) {
            this.brand.pnlDescription = this.brand.pnlDescription.replace('(C)', '©');
         }
      }
   }

   /*showHideProducts(){
      this.showProducts=!this.showProducts;
      this.loadBrand(this.brand.id);

   }*/

   loadBrand(id: string,updateAttachment?:boolean) {
      if(updateAttachment){
         this.deleteAttachments();
      }
      this.service.loadBrand(id,true,false).subscribe((res) => {
         this.brand = res as Brand;
         if(this.brand.pnls){
            this.sharedService.loadMimeTypes(this.brand.pnls);
         }
         /*Load BRand FIles*/
         let attachment: ProductAttachment = new ProductAttachment;
         attachment.licensorId=this.brand.licensorId;
         attachment.brandId=this.brand.id;
         attachment.fileCategory='BRAND_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.brand, attachment,'brandFiles');
         /*Load Brand Files ends*/

         /*Load Brand Trend FIles*/
         let brandTrendAttachment: ProductAttachment = new ProductAttachment;
         brandTrendAttachment.licensorId=this.brand.licensorId;
         brandTrendAttachment.brandId=this.brand.id;
         brandTrendAttachment.fileCategory='BRAND_TREND_FILES';
         this.fileHanldingService.loadAttachmentsUrls(this.brand, brandTrendAttachment,'brandTrendFiles');
         /*Load Brand Trend Files ends*/

         this.filteredProductList = this.brand.productList;
         this.filteredProductList = this.sharedService.sortListByPropertyName(this.filteredProductList,'sku');
         this.selectPNL();
         if (this.brand.logos !== undefined && this.brand.logos !== null && this.brand.logos.length > 0) {
            this.showLogo(this.brand.logos[0].fileSignedUrl, this.brand.logos[0].id);
         } else {
            this.showLogo(null, null);
         }
         if(updateAttachment){
            this.uploadAttachments();
         }
      });
   }


   

   createBrand() {
      this.service.createBrand(this.brand).subscribe(res => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         if (serviceStatus.status === 'SUCCESS') {
            if (serviceStatus.id !== null) {
               this.brand.id = serviceStatus.id;
               this.editable = false;
               this.createAction = false;
               this.viewAction = true;
               if (this.brand.pnls.length > 0) {
                  this.setSortOrderForPNLs(false);
                  this.brand.pnls.forEach(element => {
                     if (element.localfile !== undefined || element.localfile !== null) {
                        this.pnlsToUploadPostProductCreation.push(element.localfile);
                     }
                  });
                  this.setSortOrderForPNLs(true);
               }
               if (this.brand.logos.length > 0) {
                  this.setSortOrderForLOGOs(false);
                  this.brand.logos.forEach(element => {
                     if (element.localfile !== undefined || element.localfile !== null) {
                        this.logosToUploadPostProductCreation.push(element.localfile);
                     }
                  });
                  this.setSortOrderForLOGOs(true);
               }
               if (this.logosToUploadPostProductCreation !== undefined && this.logosToUploadPostProductCreation.length > 0) {
                  this.fileSelectedDragDrop(this.logosToUploadPostProductCreation, 'LOGO');
               }
               if (this.pnlsToUploadPostProductCreation !== undefined && this.pnlsToUploadPostProductCreation.length > 0) {
                  this.fileSelectedDragDrop(this.pnlsToUploadPostProductCreation, 'PNL');
               }
               this.uploadAttachments();
            }
         } else {
            this.toastrService.error(serviceStatus.message);
         }
         this.toastrService.success(serviceStatus.message);
      }, err => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Brand Create Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   setSortOrderForPNLs(removeLocalFileReference: boolean) {
      if (this.brand.pnls != undefined && this.brand.pnls != null && this.brand.pnls.length > 0) {
         for (let index = 0; index < this.brand.pnls.length; index++) {
            this.brand.pnls[index].sortOrder = index;
            if (removeLocalFileReference) {
               this.brand.pnls[index].localfile = undefined;
            }
         }
      }
   }

   setSortOrderForLOGOs(removeLocalFileReference: boolean) {
      if (this.brand.logos != undefined && this.brand.logos != null && this.brand.logos.length > 0) {
         for (let index = 0; index < this.brand.logos.length; index++) {
            this.brand.logos[index].sortOrder = index;
            if (removeLocalFileReference) {
               this.brand.logos[index].localfile = undefined;
            }
         }
      }
   }

   updateBrand() {
      this.service.updateBrand(this.brand).subscribe(res => {
         let serviceStatus: ServiceStatus = JSON.parse(JSON.stringify(res));
         if (serviceStatus.status === 'SUCCESS') {
            this.showProducts=true;
            this.loadBrand(this.brand.id,true);
            if (serviceStatus.id !== null) {
               this.brand.id = serviceStatus.id;
               this.editable = false;
               this.createAction = false;
               this.viewAction = true;
               this.brand.changeProductStatusOnLicensorChange=false;
            }
            this.toastrService.success(serviceStatus.message);
         } else {
            this.toastrService.error(serviceStatus.message);
         }
         
      }, err => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Brand Update Error : ' + JSON.stringify(err) + ' with status - ');
      });
   }

   evaluateAction() {
      if (this.activatedRoute.snapshot.paramMap.get('action') === "view") {
         this.viewAction = true;
      } else if (this.activatedRoute.snapshot.paramMap.get('action') === "create") {
         this.editable = true;
         this.createAction = true;
      }
   }

   goBack() {
      this.location.back();
   }

   edit() {
      this.editable = true;
      this.createAction = false;
      this.viewAction = false;
      //this.showProducts=false;
      this.loadLicensors();
   }

   cancelEdit() {
      this.editable = false;
      this.createAction = false;
      this.viewAction = true;
      this.loadBrand(this.brand.id);
   }

   loadLicensors() {
      this.licensorService.loadLicensors().subscribe((res: Licensor[]) => {
         this.licensorList = this.sharedService.sortListByPropertyName(res,'licensorName');
         if (this.activatedRoute.snapshot.paramMap.get('id') !== undefined && this.activatedRoute.snapshot.paramMap.get('id') !== null) {
            this.brand.licensorId = this.activatedRoute.snapshot.paramMap.get('id');
            this.onLicensorChange();
         }
      }, err => {
         console.error("Error in Load Licensor Response: " + err);
      });
   }

   onLicensorChange(event?) {
      if (this.brand.licensorName !== undefined) {
         let temp: Licensor[] = this.licensorList.filter(listElem => listElem.licensorName === this.brand.licensorName);
         if (temp !== undefined && temp[0] !== undefined) {
            this.brand.licensorId = temp[0].id;
            this.brand.licensorColor = temp[0].licensorColor;
         }
      } else {
         let temp: Licensor[] = this.licensorList.filter(listElem => listElem.id === this.brand.licensorId);
         if (temp !== undefined && temp[0] !== undefined) {
            this.brand.licensorName = temp[0].licensorName;
            this.brand.licensorColor = temp[0].licensorColor;
         }
      }
   }

   onLicensorChangeByUser(){
      this.onLicensorChange();
      if(!this.createAction){
         this.confirmProductStatusChange();
      }
   }
   confirmProductStatusChange() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: 'Update Product Status' ,
          message: 'Update all products under brand - '+this.brand.brandName+ ' with status as "Not Submitted"', 
          confirmBtnText: 'Yes',
          cancelBtnText:' No  '
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.brand.changeProductStatusOnLicensorChange=true;
        }else{
         this.brand.changeProductStatusOnLicensorChange=false;
        }
      });
   }

   fileSelected(event, category: string) {
      this.fileUploaded = false;
      this.fileUploading = false;
      this.uploadError = false;
      this.progress = 0;
      this.uploadSelectedFile(event.target.files[0], category);
   }

   fileSelectedDragDrop(files: File[], category: string) {
   if (files.length > 0){
      for (let index = 0; index < files.length; index++) {
         var mimeType = files[index].type;
         if (mimeType.match(/image\/*/) != null || mimeType.match(/application\/postscript\/*/) != null) {
            this.uploadError = false;
            continue;
         }else{
            this.toastrService.error('Invalid File Type');
            this.uploadError = true;
            return;
         }
      }
   }
      if (files.length > 0 && this.createAction) {
         if (this.brand.pnls === null || this.brand.pnls === undefined) {
            this.brand.pnls = [];
         }
         if (this.brand.logos === null || this.brand.logos === undefined) {
            this.brand.logos = [];
         }
         
         /*for (let index = 0; index < 1; index++) {*/
         for (let index = 0; index < files.length; index++) {/// Use this for loop to allow multiple upload
            setTimeout(() => {
               var reader = new FileReader();
               //this.imagePath = files;
               reader.readAsDataURL(files[index]);
               reader.onload = (_event) => {
                  let attachment = new ProductAttachment;
                  attachment.fileSignedUrl = reader.result;
                  attachment.thumbnailSignedUrl = reader.result;
                  attachment.localfile = files[index];
                  if (category === 'PNL') {
                     attachment.id=this.brand.pnls.length.toString();
                     this.brand.pnls.push(attachment);
                     this.selectedPnlAccesUrl = attachment.fileSignedUrl;
                     this.selectedPnlId=attachment.id;
                  } else if (category === 'LOGO') {
                     attachment.id=this.brand.pnls.length.toString();
                     this.brand.logos.push(attachment);
                     this.selectedLogoAccesUrl = attachment.fileSignedUrl;
                     this.selectedLogoId=attachment.id;
                  }
                  attachment.mimeType=this.sharedService.getMimeType(files[index].name);
               }
            });
         }
      } else {
         if (files.length > 0) {
            /*for (let index = 0; index < 1; index++) {*/
            for (let index = 0; index < files.length; index++) {// Use this for loop to allow multiple upload
               setTimeout(() => {
                  const element = files[index];
                  this.uploadSelectedFile(element, category);
               });
               this.fileUploaded = false;
               this.fileUploading = false;
               this.uploadError = false;
               this.progress = 0;
            }
         }
      }
   }

   uploadSelectedFile(imageToUpload: File, filecategory: string) {
      this.service.uploadImagesForBrandWithProgress(this.brand, imageToUpload, filecategory).subscribe(
         (event: HttpEvent<any>) => {
            switch (event.type) {
               case HttpEventType.Sent:
                  //
                  break;
               case HttpEventType.ResponseHeader:
                  //
                  this.fileUploaded = true;
                  this.fileUploading = false;
                  break;
               case HttpEventType.UploadProgress:
                  this.progress = Math.round(event.loaded / event.total * 100);
                  //
                  break;
               case HttpEventType.Response:
                  //
                  this.fileUploaded = true;
                  this.loadBrand(this.brand.id);
                  setTimeout(() => {
                     this.progress = 0;
                  }, 1500000);

            }
         }, (err) => {
            console.error('Error while upload' + err);
            this.uploadError = true;
         }
      );
   }


   deleteFile(id: string, category: string) {
      if (this.createAction) {
         if (category === 'PNL') {
            this.brand.pnls = this.brand.pnls.filter(element => element.id != id);
           this.selectPNL();
         } else if (category === 'LOGO') {
            this.brand.logos = this.brand.logos.filter(element => element.id != id);
            if(this.brand.logos.length===0){
               this.selectedLogoAccesUrl=undefined;
               this.selectedLogoId=undefined;
            }else{
            this.showLogo(this.brand.logos[0].fileSignedUrl,this.brand.logos[0].id);
            }
         }
      }else if(!this.createAction){
      const obj: FileDeleteCriteria = new FileDeleteCriteria;
      obj.id = id;
      this.service.deleteFile(obj).subscribe((res) => {
         let status: ServiceStatus = res as ServiceStatus;
         if (status.status == 'SUCCESS') {
            this.loadBrand(this.brand.id);
         }
      },
         (err) => {
            console.error('Error Encountered during Delete File: ' + err);
         });
      }
   }

   


   /*onKeyupProductFilter(event, value) {
      if (event.key !== 'Shift') {
         if (value.trim().length > 0) {
            this.filteredProductList = this.brand.productList.filter(items => items.sku.toUpperCase().indexOf(value.toUpperCase()) > -1);
         } else {
            this.filteredProductList = this.brand.productList;
         }
      }
   }*/

   private selectPNL() {
      if (this.brand.pnls !== undefined && this.brand.pnls !== null && this.brand.pnls.length > 0) {
         let tempImageList = this.brand.pnls.filter(x => x.mimeType.startsWith('image'));
         if (tempImageList && tempImageList.length > 0) {
            this.showPnl(tempImageList[0].fileSignedUrl, tempImageList[0].id);
         } else {
            this.showPnl(null, null);
         }
         // this.showPnl(this.brand.pnls[0].fileSignedUrl,this.brand.pnls[0].id);
      } else {
         this.showPnl(null, null);
      }
   }

   showPnl(accessUrl, id, event?) {
      this.selectedPnlAccesUrl = accessUrl;
      this.selectedPnlId = id;
   }

   showLogo(accessUrl, id, event?) {
      this.selectedLogoAccesUrl = accessUrl;
      this.selectedLogoId = id;
   }

   loadCategories() {
      this.metadataService.getMetadataValueListByClassificationAndType('BRAND', 'CATEGORY').subscribe((res: ApiResponse) => {
         if (res.responseStatus.status === 'SUCCESS') {
            this.categoryList = this.sharedService.sort(res.responsePayload);
         }
      });
   }



   onDropdownChange(metadatatype, dropDownselectedValue) {
      if (dropDownselectedValue === 'add') {
         this.metadata = new Metadata;
         this.metadata.metadataClassification = "BRAND";
         this.metadata.metadataType = metadatatype;
         document.getElementById('metadataModalToggler').click();
      }
   }

   saveMetadata() {
      let metadata = new Metadata();
      metadata.metadataClassification = this.metadata.metadataClassification.toUpperCase();
      metadata.metadataType = this.metadata.metadataType.toUpperCase();
      metadata.metadataValue = this.metadata.metadataValue;
      this.metadataService.createMetadata(metadata).subscribe(response => {
         if (response.responseStatus.status === 'SUCCESS') {
            if (metadata.metadataType === 'CATEGORY') {
               this.loadCategories();
            }
            this.toastrService.success(response.responseStatus.message);
         }
         else {
            this.toastrService.error(response.responseStatus.message);
         }
      }, (err) => {
         this.toastrService.error(JSON.stringify(err));
         console.error('Metadata Save Error : ' + JSON.stringify(err) + ' with status - ');
      });
      this.reset();
   }

   closeMetadataModal() {
      this.reset();
   }

   reset() {
      if (this.metadata.metadataType.toUpperCase() === 'CATEGORY') {
         this.brand.category = null;
      }
   }

    /*****File Handling Starts */ 
    uploadAttachments() {
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.licensorId=this.brand.licensorId;
      attachment.brandId=this.brand.id;
      attachment.fileCategory='BRAND_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.brandFilesUploadPostUpdate,this.brand,'brandName',attachment,'brandFiles');

      let brandTrendAttachment: ProductAttachment = new ProductAttachment;
      brandTrendAttachment.licensorId=this.brand.licensorId;
      brandTrendAttachment.brandId=this.brand.id;
      brandTrendAttachment.fileCategory='BRAND_TREND_FILES';
      this.fileHanldingService.uploadLocalFileToRepoNonBlocking(this.brandTrendFilesUploadPostUpdate,this.brand,'brandName',brandTrendAttachment,'brandTrendFiles');
   }
   async deleteAttachments() {
      await this.fileHanldingService.deleteMarkedFile(this.brand.brandFiles,this.createAction);
      let attachment: ProductAttachment = new ProductAttachment;
      attachment.licensorId=this.brand.licensorId;
      attachment.brandId=this.brand.id;
      attachment.fileCategory='BRAND_FILES';
      this.fileHanldingService.loadAttachmentsUrls(this.brand,attachment,'brandFiles');


      await this.fileHanldingService.deleteMarkedFile(this.brand.brandTrendFiles,this.createAction);
      let brandTrendAttachment: ProductAttachment = new ProductAttachment;
      brandTrendAttachment.licensorId=this.brand.licensorId;
      brandTrendAttachment.brandId=this.brand.id;
      brandTrendAttachment.fileCategory='BRAND_TREND_FILES';
      this.fileHanldingService.loadAttachmentsUrls(this.brand,brandTrendAttachment,'brandTrendFiles');
   }
     
     populateLocalFileList() {
      this.brandFilesUploadPostUpdate=this.fileHanldingService.populateLocalFileinList(this.brand.brandFiles);
      this.brandTrendFilesUploadPostUpdate=this.fileHanldingService.populateLocalFileinList(this.brand.brandTrendFiles);
     }
    /*****File Handling Ends */ 

    getEnterCount(str:string,max:number){
       let rowCount=str.split(/\r\n|\r|\n/).length;
       if(rowCount>max){
          return max;
       }else{
          return rowCount;
       }
    }

    sendPnlChangeEmail(){
       this.showEmailEditor=true;
       let emailConfig: EmailTemplateConfig = new EmailTemplateConfig;
       emailConfig.brandId=this.brand.id;
       emailConfig.licensorId=this.brand.licensorId;
       emailConfig.templateName='brandPNLChangeEmail';
       if(this.selectedPnlId){
         emailConfig.selectedAttachmentIds.push(this.selectedPnlId);
       }
       this.composeEmail=new ComposeEmail;
       this.getEmailTemplate(emailConfig);
       //this.composeEmail.emailAttachments=this.sharedService.productAttachmentToEmailAttachment(this.product.approvalDocs.filter(x=>x.isSelected));
    }
    getEmailTemplate(emailConfig:EmailTemplateConfig){
      this.composeEmailService.getEmailTemplate(emailConfig).subscribe(response => {
         let composeEmailTemp:ComposeEmail = response.responsePayload;
         this.composeEmail.emailContent=composeEmailTemp.emailContent;
         this.composeEmail.emailTo=composeEmailTemp.emailTo;
         this.composeEmail.emailCc=composeEmailTemp.emailCc;
         this.composeEmail.emailBcc=composeEmailTemp.emailBcc;
         this.composeEmail.emailSubject=composeEmailTemp.emailSubject;
         this.composeEmail.emailTemplateConfig=emailConfig;
         document.getElementById('composeEmailToggler').click();
      });
   }

   defaultPnlSelected(pnlAtt:ProductAttachment){
      if(pnlAtt && pnlAtt.id && this.brand.pnls && this.brand.pnls.length>0){
         this.brand.pnls.forEach(att => {
            att.liked=false;
         });
         pnlAtt.liked=true;
         pnlAtt.sortOrder=1;
         this.brand.defaultPnlId=pnlAtt.id;
      }
   }

   defaultPnlSelectedImage(pnlAtt:ProductAttachment){
      console.log('defaultPnlSelectedImage');
      if(pnlAtt && pnlAtt.id && this.brand.pnls && this.brand.pnls.length>0){
         pnlAtt.sortOrder=0;
         let counter=1;
         this.brand.pnls.forEach(att => {
           
            if(att.liked){
              att.sortOrder=1;
            }else{
               if(att!==pnlAtt){
               ++counter;
               att.sortOrder=counter;
               }
            }
         });
         
      }
      console.log('caling sort');
     // this.setSortOrderForPNLs(false);
   }

   addNewPnlSPeciality(){
      if(!this.brand.brandPnlSpecialities){
         this.brand.brandPnlSpecialities=[];
      }
      this.brand.brandPnlSpecialities.push(new BrandPnlSpeciality(this.brand.licensorId,this.brand.id));
   }
}

