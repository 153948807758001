export class DashletDetails {
   id:string;
   idList:string[]=[];
   objectList:any[]=[];
   iconType:string='';
   state:string='';
   title:string='';
   footerDesc:string;
   chart: any;
	chartData: any;
   chartType: string;
   tableHeaders: any;
   tableRows: any;
   landingPath:string;
   detailDisplayType:string;
   resultCount:number
   type:string;
   focused:boolean=false;
   configuration:any;
   columns:string[]=[];
}
