<ng-container *ngIf="dashletDetails.configuration && dashletDetails.configuration.filters">
    <div class="p-2 ml-2">
    <ng-container *ngFor="let filter of dashletDetails.configuration.filters; index as filterIndex">
        <!--Multi Dropdown Starts-->
            <ng-container *ngIf="filter.filterType==='multi-dropdown'">
                <div class="d-flex flex-column">
                   
                    <div class="">
                        <mat-form-field>
                            <mat-label class="f-12">{{filter.title}}</mat-label>
                       <mat-select placeholder="" multiple class="f-10" [(ngModel)]="filter.selections"
                        name="filterSelection_{{filterIndex}}" #filterSelectionVar="ngModel" (selectionChange)="filterSelectionChange(filter,$event);">
                          <mat-option *ngFor="let filterOptions of filter.filterOptions"  [value]="filterOptions.value"><span class="f-10">{{ filterOptions.display }}</span></mat-option>
                       </mat-select>
                       </mat-form-field>
                       
                    </div>
                   
                 </div>  
            </ng-container>
        <!--Multi Dropdown Starts-->
    </ng-container>
    
    
</div>
<!--<div class="row">
    <div class="col-2 ml-auto px-2 py-1">
        <button class="btn btn-sm btn-light f-10" (click)="run()">Filter</button>
    </div>
</div>-->

</ng-container>