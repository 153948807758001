import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SearchComponent } from 'src/app/common/search/search.component';
import { SearchDetails } from 'src/app/shared/model/common/search-details.model';
import { FilterConfig, SearchFilter } from 'src/app/shared/model/common/search-filter.model';
import { UserAccess } from 'src/app/shared/model/user-access.model';
import { User } from 'src/app/shared/model/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { SearchService } from 'src/app/shared/service/search.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/service/shared.service';
import { SearchCriteriaBuilder } from 'src/app/shared/model/common/search-criteria-builder.model';
import { WmsService } from '../service/wms.service';
import { ConsolidatedInventoryReport } from '../model/consolidated-inventory-report.model';
import { InventoryItemLinksView } from '../model/inventory-item-links-view.model';
import { ProductMetadataService } from 'src/app/shared/service/product-metadata.service';

@Component({
   selector: 'app-consolidated-inventory-report',
   templateUrl: './consolidated-inventory-report.component.html',
   styleUrls: ['./consolidated-inventory-report.component.css']
})
export class ConsolidatedInventoryReportComponent implements OnInit {

   constructor(private authService: AuthService, private searchService: SearchService, private sharedService: SharedService,
      public wmsService: WmsService, private toastrService: ToastrService, private productMetadataService: ProductMetadataService) { }

   @ViewChild('searchComponent') searchComponent: SearchComponent<InventoryItemLinksView>;

   userAccessSubscription: Subscription;
   userAccess: UserAccess;

   currentLoggedInUserSubscription: Subscription;
   currentLoggedInUser: User;

   searchConfig: SearchDetails;

   selectedWmsClientId: string = 'ALL';
   selectedItemTypeSearch: string = 'FINISHED_PRODUCTS';
   selectedInventoryCriteria:string = 'EXCLUDE_MAJORS';
   defaultSelectedReportViewFormat: string = 'TABBED_VIEW';
   selectedCategory: string = 'ALL';
   selectedGarment: string = 'ALL';
   showImage: boolean = false;
   garmentList = [];
   categoryList = [];

   consolidatedInventoryReportList: ConsolidatedInventoryReport[] = [];

   sortColumns = [
      { columnName: 'LAST_MODIFIED_DATE', sortByName: 'Last Modified Date' },
      { columnName: 'VENDOR_NAME', sortByName: 'Vendor Name' }
   ];


   ngOnInit(): void {
      this.handleSubscriptions();
      this.searchConfig = this.searchService.consolidatedInventoryReportSearchDetails;
      this.setSeachConfig();
      this.setFilterConfigs();
   }


   handleSubscriptions() {
      this.userAccessSubscription = this.authService.userAccess.subscribe(access => {
         this.userAccess = access;
      });
      this.currentLoggedInUserSubscription = this.authService.currentUser.subscribe(currentLoggedInUser => {
         this.currentLoggedInUser = currentLoggedInUser;
      });

      this.productMetadataService.garmentListObservable.subscribe(res => {
         this.garmentList.length = 0;
         let arrayWithNameAndValue = res.map(ele => ({ name: ele }));
         this.garmentList.push(...arrayWithNameAndValue);
         //console.log("Subscribed Garment List",this.garmentList);
      });

      this.productMetadataService.categoryListObservable.subscribe(res => {
         this.categoryList.length = 0;
         let arrayWithNameAndValue = res.map(ele => ({ name: ele }));
         this.categoryList.push(...arrayWithNameAndValue);
         //console.log("Subscribed Category List",this.categoryList);
      });
   }


   setSeachConfig() {
      let criteria: SearchCriteriaBuilder = this.searchConfig.searchCriteria;
      criteria.loadAll = true;
      criteria.loadMore = false;
      criteria.loadedCount = 0;

      if (!criteria.searchSubType) {
         criteria.searchSubType = this.defaultSelectedReportViewFormat;
      }

      //console.log('criteria.filterIndicatorList >>>>>>>>> ', criteria.filterIndicatorList);
      if (!criteria.filterIndicatorList || criteria.filterIndicatorList.length === 0) {
         this.setNewFilterIndicatorList(criteria);
      } else {
         this.setFilterIndicatorSelections();
      }

      //console.log('criteria.filterInList <<<<<<<<<<<<<< ', criteria.filterInList);
      if (!criteria.filterInList || criteria.filterInList.length === 0) {

      } else {
         this.setFilterInSelections();
      }

      this.searchConfig.searchCriteria = criteria;
   }


   setNewFilterIndicatorList(criteria: SearchCriteriaBuilder) {
      let filterIndicatorList: SearchFilter[] = [];
      let wmsClientIdIndicatorFilter: SearchFilter = new SearchFilter('WMS_CLIENTID', 'String', ['ALL']);
      let showImageIndicatorFilter: SearchFilter = new SearchFilter('SHOW_IMAGE', 'String', ['NO']);
      let itemTypeIndicatorFilter: SearchFilter = new SearchFilter('ITEM_TYPE_SEARCH', 'String', [this.selectedItemTypeSearch]);
      let inventoryCriteriaFilter:SearchFilter = new SearchFilter('INVENTORY_CRITERIA','String',[this.selectedInventoryCriteria]);

      filterIndicatorList.push(wmsClientIdIndicatorFilter);
      filterIndicatorList.push(showImageIndicatorFilter);
      filterIndicatorList.push(itemTypeIndicatorFilter);
      filterIndicatorList.push(inventoryCriteriaFilter);

      criteria.filterIndicatorList = filterIndicatorList;
   }


   private setFilterInSelections() {
      let filterInList: SearchFilter[] = this.searchConfig.searchCriteria.filterInList;
      if (filterInList) {
         let wmsClientIdFilter: SearchFilter = this.searchConfig.getExistingFilter(filterInList, 'CLIENTID');
         if (wmsClientIdFilter) {
            if (wmsClientIdFilter && wmsClientIdFilter.values && wmsClientIdFilter.values.length === 0) {

               this.selectedWmsClientId = 'ALL';
            } else {
               this.selectedWmsClientId = wmsClientIdFilter.values[0];
            }
         } else {
            this.selectedWmsClientId = 'ALL';
         }
      }
   }

   private setFilterIndicatorSelections() {
      let filterIndicatorList: SearchFilter[] = this.searchConfig.searchCriteria.filterIndicatorList;
      //console.log('Existing Indicator Filter List == ', filterIndicatorList);
      if (filterIndicatorList) {

         // ITEM TYPE
         let itemTypeFilter: SearchFilter = this.searchConfig.getExistingFilter(filterIndicatorList, 'ITEM_TYPE_SEARCH');
         if (itemTypeFilter) {
            if (itemTypeFilter && itemTypeFilter.values && itemTypeFilter.values.length === 0) {
               this.selectedItemTypeSearch = 'ALL';
            } else {
               this.selectedItemTypeSearch = itemTypeFilter.values[0];
            }
         } else {
            this.selectedItemTypeSearch = 'ALL';
         }

         // INVENTORY CRITERIA
         let inventoryCriteriaFilter: SearchFilter = this.searchConfig.getExistingFilter(filterIndicatorList, 'INVENTORY_CRITERIA');
         if (inventoryCriteriaFilter) {
            if (inventoryCriteriaFilter && inventoryCriteriaFilter.values && inventoryCriteriaFilter.values.length === 0) {
               this.selectedInventoryCriteria = 'EXCLUDE_MAJORS';
            } else {
               this.selectedInventoryCriteria = inventoryCriteriaFilter.values[0];
            }
         } else {
            this.selectedInventoryCriteria = 'EXCLUDE_MAJORS';
         }

         // WMS Client Id
         let wmsClientIdFilter: SearchFilter = this.searchConfig.getExistingFilter(filterIndicatorList, 'WMS_CLIENTID');
         if (wmsClientIdFilter) {
            if (wmsClientIdFilter && wmsClientIdFilter.values && wmsClientIdFilter.values.length === 0) {
               this.selectedWmsClientId = 'ALL';
            } else {
               this.selectedWmsClientId = wmsClientIdFilter.values[0];
            }
         } else {
            this.selectedWmsClientId = 'ALL';
         }

         // Show Image 
         let showImageFiter: SearchFilter = this.searchConfig.getExistingFilter(filterIndicatorList, 'SHOW_IMAGE');
         if (showImageFiter) {
            if (showImageFiter && showImageFiter.values && showImageFiter.values.length === 0) {
               this.showImage = false;
            } else {
               this.showImage = showImageFiter.values[0] === "YES" ? true : false;
            }
         } else {
            this.showImage = false;
         }
      }
   }


   setFilterConfigs() {
      /*
      if (!this.searchConfig.filterConfigs) {
         this.searchConfig.filterConfigs = [];
         let garmentFilterConfig: FilterConfig = {
            filterType: 'IN_CHECKLIST',
            label: 'Garment',
            currentSelection: 'ALL',
            options: this.garmentList,
            optionDislpayVar: 'name',
            optionValueVar: 'name',
            fieldName: 'garment'
         };
         this.searchConfig.filterConfigs.push(garmentFilterConfig);

         let categoryFilterConfig: FilterConfig = {
            filterType: 'IN_CHECKLIST',
            label: 'Category',
            currentSelection: 'ALL',
            options: this.categoryList,
            optionDislpayVar: 'name',
            optionValueVar: 'name',
            fieldName: 'category'
         };
         this.searchConfig.filterConfigs.push(categoryFilterConfig);
      }
      */
   }

   search() {
      this.selectAllSinglePageReportChecked=false;
      
      if (this.searchComponent) {
         this.searchComponent.search();
         this.currentSortColumnIndex = undefined;
      }
   }

   onToggleImage() {
      this.consolidatedInventoryReportList = [];
      let valueToSet = "NO";
      if (this.showImage) {
         valueToSet = "YES"
      }
      this.searchConfig.setFilterIndicatorList('SHOW_IMAGE', 'String', [valueToSet]);
      this.search();
   }

   onWmsClientChange() {
      if ('ALL' === this.selectedWmsClientId) {
         this.searchConfig.setFilterInList('CLIENTID', 'String', []);
      } else {
         this.searchConfig.setFilterInList('CLIENTID', 'String', [this.selectedWmsClientId]);
      }
      this.search();
   }


   onItemTypeSearchChange() {
      this.searchConfig.setFilterIndicatorList('ITEM_TYPE_SEARCH', 'String', [this.selectedItemTypeSearch]);
      this.search();
   }

   onInventoryCriteriaSearchChange() {
      this.searchConfig.setFilterIndicatorList('INVENTORY_CRITERIA', 'String', [this.selectedInventoryCriteria]);
      this.search();
   }


   onReportViewFormatChange() {
      if(this.searchConfig.searchCriteria.searchSubType==='TABBED_VIEW' && this.selectedInventoryCriteria==='ONLY_MAJORS'){
         this.selectedInventoryCriteria='EXCLUDE_MAJORS';
         this.onInventoryCriteriaSearchChange();
      }else{
         this.search();
      }
   }


   onCategoryChange() {
      if ('ALL' === this.selectedCategory) {
         this.searchConfig.setFilterInList('CATEGORY', 'String', []);
      } else {
         this.searchConfig.setFilterInList('CATEGORY', 'String', [this.selectedCategory]);
      }
      this.search();
   }

   onGarmentChange() {
      if ('ALL' === this.selectedGarment) {
         this.searchConfig.setFilterInList('GARMENT', 'String', []);
      } else {
         this.searchConfig.setFilterInList('GARMENT', 'String', [this.selectedGarment]);
      }
      this.search();
   }


/*********************************************** START: ROW SELECTION  *********************************************** */

   selectAllSinglePageReportChecked: boolean = false;

   selectAllSinglePageReport(dataRows: any) {
      this.selectAllSinglePageReportChecked = !this.selectAllSinglePageReportChecked;
      if (dataRows && dataRows.length > 0) {
         dataRows.forEach(rowData => {
            if (this.selectAllSinglePageReportChecked) {
               rowData[rowData.length - 1] = "true";
            } else {
               rowData[rowData.length - 1] = "false";
            }
         });
      }
   }

   onRowSelectSinglePageReport(rowDataSelected: any) {
      if (rowDataSelected[rowDataSelected.length - 1] === "true") {
         rowDataSelected[rowDataSelected.length - 1] = "false";
      } else {
         rowDataSelected[rowDataSelected.length - 1] = "true";
      }
   }

   isRowSelectedSinglePageReport(rowData: any): boolean{
      if (rowData[rowData.length - 1] === "true") {
         return true;
      }else{
         return false;
      }
   }

   onRowSelectTabbedReport(inventoryItemLinksView: InventoryItemLinksView){
      inventoryItemLinksView.selected = !inventoryItemLinksView.selected;
   }

   selectAllTabbedReport(cir:ConsolidatedInventoryReport){
      cir.selected = !cir.selected;
      cir.inventoryItemLinksViewList.forEach(item => {
         item.selected = cir.selected;
      });
   }

   /*********************************************** END: ROW SELECTION  *********************************************** */

   /*********************************************** START: EXCEL DOWNLOAD *********************************************** */

   createExcel() {
      if (this.searchConfig.searchCriteria.searchSubType === 'SINGLE_PAGE_VIEW') {
         this.createSinglePageExcel(this.showImage);
      } else {
         this.createTabbedExcel(this.showImage)
      }
   }

   createTabbedExcel(addImage: boolean) {
      this.wmsService.createConsolidateInventoryTabbedExcelReport(addImage, this.consolidatedInventoryReportList).subscribe(res => {
         if (res && res !== null) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '_' + mm + '_' + yyyy;
            const filename = 'Consolidated_Inventory_Tabbed_Report-' + todayStr + ".xlsx"
            this.toastrService.success("Consolidated Inventory Tabbed Excel Exported Successfully.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Consolidated Inventory Tabbed Excel Export Failed.");
         }
      }, err => {
         this.toastrService.error("Consolidated Inventory Tabbed Excel Export Failed. Error - " + err);
      });
   }


   createSinglePageExcel(addImage: boolean) {
      this.wmsService.createConsolidateInventorySinglePageExcelReport(addImage, this.consolidatedInventoryReportList[0]).subscribe(res => {
         if (res && res !== null) {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = today.getFullYear();
            const todayStr = dd + '_' + mm + '_' + yyyy;
            const filename = 'Consolidated_Inventory_Single_Page_Report-' + todayStr + ".xlsx"
            this.toastrService.success("Consolidated Inventory Single Page Excel Exported Successfully.");
            this.sharedService.downLoadFile(res, "application/vnd.ms-excel", filename);
         } else {
            this.toastrService.error("Consolidated Inventory Single Page Excel Export Failed.");
         }
      }, err => {
         this.toastrService.error("Consolidated Inventory Single Page Excel Export Failed. Error - " + err);
      });
   }

   /*********************************************** END: EXCEL DOWNLOAD *********************************************** */

   filteredResultList: any[] = [];
   originalResultList: any[] = [];
   currentSortColumnIndex: number;
   currentSortMode: string;

   sortListByPropertyName(colIndex: number) {
      this.consolidatedInventoryReportList[0]?.rows;
      let sortMode = 'ASC';
      if (this.currentSortColumnIndex === colIndex) {
         if (this.currentSortMode === 'ASC') {
            sortMode = 'DESC'
         }
      }
      this.currentSortColumnIndex = colIndex;
      this.currentSortMode = sortMode;
      this.sortComplexListByPropertyName(this.currentSortColumnIndex, this.consolidatedInventoryReportList[0]?.rows, sortMode);
   }

   sortComplexListByPropertyName(colIndex: number, list: any, mode?: string): [] {
      if (list && list != null) {
         //console.log('Sorting');
         let sortedList: [] = list.sort(function (a1, b1) {

            if (true) {
               const propertyValue = a1[colIndex];
               const propertyType = a1[colIndex];
               //console.log('Sorting Property Type: ', propertyType, propertyValue);
               if (propertyType === 'Date') {
                  const dateA: Date = new Date(a1[colIndex]);
                  const dateB: Date = new Date(b1[colIndex]);
                  //console.log('Compaere Dates: ', dateA, dateB, dateA.getTime() > dateB.getTime(), dateA.getTime() < dateB.getTime(), mode);
                  if (mode && mode === 'DESC') {
                     //console.log('Sorting', mode);
                     if (dateA === null && dateB === null) return 0;
                     else if (dateA === null) return -1; // Place null values at the end
                     else if (dateB === null) return 1; // Place null values at the end
                     else return (dateB.getTime() - dateA.getTime());
                  } else {
                     //console.log('Sorting', mode);
                     if (dateA === null && dateB === null) return 0;
                     else if (dateA === null) return 1; // Place null values at the end
                     else if (dateB === null) return -1; // Place null values at the end
                     else return (dateA.getTime() - dateB.getTime());
                  }
                  // return (dateA.getTime() > dateB.getTime());
               }
               else if (typeof propertyValue === 'string') {
                  var textA = a1[colIndex];
                  var textB = b1[colIndex];
                  if (!textA) { textA = null };
                  if (!textB) { textB = null };
                  if (mode && mode === 'DESC') {
                     if (textA === null || textA === undefined) return 1; // Place null/undefined values at the end
                     if (textB === null || textB === undefined) return -1; // Place null/undefined values at the end
                     return textB.localeCompare(textA);
                  } else {
                     if (textA === null || textA === undefined) return -1; // Place null/undefined values at the beginning
                     if (textB === null || textB === undefined) return 1; // Place null/undefined values at the beginning
                     return textA.localeCompare(textB);
                  }
                  // return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
               }

               else if (typeof propertyValue === 'number') {
                  const numA = (a1[colIndex]);
                  const numB = (b1[colIndex]);

                  if (mode && mode === 'DESC') {
                     return (numA > numB) ? -1 : (numA < numB) ? 1 : 0;
                  } else {
                     return (numA < numB) ? -1 : (numA > numB) ? 1 : 0;
                  }
               }
            }
         });
         //console.log('sortedList', sortedList)
         return sortedList;
      }
      return list;
   }

}
