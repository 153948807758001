import { SearchFilter } from "./search-filter.model";

export class SearchCriteriaBuilder {
    searchString: string;
    searchType: string;
    loadAll: boolean;
    searchSubType: string;
    loadMore: boolean;
    loadedCount: number;
    totalCount: number;
    loadMoreCount: number;
    previousSearchQuery: string;
    searchResult = [];
    selectedSortByName:string;
    sortMode: string='ASC';
    filterInList: SearchFilter[] = [];
    filterNotInList: SearchFilter[] = [];
    filterLikeInListWithAnd: SearchFilter[] = [];
    filterLikeInListWithOr: SearchFilter[] = [];
    //filterCustomDateRange: SearchFilter[] = [];
    filterDateRange:SearchFilter[] = [];
    filterIndicatorList:SearchFilter[] = [];
    groupBy: string[] = [];
    constructor(searchType:string){
        this.searchType=searchType;
    }
}
