import { Role } from './role.model';

export class User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userName: string;
    password: string;
    confirmpassword: string;
    phone: string;
    userRoles: Role[];
    lastLoginDate: Date;
    active: boolean;
    resetPasswordToken : string;
    createdBy: string;
    updatedBy: string;
    isUserRegistered: boolean;
    fullName:string;
    orgType:string;
    orgId:string;
    landingPath:string;
}
